import React from 'react';

const EffortSummary = ({ data, formatCurrency }) => {
  return (
    <div className="TotlU mt-32">
      <ul>
        <li className="text-black">
          {data.expectedHours} hours <span className="d-block">Expected Total</span>
        </li>
        <li className="text-dblue">
          {formatCurrency(data.expectedCost)} <span className="d-block">Expected Total cost</span>
        </li>
        <li className="text-green">
          {formatCurrency(data.overheadCost)} <span className="d-block">Overhead Cost</span>
        </li>
        <li className="text-red">
          {formatCurrency(data.clientCost)} <span className="d-block">Client Cost</span>
        </li>
        <li className="text-green">
          {data.profitPercentage}% <span className="d-block">Expected Profit %</span>
        </li>
        <li className="text-dblue">
          <span className="d-flex align-items-center justify-content-center gap-10">
            <img src="/assets/images/profit-icon.svg" className="img-fluid" alt="Profit Icon" /> {formatCurrency(data.expectedProfit)}
          </span>
          <span className="d-block">Expected Profit</span>
        </li>
      </ul>
    </div>
  );
};

export default EffortSummary