import React, { useEffect, useState } from 'react'
import LineGraph from '../LineGraph'
import BarGraph from '../BarGraph'
import DatePickerComponent from 'src/components/DatePickerComponent';

const SaleConversion = ({ data, bdm }) => {
    const [salesPerson, setSalesPerson] = useState("");
    const [dateSaleForcast, setDateSaleForcast] = useState([])

    const processData = (values) => {
        // Set the start and end dates based on dateSaleForcast
        const [startDate, endDate] = dateSaleForcast && dateSaleForcast.length === 2
            ? [new Date(dateSaleForcast[0]), new Date(dateSaleForcast[1])]
            : [null, null];

        // Filter the data based on date range and sales source
        const filteredData = values.filter(item => {
            const resTypeDate = new Date(item.res_type_date);
            const isDateInRange = !startDate || !endDate || (resTypeDate >= startDate && resTypeDate <= endDate);
            const isSourceMatch = salesPerson
                ? salesPerson === item.bde_id.toString()
                : true;

            return isDateInRange && isSourceMatch;
        });


        const result2 = {};
        filteredData.forEach(item => {
            if (item.expectedAmt && item.expectedDate) {
                const year = new Date(item.expectedDate).getFullYear();

                if (!result2[year]) {
                    result2[year] = 0;
                }

                result2[year] += item.expectedAmt;
            }
        });

        // Group data by year and status, while summing expectedAmt and count for each res_type
        const result = filteredData.reduce((acc, item) => {
            const year = new Date(item.res_type_date).getFullYear();
            const status = item.res_type === 6 ? 'Dead' : item.res_type === 9 ? 'Won' : 'unknown';
            const key = `${year}-${status}`;

            // Initialize if key doesn't exist
            if (!acc[key]) {
                acc[key] = {
                    year,
                    status,
                    expectedAmt: 0,
                    count: 0
                };
            }

            // Accumulate values
            acc[key].expectedAmt += item.expectedAmt || 0;
            acc[key].count += item.amount || 0;

            return acc;
        }, {});

        // Prepare the final result array for Dead, Won, and Expected
        const finalResult = ['Expected', 'Won', 'Dead'].map(status => ({
            name: status,
            data: []
        }));

        // Populate the final result with grouped data
        Object.values(result).forEach(item => {
            const statusIndex = finalResult.findIndex(group => group.name === item.status);
            if (statusIndex >= 0) {
                // const dataPoint = item.status === 'Expected'
                //     ? [item.year, item.expectedAmt]
                //     : [item.year, item.count];
                finalResult[statusIndex].data.push([item.year, item.count]);
                // finalResult[0].data.push([item.year, item.expectedAmt]);
            }
        });
        finalResult[0].data = Object.entries(result2).map(([year, sum]) => [parseInt(year), sum]);
        return finalResult;
    }

    const handleDateRangeChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            setDateSaleForcast(selectedDates)
        } else {
            setDateSaleForcast([])
        }
    }

    return (
        <div className="card-body p-24 mt-2">
            <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                    Sale Conversion
                </h6>
                <div className="d-flex align-items-center  gap-28">
                    <div className="form-check checked-primary d-flex align-items-center gap-2">
                        <select className="form-control" onChange={(e) => setSalesPerson(e.target.value)}>
                            <option value="">ALL BDM</option>
                            {bdm.map((row, index) => <option key={index} value={row.employeeID}>{row.fullName}</option>)}

                        </select>
                    </div>
                    <div className="position-relative">
                        {/* <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                            <iconify-icon
                                icon="solar:calendar-linear"
                                className="icon text-lg"
                            ></iconify-icon>
                        </span> */}
                        <DatePickerComponent onDateRangeChange={handleDateRangeChange} />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap align-items-center gap-1 justify-content-end my-16 salestabChart">
                <ul
                    className="nav border-gradient-tab nav-pills mb-0"
                    id="pills-tab"
                    role="tablist"
                >
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link d-flex align-items-center gap-2 active btn-primary-600 radius-6 px-14 py-6 text-sm"
                            id="chartlineTwo"
                            data-bs-toggle="pill"
                            data-bs-target="#chartviewLineTwo"
                            type="button"
                            role="tab"
                            aria-controls="chartviewLineTwo"
                            aria-selected="true"
                        >
                            <svg
                                width={18}
                                height={18}
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.72325 2.26572C2.596 2.26796 2.4748 2.32047 2.38613 2.41178C2.29747 2.50309 2.24854 2.62576 2.25003 2.75302V15.276C2.24954 15.4038 2.29982 15.5265 2.3898 15.6171C2.47978 15.7078 2.60209 15.7591 2.72984 15.7596H15.2575C15.3217 15.761 15.3855 15.7496 15.4451 15.726C15.5048 15.7025 15.5592 15.6672 15.6051 15.6224C15.651 15.5775 15.6874 15.5239 15.7123 15.4647C15.7372 15.4056 15.75 15.3421 15.75 15.2779C15.75 15.2137 15.7372 15.1502 15.7123 15.0911C15.6874 15.0319 15.651 14.9783 15.6051 14.9334C15.5592 14.8886 15.5048 14.8533 15.4451 14.8298C15.3855 14.8062 15.3217 14.7948 15.2575 14.7962H3.21435V2.75302C3.21511 2.6885 3.2029 2.62451 3.17844 2.56479C3.15398 2.50507 3.11778 2.45085 3.07197 2.40539C3.02617 2.35994 2.97169 2.32416 2.91179 2.30016C2.85189 2.27617 2.78777 2.26446 2.72325 2.26572ZM15.2462 5.39763C15.1214 5.4016 15.003 5.4539 14.916 5.54347L9.97305 10.4864L7.90423 8.21064C7.81828 8.11653 7.69853 8.0603 7.57122 8.05429C7.44391 8.04829 7.3194 8.09298 7.22497 8.17858L4.3339 10.8279C4.28719 10.8706 4.24936 10.9222 4.22256 10.9795C4.19576 11.0369 4.18053 11.099 4.17773 11.1622C4.17493 11.2255 4.18461 11.2886 4.20624 11.3482C4.22786 11.4077 4.26099 11.4624 4.30374 11.5091C4.34649 11.5558 4.39802 11.5936 4.4554 11.6204C4.51277 11.6472 4.57485 11.6624 4.63811 11.6652C4.70137 11.668 4.76455 11.6583 4.82406 11.6366C4.88357 11.615 4.93823 11.5819 4.98493 11.5391L7.52038 9.2172L9.60049 11.5071C9.64422 11.5553 9.69727 11.5942 9.75643 11.6213C9.81559 11.6485 9.87963 11.6633 9.94469 11.665C10.0098 11.6667 10.0745 11.6552 10.135 11.6312C10.1955 11.6072 10.2505 11.5712 10.2967 11.5253L15.5953 6.22396C15.6641 6.15624 15.711 6.06938 15.7299 5.97467C15.7488 5.87995 15.7388 5.78176 15.7011 5.69282C15.6635 5.60387 15.6 5.52827 15.5189 5.47586C15.4378 5.42345 15.3428 5.39538 15.2462 5.39763Z"
                                    fill="#7B7E8B"
                                />
                            </svg>
                            Line View
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link d-flex align-items-center gap-2 btn-primary-600 radius-6 px-14 py-6 text-sm"
                            id="chartBarTwo"
                            data-bs-toggle="pill"
                            data-bs-target="#chartviewBarTwo"
                            type="button"
                            role="tab"
                            aria-controls="chartviewBarTwo"
                            aria-selected="false"
                            tabIndex={-1}
                        >
                            <svg
                                width={18}
                                height={18}
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.8138 14.6406C2.73991 14.6403 2.6667 14.6546 2.59832 14.6826C2.52994 14.7106 2.46775 14.7518 2.41531 14.8039C2.36287 14.856 2.32119 14.9178 2.29267 14.986C2.26415 15.0542 2.24934 15.1273 2.24909 15.2012C2.2488 15.2754 2.26319 15.349 2.29147 15.4176C2.31975 15.4863 2.36134 15.5486 2.41384 15.6011C2.46633 15.6536 2.52872 15.6952 2.59736 15.7235C2.66601 15.7518 2.73956 15.7662 2.8138 15.7659H15.1877C15.2616 15.7656 15.3347 15.7508 15.4029 15.7223C15.471 15.6938 15.5329 15.6521 15.585 15.5997C15.637 15.5472 15.6782 15.485 15.7063 15.4167C15.7343 15.3483 15.7485 15.2751 15.7483 15.2012C15.7477 15.0527 15.6885 14.9104 15.5835 14.8054C15.4785 14.7004 15.3362 14.6411 15.1877 14.6406H2.8138Z"
                                    fill="#7B7E8B"
                                />
                                <path
                                    d="M5.06432 6.75782C4.13901 6.75782 3.37433 7.52247 3.37433 8.44781V15.1953C3.37404 15.2696 3.38846 15.3431 3.41673 15.4118C3.44501 15.4804 3.48658 15.5428 3.53908 15.5953C3.59157 15.6478 3.65395 15.6894 3.72259 15.7176C3.79124 15.7459 3.86481 15.7603 3.93905 15.76C4.01294 15.7598 4.08605 15.745 4.15422 15.7164C4.22239 15.6879 4.28426 15.6463 4.33632 15.5938C4.38838 15.5414 4.4296 15.4792 4.45762 15.4108C4.48563 15.3424 4.49992 15.2692 4.49963 15.1953V8.44781C4.49963 8.13046 4.747 7.88309 5.06432 7.88309H6.75016V15.1953C6.74987 15.2692 6.76414 15.3424 6.79216 15.4108C6.82017 15.4792 6.86139 15.5414 6.91345 15.5938C6.9655 15.6462 7.02738 15.6879 7.09555 15.7164C7.16372 15.745 7.23683 15.7598 7.31072 15.76C7.38497 15.7603 7.45853 15.7459 7.52718 15.7176C7.59583 15.6894 7.65819 15.6478 7.71069 15.5953C7.76319 15.5428 7.80478 15.4804 7.83306 15.4118C7.86133 15.3431 7.87573 15.2696 7.87544 15.1953V7.32253C7.87572 7.24829 7.86132 7.17473 7.83304 7.10609C7.80476 7.03745 7.76317 6.97507 7.71068 6.92258C7.65818 6.87008 7.59581 6.82851 7.52716 6.80023C7.45852 6.77195 7.38496 6.75753 7.31072 6.75782H5.06432Z"
                                    fill="#7B7E8B"
                                />
                                <path
                                    d="M8.43603 2.26562C7.51069 2.26562 6.75019 3.02615 6.75019 3.95146V15.2001C6.7499 15.274 6.76416 15.3472 6.79218 15.4156C6.8202 15.4839 6.86142 15.5461 6.91347 15.5986C6.96553 15.651 7.02741 15.6927 7.09558 15.7212C7.16374 15.7497 7.23686 15.7645 7.31075 15.7648C7.38499 15.7651 7.45856 15.7507 7.5272 15.7224C7.59585 15.6941 7.65822 15.6525 7.71072 15.6C7.76322 15.5475 7.8048 15.4852 7.83308 15.4165C7.86136 15.3479 7.87576 15.2743 7.87546 15.2001V3.95146C7.87546 3.63416 8.1187 3.3909 8.43603 3.3909H9.56132C9.87862 3.3909 10.126 3.63416 10.126 3.95146V15.2001C10.1257 15.274 10.14 15.3472 10.168 15.4156C10.196 15.4839 10.2372 15.5461 10.2893 15.5986C10.3414 15.651 10.4032 15.6927 10.4714 15.7212C10.5396 15.7497 10.6127 15.7645 10.6866 15.7648C10.7608 15.7651 10.8344 15.7507 10.903 15.7224C10.9717 15.6941 11.034 15.6525 11.0865 15.6C11.139 15.5475 11.1806 15.4852 11.2089 15.4165C11.2372 15.3479 11.2516 15.2743 11.2513 15.2001V3.95146C11.2513 3.02615 10.4866 2.26562 9.56132 2.26562H8.43603Z"
                                    fill="#7B7E8B"
                                />
                                <path
                                    d="M10.6866 10.6953C10.5381 10.6958 10.3958 10.7551 10.2908 10.8601C10.1858 10.9651 10.1266 11.1074 10.126 11.2559V15.1923C10.1258 15.2662 10.14 15.3394 10.168 15.4078C10.1961 15.4761 10.2373 15.5383 10.2893 15.5908C10.3414 15.6432 10.4033 15.6849 10.4714 15.7134C10.5396 15.7419 10.6127 15.7567 10.6866 15.757C10.7608 15.7573 10.8344 15.7429 10.9031 15.7146C10.9717 15.6863 11.0341 15.6447 11.0866 15.5922C11.1391 15.5397 11.1807 15.4774 11.2089 15.4087C11.2372 15.3401 11.2516 15.2665 11.2513 15.1923V11.8206H12.9372C13.2545 11.8206 13.5019 12.0638 13.5019 12.3812V15.1923C13.5016 15.2662 13.5158 15.3394 13.5439 15.4078C13.5719 15.4761 13.6131 15.5383 13.6652 15.5908C13.7172 15.6432 13.7791 15.6849 13.8473 15.7134C13.9154 15.7419 13.9885 15.7567 14.0624 15.757C14.1363 15.7567 14.2094 15.7419 14.2776 15.7134C14.3458 15.6849 14.4076 15.6432 14.4597 15.5908C14.5117 15.5383 14.553 15.4761 14.581 15.4078C14.609 15.3394 14.6233 15.2662 14.623 15.1923V12.3812C14.623 11.4558 13.8625 10.6953 12.9372 10.6953H10.6866Z"
                                    fill="#7B7E8B"
                                />
                            </svg>
                            Bar View
                        </button>
                    </li>
                </ul>
            </div>
            <br />
            <div className="tab-content" id="pills-tabContent">
                <div
                    className="tab-pane fade show active"
                    id="chartviewLineTwo"
                    role="tabpanel"
                    aria-labelledby="chartlineTwo"
                    tabIndex={0}
                >
                    <div className="mt-2 allchartsCss">
                        <LineGraph series={processData(data)} />
                    </div>
                </div>
                <div
                    className="tab-pane fade"
                    id="chartviewBarTwo"
                    role="tabpanel"
                    aria-labelledby="chartBarTwo"
                    tabIndex={0}
                >
                    {/* start Chart  */}
                    <div className="mt-2 allchartsCss">
                        <BarGraph series={processData(data)} />
                    </div>
                    {/* End Chart  */}
                </div>
            </div>
        </div>
    )
}

export default SaleConversion