import { useEffect, useState } from "react";

const getInitials = (name) => {
  if (!name) return "";
  const nameParts = name.trim().split(" ");
  return nameParts.length > 1
    ? nameParts[0][0].toUpperCase() + nameParts[1][0].toUpperCase()
    : nameParts[0][0].toUpperCase();
};

const getRandomColor = (name) => {
  const colors = ["#FF5733", "#33A1FF", "#FFC300", "#8D33FF", "#33FF57"];
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  return colors[Math.abs(hash) % colors.length];
};

const ReviewedBy = () => {
  const [reviewers, setReviewers] = useState([]);

  useEffect(() => {
    const testReviewers = [
      {
        name: "Danish Saifi",
        designation: "Project Coordinator",
        image: "/assets/images/avatar/avatar1.png",
        date: "25 February, 2025",
        count: 356,
        status: "Approved",
      },
      {
        name: "Amit Kumar",
        designation: "Project Coordinator",
        image: "",
        date: "26 February, 2025",
        count: 412,
        status: "Rejected",
      },
      {
        name: "Rahul Verma",
        designation: "Project Coordinator",
        image: "",
        date: "27 February, 2025",
        count: 198,
        status: "Rejected",
      },
    ];

    setReviewers(testReviewers);
  }, []);

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      "[data-bs-toggle='tooltip']"
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, []);

  return (
    <div
      className="modal fade cusmodl ReviewedByModal"
      id="ReviewedByModal"
      tabIndex={-1}
      aria-labelledby="ReviewedByModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-between reviewsummaryTxbg pb-0">
            <div className="d-flex justify-content-start gap-3 reviewsummaryTx">
              <span className="img">
                <img
                  src="/assets/images/production-review-summary.svg"
                  className="img-fluid"
                  alt="Production Review"
                />
              </span>
              <div className="text">
                <h5 className="modal-title" id="ReviewedByModalLabel">
                  Production Review Summary
                </h5>
                <p>
                  Keep track of all project-related production reviews in one
                  place. View approval status, reviewer details, and production
                  units for better workflow management.
                </p>
              </div>
            </div>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              ✗
            </button>
          </div>
          <div className="modal-body">
            <table className="table basic-border-table mb-0 table-hover tbodrNone">
              <thead>
                <tr>
                  <th>Reviewed by</th>
                  <th className="text-end">Date</th>
                  <th className="text-end">Count</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody>
                {reviewers.length > 0 ? (
                  reviewers.map((reviewer, index) => (
                    <tr key={index}>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span
                            className="img imgsSet me-0"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-custom-class="tooltip-dark"
                            data-bs-title={reviewer.name}
                          >
                            {reviewer.image ? (
                              <img
                                src={reviewer.image}
                                className="img-fluid"
                                alt={reviewer.name}
                              />
                            ) : (
                              <span
                                className="initials d-flex justify-content-center align-items-center w-100 h-100"
                                style={{
                                  backgroundColor: getRandomColor(reviewer.name),
                                  color: "#fff",
                                  borderRadius: "50%",
                                  width: "40px",
                                  height: "40px",
                                }}
                              >
                                {getInitials(reviewer.name)}
                              </span>
                            )}
                          </span>
                          <div className="flex-grow-1">
                            <div className="text-md mb-0 fw-medium text-black name">
                              {reviewer.name}
                            </div>
                            <span className="text-sm text-secondary-light fw-medium designation">{reviewer.designation}</span>
                          </div>
                        </div>
                      </td>
                      <td className="text-end">{reviewer.date}</td>
                      <td className="text-end">{reviewer.count}</td>
                      <td className={`text-center ${reviewer.status === "Approved" ? "text-green" : "text-red"}`}>
                        {reviewer.status}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No reviews found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewedBy;
