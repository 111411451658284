import React, { useEffect, useRef, useState } from "react";
import { ExportReactCSV } from "src/components/ExportReactCSV";
import { costFormat } from "src/utils/CommonUtils";
import SummaryInvoiceFilter from "./SummaryInvoiceFilter";
const getInitials = (name) => {
    if (!name) return '';
    const cleanName = name.replace(/\(.*?\)/g, '').trim();
    const words = cleanName.split(' ');
    const firstNameInitial = words[0]?.charAt(0).toUpperCase();
    const lastNameInitial = words[1]?.charAt(0).toUpperCase();
    return `${firstNameInitial}${lastNameInitial || ''}`;
};
const SummaryInvoiceTable = ({ onClick, item }) => {

    const [filterArr, setFilterArr] = useState({});
    // Transform and group by projectId
    const groupedData = (status, data) =>
        (data || [])
            .filter(invoice => {
                if (!ageValue1) return true; // If no filtering value, include all
                if (selectedOption === 'lessThan') return invoice?.selectDays <= ageValue1;
                if (selectedOption === 'moreThan') return invoice?.selectDays >= ageValue1;
                if (selectedOption === 'between' && ageValue2) return invoice?.selectDays >= ageValue1 && invoice?.selectDays <= ageValue2;
                return true; // Default case if no matching condition
            })
            .reduce((result, invoice) => {
                const { projectId, dueDate, clientId, projectCode, ClientRegisteredName, subTotal, totalCostInr, currency } = invoice;

                const subtotalValue = parseFloat(subTotal.$numberDecimal || 0);
                const netAmount = parseFloat(totalCostInr.$numberDecimal || 0);
                const gst = currency !== "₹" ? 0 : netAmount - subtotalValue;

                // Parse the dueDate string into a Date object
                const dueDateObj = new Date(dueDate);
                const currentDate = new Date();
                const isDue = dueDateObj <= currentDate; // Check if dueDate is in the past or today

                // Check if the projectId already exists in the result array
                const existingProject = result.find((item) => item?.projectId === projectId);

                if (existingProject) {
                    // Update existing project details
                    existingProject.noOfItems += 1;
                    existingProject.totalAmount += currency !== "₹" ? 0 : subtotalValue;
                    existingProject.totalNetAmount += netAmount;
                    existingProject.totalGst += currency === "₹" ? gst : 0;
                    existingProject.totalTds += 0;

                    if (status === "Pending") {
                        if (isDue) {
                            existingProject.dueAmount += subtotalValue;
                            existingProject.dueNetAmount += netAmount;
                            existingProject.dueGst += currency === "₹" ? gst : 0;
                            existingProject.dueTds += 0;
                        } else {
                            existingProject.nonDueAmount += subtotalValue;
                            existingProject.nonDueNetAmount += netAmount;
                            existingProject.nonDueGst += currency === "₹" ? gst : 0;
                            existingProject.nonDueTds += 0;
                        }
                    }
                } else {
                    // Add a new project entry
                    const newProject = {
                        noOfItems: 1,
                        // dueDate,
                        projectCode,
                        projectId,
                        clientId,
                        ClientRegisteredName,
                        // currency,
                        totalAmount: subtotalValue,
                        totalNetAmount: netAmount,
                        totalGst: gst,
                        totalTds: 0,
                    };

                    // Add due and non-due totals only if status is not "Pending"
                    if (item?.status === "Pending") {
                        newProject.dueAmount = isDue ? subtotalValue : 0;
                        newProject.dueNetAmount = isDue ? netAmount : 0;
                        newProject.dueGst = isDue ? gst : 0;
                        newProject.dueTds = 0;
                        newProject.nonDueAmount = !isDue ? subtotalValue : 0;
                        newProject.nonDueNetAmount = !isDue ? netAmount : 0;
                        newProject.nonDueGst = !isDue ? gst : 0;
                        newProject.nonDueTds = 0;
                    }

                    result.push(newProject);
                }
                return result;
            }, []);

    const calculateAverageDays = () => {
        const data = item?.groupOrg[0]?.data || []
        if (!data || data.length === 0) return 0;

        // Apply the filtering conditions to the data
        const filteredData = data.filter(item => {
            return (
                // Filter by projectCode
                (filterArr?.projectCode ? item?.projectId === filterArr?.projectCode : true) &&

                // Filter by clientName
                (filterArr?.clientName ? item?.clientId === filterArr?.clientName : true) &&

                // Filter by netStartAmount
                (filterArr?.netStartAmount ? item?.totalCostInr?.$numberDecimal >= filterArr?.netStartAmount : true) &&

                // Filter by netEndAmount
                (filterArr?.netEndAmount ? item?.totalCostInr?.$numberDecimal <= filterArr?.netEndAmount : true)
            );
        });

        if (filteredData.length === 0) return 0;  // If no data after filtering, return 0

        const totalDays = filteredData.reduce((sum, item) => {
            const invoiceDate = new Date(item?.invoiceDate);
            const paidDate = new Date(item?.paidDate);

            // Calculate the difference in milliseconds and convert to days
            const diffInDays = (paidDate - invoiceDate) / (1000 * 60 * 60 * 24);
            return sum + diffInDays;
        }, 0);

        // Compute the average and return it
        return (totalDays / filteredData.length).toFixed(2);
    };


    const [data, setData] = useState([])
    useEffect(() => {
        setData(groupedData(item?.status, item?.groupOrg[0]?.data));
    }, [item]);
    const totalNetAmountSum = data.reduce((sum, item) => sum + item?.totalNetAmount, 0);

    const [selectedOption, setSelectedOption] = useState(null);
    const [isDestashowVisible, setIsDestashowVisible] = useState(false);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setAgeValue1("")
        setAgeValue2("")
        setTriggerAge((prev) => !prev);
        setIsDestashowVisible(true); // Show destashow when an option is clicked
    };

    const handleClose = () => {
        setIsDestashowVisible(false); // Hide destashow when close or check is clicked
        setAgeValue1("")
        setAgeValue2("")
        setTriggerAge((prev) => !prev);
    };

    // Table Scroll Code Start
    const elementRef = useRef(null);
    const [isScrollable, setIsScrollable] = useState(false);
    const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

    useEffect(() => {
        const handleScroll = () => {
            // Clear the previous timeout to prevent multiple updates
            clearTimeout(debounceTimeoutRef.current);

            debounceTimeoutRef.current = setTimeout(() => {
                if (elementRef.current) {
                    const { top, bottom } = elementRef.current.getBoundingClientRect();
                    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

                    // Check if the table <thead> is within the viewport range
                    const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
                    setIsScrollable(shouldScroll);
                }
            }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
            clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
        };
    }, []);
    // Table Scroll Code End

    // Handle filter change
    const handleFilterChange = (updatedFilters) => {
        setFilterArr(updatedFilters);
    };

    // Function to filter data based on filterArr
    const filterData = (data, filterArr) => {
        // If filterArr is empty, return the full data without filtering
        if (!filterArr || Object.keys(filterArr).length === 0) {
            return data;
        }

        return data.filter(item => {
            return (
                (filterArr?.projectCode ? item?.projectId === filterArr?.projectCode : true)
                && (filterArr?.clientName ? item?.clientId === filterArr?.clientName : true)
                && (filterArr?.netStartAmount ? item?.totalNetAmount >= filterArr?.netStartAmount : true)
                && (filterArr?.netEndAmount ? item?.totalNetAmount <= filterArr?.netEndAmount : true)
            );
        });
    };

    const [ageValue1, setAgeValue1] = useState("");
    const [ageValue2, setAgeValue2] = useState("");
    const [triggerAge, setTriggerAge] = useState(false);

    const handleInvAge = () => {
        setTriggerAge((prev) => !prev);
        // Perform actions with inputValue
    };
    // Update the data whenever filterArr changes
    useEffect(() => {
        const filteredData = filterData(groupedData(item?.status, item?.groupOrg[0]?.data), filterArr);
        setData(filteredData);  // Update state with filtered data
    }, [filterArr, triggerAge]);  // Re-run when filterArr changes

    return (
        <>

            <button className="d-flex align-items-center gap-2 btn rounded-pill text-primary-light radius-8 pe-20 text-xl py-11" onClick={onClick}><i className="mdi mdi-arrow-left mdi-24px"></i> Invoice summary</button>
            <div className="d-flex align-items-center justify-content-between gap-4 mt-16">
                <div className="d-flex align-items-center justify-content-between gap-4">

                    <div className="dropdown">
                        <button
                            className="btn btn-primary-600 bg-primary-50 border-primary-50 text-primary-600 hover-text-primary not-active px-18 py-9 text-sm dropdown-toggle toggle-icon"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Invoice Age
                        </button>
                        <ul className="dropdown-menu border">
                            <li>
                                <a
                                    className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 d-flex align-items-center gap-2"
                                    href="#" onClick={(e) => e.preventDefault()}
                                    onClick={() => handleOptionClick("moreThan")}
                                >
                                    <span className="w-28-px text-center me-6">
                                        <i className="mdi mdi-chevron-right"></i>
                                    </span>
                                    More than
                                </a>
                            </li>
                            <li>
                                <a
                                    className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 d-flex align-items-center gap-2"
                                    href="#" onClick={(e) => e.preventDefault()}
                                    onClick={() => handleOptionClick("between")}
                                >
                                    <span className="w-28-px text-center me-6">
                                        <i className="mdi mdi-format-horizontal-align-left"></i>
                                        <i className="mdi mdi-format-horizontal-align-right"></i>
                                    </span>
                                    Between
                                </a>
                            </li>
                            <li>
                                <a
                                    className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900 d-flex align-items-center gap-2"
                                    href="#" onClick={(e) => e.preventDefault()}
                                    onClick={() => handleOptionClick("lessThan")}
                                >
                                    <span className="w-28-px text-center me-6">
                                        <i className="mdi mdi-chevron-left"></i>
                                    </span>
                                    Less than
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Conditionally render the input fields based on the selected option and visibility */}
                    {isDestashowVisible && (
                        <div className="d-flex align-items-center gap-2 destashow">
                            {selectedOption === "moreThan" && (
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="Number"
                                        value={ageValue1}
                                        onChange={(e) => setAgeValue1(e.target.value)}
                                        className="border text-center w-64-px form-control px-6"
                                    />
                                    <button className="checkDv" onClick={handleInvAge}>
                                        <i className="mdi mdi-check mdi-24px"></i>
                                    </button>
                                    <button className="closeDv" onClick={handleClose}>
                                        <i className="mdi mdi-close mdi-24px"></i>
                                    </button>
                                </div>
                            )}

                            {selectedOption === "between" && (
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="Number"
                                        value={ageValue1}
                                        onChange={(e) => setAgeValue1(e.target.value)}
                                        className="border text-center w-64-px form-control px-6"
                                    />
                                    <span>To</span>
                                    <input
                                        type="Number"
                                        value={ageValue2}
                                        onChange={(e) => setAgeValue2(e.target.value)}
                                        className="border text-center w-64-px form-control px-6"
                                    />
                                    <button className="checkDv" onClick={handleInvAge}>
                                        <i className="mdi mdi-check mdi-24px"></i>
                                    </button>
                                    <button className="closeDv" onClick={handleClose}>
                                        <i className="mdi mdi-close mdi-24px"></i>
                                    </button>
                                </div>
                            )}

                            {selectedOption === "lessThan" && (
                                <div className="d-flex align-items-center gap-2">
                                    <input
                                        type="Number"
                                        value={ageValue1}
                                        onChange={(e) => setAgeValue1(e.target.value)}
                                        className="border text-center w-64-px form-control  px-6"
                                    />
                                    <button className="checkDv" onClick={handleInvAge}>
                                        <i className="mdi mdi-check mdi-24px"></i>
                                    </button>
                                    <button className="closeDv" onClick={handleClose}>
                                        <i className="mdi mdi-close mdi-24px"></i>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="FilterBtn d-flex gap-2">
                        <button
                            type="button"
                            className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#SummaryInvoiceFilter"
                            aria-controls="SummaryInvoiceFilter"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2em"
                                height="1.5em"
                                viewBox="0 0 24 24"
                            >
                                <path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z" />
                            </svg>{" "}
                            Filter
                        </button>
                    </div>

                </div>
                <div className="d-flex align-items-center gap-4">
                    {item?.status === 'Paid' && (
                        <div className="d-flex align-items-center gap-2 text-xl fw-semibold"><span className="text-neutral-400 fw-normal">AverageTime</span> {calculateAverageDays()} Days</div>
                    )}
                    <div className="d-flex align-items-center gap-2 text-xl fw-semibold"><span className="text-neutral-400 fw-normal">Net Amount</span> {costFormat(totalNetAmountSum)}</div>
                </div>
            </div>

            <div className="d-flex justify-content-between align-items-center pt-24 pb-16">
                <h3 className="card-title">Pending Invoice Details</h3>

                <ExportReactCSV csvData={data} fileName={"ClientInvoiceList-" + new Date().toLocaleDateString()} />


            </div>
            <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
                <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                    <thead>
                        <tr>
                            <th rowSpan={2}>S.No.</th>
                            <th colSpan={1} rowSpan={2}>Project Details</th>
                            <th colSpan={1} rowSpan={2}>Client Name</th>
                            <th colSpan={1} rowSpan={2}>No. of Inv.</th>
                            <th className="text-center" colSpan={4}>Total</th>
                            {item?.status === "Pending" && (
                                <>
                                    <th className="text-center" colSpan={4}>Due</th>
                                    <th className="text-center" colSpan={4}>Non-Due</th>
                                </>
                            )}
                        </tr>
                        <tr>
                            <th>Amount</th>
                            <th>GST</th>
                            <th>TDS</th>
                            <th>Net Amount</th>
                            {item?.status === "Pending" && (
                                <>
                                    <th>Amount</th>
                                    <th>GST</th>
                                    <th>TDS</th>
                                    <th>Net Amount</th>
                                    <th>Amount</th>
                                    <th>GST</th>
                                    <th>TDS</th>
                                    <th>Net Amount</th>
                                </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.projectCode}</td>
                                {/* <td>{row.clientName}</td> */}
                                <td><div className="user-cell mxwset200">
                                    <span className="user-initials" style={{
                                        minWidth: '30px',
                                        maxWidth: '30px',
                                    }}>{getInitials(row.ClientRegisteredName)}</span> {row.ClientRegisteredName}
                                </div></td>
                                <td className="text-center">{row.noOfItems}</td>
                                <td className="text-end">{costFormat(row.totalAmount)}</td>
                                <td className="text-end">{costFormat(row.totalGst)}</td>
                                <td className="text-end">{costFormat(row.totalTds)}</td>
                                <td className="text-end">{costFormat(row.totalNetAmount)}</td>
                                {item?.status === "Pending" && (
                                    <>
                                        <td className="text-end text-dblue">{costFormat(row.dueAmount)}</td>
                                        <td className="text-end">{costFormat(row.dueGst)}</td>
                                        <td className="text-end">{costFormat(row.dueTds)}</td>
                                        <td className="text-end text-green">{costFormat(row.dueNetAmount)}</td>
                                        <td className="text-end">{costFormat(row.nonDueAmount)}</td>
                                        <td className="text-end">{costFormat(row.nonDueGst)}</td>
                                        <td className="text-end">{costFormat(row.nonDueTds)}</td>
                                        <td className="text-end text-green">{costFormat(row.nonDueNetAmount)}</td>
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <SummaryInvoiceFilter item={item?.groupOrg[0]?.data} onFilterChange={handleFilterChange} />
        </>
    );
};

export default SummaryInvoiceTable