import React from 'react'

const CancelModal = () => {
  return (
    <div>
       <div
        className="modal fade pcdModal producSecMAin cusmodl"
        id="cancelModal"
        tabIndex={-1}
        aria-labelledby="cancelModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-body pb-0 px-36">
            <h2 className="fw-semibold mb-16 font-24 text-center">Are you sure to Cancel invoice?</h2>
            <div className="col-md-12">
  <label className="form-label">Reason <span>*</span></label>
  <textarea 
    name="#0" rows={5}
    className="form-control w-100"
    placeholder="Write reason here..."
    required=""
  />
  <div className="invalid-feedback">Please provide email address.</div>
</div>
<div className="py-20 position-sticky bottom-0 z-2 d-flex justify-content-between align-items-center gap-16">
                <button
                  type="button"
                  className="btn btn-primary-600 radius-8 px-20 py-16 w-100"
                 
                >
                 Yes
                </button>
                <button
                  type="button" data-bs-dismiss="modal"
                  className="btn btn-outline-neutral-700 radius-8 px-20 py-16 w-100"
                
                >
                  No
                </button>
              </div>

            </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default CancelModal
