import React, { useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import BarGraph from "../BarGraph";
import { getMonthRange, shortMonthName } from "src/utils/CommonUtils";
import DatePickerComponent from "src/components/DatePickerComponent";

const ExceptedRevenue = ({ exceptedRevenueData }) => {
    const [filteredData, setFilteredData] = useState({ expected: [], revenue: [] });
    const [selectedVertical, setSelectedVertical] = useState("Total");
    const [dateRange, setDateRange] = useState(getMonthRange());

    const extractMonthYear = (date) => {
        const d = new Date(date);
        return { month: d.getMonth() + 1, year: d.getFullYear() };
    };

    const filterData = (data, statusKey, dateKey) =>
        data.filter(({ status, [dateKey]: date }) => {
            if (!date || status !== statusKey) return false;
            const { month, year } = extractMonthYear(date);
            return (
                (year > dateRange.start.year || (year === dateRange.start.year && month >= dateRange.start.month)) &&
                (year < dateRange.end.year || (year === dateRange.end.year && month <= dateRange.end.month))
            );
        });

    useEffect(() => {
        setFilteredData({
            expected: filterData(exceptedRevenueData, "Pending", "dueDate"),
            revenue: filterData(exceptedRevenueData, "Paid", "paidDate"),
        });
    }, [exceptedRevenueData, dateRange]);

    const groupAndSortInvoices = useMemo(() => (data, statusKey) => {
        const grouped = data
            .filter((item) => selectedVertical === "Total" || item.vertical === selectedVertical)
            .reduce((acc, { status, dueDate, paidDate, totalCostInr }) => {
                const dateKey = status === "Pending" ? dueDate : paidDate;
                const month = dayjs(dateKey).format("MMM");
                const year = dayjs(dateKey).format("YYYY");
                const key = `${month} ${year}`;
                const amount = Math.round(parseFloat(totalCostInr.$numberDecimal) || 0);

                if (status === statusKey) acc[key] = (acc[key] || 0) + amount;
                return acc;
            }, {});

        return Object.entries(grouped)
            .map(([name, y]) => ({ name, y }))
            .sort((a, b) => {
                const [aMonth, aYear] = a.name.split(" ");
                const [bMonth, bYear] = b.name.split(" ");
                return Number(aYear) - Number(bYear) || shortMonthName(aMonth) - shortMonthName(bMonth);
            });
    }, [filteredData, selectedVertical]);

    const expectedInvoices = useMemo(() => groupAndSortInvoices(filteredData.expected, "Pending"), [filteredData.expected, selectedVertical]);
    const revenueInvoices = useMemo(() => groupAndSortInvoices(filteredData.revenue, "Paid"), [filteredData.revenue, selectedVertical]);

    // Merge all dates from the three datasets
    const allDates = [...revenueInvoices, ...expectedInvoices].map(item => item.name);

    if (!allDates.length) {
        return;
    }

    // Get min and max date dynamically
    const minDate = dayjs(Math.min(...allDates.map(date => dayjs(date, "MMM YYYY").valueOf())));
    const maxDate = dayjs(Math.max(...allDates.map(date => dayjs(date, "MMM YYYY").valueOf())));

    // Generate month range
    const allMonths = [];
    for (let d = minDate; d.isBefore(maxDate) || d.isSame(maxDate); d = d.add(1, "month")) {
        allMonths.push(d.format("MMM YYYY"));
    }

    // Fill missing months
    const fillMissingMonths = (data) =>
        allMonths.map(month => {
            const existing = data.find(item => item.name === month);
            return existing ? { ...existing, visible: true } : { name: month, y: 0, visible: false };
        });
    const updatedRevenueInvoices = fillMissingMonths(revenueInvoices);
    const updatedExpectedInvoices = fillMissingMonths(expectedInvoices);

    const handleDateRangeChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            setDateRange({
                start: extractMonthYear(selectedDates[0]),
                end: extractMonthYear(selectedDates[1]),
            });
        } else {
            setDateRange(getMonthRange());
        }
    };

    return (
        <>
            <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">Expected VS Revenue</h6>
                <div className="d-flex align-items-center gap-28">
                    {["Total", "Acadecraft Government", "Acadecraft Private"].map((label) => (
                        <div key={label} className="form-check checked-primary d-flex align-items-center gap-2">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="ExceptedRevenue"
                                id={label}
                                checked={selectedVertical === label}
                                onChange={() => setSelectedVertical(label)}
                            />
                            <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor={label}>
                                {label}
                            </label>
                        </div>
                    ))}
                    <div className="position-relative">
                        <DatePickerComponent onDateRangeChange={handleDateRangeChange} />
                    </div>
                </div>
            </div>
            <br />
            <div className="mt-2 allchartsCss">
                <BarGraph series={[{ name: "Revenue", data: updatedRevenueInvoices }, { name: "Expected", data: updatedExpectedInvoices }]} />
            </div>
        </>
    );
};

export default ExceptedRevenue;
