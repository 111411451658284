import React, { useEffect } from 'react'


// Sample data
const leaveData = [
    {
      id: 1,
      leaveType: "Casual",
      subject: "Half Day",
      startDate: "16 Oct 2024",
      endDate: "16 Oct 2024",
      comment: "Going to Lucknow for Dewali",
      duration: "0.5 Day",
      addedLeaveDate: "16 Oct 2024",
      status: "Approved",
    },
    {
      id: 2,
      leaveType: "Sick Leave",
      subject: "Full Day",
      startDate: "16 Oct 2024",
      endDate: "16 Oct 2024",
      comment: "Going to Lucknow for Dewali",
      duration: "1 Day",
      addedLeaveDate: "16 Oct 2024",
      status: "Pending",
    },
    {
      id: 3,
      leaveType: "Other",
      subject: "Full Day",
      startDate: "16 Oct 2024",
      endDate: "16 Oct 2024",
      comment: "Going to Lucknow for Dewali",
      duration: "2 Days",
      addedLeaveDate: "16 Oct 2024",
      status: "Pending",
    },
  ];
  

const LeaveDetailsPopup = () => { 

     // Initialize tooltips when data or columns change
     useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
        return () => {
            tooltipList.forEach(tooltip => tooltip.dispose());
        };
    }, []); // Reinitialize tooltips when either data or columns change


  return (
    <>   
      <div
  className="modal fade cusmodl LeaveDetailsModal"
  id="LeaveDetailsModal"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel"
  style={{ display: "none" }}
  aria-hidden="true"
>
  <div className="modal-dialog modal-xl modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header d-flex justify-content-between">
        <h5 className="modal-title" id="exampleModalLabel">
        Leave Details 
        </h5>
        <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              ✗ 
        </button>
      </div>
      <div className="modal-body">
        <div className="d-flex border align-items-center">
        <div className="badge text-sm fw-semibold px-16 py-9 rounded-0 d-flex align-items-center gap-2 text-neutral-400 border-end w-100 justify-content-center"><span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle bg-primary-700  text-white">5</span>Total Leave </div>
        <div className="badge text-sm fw-semibold px-16 py-9 rounded-0 d-flex align-items-center gap-2 text-neutral-400 border-end w-100 justify-content-center">Casual Leave  <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle bg-success-700  text-white">0.5</span></div>
        <div className="badge text-sm fw-semibold px-16 py-9 rounded-0 d-flex align-items-center gap-2 text-neutral-400 border-end w-100 justify-content-center">Sick Leave  <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle bg-neutral-300  text-white">0</span></div>
        <div className="badge text-sm fw-semibold px-16 py-9 rounded-0 d-flex align-items-center gap-2 text-neutral-400 w-100 justify-content-center">Other Leave  <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle bg-violet-600  text-white">5</span></div>
        </div>

        <div className="table-responsive mt-24">
      <table className="table basic-border-table mb-0 table-hover">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Leave Type</th>
            <th>Subject</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Comment</th>
            <th>Duration</th>
            <th className="text-center">Added Leave Date</th>
            <th className="text-center">Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {leaveData.map((leave, index) => (
            <tr key={leave.id}>
              <td>{index + 1}</td>
              <td><strong>{leave.leaveType}</strong></td>
              <td><div className={leave.subject === "Half Day" ? "text-violet-600" : "text-red"}>{leave.subject}</div></td>
              <td>{leave.startDate}</td>
              <td>{leave.endDate}</td>
              <td><div className='detaset mxwsetnotes' data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-custom-class="tooltip-primary"
                                                    data-bs-title={`${leave.comment}`}>{leave.comment}</div></td>
              <td><strong>{leave.duration}</strong></td>
              <td className="text-center">{leave.addedLeaveDate}</td>
              <td>
                {leave.status === "Approved" ? (
                  <span className="status approved badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle bg-success-600  text-white mx-auto">✔</span>
                ) : (
                  <span className="status pending badge text-sm fw-semibold text-warning-600 bg-warning-100 px-20 py-9 rounded-pill text-white d-flex align-items-center gap-2"><i className="mdi mdi-clock mdi-18px"></i> Pending</span>
                )}
              </td>
              <td>
                <button className={`action-btn badge text-sm fw-semibold bg-info-600 px-20 py-9 radius-4 text-white levcontext ${leave.status === "Approved" ? "disabled" : ""}`} disabled={leave.status === "Approved"}> 
                  Consider
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
}

export default LeaveDetailsPopup