import { useCallback, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getTaskByProjectFromAPI } from "src/api/UserApi"
import { productionAssignProjectIdAction } from "../actions/ProductionActions";
import TaskTree from "./TaskTree";

const SubProjectTree = ({ clientId, project }) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const userId = user?._id
  const [isOpen, setIsOpen] = useState(false)
  const [tasks, setTasks] = useState([])
  const dispatch = useDispatch();
  // const handleLoadTaskByProject=(project)=>{
  //     setIsOpen(!isOpen)
  //     console.log('load module by project',project)
  //     alert("load module by project")
  // }
  const handleLoadTaskByProject = useCallback(async (e, project) => {
    // userId
    e.stopPropagation(); // Prevent event propagation
    setIsOpen(!isOpen)
    const task = await getTaskByProjectFromAPI(project._id, userId)
    setTasks(task.data.data)
    dispatch(productionAssignProjectIdAction({
      data: {
        projectId: project._id,
        ProjectSubCode: project.ProjectSubCode
      }
    }));
  }, [tasks])
  return (
    <>
      <div
        id={`sub-collapseOne${clientId}`}
        className="accordion-collapse collapse"
        aria-labelledby="sub-headingOne"
        data-bs-parent="#sub-accordionExample"

      >
        <div className="accordion-body">
          <div
            className="accordion sub-accordion2 newCategory"
            id="sub-accordion2"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="sub-heading6">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#sub-collapse${project._id}`}
                  aria-expanded="true"
                  aria-controls="collapse6"
                  onClick={(e) => { handleLoadTaskByProject(e, project) }}
                >
                  {project.ProjectSubCode} {project.ProjectSubCodeDescription && `(${project.ProjectSubCodeDescription})`}
                </button>
              </h2>
              {
                tasks.map((task, taskIndex) => {
                  return (
                    <TaskTree task={task} projectId={project._id} key={taskIndex} />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SubProjectTree