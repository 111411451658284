import React, { useEffect, useState } from 'react'
import LineGraph from '../LineGraph';
import DatePickerComponent from 'src/components/DatePickerComponent';
import BarGraph from '../BarGraph';
import { fullMonthName } from 'src/utils/CommonUtils';

const SalaryExpenditure = ({ salary }) => {

    const [salaryDepartmentData, setSalaryDepartmentData] = useState([])
    const [salaryCompanyData, setSalaryCompanyData] = useState([])
    const [selectedSalaryCompanyDepartment, setSelectedSalaryCompanyDepartment] = useState('Total')
    useEffect(() => {
        if (salary.length > 0) {
            setSalaryDepartmentData([...salary]);
            setSalaryCompanyData([...salary]);
        }
    }, [salary]);  // Dependency on salary

    const handleDateRangeChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            const startDate = new Date(selectedDates[0]);
            const endDate = new Date(selectedDates[1]);
            const filteredData = [...salary].filter(({ salaryYear, salaryMonth }) => {
                const recordDate = new Date(salaryYear, salaryMonth - 1, 1); // Convert to Date object
                return recordDate >= startDate && recordDate <= endDate;
            });
            setSalaryDepartmentData(filteredData)
        } else {
            setSalaryDepartmentData([...salary])
        }
    }

    const handleSalaryCompanyDateRangeChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            const startDate = new Date(selectedDates[0]);
            const endDate = new Date(selectedDates[1]);
            const filteredData = [...salary].filter(({ salaryYear, salaryMonth }) => {
                const recordDate = new Date(salaryYear, salaryMonth - 1, 1); // Convert to Date object
                return recordDate >= startDate && recordDate <= endDate;
            });
            setSalaryCompanyData(filteredData)
        } else {
            setSalaryCompanyData([...salary])
        }
    }

    const seriesSalaryDepartment = Object.values(
        salaryDepartmentData.reduce((acc, { department, salaryYear, netSalary }) => {
            if (!acc[department]) {
                acc[department] = { name: department, data: [] };
            }

            const yearIndex = acc[department].data.findIndex(([year]) => year === salaryYear);
            if (yearIndex !== -1) {
                acc[department].data[yearIndex][1] = Math.round(acc[department].data[yearIndex][1] + netSalary);
            } else {
                acc[department].data.push([salaryYear, Math.round(netSalary)]);
            }

            return acc;
        }, {})
    ).map(dep => ({ ...dep, data: dep.data.sort((a, b) => a[0] - b[0]) })); // Sort years in ascending order


    // Group data by month and year
    const seriesSalaryCompany = Object.entries(
        salaryCompanyData
            .filter(({ department }) => {
                if (selectedSalaryCompanyDepartment === "Government Department") {
                    return department === "Government Department"; // Exact match with "Government Department"
                } else if (selectedSalaryCompanyDepartment === "Acadecraft") {
                    return department !== "Government Department"; // Exclude "Government Department"
                } else {
                    return true; // "Total" - includes all departments
                }
            })
            .reduce((acc, { salaryYear, salaryMonth, netSalary }) => {
                const key = `${fullMonthName(salaryMonth, true)} ${salaryYear}`;
                acc[key] = (acc[key] || 0) + Math.round(netSalary);
                return acc;
            }, {})
    ).map(([key, sum]) => [key, sum])
        .sort(([a], [b]) => new Date(a) - new Date(b));

    return (
        <>
            {/* Start Salary Expenditure by Company  Chat Sec  */}
            <div className="card mt-12">
                {/* ============== */}
                <div className="card-body p-24 mt-2">
                    <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                        <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                            Salary Expenditure by Company
                        </h6>
                        <div className="d-flex align-items-center  gap-28">
                            <div className="form-check checked-primary d-flex align-items-center gap-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="salaryChart"
                                    id="salaryChart1"
                                    value={selectedSalaryCompanyDepartment}
                                    checked={selectedSalaryCompanyDepartment === 'Total'}
                                    onChange={() => setSelectedSalaryCompanyDepartment('Total')}
                                />
                                <label
                                    className="form-check-label line-height-1 fw-medium text-secondary-light"
                                    htmlFor="salaryChart1"
                                >
                                    {" "}
                                    Total
                                </label>
                            </div>
                            <div className="form-check checked-primary d-flex align-items-center gap-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="salaryChart"
                                    id="salaryChart2"
                                    onChange={() => setSelectedSalaryCompanyDepartment('Government Department')}
                                />
                                <label
                                    className="form-check-label line-height-1 fw-medium text-secondary-light"
                                    htmlFor="salaryChart2"
                                >
                                    {" "}
                                    Acadecraft Government
                                </label>
                            </div>
                            <div className="form-check checked-primary d-flex align-items-center gap-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="salaryChart"
                                    id="salaryChart3"
                                    onChange={() => setSelectedSalaryCompanyDepartment('Acadecraft')}
                                />
                                <label
                                    className="form-check-label line-height-1 fw-medium text-secondary-light"
                                    htmlFor="salaryChart3"
                                >
                                    {" "}
                                    Acadecraft Private
                                </label>
                            </div>
                            <div className="position-relative">
                                <DatePickerComponent
                                    onDateRangeChange={handleSalaryCompanyDateRangeChange}
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                    {/* start Chart  */}
                    <div className="mt-2 allchartsCss">
                        <BarGraph series={[{ name: '', data: seriesSalaryCompany }]} />
                    </div>
                    {/* End Chart  */}
                </div>
                {/* ================== */}
            </div>
            {/* End Salary Expenditure by Company  Chat Sec  */}
            {/* Start Salary Department Chat sec    */}
            <div className="card mt-12">
                {/* ============== */}
                <div className="card-body p-24 mt-2">
                    <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                        <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                            Salary Expenditure by Department
                        </h6>
                        <div className="d-flex align-items-center  gap-28">
                            <div className="form-check checked-primary d-flex align-items-center gap-2">

                            </div>
                            <div className="datepker position-relative">
                                <DatePickerComponent
                                    onDateRangeChange={handleDateRangeChange}
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                    {/* start Chart  */}
                    <div className="mt-2 allchartsCss">
                        <LineGraph series={seriesSalaryDepartment} />
                    </div>
                    {/* End Chart  */}
                </div>
                {/* ================== */}
            </div>
            {/* End Salary Department Chat sec    */}
        </>
    )
}

export default SalaryExpenditure