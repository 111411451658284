import React, { useState } from 'react'
import TopTabs from 'src/containers/project-container/View/TopTabs'
import EffortSummary from './EffortSummary'
import EffortEstimateManage2 from './EffortEstimateManage2'

const EffortEstimateManageSave = () => {
  const [data, setData] = useState({
    expectedHours: 256,
    expectedCost: 269900,
    overheadCost: 269900,
    clientCost: 269900,
    profitPercentage: 26,
    expectedProfit: 269900,
  });

  const formatCurrency = (value) => {
    if (value >= 1000000000) {
      return `₹${(value / 1000000000).toFixed(1)}B`;
    } else if (value >= 1000000) {
      return `₹${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `₹${(value / 1000).toFixed(1)}K`;
    }
    return `₹${value}`;
  };



  return (
    <div>
      <div className="row gy-4 mt-1">
        <div className="col-md-12">
          <div className="card h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center border-bottom pb-16 position-relative z-3 pt-24 bg-white">
                <div>
                  <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">Delivery Plan</h2>
                  <ul className="d-flex align-items-center gap-2">
                    <li className="fw-medium">
                      <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                        <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                        Special Access
                      </a>
                    </li>
                    <li>-</li>
                    <li className="fw-medium">Delivery Plan</li>
                  </ul>
                </div>
              </div>
              <TopTabs />
              <EffortSummary data={data} formatCurrency={formatCurrency} />
              <EffortEstimateManage2 />

              <div className="d-flex justify-content-end align-items-center gap-16 mt-24">
                <button type="button" className="btn text-sm btn-outline-neutral-600 radius-4 px-20 py-11">
                  Request to admin for edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EffortEstimateManageSave
