import React, { useCallback, useState, useEffect, useRef } from 'react';
import CommentModal from './CommentModal';
import { AddProductionModal } from './AddProductionModal';
import GroupCounter from './GroupCounter';
import AttendanceCalendar from './AttendanceCalendar';
import ProductionList from './ProductionList';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleChangeDateAction, productionProjectAssigneeSearchAction, productionSearchAction } from './actions/ProductionActions';
import { getAssignedProjectFromAPI } from 'src/api/UserApi';
import FlatPickerMonth from 'src/components/FlatPickerMonth';
import MonthPicker from 'src/components/MonthPicker';
import { getOldEntries, hasOldEntries } from 'src/utils/utils';
import { useSticky } from 'src/hooks/useSticky';
import RejectionAccessModal from 'src/components/RejectionAccessModal';
import RejectionCommentModal from './RejectionCommentModal';
import ProductivityvsHubstaff from '../employee-container/Perfomance/ProductivityvsHubstaff';
import ReviewedBy from './ReviewedBy';

const ProductionContainer = () => {
  const { _id: userId, employeeID } = useSelector(state => state.auth?.user, shallowEqual);
  const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
  const { production } = useSelector((state) => state.DashboardReducer.pendingList, shallowEqual);
  const isProductionPendingForApproval = hasOldEntries(production)
  const isProductionPendingForApproval1 = getOldEntries(production)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [where, setWhere] = useState({
    userId,
    $expr: {
      $and: [
        { $eq: [{ $year: "$date" }, selectedYear] },
        { $eq: [{ $month: "$date" }, selectedMonth] }
      ]
    }
  });
  const [whereMySql, setWhereMySql] = useState({
    empid: employeeID,
    year: selectedYear,
    month: selectedMonth,
  });
  const [reAppliedId, setReAppliedId] = useState(null)
  const [reApplyPendingList, setReApplyPendingList] = useState([]);

  useEffect(() => {
    search(where);
    getAssignedProjects();
  }, [
    dispatch,
    where
  ]);
  const search = useCallback(() => {
    dispatch(productionSearchAction({
      where: where,
      whereMySql: whereMySql
    }));
  }, [dispatch, where, whereMySql])
  const getAssignedProjects = useCallback(async () => {
    // userId
    const projects = await getAssignedProjectFromAPI(userId)
    dispatch(productionProjectAssigneeSearchAction({
      data: projects.data.data
    }));
  }, [dispatch, where])
  const handleChangeDate = (date) => {
    const year = date.getFullYear();  // Example year
    const month = date.getMonth() + 1;  // Since month is 0-indexed in JavaScript, add 1
    setWhere({
      ...where,
      $expr: {
        $and: [
          { $eq: [{ $year: "$date" }, year] },
          { $eq: [{ $month: "$date" }, month] }
        ]
      }
    })
    setWhereMySql({
      ...whereMySql,
      year,
      month
    })
    dispatch(handleChangeDateAction({ data: { selectedMonth: month, selectedYear: year } }))
  }
  useEffect(() => {
    // Initialize Bootstrap tooltips for elements with data-bs-toggle="tooltip"
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    // Cleanup tooltips when the component unmounts
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  // const { isSticky, elementRef } = useSticky(); // Use the custom hook

  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [theadOffset, setTheadOffset] = useState(0);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom, height } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);

          // Adjust tbody margin-top smoothly
          if (shouldScroll) {
            setTheadOffset(height / 10); // Adjusting the offset dynamically
          } else {
            setTheadOffset(0);
          }
        }
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current);
    };
  }, []);
  // Table Scroll Code Start
  const isAfterTwoPM = new Date().toLocaleTimeString('en-US', { timeZone: 'Asia/Kolkata', hour12: false }) > '14:00:00';
  const handleButtonClick = () => {
    if (isProductionPendingForApproval) {
      navigate('/pending-production-list');
    }
  };



  // New code Table Scroll set

  const tableRef = useRef(null);
  const scrollBarRef = useRef(null);
  const thumbRef = useRef(null);
  const [thumbWidth, setThumbWidth] = useState(50);
  const [thumbLeft, setThumbLeft] = useState(0);

  useEffect(() => {
    updateThumbSize();
    window.addEventListener("resize", updateThumbSize);
    return () => window.removeEventListener("resize", updateThumbSize);
  }, []);

  const updateThumbSize = () => {
    const table = tableRef.current;
    const scrollbar = scrollBarRef.current;
    if (table && scrollbar) {
      const visibleRatio = table.clientWidth / table.scrollWidth;
      setThumbWidth(visibleRatio * scrollbar.clientWidth);
      setThumbLeft((table.scrollLeft / table.scrollWidth) * scrollbar.clientWidth);
    }
  };

  const handleTableScroll = () => {
    const table = tableRef.current;
    const scrollbar = scrollBarRef.current;
    if (table && scrollbar) {
      const scrollRatio = table.scrollLeft / (table.scrollWidth - table.clientWidth);
      setThumbLeft(scrollRatio * (scrollbar.clientWidth - thumbWidth));
    }
  };

  const handleThumbDrag = (event) => {
    event.preventDefault();
    const table = tableRef.current;
    const scrollbar = scrollBarRef.current;

    if (!table || !scrollbar) return;

    const startX = event.clientX;
    const startLeft = thumbLeft;

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const newLeft = Math.min(Math.max(startLeft + deltaX, 0), scrollbar.clientWidth - thumbWidth);
      const scrollRatio = newLeft / (scrollbar.clientWidth - thumbWidth);
      table.scrollLeft = scrollRatio * (table.scrollWidth - table.clientWidth);
      setThumbLeft(newLeft);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };
  // end

  // Function to receive data from the child
  const [addedProduction, setAddedProduction] = useState([])
  const handleDataFromChild = (data) => {
    setAddedProduction(data)
  };

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width={30}
                    height={30}
                  >
                    <g data-name="20-Arrow Left">
                      <path
                        fill="#0073ea"
                        d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
                      />
                      <path
                        fill="#0073ea"
                        d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
                      />
                    </g>
                  </svg>
                </button>{" "}
                My Production
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="/"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Home
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium"> My Production</li>
              </ul>

              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16 border-top border-bottom py-16 pt100">
                <button
                  // data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-custom-class="tooltip-dark"
                  data-bs-title={`${isProductionPendingForApproval ? "Production Pending For Approval" : "Upload Production"}`}
                  data-bs-toggle={`${isProductionPendingForApproval ? "tooltip" : "modal"}`}
                  onClick={handleButtonClick}
                  data-bs-target="#addProduction"
                  className="btn btn-primary text-sm btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"
                >
                  {isProductionPendingForApproval ? "Go to Approval Page" : "Upload Production"}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={22}
                    fill="currentColor"
                    className="bi bi-file-earmark-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z" />
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                  </svg>
                </button>
                <MonthPicker handleChangeDate={handleChangeDate} />
              </div>
              <GroupCounter />
              <AttendanceCalendar isRefresh={whereMySql} />

              <ProductivityvsHubstaff userId={userId} employeeID={employeeID} addedProduction={addedProduction} />
              <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 text-neutral-900">
                  My Production Details
                </h6>
              </div>
              <div className='posSetTable'>
                <div className={`columnFz table-body-scrollable ${isScrollable ? 'scrollable' : ''}`} ref={tableRef} onScroll={handleTableScroll} >
                  <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>


                    <thead>
                      <tr className="dnd-moved">
                        <th className="w30px dragablefalse" draggable="false">
                          <label className="form-check-label">S.No.</label>
                        </th>
                        <th>Date</th>
                        <th>Project Code</th>
                        <th>Task</th>
                        <th>Module</th>
                        <th>Target</th>
                        <th>Unit</th>
                        <th className="text-center">Production</th>
                        <th>Status</th>
                        <th>Reviewed By</th>
                        <th className="text-center">File</th>
                        <th className="text-center">Comment</th>
                        <th className="text-center">Approved</th>
                        <th className="text-center">Pending</th>
                        <th className="text-center">Rejected</th>
                        <th className="text-center">Rejection Reason</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <ProductionList setReApplyPendingList={setReApplyPendingList} setReAppliedId={setReAppliedId} />
                    </tbody>
                  </table>
                </div>
                <div className="custom-scroll" ref={scrollBarRef}>
                  <div
                    className="custom-scroll-thumb"
                    ref={thumbRef}
                    style={{ width: `${thumbWidth}px`, left: `${thumbLeft}px` }}
                    onMouseDown={handleThumbDrag}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      {/* Start Add Production Modal */}
      <AddProductionModal search={search} setReAppliedId={setReAppliedId} reAppliedId={reAppliedId} sendDataToParent={handleDataFromChild} />
      {/* End Add Production */}
      {/* Start Comment Modal */}
      <CommentModal />
      <ReviewedBy />
      <RejectionCommentModal />
      {reApplyPendingList?.length > 0 && <RejectionAccessModal reApplyPendingList={reApplyPendingList} />}
      {/* End Comment */}
    </>
  )
}

export default ProductionContainer