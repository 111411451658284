import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import config from "src/config";
import { toast } from "react-toastify";
import { postRequest } from "src/utils/RequestUtil";
import { useEffect, useState } from "react";

const MyForm = ({ sendDataToParent, editData }) => {
    const { projectId } = useParams();

    // Store initial values separately
    const defaultValues = {
        title: "",
        owner: "",
        email: "",
        url: "",
    };

    // Manage dynamic initial values
    const [initialValues, setInitialValues] = useState(defaultValues);

    // Update form when `editData` changes
    useEffect(() => {
        if (editData) {
            setInitialValues({
                title: editData?.title || "",
                owner: editData?.owner || "",
                email: editData?.email || "",
                url: editData?.url || "",
            });
        } else {
            setInitialValues(defaultValues); // Reset to empty when clicking "Add"
        }
    }, [editData]);

    console.log("initialValues", initialValues);


    const formik = useFormik({
        initialValues,
        enableReinitialize: true, // Allows Formik to detect changes in initialValues
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            owner: Yup.string().required("Owner name is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            url: Yup.string().url("Invalid URL").required("Link is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            values.projectId = projectId;
            if (editData?._id) {
                values._id = editData._id;
            }
            console.log("Form Data:", values);

            const save_url = `${config.baseUrl}report/saveResourceLinks`;
            try {
                const result2 = await postRequest({
                    url: save_url,
                    data: values,
                });

                sendDataToParent(result2?.data?.data || []);
                toast.success(result2.data.message);

                // Reset form with empty values
                resetForm({ values: defaultValues });

            } catch (error) {
                toast.error("Failed to submit form.");
            }
        },
    });

    return (
        <div className="modal fade ProjectRePopu" id="projectRes" tabIndex="-1" aria-labelledby="projectResLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header d-flex gap-12 justify-content-between">
                        <div className="d-flex justify-content-start gap-3 reviewsummaryTx">
                            <span className="img">
                                <img src="/assets/images/icons/project-resource.svg" className="img-fluid" alt="Production Review" />
                            </span>
                            <div className="text">
                                <h5 className="modal-title" id="ReviewedByModalLabel">
                                    Project Resource Links
                                </h5>
                                <p>Record and manage all important project-related links in one place.</p>
                            </div>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="grayParaSec">
                            <p>Add links for internal trackers, client trackers, Google Drive
                                folders, Google Sheets, and other essential documents. Ensure all
                                links are up-to-date for easy access and collaboration.</p>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="title"
                                    placeholder="Enter title"
                                    {...formik.getFieldProps("title")}
                                />
                                {formik.touched.title && formik.errors.title ? <div className="text-danger">{formik.errors.title}</div> : null}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Owner Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="owner"
                                    placeholder="Enter Owner Name"
                                    {...formik.getFieldProps("owner")}
                                />
                                {formik.touched.owner && formik.errors.owner ? <div className="text-danger">{formik.errors.owner}</div> : null}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Email address</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    placeholder="Enter Email address"
                                    {...formik.getFieldProps("email")}
                                />
                                {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Link</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="url"
                                    placeholder="eg. Drive, Spreadsheet, Google Docs etc. Link"
                                    {...formik.getFieldProps("url")}
                                />
                                {formik.touched.url && formik.errors.url ? <div className="text-danger">{formik.errors.url}</div> : null}
                            </div>

                            <button type="submit" className="btn btn-primary w-100 mt-16">
                                Save
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyForm;
