import config from 'src/config';
import { postRequest, putRequest } from 'src/utils/RequestUtil';


export const skillsSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectSkill/${projectId}`;
    return postRequest({
        url,
        data
    })
};
export const taskProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectTask/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const operationsReportAPI = function (data) {
    const url = `${config.baseUrl}report/operationsReports`;
    return postRequest({
        url,
        data,
    });
};

export const salesReports = function (data) {
    const url = `${config.baseUrl}report/salesReports`;
    return postRequest({
        url,
        data,
    });
};

export const accountsReports = function (data) {
    const url = `${config.baseUrl}report/accountsReports`;
    return postRequest({
        url,
        data,
    });
};
export const operationsNoWorkReportAPI = function (data) {
    const url = `${config.baseUrl}report/noWorkOperationsReports`;
    return postRequest({
        url,
        data,
    });
};

export const oadSalesReports = function (data) {
    const url = `${config.baseUrl}report/oadSalesReports`;
    return postRequest({
        url,
        data,
    });
};

export const individualProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectUsers/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const vendorDeliveryProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectVendorDelivery/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const moduleProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectModule/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const productionProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectProduction/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const profitandlossProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectProfitAndLoss/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const expendituresProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getExpenditures/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const projectResourceLinksAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/projectResourceLinksAPI/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const deleteResourceRequest = function ({
    itemId,
    data
}) {
    const url = `${config.baseUrl}report/deleteResourceRequest/${itemId}`;
    return postRequest({
        url,
        data
    })
};

export const scopesProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}report/getProjectScopes/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const remarkProjectSearchAPI = function ({
    projectId,
    data
}) {
    const url = `${config.baseUrl}client/getRemarks/${projectId}`;
    return postRequest({
        url,
        data
    })
};

export const productionApproveUpdate = function ({
    productionId,
    data
}) {
    const url = `${config.baseUrl}production/approval/${productionId}`;
    return putRequest({
        url,
        data
    })
};


export const productionRejectionUpdate = function ({
    productionId,
    data
}) {
    const url = `${config.baseUrl}production/rejection/${productionId}`;
    return putRequest({
        url,
        data
    })
};
export const productionReset = function ({
    productionId
}) {
    const url = `${config.baseUrl}production/reset/${productionId}`;
    return putRequest({
        url
    })
};

