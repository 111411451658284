import CommentModal from "src/containers/production-container/CommentModal";
import ProductionList from "src/containers/production-container/ProductionList";
import { useSticky } from "src/hooks/useSticky";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from "react";
import { productionSearchAction } from "src/containers/production-container/actions/ProductionActions";
import MonthPicker from "src/components/MonthPicker";
import RejectionCommentModal from "src/containers/production-container/RejectionCommentModal";

const PerfomanceProductionList = () => {
  const dispatch = useDispatch();
  // const { isSticky, elementRef } = useSticky(); // Use the custom hook
  const { userId } = useParams();
  const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
  const [reAppliedId, setReAppliedId] = useState(null)
  const [reApplyPendingList, setReApplyPendingList] = useState([]);
  const [where, setWhere] = useState({
    userId,
    $expr: {
      $and: [
        { $eq: [{ $year: "$date" }, selectedYear] },
        { $eq: [{ $month: "$date" }, selectedMonth] }
      ]
    }
  });
  useEffect(() => {
    search(where);
  }, [
    dispatch,
    where
  ]);
  const search = useCallback(() => {
    dispatch(productionSearchAction({
      where: where,
      whereMySql: {}
    }));
  }, [dispatch, where])
  // const handleChangeDate = (date)=>{
  //   const year = date.getFullYear();  // Example year
  //   const month = date.getMonth()+1;  // Since month is 0-indexed in JavaScript, add 1
  //   setWhere({
  //     ...where,
  //     $expr: {
  //       $and: [
  //         { $eq: [{ $year: "$date" },year] },
  //         { $eq: [{ $month: "$date" }, month] } 
  //       ]
  //     }
  //   }) 
  // }
  useEffect(() => {
    setWhere({
      ...where,
      $expr: {
        $and: [
          { $eq: [{ $year: "$date" }, selectedYear] },
          { $eq: [{ $month: "$date" }, selectedMonth] }
        ]
      }
    })
  }, [
    selectedMonth
    , selectedYear
  ])

  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

  useEffect(() => {
    const handleScroll = () => {
      // Clear the previous timeout to prevent multiple updates
      clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          // Check if the table <thead> is within the viewport range
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
    };
  }, []);
  // Table Scroll Code End



  // New code Table Scroll set

  const tableRef = useRef(null);
  const scrollBarRef = useRef(null);
  const thumbRef = useRef(null);
  const [thumbWidth, setThumbWidth] = useState(50);
  const [thumbLeft, setThumbLeft] = useState(0);

  useEffect(() => {
    updateThumbSize();
    window.addEventListener("resize", updateThumbSize);
    return () => window.removeEventListener("resize", updateThumbSize);
  }, []);

  const updateThumbSize = () => {
    const table = tableRef.current;
    const scrollbar = scrollBarRef.current;
    if (table && scrollbar) {
      const visibleRatio = table.clientWidth / table.scrollWidth;
      setThumbWidth(visibleRatio * scrollbar.clientWidth);
      setThumbLeft((table.scrollLeft / table.scrollWidth) * scrollbar.clientWidth);
    }
  };

  const handleTableScroll = () => {
    const table = tableRef.current;
    const scrollbar = scrollBarRef.current;
    if (table && scrollbar) {
      const scrollRatio = table.scrollLeft / (table.scrollWidth - table.clientWidth);
      setThumbLeft(scrollRatio * (scrollbar.clientWidth - thumbWidth));
    }
  };

  const handleThumbDrag = (event) => {
    event.preventDefault();
    const table = tableRef.current;
    const scrollbar = scrollBarRef.current;

    if (!table || !scrollbar) return;

    const startX = event.clientX;
    const startLeft = thumbLeft;

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const newLeft = Math.min(Math.max(startLeft + deltaX, 0), scrollbar.clientWidth - thumbWidth);
      const scrollRatio = newLeft / (scrollbar.clientWidth - thumbWidth);
      table.scrollLeft = scrollRatio * (table.scrollWidth - table.clientWidth);
      setThumbLeft(newLeft);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };
  // end
  return (
    <>









      <div className="col-md-12">
        <div className="card h-100 p-0">
          <div className="card-body p-24 px-0">
            <div className="ms-auto d-flex justify-content-between border-bottom pb-24 align-items-center inwth">
              <h4 className="card-title">Project Summary</h4>
              {/* <MonthPicker handleChangeDate={handleChangeDate} /> */}
            </div>
            <div className="card-body px-0 pt-18">


              <div className='posSetTable'>
                <div className={`columnFz table-body-scrollable ${isScrollable ? 'scrollable' : ''}`} ref={tableRef} onScroll={handleTableScroll} >
                  <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                    <thead>
                      <tr className="dnd-moved">
                        <th className="w30px dragablefalse" draggable="false">
                          <label className="form-check-label">S.No.</label>
                        </th>
                        <th>Date </th>
                        {/* <th className="">Time </th> */}
                        <th className="">Project Code </th>
                        <th className="">Task</th>
                        <th className="">Module</th>
                        <th className="">Target</th>
                        <th className="">Unit</th>
                        <th className="text-center">Production </th>
                        <th className="">Status </th>
                        <th className="">Reviewed By </th>
                        <th className="text-center">File</th>
                        <th className="text-center">Comment</th>
                        <th className="text-center">Approved </th>
                        <th className="text-center">Pending </th>
                        <th className="text-center">Rejected </th>
                        <th className="text-center">Rejection Reason</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ProductionList setReApplyPendingList={setReApplyPendingList} setReAppliedId={setReAppliedId} />
                    </tbody>
                  </table>
                </div>
                <div className="custom-scroll" ref={scrollBarRef}>
                  <div
                    className="custom-scroll-thumb"
                    ref={thumbRef}
                    style={{ width: `${thumbWidth}px`, left: `${thumbLeft}px` }}
                    onMouseDown={handleThumbDrag}
                  ></div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <CommentModal />
      <RejectionCommentModal />
    </>
  )
}

export default PerfomanceProductionList