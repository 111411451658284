import { shallowEqual, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom"


const BackProjectButton = () => {
  const navigator = useNavigate();
  const { currentRecord } = useSelector((state) => state.ProjectReducer, shallowEqual);
  // if(!currentRecord?.RefClientId) navigator("/projects")

  // Convert to Date object
  const date = new Date(currentRecord?.projectData?.ts);

  // Formatting the date
  const day = String(date.getDate()).padStart(2, "0"); // Add leading zero for single digit days
  const month = date.toLocaleString("en-US", { month: "short" }); // Get abbreviated month name
  const year = date.getFullYear();

  // Formatting the time
  const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = String(date.getMinutes()).padStart(2, "0"); // Add leading zero for minutes
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  // Combine date and time
  const createdDateTime = `${day} ${month} ${year} | ${hours}:${minutes} ${ampm}`;

  return (
    <>

      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n    .projDes {\n        margin: 0 4px 16px 54px;\n    }\n\n    .projDes p {\n        font-size: 16px;\n        font-weight: 400;\n        margin: 3px 0;\n        line-height: 22px;\n    }\n\n    .projHead {\n        flex-wrap: wrap;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n    }\n\n    .createDate p {\n        font-size: 14px;\n        color: #A9ADBC;\n        margin: 0;\n        text-align: right;\n        line-height: 18px;\n    }\n\n    .arrowDroup {\n        display: flex;\n        align-items: center;\n        gap: 12px;\n    }\n\n    /* End Project headings set with para  */\n    "
        }}
      />
      <div className="projHead">
        <h2 className="fw-semibold d-flex align-items-center gap-4 font-24 mb-0">
          <Link to="/projects" >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width={30}
              height={30}
            >
              <g data-name="20-Arrow Left">
                <path
                  fill="#0073ea"
                  d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
                />
                <path
                  fill="#0073ea"
                  d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
                />
              </g>
            </svg>
          </Link>{" "}
          <span
            className="arrowDroup"
            data-bs-toggle="collapse"
            href="#projecthead-show"
            role="button"
            aria-expanded="true"
            aria-controls="projecthead-show"
          >
            {currentRecord?.RefClientId?.ClientProjectCode} {currentRecord?.ProjectSubCode}{" "}
            {currentRecord?.projectData?.ProjectSubCodeDescription &&
              <svg
                width={12}
                height={6}
                viewBox="0 0 12 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.99997 6.00003C6.12784 6.00003 6.25584 5.95116 6.35347 5.85353L11.3535 0.853531C11.5488 0.658156 11.5488 0.341781 11.3535 0.146531C11.1581 -0.0487188 10.8417 -0.0488438 10.6465 0.146531L5.99997 4.79303L1.35347 0.14653C1.1581 -0.0488446 0.84172 -0.0488447 0.64647 0.14653C0.45122 0.341905 0.451096 0.65828 0.64647 0.85353L5.64647 5.85353C5.74409 5.95116 5.87209 6.00003 5.99997 6.00003Z"
                  fill="#0073EA"
                />
              </svg>
            }
          </span>
        </h2>
        <div className="createDate">
          <p>Created On:</p>
          <p>{createdDateTime}</p>
        </div>
      </div>
      <div className="projDes collapse show" id="projecthead-show">
        <p>{currentRecord?.projectData?.ProjectSubCodeDescription}</p>
      </div>
    </>
  )
}
export default BackProjectButton