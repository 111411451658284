import { useState } from "react"
import SubProjectTree from "./SubProjectsTree"
import { productionAssignClientIdAction } from "../actions/ProductionActions"
import { useDispatch } from "react-redux"

const ProjectTree = ({ index, item }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch();
  const handleSelectProject = (e, client) => {
    e.stopPropagation(); // Prevent event propagation
    setIsOpen(!isOpen)
    dispatch(productionAssignClientIdAction({
      data: {
        clientId: client._id,
        ClientProjectCode: client.ClientProjectCode
      }
    }))
  }
  return (
    <>

      <div className="accordion-item">
        <h2 className="accordion-header" id="sub-headingOne">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#sub-collapseOne${item._id}`}
            aria-expanded="true"
            aria-controls="collapseOne"
            onClick={(e) => { handleSelectProject(e, item) }}
          >
            <div className="d-flex gap-3 align-items-center">
              {item.ClientProjectCode}
            </div>
          </button>
        </h2>
        {
          item.projects
            .sort((a, b) => a.ProjectSubCode.localeCompare(b.ProjectSubCode))
            .map((project, ProIndex) => {
              return (
                <SubProjectTree key={ProIndex} clientId={item._id} project={project} />
              )
            })
        }
      </div>
    </>
  )
}
export default ProjectTree