import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { taskStatusUpdateAPI } from "src/api/TaskApi";
import { actionSetPendingList } from "../dashboard-container/actions/DashboardActions";
import { useNotification } from "src/components/Notifination/ToastNotification";
import TaskParticipantsCircles from "../project-container/Task/TaskParticipantsCircles";

const TaskPendingContainer=()=>{
     const dispatch = useDispatch();
     const { openSuccessNotification, openErrorNotification } = useNotification();
    const {task} = useSelector((state) => state.DashboardReducer.pendingList,shallowEqual);

const handleChangeStatus= async(e, taskId, taskStatus)=>{
    try {   
    e.preventDefault(); // Prevent default link action
    await taskStatusUpdateAPI({ taskId, data:{taskStatus} }); 
    const filterTask = task?.filter((task) =>(task._id !== taskId))
    dispatch(actionSetPendingList({data:{          
        task: filterTask,  
      }}));
openSuccessNotification("Task Status Updated Successfully");
} catch (error) {     
    openErrorNotification(
        error.response?.data?.message ?? "Error During Update task status"
    );
    }
}
    return (
        <>
          <div className="row">
            <div className="col-xxl-12 col-xl-12">
              <div className="card h-100">
                <div className="card-body p-24">
                  <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24"> 
                    Pending Tasks List For Approval
                  </h2>
                  <ul className="d-flex align-items-center gap-2">
                    <li className="fw-medium">
                      <a
                        href="#" onClick={(e) => e.preventDefault()}
                        className="d-flex align-items-center gap-1 hover-text-primary"
                      >
                        <iconify-icon
                          icon="solar:home-smile-angle-outline"
                          className="icon text-lg"
                        ></iconify-icon>
                        Special Access
                      </a>
                    </li>
                    <li>-</li>
                    <li className="fw-medium">Tasks List </li>
                  </ul>                 
                  <div className="table-responsive table-container" id="table-container">
                    <table className="table basic-border-table mb-0 table-hover">
                      <thead>
                        <tr className="dnd-moved"> 
                          <th className="w30px dragablefalse" draggable="false">
                            <label className="form-check-label">S.No.</label>
                          </th>
                        <th>Project</th>
                        <th>Task Name</th>
                        <th>Target</th>
                        <th>Unit</th>
                        <th>Item</th>
                        <th>Created By</th>
                        <th>Assignee</th>
                        <th className="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody id="table-body">
                                    {task?.map((task, index) => (
                                    <tr key={task.id} className="dnd-moved"> 
                                        <td>{index + 1}</td>
                                        <td>{`${task.projectId.RefClientId.ClientProjectCode} (${task.projectId.ProjectSubCode})`}</td>
                                        <td>{task.taskName}</td>
                                        <td className="text-center">{task.target}</td>
                                        <td className="text-center">{task.unit}</td>
                                        <td className="text-center">{task.item}</td>
                                        <td>{task.by.firstName + " " + task.by.lastName}</td>
                                        <td className="text-center"> <TaskParticipantsCircles task={task}/></td>
                                        <td className="text-center">
                                        
                                            <div className="btn-group">
                                            <div
                                                className={`badge text-sm fw-semibold ${
                                                (task?.taskStatus) === "Approved"
                                                    ? "bg-success-600"
                                                    : (task?.taskStatus) === "Rejected"
                                                    ? "bg-danger-600"
                                                    : "bg-warning-600"
                                                } px-20 py-9 radius-4 text-white d-flex align-items-center gap-2`}
                                            >
                                                {task?.taskStatus}
                                            </div>
                                            <button
                                                type="button"
                                                className={`btn ${
                                                (task?.taskStatus) === "Approved"
                                                    ? "bg-success-600"
                                                    : (task?.taskStatus) === "Rejected"
                                                    ? "bg-danger-600"
                                                    : "bg-warning-600"
                                                } dropdown-toggle dropdown-toggle-split rounded-0 border-start px-16 text-white`}
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                data-bs-reference="parent"
                                            >
                                                <span className="visually-hidden">
                                                {task?.taskStatus}
                                                </span>
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li>
                                                <a
                                                    className={`dropdown-item d-flex align-items-center gap-2 ${
                                                    (task?.taskStatus) === "Approved"
                                                        ? "bg-success-600 text-white"
                                                        : ""
                                                    }`}
                                                    href="#"
                                                    onClick={(e) => handleChangeStatus(e, task?._id, "Approved")}
                                                >
                                                    Approved
                                                </a>
                                                </li>
                                                <li>
                                                <a
                                                    className={`dropdown-item d-flex align-items-center gap-2 ${
                                                    (task?.taskStatus) === "Pending"
                                                        ? "bg-warning-600 text-white"
                                                        : ""
                                                    }`}
                                                    href="#"
                                                    onClick={(e) => handleChangeStatus(e, task?._id, "Pending")}
                                                >
                                                    Pending
                                                </a>
                                                </li>
                                                <li>
                                                <a
                                                    className={`dropdown-item d-flex align-items-center gap-2 ${
                                                    (task?.taskStatus) === "Rejected"
                                                        ? "bg-danger-600 text-white"
                                                        : ""
                                                    }`}
                                                    href="#"
                                                    onClick={(e) => handleChangeStatus(e, task?._id, "Rejected")}
                                                >
                                                    Rejected
                                                </a>
                                                </li>
                                            </ul>
                                            </div>
                                        </td>
                                        
                                    </tr>
                                    ))}
                      </tbody>
                    </table>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </>
      );
}
export default TaskPendingContainer