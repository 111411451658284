import React, { useState } from 'react';
import DatePickerComponent from './DatePickerComponent';
import SearchInput from './SearchInput';
import Select2Component from './Select2Component';

const FeedbackLMS = () => {

  const tableDocumentation = [
    {
      line: 'Content Development|Data Visualisation	',
      recvto: 'Danish_& Faizal <ui@acadecraft.com>',
      assignproject: 'Project 001 -A',
      assignedby: 'Ahmad Monish',
    },
    {
      line: 'Content Development|Data Visualisation	',
      recvto: 'Danish_& Faizal <ui@acadecraft.com>',
      assignproject: 'Project 001 -A',
      assignedby: 'Ahmad Monish',
    },
    {
      line: 'Content Development|Data Visualisation	',
      recvto: 'Danish_& Faizal <ui@acadecraft.com>',
      assignproject: 'Project 001 -A',
      assignedby: 'Ahmad Monish',
    },
    {
      line: 'Content Development|Data Visualisation	',
      recvto: 'Danish_& Faizal <ui@acadecraft.com>',
      assignproject: 'Project 001 -A',
      assignedby: 'Ahmad Monish',
    }
  ];


  //  tableMailchimp start

  const tableMailchimp = [
    {
      useremail: 'ui@acadecraft.com',
      username: 'Aanya',
      userassignproject: '5',
    },
  ];


  //  tableMailchimp start

  // Initial table data
  const [tableExcluded, setTableExcluded] = useState([
    {
      useremail: 'acadecraft.com',
    },
  ]);

  // Function to add a new row
  const handleAddRow = () => {
    const newRow = { useremail: '' }; // Create a new empty row or customize this
    setTableExcluded([...tableExcluded, newRow]); // Add new row to the table
  };

  // Function to remove a row
  const handleRemoveRow = (index) => {
    const newTable = tableExcluded.filter((_, i) => i !== index); // Remove row at the specified index
    setTableExcluded(newTable);
  };


  const handleDateRangeChange = (selectedDates) => {

  }
  const [viewAll, setViewAll] = useState(false);

  const handleViewAllChange = (e) => {
    setViewAll(e.target.checked);
    // Expand or collapse all accordions based on checkbox state
    const accordions = document.querySelectorAll(".lmsn001");
    accordions.forEach((accordion) => {
      const collapse = new window.bootstrap.Collapse(accordion, {
        toggle: false,
      });
      viewAll ? collapse.hide() : collapse.show();
    });
  };



  const [selectedValue, setSelectedValue] = useState(null);

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    console.log('Selected Value:', value);
  };

  const options = [
    { value: '001', label: 'Project 001 -A' },
    { value: '002', label: 'Project 001 -B' },
    { value: '003', label: 'Project 001 -C' }
  ];




  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">

            <div className="card-body p-24 pt-10">
              <div className="d-flex justify-content-between align-items-center border-bottom  pb-16">
                <div className="">
                  <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                    Feedback LMS
                  </h2>
                  <ul className="d-flex align-items-center gap-2">
                    <li className="fw-medium">
                      <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                        <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                        Special Access
                      </a>
                    </li>
                    <li>-</li>
                    <li className="fw-medium">Feedback LMS</li>
                  </ul>
                </div>
              </div>

              <div className="d-flex gap-2 align-items-center py-16 mt-16">
                <SearchInput />
                <DatePickerComponent
                  onDateRangeChange={handleDateRangeChange}
                />
              </div>


              <ul className="nav focus-tab nav-pills mb-16 mt-24" id="pills-tab-two" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold text-primary-light radius-4 px-16 py-10 active" id="pills-focus-home-tab" data-bs-toggle="pill" data-bs-target="#pills-focus-home" type="button" role="tab" aria-controls="pills-focus-home" aria-selected="true">Mail Chain</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold text-primary-light radius-4 px-16 py-10" id="pills-focus-details-tab" data-bs-toggle="pill" data-bs-target="#pills-focus-details" type="button" role="tab" aria-controls="pills-focus-details" aria-selected="false" tabindex="-1">Email </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link fw-semibold text-primary-light radius-4 px-16 py-10" id="pills-focus-Domains-tab" data-bs-toggle="pill" data-bs-target="#pills-focus-Domains" type="button" role="tab" aria-controls="pills-focus-Domains" aria-selected="false" tabindex="-1">Excluded Domains </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tab-twoContent">
                <div className="tab-pane fade active show" id="pills-focus-home" role="tabpanel" aria-labelledby="pills-focus-home-tab" tabindex="0">
                  <div>
                    {/* <h6 className="text-lg mb-8">Title</h6> */}
                    <div className="table-responsive table-container mt-24 bdnonn">
                      <table className="table basic-border-table mb-0 table-hover">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Mail Subject Line	</th>
                            <th>Mail rcvd to	</th>
                            <th className='text-center'>Assign to Project	</th>
                            <th className='text-center'>Assigned by	</th>
                            <th className='text-center'>View</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableDocumentation.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td><div className="tsknem">{item.line}</div></td>
                              <td>{item.recvto}</td>
                              {/* <td className='text-center'>{item.assignproject}</td> */}
                              <td className='text-center'><div className="sct2drpdn">
                                <Select2Component
                                  options={options}
                                  placeholder="Select an option"
                                  onChange={handleSelectChange}
                                />
                                {/* {selectedValue && <p>Selected: {selectedValue}</p>} */}
                              </div></td>
                              <td className='text-center'>{item.assignedby}</td>
                              <td className='text-center'><button className="btn btn-primary text-lg px-16 py-4" data-bs-toggle="modal" data-bs-target="#ViewdescriptionModual"><i className="mdi mdi-eye"></i></button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="">
                    <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center mt-24">
  <li className="page-item">
    <a
      className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px"
      href="#" onClick={(e) => e.preventDefault()}
    >
      Previous
    </a>
  </li>
  <li className="page-item">
    <a
      className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px w-48-px"
      href="#" onClick={(e) => e.preventDefault()}
    >
      <iconify-icon icon="ep:d-arrow-left" className="text-xl" />
    </a>
  </li>
  <li className="page-item">
    <a
      className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px w-48-px"
      href="#" onClick={(e) => e.preventDefault()}
    >
      1
    </a>
  </li>
  <li className="page-item">
    <a
      className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px w-48-px border-primary-400 text-primary-600"
      href="#" onClick={(e) => e.preventDefault()}
    >
      2
    </a>
  </li>
  <li className="page-item">
    <a
      className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px w-48-px"
      href="#" onClick={(e) => e.preventDefault()}
    >
      3
    </a>
  </li>
  <li className="page-item">
    <a
      className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px w-48-px"
      href="#" onClick={(e) => e.preventDefault()}
    >
      {" "}
      <iconify-icon icon="ep:d-arrow-right" className="text-xl" />{" "}
    </a>
  </li>
  <li className="page-item">
    <a
      className="page-link bg-base border text-secondary-light fw-medium radius-8 border-0 px-20 py-10 d-flex align-items-center justify-content-center h-48-px"
      href="#" onClick={(e) => e.preventDefault()}
    >
      Next
    </a>
  </li>
</ul> 

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-focus-details" role="tabpanel" aria-labelledby="pills-focus-details-tab" tabindex="0">
                  <div>
                    <div className="d-flex justify-content-end"><button data-bs-toggle="modal" data-bs-target="#AddEmailIDModal" className="btn btn-primary text-sm btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"><i className="mdi mdi-plus"></i> Add Email ID</button></div>
                    <div className="table-responsive table-container mt-24">
                      <table className="table basic-border-table mb-0 table-hover">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Email ID	</th>
                            <th>User Name	</th>
                            <th className='text-center'>No. of Subject Lines	</th>
                            <th className='text-center'>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableMailchimp.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td><div className="tsknem">{item.useremail}</div></td>
                              <td>{item.username}</td>
                              <td className='text-center'>{item.userassignproject}</td>
                              <td className='text-center'><button type="button" className="btn btn-danger-100 text-danger-600 radius-8 px-14 py-6 text-sm">Remove</button></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-focus-Domains" role="tabpanel" aria-labelledby="pills-focus-Domains-tab" tabindex="0">
                  <div>
                    <div className="d-flex justify-content-end"><button
                      type="button"
                      className="btn btn-primary text-sm btn-sm px-18 py-10 radius-8 d-flex align-items-center gap-2"
                      onClick={handleAddRow}
                    ><i className="mdi mdi-plus"></i>
                      Add
                    </button></div>
                    <div className="table-responsive table-container mt-24">
                      <table className="table basic-border-table mb-0 table-hover">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Excluded Domains Name</th>
                            <th className="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableExcluded.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <div className="tsknem">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={item.useremail}
                                    onChange={(e) => {
                                      const newTable = [...tableExcluded];
                                      newTable[index].useremail = e.target.value;
                                      setTableExcluded(newTable); // Update the email
                                    }}
                                    placeholder="Enter Domains Name"
                                  />
                                </div>
                              </td>
                              <td className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-danger-100 remove text-danger-600 radius-8 px-14 py-6 text-sm"
                                  onClick={() => handleRemoveRow(index)}
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade cusmodl" id="ViewdescriptionModual" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between pb-0 border-0"><h6 className="mb-2 fw-bold text-lg mb-0">Content Development|Data Visualisation</h6><button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
            <div className="modal-body">
              <div className="card-body p-0">
                <div className="d-flex justify-content-end mb-20"> <label className="C_radioBtn C_CkeckBtn C_CkeckBtn2 text-start mb-0">
                  View All
                  <input
                    type="checkbox"
                    checked={viewAll}
                    onChange={handleViewAllChange}
                  />
                  <span className="checkmark" />
                </label></div>
                <div className="accordion acodLMSNew" id="accordionExample">
                  <div className="accordion-item p-0 mb-0">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button collapsed px-20 py-16 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        <div className="chat-sidebar-single w-100 p-0">
                          <div className="img imgsSet bgafnone">
                            K
                          </div>
                          <div className="info">
                            <h6 className="text-sm mb-1">Kathryn Murphy</h6>
                            <p className="mb-0 text-xs">hey! there i'm...</p>
                          </div>
                          <div className="action text-end">
                            <p className="mb-0 text-neutral-400 text-xs lh-1 text-nowrap ms-12">12:30 PM</p>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse lmsn001 collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body px-20 py-20">
                        <div className="d-flex align-items-start gap-3">
                          {/* <img src="/assets/images/user-list/user-list1.png" alt="" className="w-40-px h-40-px rounded-pill" /> */}
                          <div>
                            {/* <div className="d-flex align-items-center flex-wrap gap-2">
                            <h6 className="mb-0 text-lg">Kathryn Murphy</h6>
                            <span className="text-secondary-light text-md">kathrynmurphy@gmail.com</span>
                        </div> */}
                            <div className="mt-0">
                              <p className="mb-16 text-primary-light">Hi William</p>
                              <p className="mb-16 text-primary-light">
                                Just confirming that we transferred $63.86 to you via PayPal
                                <a href="#" onClick={(e) => e.preventDefault()} className="text-primary-600 text-decoration-underline">(info367@gmail.com)</a>
                                which you earned on the themewow Market since your last payout.
                              </p>
                              <p className="mb-0 text-primary-light">Thank you for selling with us!</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item p-0 mb-0">
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed  px-20 py-16 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <div className="chat-sidebar-single w-100 p-0">
                          <div className="img imgsSet bgafnone">
                            K
                          </div>
                          <div className="info">
                            <h6 className="text-sm mb-1">Kathryn Murphy</h6>
                            <p className="mb-0 text-xs">hey! there i'm...</p>
                          </div>
                          <div className="action text-end">
                            <p className="mb-0 text-neutral-400 text-xs lh-1 text-nowrap ms-12">02/Oct/2024 12:30 PM</p>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse lmsn001 collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body px-20 py-20">
                        <div className="d-flex align-items-start gap-3">
                          {/* <span className="imgsSet"><img src="/assets/images/user-list/user-list2.png" alt="" className="w-40-px h-40-px rounded-pill" /></span> */}
                          <div>
                            {/* <div className="d-flex align-items-center flex-wrap gap-2">
                            <h6 className="mb-0 text-lg">Subrata Sen</h6>
                            <span className="text-secondary-light text-md">subratasen@gmail.com</span>
                        </div> */}
                            <div className="mt-0">
                              <p className="mb-0 text-primary-light">Awesome, thank you so much!</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item p-0 mb-0">
                    <h2 className="accordion-header" id="heading3">
                      <button className="accordion-button collapsed  px-20 py-16 bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                        <div className="chat-sidebar-single w-100 p-0">
                          <div className="img imgsSet bgafnone">
                            K
                          </div>
                          <div className="info">
                            <h6 className="text-sm mb-1">Kathryn Murphy</h6>
                            <p className="mb-0 text-xs">hey! there i'm...</p>
                          </div>
                          <div className="action text-end">
                            <p className="mb-0 text-neutral-400 text-xs lh-1 text-nowrap ms-12">12:30 PM</p>
                          </div>
                        </div>
                      </button>
                    </h2>
                    <div id="collapse3" className="accordion-collapse lmsn001 collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                      <div className="accordion-body px-20 py-20">
                        <div className="d-flex align-items-start gap-3">
                          {/* <span className="imgsSet"><img src="/assets/images/user-list/user-list2.png" alt="" className="w-40-px h-40-px rounded-pill" /></span> */}
                          <div>
                            {/* <div className="d-flex align-items-center flex-wrap gap-2">
                            <h6 className="mb-0 text-lg">Subrata Sen</h6>
                            <span className="text-secondary-light text-md">subratasen@gmail.com</span>
                        </div> */}
                            <div className="mt-0">
                              <p className="mb-0 text-primary-light">Awesome, thank you so much!</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="modal fade cusmodl" id="AddEmailIDModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between pb-0 border-0"><h6 className="mb-2 fw-bold text-lg mb-0">Add Email ID</h6><button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
            <div className="modal-body">
              <div className="row gy-3">
                <div className="col-12">
                  <label className="form-label">Full Name</label>
                  <div className="icon-field">
                    <span className="icon">
                      <iconify-icon icon="f7:person" />
                    </span>
                    <input
                      type="text"
                      name="#0"
                      className="form-control"
                      placeholder="Enter Full Name"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <label className="form-label">Email</label>
                  <div className="icon-field">
                    <span className="icon">
                      <iconify-icon icon="mage:email" />
                    </span>
                    <input
                      type="email"
                      name="#0"
                      className="form-control"
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <button type="submit" className="btn btn-primary-600 w-100">
                    Submit
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FeedbackLMS
