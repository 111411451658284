import React, { useEffect, useRef, useState } from "react";
import { truncateNotes } from "src/utils/CommonUtils";
import VendorFilterPopup from "./VendorFilterPopup";
import CombinedInvoice from "./CombinedInvoice";
import ParcelAmount from "./ParcelAmount";

const getInitials = (name) => {
  if (!name) return "";
  const cleanName = name.replace(/\(.*?\)/g, "").trim();
  const words = cleanName.split(" ");
  const firstNameInitial = words[0]?.charAt(0).toUpperCase();
  const lastNameInitial = words[1]?.charAt(0).toUpperCase();
  return `${firstNameInitial}${lastNameInitial || ""}`;
};

const VendorLedger = ({ columns: initialColumns, data: initialData, activeTitle }) => {
  const [isVendorListOpen, setIsVendorListOpen] = useState(false);
  const [vendorSearchQuery, setVendorSearchQuery] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [isContentVisible, setIsContentVisible] = useState(false); // Control visibility of content

  const vendors = [
    { name: "Nshkfc (Raghu)", PanCard: "ABCDE1234F", image: "/assets/images/users/user4.png" },
    { name: "Nshkfc (Raghu)", PanCard: "FGHIJ5678K", image: "/assets/images/users/user3.png" },
    { name: "Nshkfc (Raghu)", PanCard: "JKLMN9012P", image: "" },
  ];

  const filteredVendors = vendors.filter(
    (vendor) =>
      vendor.name.toLowerCase().includes(vendorSearchQuery.toLowerCase()) ||
      vendor.PanCard.toLowerCase().includes(vendorSearchQuery.toLowerCase())
  );

  const handleVendorSelect = (vendor) => {
    setSelectedVendor(vendor.name);
    setVendorSearchQuery(vendor.name);
    setIsVendorListOpen(false);
  };

  const vendorRef = useRef(null);
  const handleClickOutside = (event) => {
    if (vendorRef.current && !vendorRef.current.contains(event.target)) {
      setIsVendorListOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [columns, setColumns] = useState(initialColumns);
  const [tableData, setTableData] = useState(initialData);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleColumnVisibilityChange = (id) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) => (col.id === id ? { ...col, visible: !col.visible } : col))
    );
  };

  const handleViewAllChange = (e) => {
    const isChecked = e.target.checked;
    setColumns((prevColumns) =>
      prevColumns.map((col) => ({ ...col, visible: isChecked }))
    );
  };

  const handleApply = () => {
    console.log("Column visibility applied");
  };

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, [columns]);

  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current);
    };
  }, []);

  return (
    <> 
    {!isContentVisible && (
        <div className="col-md-8 mx-auto mt-24 firsttime">
          <div className="ProjectManagersearch" ref={vendorRef}>
            <div className="selectManger">
              <input
                type="text"
                className="form-select"
                placeholder="Search name or pan card number"
                value={vendorSearchQuery}
                onClick={() => setIsVendorListOpen(true)}
                onChange={(e) => {
                  setVendorSearchQuery(e.target.value);
                  setSelectedVendor("");
                }}
              />
              {selectedVendor && (
                <button
                  type="button"
                  className="clear-btn"
                  onClick={() => {
                    setSelectedVendor("");
                    setVendorSearchQuery("");
                  }}
                >
                  ✕
                </button>
              )}
            </div>
            {isVendorListOpen && (
              <div className="ProjectManagerList">
                {filteredVendors.length > 0 ? (
                  filteredVendors.map((vendor, index) => (
                    <label
                      key={index}
                      className="C_radioBtn C_CkeckBtn C_CkeckBtn2 text-start mb-0 py-10"
                      onClick={() => handleVendorSelect(vendor)}
                    >
                      <div className="user-cell">
                        <span className="user-initials">
                          {vendor.image ? (
                            <img
                              src={vendor.image}
                              className="img-fluid"
                              alt={vendor.name}
                            />
                          ) : (
                            vendor.name
                              .split(" ")
                              .map((word) => word.charAt(0).toUpperCase())
                              .join("")
                          )}
                        </span>
                        <div className="flex-grow-1">
                          <h6 className="text-sm mb-0 fw-medium">{vendor.name}</h6>
                          <span className="text-sm text-secondary-light fw-normal">
                            Pan Card: {vendor.PanCard}
                          </span>
                        </div>
                      </div>
                      <input type="radio" name="vendor-radio" />
                      <span className="checkmark rounded-pill" />
                    </label>
                  ))
                ) : (
                  <div className="text-sm text-secondary-light p-20 text-center">
                    No Vendor/Freelancer found
                  </div>
                )}
              </div>
            )}
          </div>
          <button
            type="button"
            className="btn btn-primary-600 radius-8 px-20 py-11 d-flex align-items-center justify-content-center mx-auto mt-16 gap-2"
            onClick={() => setIsContentVisible(true)}
          >
            <i className="mdi mdi-magnify mdi-24px"></i> Search
          </button>
        </div>
      )}

{isContentVisible && (
  <div className="d-flex align-items-center gap-3 justify-content-between mb-16">
  <div className="d-flex align-items-center gap-3">
   <div className="ProjectManagersearch" ref={vendorRef}>
            <div className="selectManger">
              <input
                type="text"
                className="form-select"
                placeholder="Search name or pan card number"
                value={vendorSearchQuery}
                onClick={() => setIsVendorListOpen(true)}
                onChange={(e) => {
                  setVendorSearchQuery(e.target.value);
                  setSelectedVendor("");
                }}
              />
              {selectedVendor && (
                <button
                  type="button"
                  className="clear-btn"
                  onClick={() => {
                    setSelectedVendor("");
                    setVendorSearchQuery("");
                  }}
                >
                  ✕
                </button>
              )}
            </div>
            {isVendorListOpen && (
              <div className="ProjectManagerList">
                {filteredVendors.length > 0 ? (
                  filteredVendors.map((vendor, index) => (
                    <label
                      key={index}
                      className="C_radioBtn C_CkeckBtn C_CkeckBtn2 text-start mb-0 py-10"
                      onClick={() => handleVendorSelect(vendor)}
                    >
                      <div className="user-cell">
                        <span className="user-initials">
                          {vendor.image ? (
                            <img
                              src={vendor.image}
                              className="img-fluid"
                              alt={vendor.name}
                            />
                          ) : (
                            vendor.name
                              .split(" ")
                              .map((word) => word.charAt(0).toUpperCase())
                              .join("")
                          )}
                        </span>
                        <div className="flex-grow-1">
                          <h6 className="text-sm mb-0 fw-medium">{vendor.name}</h6>
                          <span className="text-sm text-secondary-light fw-normal">
                            Pan Card: {vendor.PanCard}
                          </span>
                        </div>
                      </div>
                      <input type="radio" name="vendor-radio" />
                      <span className="checkmark rounded-pill" />
                    </label>
                  ))
                ) : (
                  <div className="text-sm text-secondary-light p-20 text-center">
                    No Vendor/Freelancer found
                  </div>
                )}
              </div>
            )}
          </div>
         <div className="dropdown mb-0">
         <button
           className="btn bg-base px-18 py-9 dropdown-toggle toggle-icon border d-flex align-items-center gap-2"
           type="button"
           data-bs-toggle="dropdown"
           aria-expanded="true"
         >
           <img
             src="/assets/images/add-column.svg"
             className="img-fluid"
             alt="add-column"
           />
           Manage Columns
         </button>
         <div className="dropdown-menu border wsetColumn p-3">
           <div className="form-check">
             <input
               type="checkbox"
               className="form-check-input"
               id="viewAll"
               onChange={handleViewAllChange}
               checked={columns.every((col) => col.visible)}
             />
             <label className="form-check-label" htmlFor="viewAll">
               View All
             </label>
           </div>
           <div className="mx-hight200px mt-2">
             {columns.map((col) => (
               <div key={col.id} className="form-check">
                 <input
                   type="checkbox"
                   className="form-check-input"
                   id={col.id}
                   checked={col.visible}
                   onChange={() => handleColumnVisibilityChange(col.id)}
                 />
                 <label className="form-check-label" htmlFor={col.id}>
                   {col.label}
                 </label>
               </div>
             ))}
           </div>
           <button className="btn btn-primary mt-16 w-100" onClick={handleApply}>
             Apply
           </button>
         </div>
       </div>
         <button type="button" className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2" 
    data-bs-toggle="offcanvas" data-bs-target="#VendorFilterPopup" aria-controls="VendorFilterPopup"
    >
  <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 24 24">
    <path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z" />
  </svg>{" "}
  Filter
</button>
 </div>
 <div className="user-cell">
                          <span className="user-initials">N</span>
                          Nshkfc (Raghu)
                        </div>
 </div>
      )}
      {/* Manage Columns Dropdown */}
      

 
   

      {/* Table */}
      {isContentVisible && (
        <>
      <h3 className="card-title text-primary-900">{activeTitle}</h3>
      <div className={`table-body-scrollable mt-24 ${isScrollable ? 'scrollable' : ''}`} style={{minHeight: '250px'}}>
      <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
          <thead>
            <tr>
              {columns
                .filter((col) => col.visible)
                .map((col) => (
                  <th key={col.id}>{col.label}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={row.id}>
                {columns
                  .filter((col) => col.visible)
                  .map((col) => (
                    <td key={col.id}>
                      {/* Conditional rendering based on column id */}
                      {col.id === "sNo" && (rowIndex + 1)} {/* Serial Number */} 
                      {col.id === "invoiceNumber" && row.invoiceNumber} {/* Invoice Number */}
                      {col.id === "invoiceDate" && row.invoiceDate} {/* Invoice Date */}
                      {col.id === "baseAmount" && row.baseAmount} {/* Base Amount */}
                      {col.id === "gstAmount" && row.gstAmount} {/* GST Amount */}
                      {col.id === "totalAmount" && row.totalAmount} {/* Total Amount */}
                      {col.id === "tds" && row.tds} {/* TDS */}
                      {col.id === "netPayable" && row.netPayable} {/* Net Payable */}
                      {col.id === "partiallyPaid" && row.partiallyPaid} {/* Partially Paid */}
                      {col.id === "balanceAmt" && row.balanceAmt} {/* Balance Amt */}
                      {col.id === "projectCode" && row.projectCode} {/* Project Code */}
                      {col.id === "projectManager" && (
                        <div className="user-cell"
                             data-bs-toggle="tooltip"
                             data-bs-placement="top"
                             data-bs-custom-class="tooltip-primary"
                             data-bs-title={`${row.projectManager}`}>
                          <span className="user-initials">{getInitials(row.projectManager)}</span>
                          {truncateNotes(row.projectManager)}
                        </div>
                      )} {/* Project Manager */}
                      {col.id === "customerName" && (
                        <div className="user-cell"
                             data-bs-toggle="tooltip"
                             data-bs-placement="top"
                             data-bs-custom-class="tooltip-primary"
                             data-bs-title={`${row.customerName}`}>
                          <span className="user-initials">{getInitials(row.customerName)}</span>
                          {truncateNotes(row.customerName)}
                        </div>
                      )} {/* Customer Name */}
                      {col.id === "customerInvoiceNo" && row.customerInvoiceNo} {/* Cust. Invoice No. */}
                      {col.id === "currency" && row.currency} {/* Currency */}
                      {col.id === "customerInvoiceAmount" && row.customerInvoiceAmount} {/* Cust. Invoice Amt */}
                      {/* {col.id === "viewDetails" && <button className="btn bg-base border text-primary-600 radius-8 px-20 py-6 text-sm" data-bs-toggle="modal" data-bs-target="#ViewDetails">View</button>}  */}
                      {col.id === "viewDetails" && (
  <button
    className="btn bg-base border text-primary-600 radius-8 px-20 py-6 text-sm"
    data-bs-toggle="modal"
    data-bs-target="#ViewDetails"
    onClick={() => setSelectedRow(row)} // Set the selected row when the button is clicked
  >
    View
  </button>
)}
                      {col.id === "viewInvoice" && <button className="btn bg-base border text-primary-600 radius-8 px-20 py-6 text-sm">Invoice</button>} {/* View Invoice */}
                      {col.id === "action" && (
                        <div className="dropdown">
                          <button
                            className="btn btn-primary-600 not-active px-18 py-6 text-sm dropdown-toggle toggle-icon"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Action
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <a className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900"
                                 href="#" onClick={(e) => e.preventDefault()} data-bs-toggle="modal" data-bs-target="#approvedModal">Approve</a>
                            </li>  
                            <li>
                              <a className="dropdown-item px-16 py-8 rounded text-secondary-light bg-hover-neutral-200 text-hover-neutral-900"
                                 href="#" onClick={(e) => e.preventDefault()} data-bs-toggle="modal" data-bs-target="#cancelModal">Cancel</a>
                            </li>  
                          </ul>
                        </div>
                      )} {/* Action */}
                       {col.id === "paidDate" && (<div className="d-flex align-items-center gap-3">{row.paidDate} <button data-bs-toggle="modal" data-bs-target="#ParcelAmount"><i className="mdi mdi-eye-outline mdi-24px"></i></button></div>)} {/* Paid Date */}
                   {col.id === "cancelledDate" && row.cancelledDate} {/* cancelled Date */}
                   {col.id === "reasons" && (<div className="mx-set-w200"  data-bs-toggle="tooltip"
                             data-bs-placement="top"
                             data-bs-custom-class="tooltip-primary"
                             data-bs-title={`${row.reasons}`}>{row.reasons}</div>)} {/* reasons */}
                    </td>
                  ))}
                  
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </>
)}
      <VendorFilterPopup />
      <CombinedInvoice row={selectedRow} />
      <ParcelAmount />
    </>
  );
};

export default VendorLedger