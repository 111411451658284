import React, { useCallback, useEffect, useState } from 'react'
// import StatusCard from '../PaymentDetails/StatusCard'
import AddEmployeeSalaryTable from './AddEmployeeSalaryTable'
import SearchInput from 'src/components/SearchInput';
import MonthPicker from 'src/components/MonthPicker';
import StatusCard from 'src/containers/client-invoice-container/StatusCard';
import { shallowEqual, useSelector } from 'react-redux';
import { getPendingSalary } from 'src/api/SalaryApi';

const AddEmployeeSalary = () => {
  const lastMonthMidDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 15);
  const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
  const [where, setWhere] = useState({
    year: selectedYear,
    month: (new Date().getMonth()),
  });
  const [cards, setCards] = useState([
    // { amount: '₹31,583.36', status: 'Total Salary', color: 'success' },
    // { amount: '₹31,583.36', status: 'Total Net Salary', color: 'lilac' },
    // { amount: '256', status: 'Total Effective Days', color: 'warning' },
    // { amount: '20', status: 'Total Pending ', color: 'info' },
    // { amount: '30', status: 'Credited', color: 'info' },
  ]);
  const [employees,setEmployees] = useState([]);

  const handleChangeDate = (date) => {
    const year = date.getFullYear();  // Example year
    const month = date.getMonth() + 1;  // Since month is 0-indexed in JavaScript, add 1
    setWhere({
      ...where,
      year,
      month,
    })
  }
  useEffect(() => {
    search(where);
  }, [where]);
  const search = useCallback(async () => {
    const salaryData = await getPendingSalary({ where })
    setEmployees(salaryData.data.data)    
    const totalSalary = salaryData.data.data.reduce((sum, employee) => {
      return sum + employee.monthlySalary || 0;
    },0)
    setCards([
      { amount: `₹${totalSalary.toFixed(2)}`, status: 'Total Salary', color: 'warning' },     
    ])
  }, [where])

  return (
    <div>
      <div className="card h-100">
        <div className="card-body p-24">
          <div className="d-flex justify-content-between align-items-center border-bottom  pb-16">
            <div className="">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                <a href='/manage-salary'>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width={30}
                    height={30}
                  >
                    <g data-name="20-Arrow Left">
                      <path
                        fill="#0073ea"
                        d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"
                      />
                      <path
                        fill="#0073ea"
                        d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"
                      />
                    </g>
                  </svg>
                </a>{" "}
                Add Employee Salary
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="/"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      classname="icon text-lg"
                    />
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">
                  <a
                    href="#"
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    HR
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Add Employee Salary</li>
              </ul>
            </div>
            <a
              href="#"
              // data-bs-toggle="offcanvas"
              // data-bs-target="#addClientpop"
              // aria-controls="addClientpop"
              className="btn btn-primary text-sm btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"
            >
              <iconify-icon
                icon="ic:baseline-plus"
                classname="icon text-xl line-height-1"
              />{" "}
              Import
            </a>
          </div>

          <div className='d-flex justify-content-between align-items-center gap-3 mt-24 mb-24 hsbsgnsBun'>
            {cards.map((card, index) => (
              <StatusCard key={index} amount={card.amount} status={card.status} color={card.color} className="pe-2 text-center HremplSalary" />
            ))}
          </div>

          <div className="d-flex align-items-center gap-3 my-16">
            <SearchInput />
            <MonthPicker handleChangeDate={handleChangeDate} lastMonthMidDate={lastMonthMidDate} />
            <button type="button" className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
              data-bs-toggle="offcanvas" data-bs-target="#special-access-fil" aria-controls="special-access-fil"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 24 24">
                <path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z" />
              </svg>{" "}
              Filter
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="card-title"><i className="mdi mdi-account-multiple mdi-24px"></i> Employee List</h4>
            <div className="d-flex flex-wrap align-items-center gap-3">
              <button type="button" className="btn btn-outline-primary-600 text-sm radius-8 px-20 py-6 d-flex align-items-center gap-2">
                Download <i className="mdi mdi-format-vertical-align-bottom mdi-18px"></i>
              </button>
            </div>
          </div>
          <AddEmployeeSalaryTable  
          employees={employees} 
          where={where}
          search={search}
          />
        </div>
      </div>

    </div>
  )
}

export default AddEmployeeSalary
