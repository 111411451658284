import { useState } from "react"

const FilterAuditReportModal=({where,setWhere})=>{
  const [status,setStatus] =useState(undefined);
  const [OAD,setOAD] = useState({type:'above',value:null});
  const [CMD,setCMD] = useState({type:'Below',value:null});

  const applyFilter = () => {
    let updatedWhere = { ...where };    
    // Check if status is set
    if (status!==undefined) {
        updatedWhere.active = status;
    } else {
        delete updatedWhere.active;
    }

    // Check if OAD has a value
    if (OAD?.value) {
        updatedWhere.OAD = OAD;
    } else {
        delete updatedWhere.OAD;
    }

    // Check if CMD has a value
    if (CMD?.value) {
        updatedWhere.CMD = CMD;
    } else {
        delete updatedWhere.CMD;
    }

    // Update the `where` state with the combined filters
    setWhere(updatedWhere);
};
    return(
        <div
  className="offcanvas offcanvas-end"
  id="FilterAuditReportModal"
  aria-labelledby="filterRightLayout"
>
  <div className="offcanvas-header border-bottom"> 
    <h5 className="modal-title">Filter</h5>
    <button
      type="button"
      className="btn-close d-none"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    />
  </div>
  <div className="offcanvas-body">
    <div className="assignPopoHeight">     
      <form className="AllFilter_Select" action="">
        {/* <input className="form-check-input" type="checkbox" id="allFilter" />
        <label className="allFilter" htmlFor="allFilter">
          All Filter <span>3 Select</span>
        </label> */}
      </form>
      <div className="selectSec pt-2 radioBtnActiveDe">
      <label className="form-label fw-semibold text-primary-light text-sm mb-8">Status</label> 
       
       <label className="C_radioBtn">
    Active
    <input type="radio" defaultChecked="checked" name="radio" />
    <span className="checkmark" />
  </label>
  <label className="C_radioBtn">
    Inactive
    <input type="radio" name="radio" />
    <span className="checkmark" />
  </label>
      </div> 
      <div className="selectSec pt-0">
      <label className="form-label fw-semibold text-primary-light text-sm mb-8">CMD</label>
        <div className="costSec">
          <div className="costinputSec">
          <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">₹</label>
            <input type="number" name="CMD" min={0} defaultValue="00" onChange={(e)=>setCMD({...CMD,value:e.target.value})}/>
          </div>
          <select className="slectIconsShow" onChange={(e)=>setCMD({...CMD,type:e.target.value})}>
            <option value="Above">Below</option>
            <option value="Below">Above</option>
          </select>
        </div>
      </div>
      <div className="selectSec pt-0">
      <label className="form-label fw-semibold text-primary-light text-sm mb-8">Milestone 1 <span className="text-neutral-400 fw-normal text-sm ms-8">(1 - 10 days)</span> </label>
        <div className="costSec">
          <div className="costinputSec">
          <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">₹</label>
            <input type="number" name="OAD" min={0} defaultValue="00" onChange={(e)=>setOAD({...OAD,value:e.target.value})}/>
          </div>
          <select className="slectIconsShow" onChange={(e)=>setOAD({...OAD,type:e.target.value})}>
            <option value="Above">Above</option>           
            <option value="Below">Below</option>           
          </select> 
        </div>
      </div>
      <div className="selectSec pt-0">
      <label className="form-label fw-semibold text-primary-light text-sm mb-8">Milestone 2 <span className="text-neutral-400 fw-normal text-sm ms-8">(11 - 20 days)</span> </label>
        <div className="costSec">
          <div className="costinputSec">
          <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">₹</label>
            <input type="number" name="OAD" min={0} defaultValue="00" onChange={(e)=>setOAD({...OAD,value:e.target.value})}/>
          </div>
          <select className="slectIconsShow" onChange={(e)=>setOAD({...OAD,type:e.target.value})}>
            <option value="Above">Above</option>           
            <option value="Below">Below</option>           
          </select> 
        </div>
      </div>
      <div className="selectSec pt-0">
      <label className="form-label fw-semibold text-primary-light text-sm mb-8">Milestone 3 <span className="text-neutral-400 fw-normal text-sm ms-8">(21 - 30 days)</span> </label>
        <div className="costSec">
          <div className="costinputSec">
          <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">₹</label>
            <input type="number" name="OAD" min={0} defaultValue="00" onChange={(e)=>setOAD({...OAD,value:e.target.value})}/>
          </div>
          <select className="slectIconsShow" onChange={(e)=>setOAD({...OAD,type:e.target.value})}>
            <option value="Above">Above</option>           
            <option value="Below">Below</option>           
          </select> 
        </div>
      </div>
      <div className="selectSec pt-0">
      <label className="form-label fw-semibold text-primary-light text-sm mb-8">Delivered 1 <span className="text-neutral-400 fw-normal text-sm ms-8">(1 - 10 days)</span> </label>
        <div className="costSec">
          <div className="costinputSec">
          <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">₹</label>
            <input type="number" name="OAD" min={0} defaultValue="00" onChange={(e)=>setOAD({...OAD,value:e.target.value})}/>
          </div>
          <select className="slectIconsShow" onChange={(e)=>setOAD({...OAD,type:e.target.value})}>
            <option value="Above">Above</option>           
            <option value="Below">Below</option>           
          </select> 
        </div>
      </div>
      <div className="selectSec pt-0">
      <label className="form-label fw-semibold text-primary-light text-sm mb-8">Delivered 2 <span className="text-neutral-400 fw-normal text-sm ms-8">(11 - 20 days)</span> </label>
        <div className="costSec">
          <div className="costinputSec">
          <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">₹</label>
            <input type="number" name="OAD" min={0} defaultValue="00" onChange={(e)=>setOAD({...OAD,value:e.target.value})}/>
          </div>
          <select className="slectIconsShow" onChange={(e)=>setOAD({...OAD,type:e.target.value})}>
            <option value="Above">Above</option>           
            <option value="Below">Below</option>           
          </select> 
        </div>
      </div>
      <div className="selectSec pt-0">
      <label className="form-label fw-semibold text-primary-light text-sm mb-8">Delivered 3 <span className="text-neutral-400 fw-normal text-sm ms-8">(21 - 30 days)</span> </label>
        <div className="costSec">
          <div className="costinputSec">
          <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">₹</label>
            <input type="number" name="OAD" min={0} defaultValue="00" onChange={(e)=>setOAD({...OAD,value:e.target.value})}/>
          </div>
          <select className="slectIconsShow" onChange={(e)=>setOAD({...OAD,type:e.target.value})}>
            <option value="Above">Above</option>           
            <option value="Below">Below</option>           
          </select> 
        </div>
      </div>
    </div> 
  </div>
  <div className="offcanvas-footer px-16">
      <button
        type="button"
        className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12"
        onClick={applyFilter}
      >
        Save Filter
      </button>
    </div>
</div>

    )
}
export default FilterAuditReportModal