import React, { useState, useEffect } from 'react'
import ReportTab from '../ReportTab'
import SalaryExpenditure from './SalaryExpenditure'
import { accountsReports } from 'src/api/ReportsAPI'
import dayjs from "dayjs";
import ExceptedRevenue from './ExceptedRevenue';
import ExceptedReceived from './ExceptedReceived';

const AccountsReportContainer = () => {

    const [salary, setSalary] = useState([])
    const [exceptedRevenue, setExceptedRevenue] = useState([])
    const apiData = async () => {
        try {
            const resp = await accountsReports({});
            // console.log("resp", resp?.data?.data);
            setSalary(resp?.data?.salaryExpenditure)
            setExceptedRevenue(resp?.data?.exceptedRevenue)
        } catch (err) {
            console.error('Failed to accounts report. ' + err.message);
        }
    }
    useEffect(() => {
        apiData()
    }, [])


    return (
        <div className="dashboard-main-body">
            {/*gy-4 mt-1*/}
            <div className="row">
                <div className="card">
                    <div className="card-body p-24">
                        <ReportTab />
                        <ExceptedRevenue exceptedRevenueData={exceptedRevenue} />
                    </div>
                </div>
                <ExceptedReceived exceptedRevenueData={exceptedRevenue} salary={salary} />
                {/* Start Deliverd Chat one */}
                {/* <div className="card mt-12">
                    <div className="card-body p-24 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                Delivered Amount
                            </h6>
                            <div className="d-flex align-items-center  gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="horizontal"
                                        id="deliveredChart1"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="deliveredChart1"
                                    >
                                        {" "}
                                        Total
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="deliveredChart"
                                        id="deliveredChart2"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="deliveredChart2"
                                    >
                                        {" "}
                                        Govt
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="deliveredChart"
                                        id="deliveredChart3"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="deliveredChart3"
                                    >
                                        {" "}
                                        Acadecarft
                                    </label>
                                </div>
                                <div className="datepker position-relative">
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <iconify-icon
                                            icon="solar:calendar-linear"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                    </span>
                                    <input
                                        className="form-control radius-8 bg-base flatpickr-input"
                                        type="text"
                                        id="flat"
                                        placeholder="Date"
                                        readOnly="readonly"
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="mt-2 allchartsCss">
                            <div id="dilverChatSecOne" />
                        </div>
                    </div>
                </div> */}
                {/* End Deliverd Chat one */}
                {/* Start Deliverd Chat Two */}
                {/* <div className="card mt-12">
                    <div className="card-body p-24 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                Delivered Amount
                            </h6>
                            <div className="d-flex align-items-center  gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="horizontal"
                                        id="amountChart1"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="amountChart1"
                                    >
                                        {" "}
                                        Total
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="amountChart"
                                        id="amountChart2"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="amountChart2"
                                    >
                                        {" "}
                                        Govt
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="amountChart"
                                        id="amountChart3"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="amountChart3"
                                    >
                                        {" "}
                                        Acadecarft
                                    </label>
                                </div>
                                <div className="datepker position-relative">
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <iconify-icon
                                            icon="solar:calendar-linear"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                    </span>
                                    <input
                                        className="form-control radius-8 bg-base flatpickr-input"
                                        type="text"
                                        id="flat"
                                        placeholder="Date"
                                        readOnly="readonly"
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="mt-2 allchartsCss">
                            <div id="dilverChatSecTwo" />
                        </div>
                    </div>
                </div> */}
                {/* End Deliverd Chat Two */}
                {/* Start Profit & Loss Chat  */}
                {/* <div className="card mt-12">
                    <div className="card-body p-24 mt-2">
                        <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                            <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                Profit &amp; Loss
                            </h6>
                            <div className="d-flex align-items-center  gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="horizontal"
                                        id="profitChart1"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="profitChart1"
                                    >
                                        {" "}
                                        Total
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="profitChart"
                                        id="profitChart2"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="profitChart2"
                                    >
                                        {" "}
                                        Govt
                                    </label>
                                </div>
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="profitChart"
                                        id="profitChart3"
                                    />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="profitChart3"
                                    >
                                        {" "}
                                        Acadecarft
                                    </label>
                                </div>
                                <div className="datepker position-relative">
                                    <span className="position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <iconify-icon
                                            icon="solar:calendar-linear"
                                            className="icon text-lg"
                                        ></iconify-icon>
                                    </span>
                                    <input
                                        className="form-control radius-8 bg-base flatpickr-input"
                                        type="text"
                                        id="flat"
                                        placeholder="Date"
                                        readOnly="readonly"
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="mt-2 allchartsCss">
                            <div id="profitlosschat" />
                        </div>
                    </div>
                </div> */}
                {/* End Profit & Loss Chat  */}

                <SalaryExpenditure salary={salary} />
            </div>
        </div>
    )
}

export default AccountsReportContainer