import React, { useEffect, useState } from 'react'
import FinancialYearDropdown from 'src/components/FinancialYearDropdown'
import NoWorkAvailable from './NoWorkAvailable'
import { operationsNoWorkReportAPI } from 'src/api/ReportsAPI';
import { convertMintuesToHours, convertYearRangeToObject, getCurrentFinancialYear } from 'src/utils/CommonUtils';

// Function to get initials from manager name
const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts.map(part => part[0]).join('');
    return initials;
};

// Function to determine background color based on manager's name
const getBackgroundColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 70%, 50%)`; // HSL for more variation
    return color;
};

// Function to determine text color based on background color (light or dark)
const getTextColor = (bgColor) => {
    const rgb = bgColor.match(/\d+/g).map(Number);
    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return brightness > 150 ? '#000' : '#fff'; // Light or dark text based on background
};

const NoWorkContainer = () => {    
    const [selectedYear, setSelectedYear] = useState(getCurrentFinancialYear(false)); // Default selected year
    const [where, setWhere] = useState(getCurrentFinancialYear());
    const [productions, setProductions] = useState([])

    const apiData = async () => {
        try {
            const resp = await operationsNoWorkReportAPI({ where });
            setProductions(resp?.data?.data?.production);
        } catch (err) {
            console.error('Failed to operation report. ' + err.message);
        }
    }
    useEffect(() => {
        apiData()
    }, [where])
    // Group by month-year and then by departmentName, converting minutes to hours
    const groupedData = productions.reduce((acc, item) => {
        const date = new Date(item?.date);
        const monthYear = date.toLocaleString('en-US', { month: 'short', year: 'numeric' }); // e.g., 'Jan 2025'
        const departmentName = item?.departmentName;
        const totalDurationsInHours = item?.totalDurations;

        // Initialize the month-year group if it doesn't exist
        if (!acc[monthYear]) {
            acc[monthYear] = {};
        }

        // Initialize the department if it doesn't exist within the month-year group
        if (!acc[monthYear][departmentName]) {
            acc[monthYear][departmentName] = 0;
        }

        // Add the totalDurations (in hours) to the department within the month-year group
        acc[monthYear][departmentName] += totalDurationsInHours;

        return acc;
    }, {});

    // Transform grouped data into pie chart format
    const pieChartData = Object.keys(groupedData).map((monthYear) => ({
        id: `pieChartWork${monthYear.replace(/\s/g, '')}`,  // Generate unique id using the monthYear
        month: monthYear,  // Set the month-year as the label (e.g., 'Jan 2025')
        title: `Total No work<br>Available<br>${convertMintuesToHours(Object.values(groupedData[monthYear]).reduce((a, b) => a + b, 0))} Hrs`,
        data: Object.keys(groupedData[monthYear]).map((departmentName) => [
            departmentName,
            convertMintuesToHours(groupedData[monthYear][departmentName])
        ]),
    }));

    const handleYearChange = (yearRange) => {
        setWhere(convertYearRangeToObject(yearRange))
        setSelectedYear(yearRange); // Update selected year
    }

    const [modalParam, setModalParam] = useState({});
    const [usersData, setUsersData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('')
    const openOffcanvas = (data) => {
        setModalParam(data); // Set the data for the offcanvas content
        const offcanvasElement = document.getElementById("offcanvasRight");
        const offcanvas = new window.bootstrap.Offcanvas(offcanvasElement);
        offcanvas.show(); // Show the offcanvas

        setUsersData(productions.filter((item) => item?.departmentName === data.name))
        // Filter by departmentName and input date
        const filteredData = productions.filter((item) => {
            const itemDate = new Date(item?.date);
            const itemMonthYear = itemDate.toLocaleString("en-US", { month: "short", year: "numeric" }); // e.g., "Jan 2025"
            return item?.departmentName === data.name && itemMonthYear === data.month;
        });

        setUsersData(filteredData);
        setSearchTerm('')
    };

    const groupByUserId = (data) => {
        const grouped = {};

        data.forEach((item) => {
            const { userId, totalDurations, date, ...rest } = item;

            if (!grouped[userId]) {
                grouped[userId] = {
                    userId,
                    sumtotalDurations: 0,
                    dates: [],
                    ...rest,
                };
            }

            grouped[userId].sumtotalDurations += totalDurations;

            // Extract date-only (YYYY-MM-DD)
            const dateOnly = new Date(date).toISOString().split("T")[0];

            // Check if the date already exists in the dates array
            const existingDate = grouped[userId].dates.find((d) => d.date === dateOnly);

            if (existingDate) {
                existingDate.totalDurations += totalDurations; // Add to the existing totalDurations
            } else {
                grouped[userId].dates.push({ totalDurations, date: dateOnly });
            }
        });

        return Object.values(grouped);
    };

    const filteredData = searchTerm
        ? usersData.filter(item =>
            (item?.employeeID && item?.employeeID.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item?.fullName && item?.fullName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item?.cadre_level && item?.cadre_level.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (item?.designationName && item?.designationName.toLowerCase().includes(searchTerm.toLowerCase()))
        )
        : usersData;
    const result = groupByUserId(filteredData);

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <div className="card-body">
            <div className='d-flex justify-content-between align-items-center mb-24'>
                <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                    No work Available
                </h6>
                <div className="mw-200-px">
                    <FinancialYearDropdown isBlankOption={false} selectedYear={selectedYear} onYearChange={handleYearChange} />
                </div>
            </div>

            <div className='col-md-12' style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(49%, 1fr))',
                gap: '20px',
            }}>
                {pieChartData.map((chart, index) => (
                    <NoWorkAvailable
                        key={index}
                        chartId={chart.id}
                        month={chart.month}
                        title={chart.title}
                        data={chart.data}
                        onChartClick={openOffcanvas}
                    />
                ))}
            </div>

            <>

                <div
                    className="offcanvas offcanvas-end resizable_div"
                    tabIndex={-1}
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                    style={{ width: "100%" }}
                >
                    <div className="offcanvas-header">
                        <h5 id="offcanvasRightLabel">{modalParam?.month} No Work Available</h5>
                        <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        />
                    </div>
                    <div className="offcanvas-body">
                        <div className="noWorkSec">
                            <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between mb-16">
                                <div className="workHeadSec">
                                    <h6 className="text-lg fw-semibold mb-0 text-info-600">
                                        <span>Department :</span>{modalParam?.name}
                                    </h6>
                                    <h6 className="text-lg fw-semibold mb-0 text-info-600">
                                        <span>Total Hrs :</span>{convertMintuesToHours(result.reduce((accumulator, currentItem) => { return accumulator + currentItem.sumtotalDurations; }, 0))} Hrs
                                    </h6>
                                </div>
                                <div className="search searWithTb">
                                    <span className="searchBtn position-absolute end-0 top-50 translate-middle-y me-12 line-height-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeMiterlimit={10}
                                                strokeWidth={32}
                                                d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                                            ></path>
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeMiterlimit={10}
                                                strokeWidth={32}
                                                d="M338.29 338.29L448 448"
                                            ></path>
                                        </svg>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control radius-8"
                                        id="search"
                                        autoComplete='off'
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleSearchInputChange}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive table-container" id="table-container">
                                {result.length === 0 ? <div>No search data</div> :
                                    <table className="table basic-border-table mb-0 table-hover">
                                        <thead>
                                            <tr className="dnd-moved">
                                                <th className="w30px dragablefalse" draggable="true">
                                                    <label className="form-check-label">S.No.</label>
                                                </th>
                                                <th draggable="true">Employee ID</th>
                                                <th draggable="true">Name</th>
                                                <th draggable="true">Cadre level</th>
                                                <th draggable="true">Designation</th>
                                                <th className="text-center" draggable="true">
                                                    No of Hours
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {result
                                                .sort((a, b) => a.fullName.localeCompare(b.fullName))
                                                .map((item, index) => {

                                                    const bgColor = getBackgroundColor(item?.fullName);
                                                    const textColor = getTextColor(bgColor);
                                                    return (
                                                        <>
                                                            <tr
                                                                className="dnd-moved collapseSecTb"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#${item?._id}`}
                                                            >
                                                                <td>
                                                                    <label className="form-check-label">{index + 1}</label>
                                                                </td>
                                                                <td className="text-center">
                                                                    <span className="bg-neutral-focus btn-neutral-900 text-bas px-24 py-4 rounded-pill fw-medium text-sm bgPutple">
                                                                        {item?.employeeID}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        {item?.profilePicture ? (
                                                                            <img
                                                                                src={item?.profilePicture}
                                                                                alt={item?.fullName}
                                                                                className="initials-image"
                                                                                style={{ width: '35px', height: '35px', borderRadius: '50%', marginRight: '8px' }}
                                                                            />
                                                                        ) : (
                                                                            <span
                                                                                className="initials-circle UsIMgName"
                                                                                style={{ backgroundColor: bgColor, color: textColor }}>
                                                                                {getInitials(item?.fullName)}
                                                                            </span>
                                                                        )}
                                                                        {item?.fullName}
                                                                    </div>
                                                                </td>
                                                                <td>{item?.cadre_level}</td>
                                                                <td>{item?.designationName}</td>
                                                                <td className="text-center">
                                                                    <div className="d-flex align-items-center gap-2 justify-content-center">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <div className="flex-grow-1">
                                                                                <h6 className="text-md mb-0 fw-medium text-primary-600">
                                                                                    {convertMintuesToHours(item?.sumtotalDurations)}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                        <button
                                                                            className="btn rounded-pill btn-outline-info-600 d-flex align-items-center justify-content-center accordion-toggle togalnewscl p-0 me-2 collapsed"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#${item?._id}`}
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width={16}
                                                                                height={16}
                                                                                fill="currentColor"
                                                                                className="bi bi-caret-down-fill"
                                                                                viewBox="0 0 16 16"
                                                                            >
                                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr
                                                                id={item?._id}
                                                                className="accordion-collapse collapse uldropdn bgliggry custoLineSet"
                                                            >
                                                                <td colSpan={1} className="hiddenRow p-0 bgliggry" />
                                                                <td colSpan={3} className="hiddenRow p-0 bgliggry">
                                                                    <div className="subNameD">
                                                                        {item?.dates.map(row =>
                                                                            <div className="subNamev">&nbsp;</div>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td className="hiddenRow bgliggry">
                                                                    <div className="noOfhoursD">
                                                                        {item?.dates.sort((a, b) => a.date.localeCompare(b.date)).map(row =>
                                                                            <div className="d-flex flex-wrap align-items-center justify-content-between gap-5 marTabSet">
                                                                                <span className="fw-bold">Date :</span> {row?.date}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td className="hiddenRow bgliggry">
                                                                    <div className="noOfhoursD">
                                                                        {item?.dates.sort((a, b) => a.date.localeCompare(b.date)).map(row =>
                                                                            <div className="text-center marTabSet">{convertMintuesToHours(row?.totalDurations)}</div>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>)
                                                })}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>

                    </div>
                    <div className="resizer1" />
                </div>
            </>
        </div>
    )
}

export default NoWorkContainer