import React, { useEffect } from "react";
import classNames from "classnames";
import { shallowEqual, useSelector } from "react-redux";
import { getDateFromDate, getDateRange, getFullDayFromDate, getSupTag } from "src/utils/utils";
import { generateMonthData } from "src/utils/CommonUtils";

const DayCard = ({ date, status, attendance, hours, activity, comments, Leave }) => {
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  return (
    <div className={`day-card ${(status !== "Present" && attendance === "Present") ? 'mark-present-non-working' : ''}`}>
      <div className="d-flex justify-content-between align-items-center">
        <div data-bs-toggle="modal" data-bs-target="#ReviewEmployeeModalModal"
          className={classNames("d-flex gap-2 align-items-center text-sm status mb-6", {
            "text-danger-500": status === "Week Off" || status === "Absent",
            "text-danger-800": status === "Other Leave",
            "text-success-600": status === "Present",
            "text-pink-500": status === "Holiday",
            "text-blue-500": status === "Casual Leave",
            "text-yellow-500": status === "Sick Leave"
          })}
        >
          <span
            className={classNames("badge text-sm fw-semibold w-20-px h-20-px d-flex justify-content-center align-items-center rounded-circle text-white", {
              "bg-danger-500": status === "Week Off" || status === "Absent",
              "bg-danger-800": status === "Other Leave",
              "bg-success-600": status === "Present",
              "bg-pink-500": status === "Holiday",
              "bg-blue-500": status === "Casual Leave",
              "bg-yellow-500": status === "Sick Leave"
            })}
          >
            {date}
          </span>
          {status}
          {getSupTag(Leave?.dayType)}
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center text-sm fw-medium">
        Hour: <span className="fw-bold">{hours}</span>
      </div>
      <div className="d-flex justify-content-between align-items-center text-sm fw-medium">
        Activity: <span className="text-success-600 fw-semibold" data-bs-toggle="modal" data-bs-target="#ReviewEmployeeModalModal">{activity}</span>
      </div>
      <div className="d-flex justify-content-between align-items-center text-sm fw-medium">
        Status:
        <span className="text-end">
          {
            (status === "Casual Leave" ||
              status === "Sick Leave" ||
              status === "Other Leave")
              ? (
                Leave.status == 1 ? (
                  <span className="badge text-sm fw-semibold w-20-px h-20-px d-flex justify-content-center align-items-center rounded-circle bg-primary-600 text-white"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-custom-class="tooltip-dark"
                    data-bs-title="Leave Approved"
                  >
                    <i className="mdi mdi-thumb-up"></i>
                  </span>
                ) : (
                  <span className="badge text-sm fw-semibold w-20-px h-20-px d-flex justify-content-center align-items-center rounded-circle bg-danger-600 text-white"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-custom-class="tooltip-dark"
                    data-bs-title="Leave Not Approved"
                  >
                    <i className="mdi mdi-thumb-down"></i>
                  </span>
                )
              ) : null
          }
        </span>
      </div>
    </div>
  );
};

const WeekDayColumn = ({ day, entries }) => {
  return (
    <div className="weekday-column">
      <h2 className="text-sm">{day}</h2>
      {entries.map((entry, index) =>
        entry && Object.keys(entry).length === 0 ? (
          <div key={index} className="day-card bg-gray" />
        ) : (
          <DayCard key={index} {...entry} />
        )
      )}
    </div>
  );
};

const ShowDetailsEmployee = () => {
  const { selectedMonth, selectedYear, search } = useSelector((state) => state.ProductionReducer, shallowEqual);
  const year = selectedYear;
  const month = selectedMonth - 1; // Adjust month index for zero-based month
  const dateWiseProductions = search.data.filter(item => item.date).map(item => new Date(item.date).toISOString().split('T')[0]);
  const dateWiseLeaves = search?.leaves?.flatMap(item => getDateRange(item.startdate, item.endate, item.subject, item.dayType, item.status));
  const dateWiseHolidays = search?.holidays;
  const monthDays = generateMonthData(year, month, dateWiseProductions, dateWiseLeaves, search?.HubstaffResponse);

  const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  // Determine if the first day of the month is not on Monday
  const firstDateOfMonth = new Date(year, month, 1);
  const firstDayOfWeek = firstDateOfMonth.getDay(); // Sunday = 0, Monday = 1, ...

  const weekdayGroups = weekDays.map((day, dayIndex) => {
    // Calculate the starting weekday of the month
    const startWeekDay = (firstDayOfWeek === 0 ? 7 : firstDayOfWeek); // Adjust Sunday (0) to 7
    const entries = [];

    // Add placeholders for days before the first day of the month
    if (dayIndex < startWeekDay - 1) {
      entries.push({}); // Push an empty placeholder
    }

    // Add actual entries for the day
    entries.push(
      ...monthDays.flatMap(dayData => {
        return dayData.filter(entry => getFullDayFromDate(entry.date) === day).map(entry => {
          const formattedDayDate = new Date(entry.date).toISOString().split('T')[0];
          const dayOfMonth = new Date(entry.date).getDate();
          const Leave = dateWiseLeaves?.find(leave => leave.date === formattedDayDate);
          const holiday = dateWiseHolidays?.find(holiday => holiday.day === dayOfMonth);

          let status = "Absent";
          // if (entry.label === "P") {
          //   status = "Present";
          //   if (Leave && Leave.subject == 1) {
          //     status = "Casual Leave";
          //   } else if (Leave && Leave.subject == 2) {
          //     status = "Sick Leave";
          //     // } else if (Leave && Leave.subject==4) {
          //     //   status = "Half Day";
          //   }
          // } else 
          if (Leave && Leave.subject == 1) {
            status = "Casual Leave";
          } else if (Leave && Leave.subject == 2) {
            status = "Sick Leave";
          } else if (Leave && Leave.subject == 3) {
            status = "Other Leave";
          } else if (holiday) {
            status = "Holiday";
          } else if (["Saturday", "Sunday"].includes(day) || entry.label === "W") {
            status = "Week Off";
            // } else if (entry.label === "P" && Leave && Leave.subject==4) {
            //   status = "Half Day";
          } else if (entry.label === "A") {
            status = "Absent";
          } else if (entry.label === "P") {
            status = "Present";
          }

          // half day count in same day
          const LeaveIST_HALF = dateWiseLeaves?.find(leave => leave.date === formattedDayDate && leave.dayType === 'IST_HALF');
          const LeaveIIND_HALF = dateWiseLeaves?.find(leave => leave.date === formattedDayDate && leave.dayType === 'IIND_HALF');
          if (LeaveIST_HALF && Object.keys(LeaveIST_HALF).length > 0 &&
            LeaveIIND_HALF && Object.keys(LeaveIIND_HALF).length > 0) {
            // Both objects exist and have properties
            Leave.dayType = null
          }

          return {
            date: getDateFromDate(entry.date),
            status,
            attendance: (entry.label === "P") ? 'Present' : 'Absent',
            hours: entry.time,
            activity: entry.activity,
            comments: Leave ? Leave.comments : holiday ? holiday.title : "NA",
            Leave,
          };
        });
      })
    );

    return { day, entries };
  });

  return (
    <div className="calendar d-flex justify-content-between">
      {weekdayGroups.map((group, index) => (
        <WeekDayColumn key={index} day={group.day} entries={group.entries} />
      ))}
    </div>
  );
};

export default ShowDetailsEmployee;
