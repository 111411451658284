import React, { useState, useEffect, useRef } from 'react'
import SummariesComponent from './SummariesComponent';
import { calculateDeviation, costFormat, fullMonthName, getDaysInMonth } from 'src/utils/CommonUtils';
import { useSticky } from 'src/hooks/useSticky';
import OrdinalNumberDisplay from 'src/components/OrdinalNumberDisplay';

// Function to get initials from manager name
const getInitials = (name) => {
  const nameParts = name.split(' ');
  const initials = nameParts.map(part => part[0]).join('');
  return initials;
};

// Function to determine background color based on manager's name
const getBackgroundColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 70%, 50%)`; // HSL for more variation
  return color;
};

// Function to determine text color based on background color (light or dark)
const getTextColor = (bgColor) => {
  const rgb = bgColor.match(/\d+/g).map(Number);
  const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return brightness > 150 ? '#000' : '#fff'; // Light or dark text based on background
};

// Function to determine text color based on delivery percentage
const getClassName = (value) => {
  return value.includes('-') ? 'text-red' : 'text-green';
};


const PddstatsManagerMilestone = (props) => {
  const monthName = fullMonthName(props.currentMonth, true)
  const lastDayMonth = getDaysInMonth({ year: props.currentYear, month: props.currentMonth }) //getDaysInMonth('2025-01-01') // 31
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props?.search)
  }, [props])


  // Group by managerId and sum the totals
  const groupedData = Object.values(
    data.reduce((acc, item) => {
      if (!acc[item.managerId]) {
        acc[item.managerId] = {
          managerId: item.managerId,
          profilePicture: item.profilePicture,
          fullName: item.fullName.trim() ? item.fullName : "No Manager", // add fullName on first encounter
          totalOad: 0,
          totalcmd: 0,
          totalDeliveryCost: 0,
          milestone1CMD: 0,
          milestone1DeliveryCost: 0,
          milestone2CMD: 0,
          milestone2DeliveryCost: 0,
          milestone3CMD: 0,
          milestone3DeliveryCost: 0,
        };
      }
      acc[item.managerId].totalOad += item.totalOad;
      acc[item.managerId].totalcmd += item.totalcmd;
      acc[item.managerId].milestone1CMD += item.milestone1CMD;
      acc[item.managerId].milestone2CMD += item.milestone2CMD;
      acc[item.managerId].milestone3CMD += item.milestone3CMD;
      acc[item.managerId].totalDeliveryCost += item.totalDeliveryCost;
      acc[item.managerId].milestone1DeliveryCost += item.milestone1DeliveryCost;
      acc[item.managerId].milestone2DeliveryCost += item.milestone2DeliveryCost;
      acc[item.managerId].milestone3DeliveryCost += item.milestone3DeliveryCost;
      return acc;
    }, {})
  );
  // Sort by fullName in ascending order
  groupedData.sort((a, b) => a.fullName.localeCompare(b.fullName));

  // console.log("groupedData22", getDaysInMonth({ year: "2025", month: "1" }));



  const trimmedQuery = (typeof props.searchQuery === 'string' ? props.searchQuery : '').trim().toLowerCase();
  const filteredData = trimmedQuery
    ? groupedData.filter((item) =>
      // item.ClientProjectCode.toLowerCase().includes(trimmedQuery) ||
      item.fullName.toLowerCase().includes(trimmedQuery)
    )
    : groupedData; // Return all data if the searchQuery is empty

  console.log("searchQuery", props?.searchQuery);


  const [totalOAD2, finalCMD, totalCMD1, totalMilestone1Delivery, totalCMD2, totalMilestone2Delivery, totalCMD3, totalMilestone3Delivery, totalDelivery2] = filteredData.reduce(
    ([oadSum, cmdSum, cmd1Sum, milestone1DeliverySum, cmd2Sum, milestone2DeliverySum, cmd3Sum, milestone3DeliverySum, deliverySum], item) => [
      oadSum + (item.totalOad || 0),
      cmdSum + (item.totalcmd || 0),
      cmd1Sum + (item.milestone1CMD || 0),
      milestone1DeliverySum + (item.milestone1DeliveryCost || 0),
      cmd2Sum + (item.milestone2CMD || 0),
      milestone2DeliverySum + (item.milestone2DeliveryCost || 0),
      cmd3Sum + (item.milestone3CMD || 0),
      milestone3DeliverySum + (item.milestone3DeliveryCost || 0),
      deliverySum + (item.totalDeliveryCost || 0)
    ],
    [0, 0, 0, 0, 0, 0, 0, 0, 0]
  );

  const summaries2 = [
    { title: 'Total Manager', value: filteredData.length },
    { title: 'Total OAD', value: costFormat(totalOAD2) },
    { title: 'Total CMD', value: costFormat(finalCMD) },
    { title: 'Total Delivered', value: costFormat(totalDelivery2) },
    // {
    //   title: (
    //     <>
    //       {'CMD '}
    //       <OrdinalNumberDisplay number="1" /> - <OrdinalNumberDisplay number="10" />
    //     </>
    //   ), value: costFormat(totalCMD1)
    // },
    // {
    //   title:
    //     (
    //       <>
    //         {'CMD '}
    //         <OrdinalNumberDisplay number="11" /> - <OrdinalNumberDisplay number="20" />
    //       </>
    //     ), value: costFormat(totalCMD2)
    // },
    // {
    //   title: (
    //     <>
    //       {'CMD '}
    //       <OrdinalNumberDisplay number="21" /> - <OrdinalNumberDisplay number={lastDayMonth} />
    //     </>
    //   ), value: costFormat(totalCMD3)
    // },
  ];

  // const { isSticky, elementRef } = useSticky(); // Use the custom hook
  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

  useEffect(() => {
    const handleScroll = () => {
      // Clear the previous timeout to prevent multiple updates
      clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          // Check if the table <thead> is within the viewport range
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
    };
  }, []);
  // Table Scroll Code Start

  return (

    <>
      <div className="TotlUbgset pb-24">
        <SummariesComponent summaries={summaries2} /></div>
      <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
        <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Manager</th>
              <th>OAD</th>
              <th>CMD <OrdinalNumberDisplay number="1" />-<OrdinalNumberDisplay number="10" /> {monthName}</th>
              <th>Delivered</th>
              <th>CMD <OrdinalNumberDisplay number="11" />-<OrdinalNumberDisplay number="21" /> {monthName}</th>
              <th>Delivered</th>
              <th>CMD <OrdinalNumberDisplay number="21" />-<OrdinalNumberDisplay number={lastDayMonth} /> {monthName}</th>
              <th>Delivered</th>
              <th>% Delivered</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">No data available.</td>
              </tr>
            ) : (
              filteredData.map((row, index) => {
                const bgColor = getBackgroundColor(row.fullName);
                const textColor = getTextColor(bgColor);
                const deviation = calculateDeviation(row.totalDeliveryCost, row.totalOad)
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        {row.profilePicture ? (
                          <img
                            src={row.profilePicture}
                            alt={row.fullName}
                            className="initials-image"
                            style={{ width: '35px', height: '35px', borderRadius: '50%', marginRight: '8px' }}
                          />
                        ) : (
                          <span
                            className="initials-circle UsIMgName"
                            style={{ backgroundColor: bgColor, color: textColor }}>
                            {getInitials(row.fullName)}
                          </span>
                        )}
                        {row.fullName}
                      </div>
                    </td>
                    <td>{costFormat(row.totalOad)}</td>
                    <td>{costFormat(row.milestone1CMD)}</td>
                    <td>{costFormat(row.milestone1DeliveryCost)}</td>
                    <td>{costFormat(row.milestone2CMD)}</td>
                    <td>{costFormat(row.milestone2DeliveryCost)}</td>
                    <td>{costFormat(row.milestone3CMD)}</td>
                    <td>{costFormat(row.milestone3DeliveryCost)}</td>
                    <td className={`text-end ${getClassName(deviation)}`}>
                      {deviation}
                    </td>
                  </tr>
                );
              }))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="2">&nbsp;</th>
              <th>{costFormat(totalOAD2)}</th>
              <th>{costFormat(totalCMD1)}</th>
              <th>{costFormat(totalMilestone1Delivery)}</th>
              <th>{costFormat(totalCMD2)}</th>
              <th>{costFormat(totalMilestone2Delivery)}</th>
              <th>{costFormat(totalCMD3)}</th>
              <th>{costFormat(totalMilestone3Delivery)}</th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
    </>

  )
}

export default PddstatsManagerMilestone
