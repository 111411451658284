import { shallowEqual, useSelector } from "react-redux"
import { formatDate, formatTime } from "src/utils/utils";
import { useDispatch } from 'react-redux';
import { useEffect } from "react";

const ProductionList = ({ setReApplyPendingList,setReAppliedId }) => {
    // const {} = props;
    const dispatch = useDispatch();

    const { search } = useSelector((state) => state.ProductionReducer, shallowEqual);
    const handleComment = (production) => {
        dispatch({ type: "PRODUCTION_CURRENT_PRODUCTION", data: production });
    };
    const handleFileDownload = (production) => {
        production?.files.forEach(file => {
            const a = document.createElement('a');
            a.href = file.url;
            a.target = '_blank'; // Open in a new tab
            a.click();
        });
    };
    useEffect(() => {
        const filterReapplyPendingList = search?.data?.filter((item) => item?.reApply === true && item?.reApplied === false);
        setReApplyPendingList(filterReapplyPendingList);
    }, [search])
    return (
        <>
            {
                search?.data?.map((item, index) => {
                    const productionManager = (item?.productionManagers) ? item?.productionManagers?.firstName + " " + item?.productionManagers?.lastName : "NA";
                    const status = item?.totalDurations <= ((item.approvedDurations || 0) + (item.rejectedDurations || 0)) ? "Reviewed" : "Pending";
                    return (
                        <tr key={item._id}>
                            <td>
                                <label className="form-check-label">{index + 1}</label>
                            </td>
                            <td>{formatDate(item?.date)}</td>
                            {/* <td>{formatTime(item?.ts)}</td>  */}
                            <td>
                                <div className="fw-medium">{`${item?.clientId?.ClientProjectCode} (${item?.projectId?.ProjectSubCode})`}</div>
                            </td>

                            <td className="text-center">{item?.taskId?.taskName}</td>
                            <td className="text-center">{item?.moduleId?.moduleName || "NA"}</td>
                            <td className="text-center">{item?.taskId?.target}</td>
                            <td className="text-center">{item?.taskId?.unit}</td>
                            <td className="text-center">{item?.totalDurations}</td>
                            <td className={`text-center ${(status === "Reviewed") ? 'bg-success-400' : 'bg-warning-600'}`}>
                                <span className="d-flex border-0 align-items-center gap-4 justify-content-between text-white fw-semibold py-2 w-100">
                                    {status}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={22}
                                        height={22}
                                        fill="currentColor"
                                        className={`bi ${(status === "Reviewed") ? 'bi-check-circle' : 'bi-clock'} `}
                                        viewBox="0 0 16 16"
                                    >
                                        {
                                            (status === "Reviewed") ? (
                                                <>
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                                    <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                                </>
                                            ) : (
                                                <>
                                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"></path>
                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"></path>
                                                </>
                                            )
                                        }

                                    </svg>

                                    {" "}
                                </span>
                            </td>
                            <td className="text-center">{productionManager} 
                                 {/* <button
                                    className="text-primary-900"
                                    data-bs-toggle="modal"
                                    data-bs-target="#ReviewedByModal" 
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={22}
                                        height={22}
                                        fill="currentColor"
                                        className="bi bi-eye-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                </button> */}
                                </td>
                            <td className="text-center">
                                {
                                    (item?.files?.length > 0) ?
                                        <button className="d-flex align-items-center gap-2 text-primary-600"
                                            onClick={() => { handleFileDownload(item) }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={22}
                                                height={22}
                                                fill="currentColor"
                                                className="bi bi-file-earmark-fill"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z" />
                                            </svg>{" "}
                                            View
                                        </button>
                                        : "NA"
                                }

                            </td>
                            <td className="text-center">
                                <button
                                    className="text-primary-900"
                                    data-bs-toggle="modal"
                                    data-bs-target="#CommentModal"
                                    onClick={() => handleComment(item)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={22}
                                        height={22}
                                        fill="currentColor"
                                        className="bi bi-eye-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                    </svg>
                                </button>
                            </td>
                            <td className="text-center">{item?.approvedDurations || 0}</td>
                            <td className="text-center">{((item?.totalDurations || 0) - ((item?.approvedDurations || 0) + (item?.rejectedDurations || 0)))}</td>
                            <td className="text-center">{item?.rejectedDurations || 0}</td>
                            <td className="text-center">
                                {
                                    (item?.reApplyComment) ?
                                        (
                                            <button
                                                className="text-primary-900"
                                                data-bs-toggle="modal"
                                                data-bs-target="#RejectionCommentModal"
                                                onClick={() => handleComment(item)}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={22}
                                                    height={22}
                                                    fill="currentColor"
                                                    className="bi bi-eye-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                                                </svg>
                                            </button>
                                        ) : "NA"
                                }
                            </td>
                            <td className="text-center">
                                {
                                    (item?.reApply&& !item?.reApplied) ?
                                        (
                                            <button
                                            data-bs-placement="top"
                                            data-bs-custom-class="tooltip-dark"
                                            data-bs-title={`Add new Production`}
                                            data-bs-toggle={`modal`}
                                            data-bs-target="#addProduction"
                                            className="btn btn-primary text-sm btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2" 
                                            onClick={()=>{setReAppliedId(item._id)}}
                                            >
                                            Re-Apply
                                            </button>
                                        ) : "NA"
                                }
                            </td>
                        </tr>
                    )
                })
            }
        </>
    )
}
export default ProductionList