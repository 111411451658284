import { Field, ErrorMessage } from 'formik';
import React, { useEffect, useState } from "react";

const EncashmentDetail = ({encashmentDetails,leaveDetails,values}) => {
    // Define state variables for each input field 
    const [totalDeduction, setTotalDeduction] = useState(0); // Initial value
    useEffect(() => {
        const day_salary = Number(leaveDetails?.daySalary) || 0;  
        if (values?.encashmentDetails) {
            // Calculate amounts and ensure all values are parsed as numbers
            values.encashmentDetails.leaveEncashmentAmount = Number((values.encashmentDetails.leaveEncashment || 0) * day_salary).toFixed(2);
            values.encashmentDetails.incentivesAmount = Number((values.encashmentDetails.incentives || 0) * day_salary).toFixed(2);
            values.encashmentDetails.previousMonthAmount = Number((values.encashmentDetails.previousMonth || 0) * day_salary).toFixed(2);
            // values.encashmentDetails.performanceBonus = Number((values.encashmentDetails.performanceBonus || 0) * day_salary).toFixed(2);
    
            // Deduction calculation and total encashment calculation
            const totalDeduction = Number(values.encashmentDetails.totalDeduction || 0) * day_salary;
            values.encashmentDetails.totalDeduction = totalDeduction.toFixed(2);
    
            const totalEncashment = Number((
                Number(values.encashmentDetails.leaveEncashmentAmount) +
                Number(values.encashmentDetails.incentivesAmount) +
                Number(values.encashmentDetails.previousMonthAmount) +
                Number(values.encashmentDetails.performanceBonus)+
                Number(values.encashmentDetails.increment)
            )).toFixed(2);
    
            values.encashmentDetails.totalEncashment = totalEncashment;
    
            // Update netSalary, ensuring it’s a number
            // values.netSalary = (Number(values.netSalary) + Number(totalEncashment)).toFixed(2);
        }
    }, [values]);

    return (
        <div className="setbdn100">
            <h4 className="card-title">Encashment Detail</h4>
            <div className="radius-10 border p-16">
                <div className="align-items-center d-flex justify-content-between py-8 mt-16">
                    <label className="form-label mb-0">Leave Encashment</label>
                    <div className="d-flex gap-2 align-items-center">
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base">Day</span>
                            <Field
                                type="text"
                                id="leaveEncashment"
                                className="form-control flex-grow-1 text-end"
                                placeholder="0"
                                name="encashmentDetails.leaveEncashment"
                                // value={encashmentDetails?.leaveEncashment}
                                // onChange={(e) => setLeaveEncashmentDay(e.target.value)}
                            />
                        </div>
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base border-end-0">&#8377;</span>
                            <Field
                                type="text"
                                id="leaveEncashmentAmount"
                                name="encashmentDetails.leaveEncashmentAmount"
                                className="form-control flex-grow-1 text-end border-start-0"
                                placeholder="0"
                                // value={encashmentDetails?.leaveEncashmentAmount}
                                // onChange={(e) => setLeaveEncashmentAmount(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Incentives:</label>
                    <div className="d-flex gap-2 align-items-center">
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base">Day</span>
                            <Field
                                type="text"
                                id="incentives"
                                name="encashmentDetails.incentives"
                                className="form-control flex-grow-1 text-end"
                                placeholder="0"
                                // value={encashmentDetails?.incentives}
                                // onChange={(e) => setIncentivesDay(e.target.value)}
                            />
                        </div>
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base border-end-0">&#8377;</span>
                            <Field
                                type="text"
                                id="incentivesAmount"
                                name="encashmentDetails.incentivesAmount"
                                className="form-control flex-grow-1 text-end border-start-0"
                                placeholder="0"
                                // value={encashmentDetails?.incentivesAmount}
                                // onChange={(e) => setIncentivesAmount(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Previous Month</label>
                    <div className="d-flex gap-2 align-items-center">
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base">Day</span>
                            <Field
                                type="text"
                                name="encashmentDetails.previousMonth"
                                id="previousMonth"
                                className="form-control flex-grow-1 text-end"
                                placeholder="0"
                                // value={encashmentDetails?.previousMonth}
                                // onChange={(e) => setPreviousMonthDay(e.target.value)}
                            />
                        </div>
                        <div className="input-group w-160-px">
                            <span className="input-group-text bg-base border-end-0">&#8377;</span>
                            <Field
                                type="text"
                                name="encashmentDetails.previousMonthAmount"
                                id="previousMonthAmount"
                                className="form-control flex-grow-1 text-end border-start-0"
                                placeholder="0"
                                // value={encashmentDetails?.previousMonthAmount}
                                // onChange={(e) => setPreviousMonthAmount(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Performance Bonus:</label>
                    <Field
                        type="text"
                        name="encashmentDetails.performanceBonus"
                        id="performanceBonus"
                        className="form-control text-end w-160-px"
                        placeholder="0"
                        // value={encashmentDetails?.performanceBonus}
                        // onChange={(e) => setPerformanceBonus(e.target.value)}
                    />
                </div>
                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0 d-flex align-items-center gap-2">
                        <input type="checkbox" className="form-check-input" style={{ width: "24px", height: "24px" }}/> Increment</label>
                    <Field
                        name="encashmentDetails.increment"
                        type="text"
                        id="increment"
                        className="form-control text-end w-160-px"
                        placeholder="0"
                        // value={encashmentDetails?.increment}
                        // onChange={(e) => setPerformanceBonus(e.target.value)}
                    />
                </div>

                <div className="align-items-center d-flex justify-content-between py-8 py-16 px-16 bg-primary-50 radius-10">
                    <label className="form-label mb-0 text-dblue">Total</label>
                    <span className={`text-xl fw-bold ${values?.encashmentDetails?.totalEncashment < 0 ? "text-red" : ""}`}>
                        {values?.encashmentDetails?.totalEncashment < 0 ? `-₹ ${Math.abs(values?.encashmentDetails?.totalEncashment)}` : `₹ ${values.encashmentDetails?.totalEncashment}`}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default EncashmentDetail;
