import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { calculateTotalLagByDateAndSum, formatProductionCounter, formatProductionDayCounter, sumIdleTimes, sumWorkingTimes } from "src/utils/utils";

const GroupCounter = ({ onWorkingStats }) => {
  // const { groupCounter, search, lag, leaves, HubstaffResponse } = useSelector((state) => state.ProductionReducer, shallowEqual);
  // const { selectedYear, selectedMonth, HubstaffResponse } = useSelector((state) => state.ProductionReducer, shallowEqual);
  const dataProductionReducer = useSelector((state) => state.ProductionReducer, shallowEqual);
  const { groupCounter, search, lag, leaves, HubstaffResponse } = dataProductionReducer
  const halfDays = search?.leaves?.filter((item) => item.subject == "4");
  const { totalIdleTime, totalAllowedIdleTime } = sumIdleTimes(HubstaffResponse, (halfDays?.length || 0));
  const { workingStats, totalWorkingTime, totalAllowedWorkingTime } = sumWorkingTimes(HubstaffResponse, (halfDays?.length || 0), search);

  useEffect(() => {
    if (typeof onWorkingStats === "function") {
      onWorkingStats(workingStats);
    }
  }, [search, HubstaffResponse]);

  const totalLag = lag; //calculateTotalLagByDateAndSum(search?.data);
  // console.log("halfdayCounthalfdayCount ==2=", test, groupCounter, dataProductionReducer);

  // useEffect(() => {
  //   console.log("GroupCounter Updated2e:", { selectedMonth, selectedYear, HubstaffResponse });
  // }, []);
  return (
    <>
      <div className="row row-cols-xxxl-6 row-cols-xl-6 row-cols-lg-6 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-4">
        <div className="col">
          <div className="card shadow-none h-100 totproild Idle-border rounded-0">
            <div className="card-body p-20 textresponSec">
              <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div>
                  <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                    Total Idle
                  </p>
                  <p className="mb-0 totltm text-neutral-900 fw-semibold">
                    {totalIdleTime}{" "}
                    <span className="text-neutral-400 fw-normal">
                      /{totalAllowedIdleTime}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* card end */}
        </div>
        <div className="col">
          <div className="card shadow-none h-100 totproild Hours-border rounded-0">
            <div className="card-body p-20 textresponSec">
              <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div>
                  <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                    Total Working{" "}
                  </p>
                  <p className="mb-0 totltm text-neutral-900 fw-semibold">
                    {totalWorkingTime}{" "}
                    <span className="text-neutral-400 fw-normal">
                      /{totalAllowedWorkingTime}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* card end */}
        </div>
        <div className="col">
          <div className="card shadow-none h-100 totproild Accepted-border rounded-0">
            <div className="card-body p-20 textresponSec">
              <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div>
                  <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                    Accepted(Hour/Day)
                  </p>
                  <p className="mb-0 totltm text-neutral-900 fw-semibold">
                    <span className="d-block">{`${formatProductionCounter(groupCounter.totalAccepted)} `}{" "}hours</span>
                    <span>{`${formatProductionDayCounter(groupCounter.totalAccepted)} `}{" "}days</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* card end */}
        </div>
        <div className="col">
          <div className="card shadow-none h-100 totproild Rejected-border rounded-0">
            <div className="card-body p-20 textresponSec">
              <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div>
                  <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                    Rejected(Hour/Day)
                  </p>
                  <p className="mb-0 totltm text-neutral-900 fw-semibold">
                    <span className="d-block">{`${formatProductionCounter(groupCounter.totalRejected)} `}{" "}hours </span>
                    <span>{`${formatProductionDayCounter(groupCounter.totalRejected)} `}{" "}days</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* card end */}
        </div>
        <div className="col">
          <div className="card shadow-none h-100 totproild Pending-border rounded-0">
            <div className="card-body p-20 textresponSec">
              <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div>
                  <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                    Pending(Hour/Day)
                  </p>
                  <p className="mb-0 totltm text-neutral-900 fw-semibold">
                    <span className="d-block">{`${formatProductionCounter(groupCounter.totalPending)} `}{" "}hours</span>
                    <span>{`${formatProductionDayCounter(groupCounter.totalPending)} `}{" "}days</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* card end */}
        </div>
        <div className="col">
          <div className="card shadow-none h-100 totproild Lag-border rounded-0">
            <div className="card-body p-20 textresponSec">
              <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div>
                  <p className="fw-medium text-neutral-400 mb-1 fw-semibold">
                    Lag
                  </p>
                  <p className="mb-0 totltm text-neutral-900 fw-semibold">
                    <span className={`${totalLag < 0 ? 'text-danger-700' : 'text-success-700'}`}>{totalLag?.toFixed(2)}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* card end */}
        </div>
      </div>
    </>
  )
}

export default GroupCounter