import React, {
   useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux'; 
import { Formik, Field, Form } from 'formik';
import PropTypes from 'prop-types';
import FormikTree from 'src/components/formik/FormikTree';
import { withFormikDevtools } from 'formik-devtools-extension';
import FormikInput from 'src/components/formik/FormikInput';
import { REQUEST_STATUS } from 'src/constants/CommonConstants';
import { usePrevious } from 'src/utils/ReactHooksUtil';
import { permissionMasterFetch } from './actions/PermissionsMasterActions';
import {
   rolePermissionsEditAction, rolePermissionsResetAction, rolePermissionsSearchAction, rolePermissionsUpsertAction,
} from './actions/RolePermissionsActions';
import { permissionsToTreeStructure } from 'src/utils/CommonUtils';
import { includeStamp } from 'src/utils/StampUtils';
import FormikRadioBox from 'src/components/formik/FormikRadioBox';
import { RolePermissionsDefaultProps, RolePermissionsYupSchema } from './PermissionPropTypes';
import UserDropDown from 'src/business-components/UserDropDown';
import UserInfo from 'src/containers/user-container/UserInfo';
import RoleDropDown from 'src/business-components/RoleDropDown';


const Render=(props)=>{ 
  const {
      values, 
      isSubmitting,
      onClear,
      resetForm,
      forceRefreshSearchContainer,
      scopeTree,
  } = props;
  const [isRoleType,setIsRoleType] = useState(true);

  return(
   <>
   <Form autoComplete="disabled">
   {/* <div className="addpermission">
   <label className="font-20 fw-bold color1a mb-2">Add new permission</label>
   <div className="d-flex justify-content-between align-items-center "> 

    <div className="yesnolable">
      <label className="font-14 color1a fw-bold mb-2 mt-3">Type</label>
         <div className="d-flex align-items-center">
            <label className="cstCheck fradio"> 
               {}
               <Field type="radio" name="isRoleType" value="role" checked={isRoleType} onClick={()=>{setIsRoleType(true)}} />
               Role  
               <span className="checkmark"></span>
            </label>
            <label className="cstCheck fradio">            
            {} 
               <Field type="radio" name="isRoleType" value="user" checked={(!isRoleType)} onClick={()=>{setIsRoleType(false)}}/>
               User  
               <span className="checkmark"></span>
            </label>
         </div>
      </div> 

       {isRoleType?<div className="form-group col">
         <label>Role Title</label>
            <UserDropDown
               name="roleId"
               value={values.roleId}
               onChange={(id)=>{
                  values.roleId=id
              }}
            />
            {}
      </div>:<div className="form-group col">
         <label>User</label>
               <UserDropDown
               name="userId"
               className="form-select mb-2"
               value={values.userId}
               onChange={(id)=>{
                   values.userId=id
               }}
               />  
      </div>  }
       
         <div className="form-group col">
            <FormikTree
                  nodes={scopeTree}
                  name="permissions"
                  checked={values.permissions}
            />
         </div>
      <div className='addinputwbtn'>
         <button type='submit'>Add</button>
         <button type="reset" onClick={onClear} className='btn-default'>Reset</button>
      </div>
   </div>
   </div> */}
   <div className="card-body">
                        <h6 className="mb-4 text-xl">Permission</h6>
                        <div className="mt-24">
                            <label className="form-label">Type</label>
                            <div className="d-flex align-items-center flex-wrap gap-28">
                                <div className="form-check checked-primary d-flex align-items-center gap-2">
                                     
                                    <Field type="radio" className="form-check-input" name="isRoleType" value="role" checked={isRoleType} onClick={()=>{setIsRoleType(true)}} />
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="horizontal1"
                                    >
                                        {" "}
                                        Role  {" "}
                                    </label>
                                </div>
                                <div className="form-check checked-secondary d-flex align-items-center gap-2"> 
                                    <Field type="radio" className="form-check-input" name="isRoleType" value="user" checked={(!isRoleType)} onClick={()=>{setIsRoleType(false)}}/>
                                    <label
                                        className="form-check-label line-height-1 fw-medium text-secondary-light"
                                        htmlFor="horizontal2"
                                    >
                                        {" "}
                                        User{" "}
                                    </label>
                                </div>
                            </div>
                        </div>
                            {isRoleType?<div className="mt-24">
                            <label className="form-label">Role Title</label> 
                        <RoleDropDown
                            placeholder="Enter Role"
                            name="roleId"
                            className="form-select form-select-md w-100 bg-base border text-secondary-light"
                            value={values?.roleId}
                            onChange={(id)=>{
                                values.roleId=id
                            }}
                            />
                        </div>:<div className="mt-24">
                            <label className="form-label">Role Title</label> 
                        <UserDropDown
                            placeholder="Enter User Name"
                            name="userId"
                            className="form-select form-select-md w-100 bg-base border text-secondary-light"
                            value={values?.userId}
                            onChange={(id)=>{
                                values.userId=id
                            }}
                            />
                        </div> }
                        <div className="form-group col mt-24">
                        <FormikTree
                            nodes={scopeTree}
                            name="permissions"
                            checked={values.permissions}
                        />
                      </div>
                        <div className="form-group text-end gap-3 d-flex justify-content-end mt-24">
                            <button type="submit" className="form-wizard-next-btn btn btn-primary-600 px-32">Add</button>
                            <button type="reset" className="form-wizard-next-btn btn btn-primary-600 px-32">Reset</button> 
                        </div>
                    </div>
</Form>
   </>
  ) 
}

Render.propTypes = {
   scopeTree: PropTypes.array.isRequired,
   values: PropTypes.shape({
       permissions: PropTypes.array,
       permissions: PropTypes.bool,
   }),
   resetForm: PropTypes.func.isRequired,
   isSubmitting: PropTypes.bool.isRequired,
   forceRefreshSearchContainer: PropTypes.object.isRequired,
   onClear: PropTypes.func.isRequired,
};


let setSubmitting = () => { };

const PermissionContainer=()=>{    
    const dispatch = useDispatch(); 
    const {
      scopes,
      permissionMasterFetchReqStatus,
    } = useSelector((state) => state.PermissionsMasterReducer, shallowEqual);
     
     const {
      currentRecord,
      upsertReqStatus,
  } = useSelector((state) => state.RolePermissionsReducer, shallowEqual);

     
     usePrevious({
      currentRecord,
      upsertReqStatus,
  }, (prev) => {
      if (
          prev.currentRecord !== currentRecord
          || (upsertReqStatus && prev.upsertReqStatus !== upsertReqStatus && upsertReqStatus !== REQUEST_STATUS.PENDING)) {
          setSubmitting(false);
      }
  });

  
    
       const [
         forceRefreshSearchContainer,
         setForceRefreshSearchContainer,
     ] = useState({});
 
     usePrevious({ upsertReqStatus }, (preState) => {
         if (preState.upsertReqStatus !== upsertReqStatus && upsertReqStatus === REQUEST_STATUS.SUCCESS) {
             setForceRefreshSearchContainer({});
         }
     });
 
     useEffect(() => {
         
         if (!scopes && !permissionMasterFetchReqStatus) {
             dispatch(permissionMasterFetch({
                 where: {},
                 currentPage: 0,
                 autopopulate: false,
                 fullTextSearch: false,
             }));
         }
     }, [
         dispatch,
         scopes,
         permissionMasterFetchReqStatus,
     ]);
 
     
     const scopeTree = useMemo(() => permissionsToTreeStructure(scopes), [
         scopes,
     ]);

    const onSubmit = useCallback((
      values,
      formikUtils,
  ) => {
      ({ setSubmitting } = formikUtils);     
       includeStamp(values);
      dispatch(rolePermissionsUpsertAction(values)); 
  }, [
      dispatch,
  ]);
 
   const onClear = useCallback(() => {
      dispatch(rolePermissionsResetAction());
  }, [
      dispatch,
  ]); 
  return (
    <>
<div className="col-md-6 mx-auto">
<div className="card">
 
<Formik
            onSubmit={onSubmit}
            onClear={onClear}
            enableReinitialize
            initialValues={currentRecord || RolePermissionsDefaultProps}
            validationSchema={RolePermissionsYupSchema}
        >
            {(formikProps) => (
                <Render
                    onClear={onClear}
                    scopeTree={scopeTree}
                    forceRefreshSearchContainer={forceRefreshSearchContainer}
 
                    {...formikProps}
                />
            )}
   </Formik>
    {}
    </div>
    </div>
    <UserInfo />
    </>
  )
}

export default PermissionContainer