import React, { useState } from "react";

const AssetsUploadModal = () => {
  // State for uploaded files
  const [uploadedFiles, setUploadedFiles] = useState([
    {
      id: 1,
      name: "Assets.zip",
      size: "5.3MB",
      icon: "./assets/images/content-hub/media-file.svg",
    },
  ]);

  // State for upload progress
  const [uploadProgress, setUploadProgress] = useState({
    isUploading: true,
    progress: 65,
    timeRemaining: "30 seconds",
  });

  // Handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newFile = {
        id: uploadedFiles.length + 1,
        name: file.name,
        size: `${(file.size / 1024 / 1024).toFixed(2)}MB`,
        icon: "./assets/images/content-hub/media-file.svg",
      };
      setUploadedFiles([...uploadedFiles, newFile]);
      setUploadProgress({
        isUploading: true,
        progress: 0,
        timeRemaining: "Calculating...",
      });

      // Simulate upload progress
      const interval = setInterval(() => {
        setUploadProgress((prev) => {
          const newProgress = prev.progress + 10;
          if (newProgress >= 100) {
            clearInterval(interval);
            return {
              isUploading: false,
              progress: 100,
              timeRemaining: "Upload complete",
            };
          }
          return {
            ...prev,
            progress: newProgress,
            timeRemaining: `${30 - (newProgress / 10) * 3} seconds`,
          };
        });
      }, 1000);
    }
  };

  return (
    <div
      className="modal assetsUploadSec fade"
      id="assetsUpload"
      tabIndex={-1}
      aria-labelledby="mediaUploadLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="mediaUploadLabel">
              Media Assets Upload{" "}
              <span className="paraSec">
                Add your media assets here, and you can upload up to 1 files max
              </span>
            </h1>
          </div>
          <div className="modal-body">
            <div className="uploadFiles">
              <div className="formbold-file-input formbold-mb-5">
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleFileUpload}
                />
                <label htmlFor="file">
                  <div>
                    <span className="mb-24">
                      <img
                        src="./assets/images/content-hub/upload-file.svg"
                        alt=""
                      />
                    </span>
                    <span className="formbold-drop-file">
                      Drag your file(s) to start uploading
                    </span>
                    <span className="formbold-or"> Or </span>
                    <span className="formbold-browse">Browse files</span>
                  </div>
                </label>
              </div>
              <p>Only support .jpg, .png, .svg, .mp4, and zip files</p>

              {/* Uploaded Files */}
              <div className="formbold-file-list formbold-mb-5">
                {uploadedFiles.map((file) => (
                  <div key={file.id} className="formbold-file-item">
                    <div className="formFilesec">
                      <img src={file.icon} alt="" />
                      <span className="formbold-file-name">
                        {file.name} <span>{file.size}</span>
                      </span>
                    </div>
                    <button>
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M10 2C5.59 2 2 5.59 2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2ZM14 12.59L12.59 14L10 11.41L7.41 14L6 12.59L8.59 10L6 7.41L7.41 6L10 8.59L12.59 6L14 7.41L11.41 10L14 12.59Z"
                          fill="#858585"
                        />
                        <path
                          d="M12.59 6L10 8.59L7.41 6L6 7.41L8.59 10L6 12.59L7.41 14L10 11.41L12.59 14L14 12.59L11.41 10L14 7.41L12.59 6ZM10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                          fill="#858585"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
              </div>

              {/* Upload Progress */}
              {uploadProgress.isUploading && (
                <div className="formbold-file-list formbold-mb-5 mb-0">
                  <div className="formbold-file-item">
                    <span className="formbold-file-name">
                      Uploading...{" "}
                      <span>
                        {uploadProgress.progress}% • {uploadProgress.timeRemaining}
                      </span>
                    </span>
                    <div className="d-flex gap-2">
                      <button>
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M10 2C5.59 2 2 5.59 2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2ZM9 14H7V6H9V14ZM13 14H11V6H13V14Z"
                            fill="#6D6D6D"
                          />
                          <path d="M13 6H11V14H13V6Z" fill="#6D6D6D" />
                          <path d="M9 6H7V14H9V6Z" fill="#6D6D6D" />
                          <path
                            d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                            fill="#6D6D6D"
                          />
                        </svg>
                      </button>
                      <button>
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.3"
                            d="M10 2C5.59 2 2 5.59 2 10C2 14.41 5.59 18 10 18C14.41 18 18 14.41 18 10C18 5.59 14.41 2 10 2ZM14 12.59L12.59 14L10 11.41L7.41 14L6 12.59L8.59 10L6 7.41L7.41 6L10 8.59L12.59 6L14 7.41L11.41 10L14 12.59Z"
                            fill="#FF3636"
                          />
                          <path
                            d="M12.59 6L10 8.59L7.41 6L6 7.41L8.59 10L6 12.59L7.41 14L10 11.41L12.59 14L14 12.59L11.41 10L14 7.41L12.59 6ZM10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                            fill="#FF3636"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="formbold-progress-bar">
                    <div
                      className="formbold-progress"
                      style={{ width: `${uploadProgress.progress}%` }}
                    />
                  </div>
                </div>
              )}

              <button
                type="button"
                className="btn btn-primary w-100 mt-32"
                data-bs-dismiss="modal"
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetsUploadModal;