import { useCallback, useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getDeliveryDeviationAPI } from "src/api/ProductionApi";
import MonthPicker from "src/components/MonthPicker";
import SearchInput from "src/components/SearchInput";
import DeviationRow from "./DeviationRow";

const DeliveryDeviationContainer = () => {
  const [tableData, setTableDate] = useState([]);
  const [searchedDelivery, setSearchedDelivery] = useState([]);
  const [dateCol, setDateCol] = useState([]);
  const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
  const [where, setWhere] = useState({
    $expr: {
      $and: [
        { $eq: [{ $year: "$date" }, selectedYear] },
        { $eq: [{ $month: "$date" }, selectedMonth] }
      ]
    }
  }
  );

  const handleChangeDate = (date) => {
    const year = date.getFullYear();  // Example year
    const month = date.getMonth() + 1;  // Since month is 0-indexed in JavaScript, add 1
    setWhere({
      ...where,
      $expr: {
        $and: [
          { $eq: [{ $year: "$date" }, year] },
          { $eq: [{ $month: "$date" }, month] }
        ]
      }
    })
  }
  const search = useCallback(async () => {
    const MDTDeliveryData = await getDeliveryDeviationAPI({ where })
    setSearchedDelivery(MDTDeliveryData.data.data)
    setTableDate(MDTDeliveryData.data.data)
    const allDates = MDTDeliveryData.data.data.reduce((acc, item) => {
      const dates = item.durationsByDate.map(d => d.date);
      return acc.concat(dates);
    }, []);
    const dateCol = [...new Set(allDates)].sort((a, b) =>
      a.split('/').reverse().join('') > b.split('/').reverse().join('') ? 1 : -1
    );

    setDateCol(dateCol)
  }, [where])
  useEffect(() => {
    search()
  }, [where])

  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value.trim().toLowerCase(); // Normalize and trim search input
    if (searchKey.length === 0) {
      // If search key is empty, reset to full table data
      setSearchedDelivery(tableData);
    } else {
      // Filter based on the productionSlug field
      const filteredData = tableData.filter((production) => {
        return production?.productionSlug?.toLowerCase().includes(searchKey);
      });
      setSearchedDelivery(filteredData); // Set the filtered results
    }
  };


  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

  useEffect(() => {
    const handleScroll = () => {
      // Clear the previous timeout to prevent multiple updates
      clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          // Check if the table <thead> is within the viewport range
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
    };
  }, []);
  // Table Scroll Code Start
  return (
    <>
      <div>
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header pt170">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24"><button><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="30" height="30"><g data-name="20-Arrow Left"><path fill="#0073ea" d="M16 0a16 16 0 1 0 16 16A16 16 0 0 0 16 0zm0 30a14 14 0 1 1 14-14 14 14 0 0 1-14 14z"></path><path fill="#0073ea" d="m8.41 15 5.29-5.29-1.41-1.42-7 7a1 1 0 0 0 0 1.41l7 7 1.41-1.41L8.41 17H27v-2z"></path></g></svg></button> Delivery Deviation</h2>
              <ul className="d-flex align-items-center gap-2"><li className="fw-medium"><a href="/" className="d-flex align-items-center gap-1 hover-text-primary"><iconify-icon icon="solar:home-smile-angle-outline" classname="icon text-lg"></iconify-icon>Home</a></li><li>-</li><li className="fw-medium"> Delivery Deviation </li></ul>
              <div className="d-flex align-items-center gap-4 mt-16">
                {/* Search start */}
                <SearchInput handleSearchInputChange={handleSearchInputChange} />
                {/* Search End */}
                <MonthPicker handleChangeDate={handleChangeDate} />
              </div>
            </div>
            <div className="card-body">
              <h5 className="card-title mb-16">Delivery Deviation</h5>
              <div className="posSetTable">
                <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`}>
                  <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>
                    <thead>
                      <tr>
                        <th scope="col">S.No.</th>
                        <th scope="col">Project Code</th>
                        <th scope="col">Domain</th>
                        <th scope="col" className="text-center">Director</th>
                        <th scope="col" className="text-center">OAD</th>
                        <th scope="col" className="text-center">CMD</th>
                        <th scope="col" className="text-center">Total Delivery</th>
                        <th scope="col" className="text-center">Deviation(%)</th>
                        <th scope="col" className="text-center">Closer Date</th>
                        {
                          dateCol.map((date) => (<th scope="col" className="text-center">{date}</th>))
                        }

                      </tr>
                    </thead>
                    <tbody>
                      {
                        [...searchedDelivery]
                          .sort((a, b) =>
                            `${a.clientDetails.ClientProjectCode}(${a.projectDetails.ProjectSubCode})`.localeCompare(
                              `${b.clientDetails.ClientProjectCode}(${b.projectDetails.ProjectSubCode})`
                            )
                          ).map((row, index) => (
                            <DeviationRow row={row} dateCol={dateCol} index={index} search={search} />
                          ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default DeliveryDeviationContainer