import React from 'react'

const AuditCommentModal = () => {
    return (
        <>
            <div
                className="modal fade cusmodl"
                id="AuditCommentModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-md modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between pb-0 border-0">
                            <label className="mb-0 fw-medium text-neutral-900">Comment <span className="text-danger-600">*</span></label>
                            <button
                                type="button"
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                ✗
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="">
                                <textarea className="form-control" placeholder="Write comment here..." rows={7}></textarea>
                                <button type="button" className="btn btn-primary-600 radius-8 px-20 py-11 mt-24 w-160-px d-flex justify-content-center align-items-center ms-auto">Save</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuditCommentModal
