import React, { useState } from 'react';
import EffortEstimateManage from './EffortEstimateManage';
import TopTabs from 'src/containers/project-container/View/TopTabs';
import EffortSummary from './EffortSummary';

const EffortMain = () => {
    const [data, setData] = useState({
        expectedHours: 256,
        expectedCost: 269900,
        overheadCost: 269900,
        clientCost: 269900,
        profitPercentage: 26,
        expectedProfit: 269900,
      });

  // Input Change Handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatCurrency = (value) => {
    if (value >= 1000000000) {
      return `₹${(value / 1000000000).toFixed(1)}B`;
    } else if (value >= 1000000) {
      return `₹${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `₹${(value / 1000).toFixed(1)}K`;
    }
    return `₹${value}`;
  };

  

  return (
    <div>
      <div className="row gy-4 mt-1">
        <div className="col-md-12">
          <div className="card h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center border-bottom pb-16 position-relative z-3 pt-24 bg-white">
                <div>
                  <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">Delivery Plan</h2>
                  <ul className="d-flex align-items-center gap-2">
                    <li className="fw-medium">
                      <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                        <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                        Special Access
                      </a>
                    </li>
                    <li>-</li>
                    <li className="fw-medium">Delivery Plan</li>
                  </ul>
                </div>
              </div>
              <TopTabs />
              {/* <div className="TotlU mt-32">
                <ul> 


                  <li className="text-black">
                    {data.expectedHours} hours <span className="d-block">Expected Total</span>
                  </li>
                  <li className="text-dblue">{formatCurrency(data.expectedCost)} <span className="d-block">Expected Total cost</span></li>
                  <li className="text-green">{formatCurrency(data.overheadCost)} <span className="d-block">Overhead Cost</span></li>
                  <li className="text-red">{formatCurrency(data.clientCost)} <span className="d-block">Client Cost</span></li>
                  <li className="text-green">
                    {data.profitPercentage}% <span className="d-block">Expected Profit %</span>
                  </li>
                  <li className="text-dblue"><span className='d-flex align-items-center justify-content-center gap-10'><img src="/assets/images/profit-icon.svg" className="img-fluid" alt="" /> {formatCurrency(data.expectedProfit)}</span> <span className="d-block">Expected Profit</span></li>
                </ul>
              </div> */}
              <EffortSummary data={data} formatCurrency={formatCurrency} />
              <EffortEstimateManage />
            </div>
          </div>
        </div>

        {/* Editable Fields */}
        <div className="col-md-12">
          <div className="card h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center gap-16 AmountText0001">
                <div className="AmountTextbg max-w-634-px w-100">
                  <div className="AmountWT">
                    <div className="AmountText text-dblue">${data.expectedProfit}</div>
                    <div className="text">Expected Profit</div>
                  </div>
                  <div className="ExpectedBgt01 gap-16 w-100">
                    <div className="ExpectedBgt01_1">
                      <div className="ExpectedAmt01_2">
                        {data.expectedHours} <span>hours</span>
                      </div>
                      <div className="ExpectedAmt01_3">Expected Total (hrs)</div>
                    </div>
                    <div className="ExpectedBgt01_1">
                      <div className="ExpectedAmt01_2">${data.expectedCost}</div>
                      <div className="ExpectedAmt01_3">Expected Total cost</div>
                    </div>
                    <div className="ExpectedBgt01_1">
                      <div className="ExpectedAmt01_2 text-green">{data.profitPercentage}%</div>
                      <div className="ExpectedAmt01_3">Expected Profit %</div>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <div className="text-end mb-16">
                    <label className="form-label">Overhead Cost</label>
                    <div className="position-relative text-end">
                      <input
                        type="number"
                        className="form-control max-w-290-px"
                        placeholder="0"
                        name="overheadCost"
                        value={data.overheadCost}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="text-end">
                    <label className="form-label">Client Cost</label>
                    <div className="position-relative text-end">
                      <input
                        type="number"
                        className="form-control max-w-290-px"
                        placeholder="0"
                        name="clientCost"
                        value={data.clientCost}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center gap-16">
                <button type="button" className="btn text-sm btn-outline-neutral-400 radius-4 px-20 py-11">
                  Save in Draft
                </button>
                <button type="button" className="btn text-sm btn-primary-600 radius-4 px-20 py-11">
                  Save & Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EffortMain;
