import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import moment from 'moment';
import DateRangePickerWithAll from 'src/components/DateRangePickerWithAll';
import { getProductivityVsActivityAPI, getProductivityVsHubstaffAPI } from 'src/api/PerfomanceAPI';
import { useParams } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { convertDecimalToProperTime } from 'src/utils/CommonUtils';
Highcharts3D(Highcharts);

const ProductivityvsHubstaff = ({ employeeID: propEmployeeID = null, userId: propUserId = null, addedProduction = null }) => {
    const params = useParams();
    // Conditionally assign values
    const employeeID = propEmployeeID !== null ? propEmployeeID : params.employeeID;
    const userId = propUserId !== null ? propUserId : params.userId;

    const [series, setSeries] = useState({ dates: ["02-11-1992"], Pending: [0], Accepted: [0], Rejected: [0] });
    const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
    const [ischange, setIsChange] = useState(false)

    const [where, setWhere] = useState({
        userId,
        $expr: {
            $and: [
                { $eq: [{ $year: "$date" }, selectedYear] },
                { $eq: [{ $month: "$date" }, selectedMonth] }
            ]
        }
    });
    const [whereMySql, setWhereMySql] = useState({
        empid: employeeID,
        year: selectedYear,
        month: selectedMonth,
    });
    // const search = useCallback(async () => {
    //     const hubstaffData = await getProductivityVsHubstaffAPI({ where, whereMySql });
    //     setSeries(hubstaffData.data.data);

    const [graph, setGraph] = useState([])
    // }, [where, whereMySql]);
    const search = async () => {
        const hubstaffData = await getProductivityVsHubstaffAPI({ where, whereMySql });
        setSeries(hubstaffData.data.data);
        setGraph(hubstaffData.data.data);
    };

    useEffect(() => {
        setWhere({
            ...where,
            $expr: {
                $and: [
                    { $eq: [{ $year: "$date" }, selectedYear] },
                    { $eq: [{ $month: "$date" }, selectedMonth] }
                ]
            }
        });

        setWhereMySql({
            ...whereMySql,
            year: selectedYear,
            month: selectedMonth
        });
    }, [selectedMonth, selectedYear, addedProduction]);

    useEffect(() => {
        search();
    }, [where, whereMySql]);

    const [dateRangeStatus, setDateRangeStatus] = useState([
        moment().subtract(29, 'days'),
        moment(),
    ]);
    const [unit, setUnit] = useState('hours'); // State to toggle between hours and minutes


    const chartRef = useRef(null); // Create a ref for the chart
    const [isLeftDisabled, setIsLeftDisabled] = useState(true); // Initially, left button is disabled
    const [isRightDisabled, setIsRightDisabled] = useState(false); // Initially, right button is enabled

    const dates = series?.dates;

    const colorPalette = ['#0073EA', '#FF9900', '#22C55E', '#FF5656'];

    // const hoursData = [
    //     { name: 'Hubstaff Hours', data: series?.HubstaffHours, stack: 'Hubstaff Hours' },
    //     // { name: 'Hubstaff Hours', data: ddd, stack: 'Hubstaff Hours' },
    //     { name: 'Pending', data: series?.pendingProductionHours, stack: 'Production Hours' },
    //     { name: 'Accepted', data: series?.approvedProductionHours, stack: 'Production Hours' },
    //     { name: 'Rejected', data: series?.rejectedProductionHours, stack: 'Production Hours' },
    // ].map((series, index) => ({
    //     ...series,
    //     color: colorPalette[index],
    // }));

    // const minutesData = [...hoursData].map((series) => ({
    //     ...series,
    //     data: series?.data?.map((value) => Number((value * 60)?.toFixed(0))),
    // }));
    const hoursData = series
        ? [
            { name: 'Hubstaff Hours', data: series.HubstaffHours, stack: 'Hubstaff Hours' },
            { name: 'Pending', data: series.pendingProductionHours, stack: 'Production Hours' },
            { name: 'Accepted', data: series.approvedProductionHours, stack: 'Production Hours' },
            { name: 'Rejected', data: series.rejectedProductionHours, stack: 'Production Hours' },
        ].map((item, index) => ({
            ...item,
            color: colorPalette[index],
        }))
        : [];

    const [minutesData2, setMinutesData2] = useState(hoursData);

    // useEffect(() => {
    //     setMinutesData2(hoursData.map((item) => ({
    //         ...item,
    //         data: item?.data?.map((value) => {
    //             const [whole, decimal] = value.toString().split(".");
    //             const minutes = Number(whole) * 60 + Number(decimal || 0);
    //             return minutes;
    //         }),
    //     })))
    // }, [unit]);

    const minutesData = [...hoursData].map((item) => ({
        ...item,
        // data: item?.data?.map((value) => Number((value * 60)?.toFixed(0))),
        data: item?.data?.map((value) => {
            if (unit === 'hours') {
                // const hours = Math.floor(value / 60);
                // const remainingMinutes = value % 60;
                // return parseFloat(`${hours}.${remainingMinutes}`);

                return value
            } else if (unit === 'minutes') {
                let [hours, minutes] = value.toString().split(".");
                // If minutes is a single digit (less than 10), assume it's intended as x0 (e.g., 2 → 20)
                if (minutes < 10 && minutes.length === 1) {
                    minutes *= 10; // Converts 1:2 → 1:20
                }
                return (Number(hours) * 60 + Number(minutes || 0));
            }
        }),
    }));

    const chartOptions = {
        chart: {
            type: 'column',
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 1,
                depth: 0,
                viewDistance: 40,
            },
            panning: true, // Enable panning for scroll functionality
            panKey: 'shift', // Hold shift key to scroll
            spacingRight: 140,
            events: {
                render: function () {
                    const chart = this;
                    const yAxis = chart.yAxis[0];

                    // Clear existing custom labels and lines
                    if (chart.customLabels) {
                        chart.customLabels.forEach((label) => label.destroy());
                    }
                    if (chart.customLines) {
                        chart.customLines.forEach((line) => line.destroy());
                    }
                    chart.customLabels = [];
                    chart.customLines = [];

                    // Dynamically adjust values based on the unit (hours or minutes)
                    const customValues = [
                        {
                            value: unit === 'hours' ? 9 : 9 * 60,
                            text: unit === 'hours' ? '9 Hubstaff Hours' : '540 Minutes',
                            color: '#22C55E'
                        },
                        {
                            value: unit === 'hours' ? 8 : 8 * 60,
                            text: unit === 'hours' ? '8 Production Hours' : '480 Minutes',
                            color: '#FF9900'
                        },
                    ];

                    customValues.forEach(({ value, text, color }) => {
                        const pos = yAxis.toPixels(value, false); // Get pixel position for value
                        if (pos >= chart.plotTop && pos <= chart.plotTop + chart.plotHeight) {
                            // Add the label
                            const label = chart.renderer
                                .text(text, chart.chartWidth - 105, pos - 0) // Adjust label position
                                .css({ color: '#333', fontSize: '12px' })
                                .add();
                            chart.customLabels.push(label);

                            // Add the line with dynamic color
                            const line = chart.renderer
                                .path(['M', chart.plotLeft, pos, 'L', chart.chartWidth - 120, pos]) // Line from left to label
                                .attr({
                                    stroke: color, // Use the color from customValues
                                    'stroke-width': 1, // Line thickness
                                    dashstyle: 'Dash', // Optional: dashed line style
                                })
                                .add();
                            chart.customLines.push(line);
                        }
                    });
                },
            },



        },
        title: {
            text: '',
            align: 'left',
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            categories: dates,
            scrollbar: {
                enabled: true, // Enable horizontal scrollbar
            },
            max: 6, // Initially show only 7 days (indices 0 to 6)
        },
        // yAxis: {
        //     allowDecimals: false, 
        //     title: {
        //         text: `Count (${unit === 'hours' ? 'Hours' : 'Minutes'})`,
        //     },
        //     stackLabels: {
        //         enabled: true,
        //     },
        //     min: 0, 
        // },

        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: `Count (${unit === 'hours' ? 'Hours' : 'Minutes'})`,
            },
            stackLabels: {
                enabled: true,
                formatter: function () {
                    const xAxis = this.axis.chart.xAxis[0]; // Access the xAxis
                    const pointIndex = this.x; // Get the index of the current point
                    const min = xAxis.min; // Minimum visible range
                    const max = xAxis.max; // Maximum visible range

                    // Check if the current stack index is within the visible range
                    if (pointIndex >= min && pointIndex <= max) {
                        return convertDecimalToProperTime(this.total, ":", unit)
                        // return unit === 'hours' ? this.total.toFixed(2).replace('.', ':') : this.total; // Show the total stack value
                    }
                    return null; // Hide the stack label for points outside the range
                },
                style: {
                    fontWeight: 'bold',
                    color: '#000', // Stack label color
                },
            },
        },

        // tooltip: {
        //     pointFormat: `<b>{point.category}</b><br/>{series.name}: {point.y}<br/>` + `Total: {convertDecimalToProperTime(point.stackTotal)}`,
        // },
        // tooltip: {
        //     pointFormat: `<b>{point.category}</b><br/>
        //                   {series.name}: {point.y}<br/>
        //                   Total: ${point.stackTotal ? convertDecimalToProperTime(point.stackTotal) : '0:00'}`,
        // },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.category}</b><br/>
                        ${this.series.name}: ${convertDecimalToProperTime(this.point.y, ":", unit)}<br/>
                        Total: ${convertDecimalToProperTime(this.point.stackTotal, ':', unit)}`;
            }
        },

        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    // formatter: function () {
                    //     const value = this.y;
                    //     if (unit === 'hours') {
                    //         const hours = Math.floor(value);
                    //         const minutes = Math.round((value % 1) * 60);
                    //         return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                    //     } else {
                    //         return `${value}`;
                    //     }
                    // },
                    formatter: function () {
                        const value = this.y;
                        // Skip data label rendering for 0 values
                        if (value === 0) {
                            return null; // Returning null hides the label
                        }
                        if (unit === 'hours') {
                            // const hours = Math.floor(value);
                            // const minutes = Math.round((value % 1) * 60);
                            // return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                            return `${value.toFixed(2).replace('.', ':')}`;
                        } else {
                            return `${value}`;
                        }
                    },

                    style: {
                        fontWeight: '500',
                        color: '#fff',
                        textOutline: 'none',
                    },
                },
            },
            column: {
                stacking: 'normal',
            },
        },

        series: minutesData,
        // series: unit === 'hours' ? hoursData : minutesData,
    };


    const updateButtonState = () => {
        const chart = chartRef.current?.chart; // Use optional chaining to ensure chartRef is not null
        if (!chart) return; // Ensure chart is not null
        const xAxis = chart.xAxis[0];
        const min = xAxis.min;
        const max = xAxis.max;
        const dataLength = chartOptions.series[0]?.data.length || 0; // Ensure data length exists

        // Disable left button if at the start
        setIsLeftDisabled(min === 0);
        // Disable right button if at the end
        setIsRightDisabled(max >= dataLength - 1);
    };


    const handleScrollProductivityLeft = () => {
        const chart = chartRef.current?.chart; // Access the chart instance using ref
        if (!chart) return;
        chart.xAxis[0].setExtremes(chart.xAxis[0].min - 6, chart.xAxis[0].max - 6); // Scroll left by 1
        updateButtonState(); // Update button state after scroll
    };

    const handleScrollProductivityRight = () => {
        const chart = chartRef.current?.chart; // Access the chart instance using ref
        if (!chart) return;
        chart.xAxis[0].setExtremes(chart.xAxis[0].min + 6, chart.xAxis[0].max + 6); // Scroll right by 1
        updateButtonState(); // Update button state after scroll
    };

    const setupUnit = (unit) => {
        setUnit(unit)
        setIsChange(true)
    }


    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center gap-2 mb-24">
                    <div>
                        <h4 className="card-title">Productivity vs Hubstaff</h4>
                    </div>
                    <div className="d-flex align-items-center gap-4">
                        <div className="d-flex align-items-center flex-wrap gap-28">
                            <div className="form-check checked-primary d-flex align-items-center gap-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="unit"
                                    id="hours"
                                    checked={unit === 'hours'}
                                    onChange={() => setupUnit('hours')}
                                />
                                <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="hours">
                                    Hours
                                </label>
                            </div>
                            <div className="form-check checked-secondary d-flex align-items-center gap-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="unit"
                                    id="minutes"
                                    checked={unit === 'minutes'}
                                    onChange={() => setupUnit('minutes')}
                                />
                                <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor="minutes">
                                    Minutes
                                </label>
                            </div>
                        </div>
                        <div>
                            {/* <DateRangePickerWithAll value={dateRangeStatus} onChange={setDateRangeStatus} /> */}
                        </div>
                    </div>
                </div>
                <HighchartsReact highcharts={Highcharts} options={chartOptions}
                    ref={chartRef} // Attach the ref to the chart component
                    callback={updateButtonState} // Callback to update button state when chart is rendered
                />

                <div className="d-flex justify-content-end p-2 gap-2">
                    <button
                        className="btn btn-primary"
                        onClick={handleScrollProductivityLeft}
                        disabled={isLeftDisabled} // Disable button based on state
                    >
                        <i className="mdi mdi-keyboard-backspace"></i>
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={handleScrollProductivityRight}
                        disabled={isRightDisabled} // Disable button based on state
                    >
                        <i className="mdi mdi-keyboard-backspace mdi-rotate-180"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProductivityvsHubstaff;
