import React, { useEffect, useState } from 'react'
import { operationsReportAPI } from 'src/api/ReportsAPI';
import MonthPicker from 'src/components/MonthPicker';
import { costFormat, getDaysInMonth } from 'src/utils/CommonUtils';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';
import { round } from 'lodash';
import OadSalesReportContainer from './OadSalesReportContainer';
import ReportTab from '../ReportTab';
import NoWorkContainer from './NoWorkContainer';
Highcharts3D(Highcharts);
const OperationsReportContainer = () => {
    const [searchVertical, setSearchVertical] = useState('');
    const [delivery, setDelivery] = useState([]);
    const [cmd, setCmd] = useState([]);
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const options = {
        chart: {
            type: 'column',
            options3d: {
                enabled: true,
                alpha: 15,
                beta: 3,
                depth: 0,
                viewDistance: 40,
            },
        },
        title: {
            text: '',
            align: 'left',
            style: {
                float: 'left',
                marginBottom: '20px',
            },
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: 'category',
            title: {
                text: 'Days',
            },
        },
        yAxis: {
            title: {
                text: 'Amount',
            },
            min: 0,
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: true, // Enables labels on columns
                    format: '{y}', // Format label to show value with "M"
                    style: {
                        fontWeight: 'bold',
                    },
                },
            },
        },
        series: [],
        colors: ['#22C55E', '#487fff', '#EF4444'],
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            floating: true,
            x: -10,
            y: 10,
        },
        tooltip: {
            shared: true,
            valueSuffix: ' ',
        },
    };
    const [where, setWhere] = useState({
        year: currentYear,
        month: currentMonth,
    });

    const apiData = async () => {
        try {
            const resp = await operationsReportAPI({ where });
            setDelivery(resp?.data?.data?.delivery)
            setCmd(resp?.data?.data?.cmd)
            // console.log("resp", resp?.data?.data?.delivery, resp?.data?.data?.cmd);

        } catch (err) {
            console.error('Failed to operation report. ' + err.message);
        }
    }
    useEffect(() => {
        apiData()
    }, [where])

    // Function to categorize items into predefined ranges
    const getRangeByDay = (day) => {
        if (day >= 1 && day <= 10) return "1-10";
        if (day >= 11 && day <= 20) return "11-20";
        if (day >= 21) return ">=21";
        return "Other"; // Optional: for days < 1 (if needed)
    };

    const handleChangeDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        setWhere(prev => ({
            ...prev,
            year,
            month,
        }));
        // Call fetchData with the dynamic yearmonth
    }
    // Function to combine and group by range
    const combineAndGroup = (arr1, arr2) => {
        // Initialize predefined ranges
        const predefinedRanges = {
            "1-10": { range: "1-10", totalDeliveryCost: 0, totalCMD: 0, dates: [] },
            "11-20": { range: "11-20", totalDeliveryCost: 0, totalCMD: 0, dates: [] },
            ">=21": { range: "21-" + getDaysInMonth(where), totalDeliveryCost: 0, totalCMD: 0, dates: [] },
        };

        // Combine and group data by range
        [...arr1, ...arr2]
            .filter((item) => !searchVertical || item.vertical.toLowerCase() === searchVertical.toLowerCase()) // Filter by vertical
            .forEach((item) => {
                const range = getRangeByDay(item.dayOfMonth); // Categorize by dayOfMonth

                if (predefinedRanges[range]) {
                    if (item.deliveryCost) {
                        predefinedRanges[range].totalDeliveryCost += item.deliveryCost;
                    }

                    if (item.totalCMD) {
                        predefinedRanges[range].totalCMD += item.totalCMD;
                    }

                    const date = item.date?.$date || item.ts?.$date;
                    if (date) {
                        predefinedRanges[range].dates.push(new Date(date));
                    }
                }
            });

        // Convert the grouped data to an array and return it
        return Object.values(predefinedRanges);
    };

    // Process the arrays
    const result = combineAndGroup(delivery, cmd);
    const transformData = (data) => {
        // Prepare the output structure
        const output = [
            { name: 'CMD ', data: [] },
            { name: 'Delivered', data: [] },
        ];

        // Iterate through the input array
        data.forEach((item) => {
            // Add data for totalCMD
            output[0].data.push([item.range.replace('-', ' to '), round(item.totalCMD)]);
            // Add data for totalDeliveryCost
            output[1].data.push([item.range.replace('-', ' to '), round(item.totalDeliveryCost)]);
        });

        return output;
    };

    options.series = transformData(result)
    const handleVertical = (e) => {
        setSearchVertical(e.target.value);
    }

    return (
        <div className="dashboard-main-body">
            <div className='row'>
                <div className="col-xxl-12 col-xl-12">
                    <div className="card">
                        <div className="card-body p-24">
                            <ReportTab />
                            <OadSalesReportContainer />
                        </div>
                    </div>
                    <div className="card mt-12">
                        <div className="card-body">
                            <div className=''>
                                <div className="d-flex justify-content-between gap-4 align-items-center mt-24 mb-16">
                                    <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">
                                        CMD / Delivered
                                    </h6>
                                    <div>
                                        <div className="d-flex align-items-center flex-wrap gap-28">
                                            <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="horizontal"
                                                    id="horizontal1"
                                                    defaultChecked={true}
                                                    value=""
                                                    onChange={handleVertical}
                                                />
                                                <label
                                                    className="form-check-label line-height-1 fw-medium text-secondary-light"
                                                    htmlFor="horizontal1"
                                                >
                                                    {" "}

                                                    Total  {" "}
                                                </label>
                                            </div>
                                            <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="horizontal"
                                                    id="horizontal4"
                                                    value='Acadecraft Government'
                                                    onChange={handleVertical}
                                                />
                                                <label
                                                    className="form-check-label line-height-1 fw-medium text-secondary-light"
                                                    htmlFor="horizontal4"
                                                >
                                                    {" "}
                                                    Acadecraft Government {" "}
                                                </label>
                                            </div>
                                            <div className="form-check checked-primary d-flex align-items-center gap-2">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="horizontal"
                                                    id="horizontal3"
                                                    value='Acadecraft Private'
                                                    onChange={handleVertical}
                                                />
                                                <label
                                                    className="form-check-label line-height-1 fw-medium text-secondary-light"
                                                    htmlFor="horizontal3"
                                                >
                                                    {" "}
                                                    Acadecraft Private{" "}
                                                </label>
                                            </div>
                                            <MonthPicker handleChangeDate={handleChangeDate} />
                                        </div>
                                    </div>
                                </div>
                                <div className=''><div className=''>Total CMD <span>
                                    {costFormat(round(cmd
                                        .filter((item) => !searchVertical || item.vertical.toLowerCase() === searchVertical.toLowerCase())
                                        .reduce((sum, item) => sum + (item.totalCMD || 0), 0)))}
                                </span></div></div>
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={options}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="card mt-12">
                        <NoWorkContainer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OperationsReportContainer