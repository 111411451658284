import React from "react";

const ContentTable = () => {
  // Table data
  const tableData = [
    {
      id: 1,
      date: "15-Jan-2025",
      uniqueId: "Level_PT_ST/QT_001",
      uploadedBy: {
        initials: "KM",
        name: "Kiran Mazumdar",
        bgColor: "rgb(159, 163, 0)",
      },
      assetType: "eLearning",
      downloads: 7,
    },
    // Add more rows as needed
  ];

  return (
    <div className="table-container table-responsive" id="table-container">
      <table className="table table-hover basic-border-table mb-0">
        <thead>
          <tr className="dnd-moved">
            <th className="text-center dragablefalse w30px" draggable="true">
              <input className="form-check-input" type="checkbox" />
            </th>
            <th className="text-center dragablefalse w30px" draggable="true">
              <label className="form-check-label">Date</label>
            </th>
            <th draggable="true" className="text-center">
              Unique ID
            </th>
            <th draggable="true">Upload by</th>
            <th draggable="true" className="text-center">
              Asset Type
            </th>
            <th draggable="true" className="text-center">
              No. of Downloads
            </th>
            <th className="text-center" draggable="true">
              Action
            </th>
          </tr>
        </thead>
        <tbody id="table-body">
          {tableData.map((row) => (
            <tr key={row.id} className="dnd-moved">
              <td draggable="false">
                <input className="form-check-input" type="checkbox" />
              </td>
              <td className="text-center text-nowrap">
                <label className="form-check-label">{row.date}</label>
              </td>
              <td className="text-center">
                <span className="btn btn-primary bg-primary-focus rounded-pill text-bas text-sm fw-medium px-24 py-2">
                  {row.uniqueId}
                </span>
              </td>
              <td>
                <div className="d-flex align-items-center gap-2">
                  <span
                    className="userimg"
                    style={{
                      backgroundColor: row.uploadedBy.bgColor,
                      color: "black",
                    }}
                  >
                    {row.uploadedBy.initials}
                  </span>
                  <div className="flex-grow-1">
                    <h6 className="text-md text-primary-600 fw-medium mb-0">
                      {row.uploadedBy.name}
                    </h6>
                  </div>
                </div>
              </td>
              <td className="text-center">{row.assetType}</td>
              <td className="text-center">
                <a
                  data-bs-toggle="offcanvas"
                  href="#no-downloads"
                  role="button"
                  aria-controls="no-downloads"
                  className="text-decoration-underline text-primary"
                  style={{ textUnderlineOffset: 4 }}
                >
                  {row.downloads}
                </a>
              </td>
              <td className="text-center">
                <div className="d-flex align-items-center justify-content-around gap-12">
                  <button>
                    <svg
                      width={25}
                      height={16}
                      viewBox="0 0 25 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.334 0.846863C7.74853 0.846863 3.5902 3.35561 0.521772 7.43048C0.271388 7.76432 0.271388 8.23072 0.521772 8.56457C3.5902 12.6443 7.74853 15.1531 12.334 15.1531C16.9194 15.1531 21.0778 12.6443 24.1462 8.56948C24.3966 8.23563 24.3966 7.76923 24.1462 7.43539C21.0778 3.35561 16.9194 0.846863 12.334 0.846863ZM12.6629 13.0371C9.61904 13.2286 7.10539 10.7198 7.29686 7.67104C7.45396 5.15739 9.49139 3.11995 12.005 2.96285C15.0489 2.77138 17.5626 5.28012 17.3711 8.32891C17.2091 10.8377 15.1717 12.8751 12.6629 13.0371ZM12.5107 10.71C10.871 10.8131 9.51594 9.463 9.62395 7.82324C9.70741 6.46822 10.8071 5.3734 12.1622 5.28503C13.8019 5.18193 15.1569 6.53204 15.0489 8.17181C14.9606 9.53174 13.8608 10.6265 12.5107 10.71Z"
                        fill="#0073EA"
                      />
                    </svg>
                  </button>
                  <button>
                    <svg
                      width={19}
                      height={18}
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.8935 1.41277L12.6303 0.66383C13.501 -0.221277 15.0163 -0.221277 15.887 0.66383L16.4814 1.26808C16.9168 1.71064 17.1596 2.29787 17.1596 2.91915C17.1596 3.54043 16.9168 4.13617 16.4814 4.57021L15.7447 5.31915L11.8935 1.40426V1.41277ZM11.0061 2.31489L3.3791 10.0681C3.13631 10.3149 2.98561 10.6383 2.9605 10.9872L2.73445 13.4809C2.70933 13.7957 2.81817 14.1021 3.03584 14.3319C3.23678 14.5362 3.49631 14.6468 3.77259 14.6468H3.86468L6.31771 14.417C6.66096 14.383 6.9791 14.2298 7.22189 13.983L14.8489 6.22979L10.9977 2.31489H11.0061ZM18.834 17.3617C18.834 17.0128 18.5493 16.7234 18.2061 16.7234H1.46189C1.11864 16.7234 0.833984 17.0128 0.833984 17.3617C0.833984 17.7106 1.11864 18 1.46189 18H18.2061C18.5493 18 18.834 17.7106 18.834 17.3617Z"
                        fill="#0073EA"
                      />
                    </svg>
                  </button>
                  <button>
                    <svg
                      width={14}
                      height={18}
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.00098 16C1.00098 17.105 1.89594 18 3.00095 18H11.0008C12.1057 18 13.0007 17.105 13.0007 16V4.00024H1.00098V16Z"
                        fill="#FF5656"
                      />
                      <path
                        d="M10.5007 0.999962L9.50073 0H4.50088L3.50087 0.999962H0.000976562V2.99993H14.0006V0.999962H10.5007Z"
                        fill="#FF5656"
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContentTable;