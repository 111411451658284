import React, { useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import BarGraph from "../BarGraph";
import { fullMonthName, getMonthRange, shortMonthName } from "src/utils/CommonUtils";
import DatePickerComponent from "src/components/DatePickerComponent";

const ExceptedReceived = ({ exceptedRevenueData, salary }) => {
    const [selectedVertical, setSelectedVertical] = useState("Total");
    const [dateRange, setDateRange] = useState(getMonthRange());

    const extractMonthYear = (date) => {
        const d = new Date(date);
        return { month: d.getMonth() + 1, year: d.getFullYear() };
    };

    const filterData = useMemo(() => (data, statusKey, dateKey) =>
        data.filter(({ status, [dateKey]: date, vertical }) => {
            if (!date || status !== statusKey) return false;
            const { month, year } = extractMonthYear(date);
            const withinRange =
                (year > dateRange.start.year || (year === dateRange.start.year && month >= dateRange.start.month)) &&
                (year < dateRange.end.year || (year === dateRange.end.year && month <= dateRange.end.month));

            return withinRange && (selectedVertical === "Total" || vertical === selectedVertical);
        })
        , [dateRange, selectedVertical]);

    const expectedInvoices = useMemo(() => {
        const grouped = {};
        filterData(exceptedRevenueData, "Pending", "dueDate").forEach(({ dueDate, totalCostInr }) => {
            const month = dayjs(dueDate).format("MMM");
            const year = dayjs(dueDate).format("YYYY");
            const key = `${month} ${year}`;
            grouped[key] = (grouped[key] || 0) + Math.round(parseFloat(totalCostInr.$numberDecimal) || 0);
        });

        return Object.entries(grouped)
            .map(([name, y]) => ({ name, y }))
            .sort((a, b) => {
                const dateA = dayjs(a.name, "MMM YYYY").format("YYYY-MM");
                const dateB = dayjs(b.name, "MMM YYYY").format("YYYY-MM");
                return dateA.localeCompare(dateB);
            });
        // .sort((a, b) => shortMonthName(a.name.split(" ")[0]) - shortMonthName(b.name.split(" ")[0]) || Number(a.name.split(" ")[1]) - Number(b.name.split(" ")[1]));
    }, [exceptedRevenueData, selectedVertical, dateRange]);

    const revenueInvoices = useMemo(() => {
        const grouped = {};
        filterData(exceptedRevenueData, "Paid", "paidDate").forEach(({ paidDate, totalCostInr }) => {
            const month = dayjs(paidDate).format("MMM");
            const year = dayjs(paidDate).format("YYYY");
            const key = `${month} ${year}`;
            grouped[key] = (grouped[key] || 0) + Math.round(parseFloat(totalCostInr.$numberDecimal) || 0);
        });

        return Object.entries(grouped)
            .map(([name, y]) => ({ name, y }))
            .sort((a, b) => {
                const dateA = dayjs(a.name, "MMM YYYY").format("YYYY-MM");
                const dateB = dayjs(b.name, "MMM YYYY").format("YYYY-MM");
                return dateA.localeCompare(dateB);
            });
        // .sort((a, b) => shortMonthName(a.name.split(" ")[0]) - shortMonthName(b.name.split(" ")[0]) || Number(a.name.split(" ")[1]) - Number(b.name.split(" ")[1]));
    }, [exceptedRevenueData, selectedVertical, dateRange]);

    const salarySeries = useMemo(() => {
        const grouped = salary
            .filter(({ department, salaryYear, salaryMonth }) => {
                const recordDate = new Date(salaryYear, salaryMonth - 1, 1);
                return recordDate >= new Date(dateRange.start.year, dateRange.start.month - 1, 1) &&
                    recordDate <= new Date(dateRange.end.year, dateRange.end.month - 1, 1) &&
                    (selectedVertical === "Total" || (selectedVertical === "Acadecraft Government" ? department === "Government Department" : department !== "Government Department"));
            })
            .reduce((acc, { salaryYear, salaryMonth, netSalary }) => {
                const key = `${fullMonthName(salaryMonth, true)} ${salaryYear}`;
                acc[key] = (acc[key] || 0) + Math.round(netSalary);
                return acc;
            }, {});

        return Object.entries(grouped)
            .map(([name, y]) => ({ name, y }))
            .sort((a, b) => {
                const dateA = dayjs(a.name, "MMM YYYY").format("YYYY-MM");
                const dateB = dayjs(b.name, "MMM YYYY").format("YYYY-MM");
                return dateA.localeCompare(dateB);
            });
        // .sort((a, b) => shortMonthName(a.name.split(" ")[0]) - shortMonthName(b.name.split(" ")[0]) || Number(a.name.split(" ")[1]) - Number(b.name.split(" ")[1]));
    }, [salary, selectedVertical, dateRange]);

    const handleDateRangeChange = (selectedDates) => {
        if (selectedDates.length === 2) {
            setDateRange({
                start: extractMonthYear(selectedDates[0]),
                end: extractMonthYear(selectedDates[1]),
            });
        } else {
            setDateRange(getMonthRange());
        }
    };

    // Merge all dates from the three datasets
    const allDates = [...revenueInvoices, ...expectedInvoices, ...salarySeries].map(item => item.name);

    if (!allDates.length) {
        return;
    }

    // Get min and max date dynamically
    const minDate = dayjs(Math.min(...allDates.map(date => dayjs(date, "MMM YYYY").valueOf())));
    const maxDate = dayjs(Math.max(...allDates.map(date => dayjs(date, "MMM YYYY").valueOf())));

    // Generate month range
    const allMonths = [];
    for (let d = minDate; d.isBefore(maxDate) || d.isSame(maxDate); d = d.add(1, "month")) {
        allMonths.push(d.format("MMM YYYY"));
    }

    // Fill missing months
    const fillMissingMonths = (data) =>
        allMonths.map(month => {
            const existing = data.find(item => item.name === month);
            return existing ? { ...existing, visible: true } : { name: month, y: 0, visible: false };
        });
    const updatedRevenueInvoices = fillMissingMonths(revenueInvoices);
    const updatedExpectedInvoices = fillMissingMonths(expectedInvoices);
    const updatedSalarySeries = fillMissingMonths(salarySeries);

    // console.log(updatedRevenueInvoices);
    // console.log(updatedExpectedInvoices);
    // console.log(updatedSalarySeries);

    return (
        <>
            <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between my-16">
                <h6 className="text-lg fw-semibold mb-0 d-flex align-items-center gap-2">Expected VS Received</h6>
                <div className="d-flex align-items-center gap-28">
                    {["Total", "Acadecraft Government", "Acadecraft Private"].map((label) => (
                        <div key={label} className="form-check checked-primary d-flex align-items-center gap-2">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="ExceptedReceived"
                                id={`r${label}`}
                                checked={selectedVertical === label}
                                onChange={() => setSelectedVertical(label)}
                            />
                            <label className="form-check-label line-height-1 fw-medium text-secondary-light" htmlFor={`r${label}`}>
                                {label}
                            </label>
                        </div>
                    ))}
                    <div className="">
                        <DatePickerComponent onDateRangeChange={handleDateRangeChange} />
                    </div>
                </div>
            </div>
            <br />
            <div className="mt-2 allchartsCss">
                <BarGraph series={[
                    { name: "Revenue", data: updatedRevenueInvoices },
                    { name: "Expected", data: updatedExpectedInvoices },
                    { name: "Expenditure", data: updatedSalarySeries }
                ]} />
            </div>
        </>
    );
};

export default ExceptedReceived;
