
// import { createTheme } from "@mui/material/styles";
import moment from "moment";
export const isHtmlTag = (str) => {
  const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  return htmlRegex.test(str);
};

export const isTruthy = (value) => {
  return value !== null && value !== undefined && value !== 0 && value !== "";
};

export const checkIfLink = (message) => {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  if (urlRegex.test(message)) {
    return true;
  } else {
    return false;
  }
};
const imageExtensions = [
  "jpeg",
  "jpg",
  "png",
  "gif",
  "bmp",
  "tiff",
  "tif",
  "svg",
  "webp",
  "heic",
  "heif",
];
export const getFileType = (media) => {
  const fileUrl = media.url;

  if (
    (checkIfLink(media.message) && fileUrl === null) ||
    fileUrl === undefined
  ) {
    return "unknown";
  }
  if (fileUrl) {
    const extension = fileUrl.split(".").pop().toLowerCase();
    const extension2 = media?.fileType?.split("/")[1];
    if (
      imageExtensions.includes(extension) ||
      imageExtensions.includes(extension2)
    ) {
      return "image";
    } else if (
      extension === "mp3" ||
      extension === "wav" ||
      extension === "ogg" ||
      extension === "m4a" ||
      extension === "opus" ||
      extension === "blob"
    ) {
      return "audio";
    } else if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mkv" ||
      extension === "mov"
    ) {
      return "video";
    } else if (
      extension === "pdf" ||
      extension === "doc" ||
      extension === "docx" ||
      extension === "txt" ||
      [
        "zip",
        "rar",
        "7z",
        "tar",
        "gzip",
        "gz",
        "docx",
        "xlsx",
        "csv",
        "ppt",
        "pptx",
        "json",
      ].includes(extension)
    ) {
      return "document";
    } else if (checkIfLink(media.message)) {
      return "link";
    } else {
      return "unknown";
    }
  }
};

export const formatDate = (timestamp) => {
  return moment.utc(new Date(timestamp)).format("DD/MM/YYYY");
};
export const formatTime = (timestamp) => {
  // return moment.utc(new Date(timestamp)).format("HH:MM:SS");
  return moment(timestamp).format("hh:mm A");
};
export const getDateFromDate = (date) => {
  return moment(date).format("DD");
};
export const getDayFromDate = (date) => {
  return moment(date).format("dd")[0]; // 'ddd' gives the abbreviated name of the day, e.g., 'Mon'
};
export const getFullDayFromDate = (date) => {
  return moment(date).format("dddd"); // 'ddd' gives the abbreviated name of the day, e.g., 'Mon'
};
export const formatDateToMon = (dateString) => {
  const months = [
    "JAN",
    "FAB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  return `${day} ${month}  `;
};

function isSameDateTime(date, time) {
  const [timePart, amOrPm] = time.split(" ");
  const [hours, minutes] = timePart.split(":").map(Number);

  let adjustedHours = hours;
  if (amOrPm === "pm" && hours !== 12) {
    adjustedHours += 12;
  } else if (amOrPm === "am" && hours === 12) {
    adjustedHours = 0;
  }
  const [year, month, day] = date.split("-").map(Number);
  const reminderDate = new Date(year, month - 1, day, adjustedHours, minutes);
  const now = new Date();

  const timeDiff = reminderDate.getTime() - now.getTime();
  return timeDiff <= 0;
}

// export const theme = createTheme({
//   components: {
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           "& $notchedOutline": {
//             "& .MuiOutlinedInput-notchedOutline": {
//               borderWidth: 1,
//             },
//           },
//         },
//       },
//     },
//   },
// });

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const convertTimestampToDdMmYyyy = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
export const unAcceptedExtensions = [
  ".bat",
  ".cmd",
  ".com",
  ".msi",
  ".ps1",
  ".sh",
  ".bash",
  ".py",
  ".rb",
  ".pl",
  ".js",
  ".vbs",
  ".wsf",
  ".hta",
  ".docm",
  ".xlsm",
  ".pptm",
  ".dll",
  ".so",
  ".dylib",
  ".pif",
  ".cpl",
  ".sys",
  ".eml",
  ".msg",
];

export const makeUniqueById = (array) => {
  const map = new Map();
  array.forEach((obj) => map.set(obj._id, obj));
  return [...map.values()];
};
export const titleCase = (str, isCamel = false) => {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  if (isCamel) return str.join("");
  else return str.join(" ");
};


export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};
export const getDeviation = (actualDelivery, targetDelivery) => {
  if (targetDelivery === 0) return 0
  if (targetDelivery === 0) {
    // Handle the case where targetDelivery is 0 to avoid division by zero
    return actualDelivery === 0 ? 0 : (actualDelivery > 0 ? Infinity : -Infinity);
  }
  return ((actualDelivery - targetDelivery) / targetDelivery).toFixed(2);
};

export const CurrentYear = () => {
  const year = new Date().getFullYear();
  return year;
};

export const sumIdleTimes = (data, halfdayCount) => {
  // Function to convert time string (HH:MM:SS) to total minutes
  function timeToMinutes(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return hours * 60 + minutes + (seconds / 60);
  }

  // Function to convert total minutes to hours and minutes string
  function minutesToHoursMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60); // Round to handle floating-point precision issues
    return `${hours}H:${minutes}M`;
  }

  // Total number of records
  const totalRecords = data.length;

  // Total allowed idle time in minutes based on records and half-day count
  const idleTimeAllowedPerRecord = 60;
  const idleTimeAllowedPerHalfDay = idleTimeAllowedPerRecord / 2;
  const totalAllowedIdleMinutes = (totalRecords - halfdayCount) * idleTimeAllowedPerRecord + halfdayCount * idleTimeAllowedPerHalfDay;

  // Sum all idle times
  const totalIdleMinutes = data.reduce((sum, item) => sum + timeToMinutes(item.idle), 0);

  // Convert total minutes to hours and minutes format
  const totalIdleTimeFormatted = minutesToHoursMinutes(totalIdleMinutes);
  const totalAllowedIdleTimeFormatted = minutesToHoursMinutes(totalAllowedIdleMinutes);

  return {
    totalIdleTime: totalIdleTimeFormatted,
    totalAllowedIdleTime: totalAllowedIdleTimeFormatted
  };
}

export const getWeekendsInMonth = (dateString) => {
  const [day, month, year] = dateString.split('-').map(Number);
  const daysInMonth = new Date(year, month, 0).getDate(); // Get total days in month
  let weekends = [];

  for (let day = 1; day <= daysInMonth; day++) {
    const currentDay = new Date(year, month - 1, day).getDay(); // Get day of the week
    if (currentDay === 0 || currentDay === 6) { // Sunday (0) or Saturday (6)
      weekends.push(day);
    }
  }

  return weekends;
}
export const getDaysArrayInMonth = (dateString) => {
  if (!dateString) return [];
  const [day, month, year] = dateString.split('-').map(Number);
  const totalDays = new Date(year, month, 0).getDate(); // Get total days in month
  return Array.from({ length: totalDays }, (_, i) => i + 1); // Generate array [1, 2, ..., totalDays]
}

export const isFullDayLeave = (data, formattedDayDate) => {
  if (!data) return false;

  const Leave = data.find(leave => leave.startdate === formattedDayDate);
  if (!Leave) return false; // No leave found for this date

  if (Leave.dayType === 'FULL_DAY') return true;

  const hasIST_HALF = data.some(leave => leave.startdate === formattedDayDate && leave.dayType === 'IST_HALF');
  const hasIIND_HALF = data.some(leave => leave.startdate === formattedDayDate && leave.dayType === 'IIND_HALF');

  return hasIST_HALF && hasIIND_HALF;
};

export const separateDaysByType = (data, dateString) => {
  const [day, month, year] = dateString.split('-').map(Number);
  let fullDaysLeave = [];
  let halfDaysLeave = [];

  data?.forEach(item => {
    let start = new Date(item.startdate);
    let end = new Date(item.endate);

    while (start <= end) {
      if (start.getMonth() + 1 === month && start.getFullYear() === year) { // Check if within the month & year
        if (isFullDayLeave(data, item.startdate)) {
          fullDaysLeave.push(start.getDate()); // Full day
        } else {
          halfDaysLeave.push(start.getDate()); // Half day
        }
      }
      start.setDate(start.getDate() + 1); // Move to next day
    }
  });

  //removes duplicates instantly. 
  fullDaysLeave = [...new Set(fullDaysLeave)];
  halfDaysLeave = [...new Set(halfDaysLeave)];

  // remove halfday if match data into fullDay
  halfDaysLeave = halfDaysLeave.filter(day => !fullDaysLeave.includes(day));

  return { fullDaysLeave, halfDaysLeave };
}

const mergeUniqueDays = (weekends, holidays, fullDaysLeave, halfDaysLeave) => {
  // Ensure all inputs are arrays to avoid the "not iterable" error
  weekends = Array.isArray(weekends) ? weekends : [];
  holidays = Array.isArray(holidays) ? holidays : [];
  fullDaysLeave = Array.isArray(fullDaysLeave) ? fullDaysLeave : [];
  halfDaysLeave = Array.isArray(halfDaysLeave) ? halfDaysLeave : [];

  return [...new Set([...weekends, ...holidays, ...fullDaysLeave, ...halfDaysLeave])].sort((a, b) => a - b);
}

export const sumWorkingTimes = (data = [], halfdayCount, search = null) => {

  const markProduction =
    [...new Set(
      search?.data
        .filter(item => item.active) // Filter active entries
        .map(item => new Date(item.date).getUTCDate()) // Extract the day
    )]

  // Function to convert time string (HH:MM:SS) to total minutes
  function timeToMinutes(timeStr) {
    const [hours, minutes, seconds] = timeStr.split(':').map(Number);
    return hours * 60 + minutes + (seconds / 60);
  }

  // Function to convert total minutes to hours and minutes string
  function minutesToHoursMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60); // Round to handle floating-point precision issues
    return `${hours}H:${minutes}M`;
  }

  // Total number of records
  const totalRecords = data.length;
  let totalAllowedWorkingTimeFormatted = 0
  let workingStats = {}

  // Total allowed working time in minutes based on records and half-day count
  const workingTimeAllowedPerDay = 9 * 60; // 9 hours in minutes
  const workingTimeAllowedPerHalfDay = 4.5 * 60; // 4.5 hours in minutes
  const totalAllowedWorkingMinutes = (totalRecords - halfdayCount) * workingTimeAllowedPerDay + halfdayCount * workingTimeAllowedPerHalfDay;

  // Sum all working times and idle times
  const totalWorkingMinutes = data.reduce((sum, item) => sum + timeToMinutes(item.Time), 0);
  const totalIdleMinutes = data.reduce((sum, item) => sum + timeToMinutes(item.idle), 0);

  // Convert total minutes to hours and minutes format
  const totalWorkingTimeFormatted = minutesToHoursMinutes(totalWorkingMinutes);
  const totalIdleTimeFormatted = minutesToHoursMinutes(totalIdleMinutes);

  //new change build here
  if (search?.leaves) {
    const holidays = search?.holidays?.reduce((acc, item) => {
      acc.push(item.day);
      return acc;
    }, []);

    // const leaves = [
    //   {
    //     "id": 31676,
    //     "empid": 6108,
    //     "subject": "2",
    //     "currentWorking": "Instructional Designer Chemistry",
    //     "startdate": "2024-12-11",
    //     "endate": "2024-12-15",
    //     "comments": "Due to perosnal reasons and prepartion of pre wedding marriage functions",
    //     "rcomments": null,
    //     "send_to_manager": 4974,
    //     "approved_by": null,
    //     "is_created": "2024-12-10T13:42:26.000Z",
    //     "status": 0
    //   },
    //   {
    //     "id": 31677,
    //     "empid": 6108,
    //     "subject": "1",
    //     "currentWorking": "Instructional Designer Chemistry",
    //     "startdate": "2024-12-16",
    //     "endate": "2024-12-17",
    //     "comments": "Due to some personal reasons.",
    //     "rcomments": null,
    //     "send_to_manager": 4974,
    //     "approved_by": null,
    //     "is_created": "2024-12-10T13:44:50.000Z",
    //     "status": 0
    //   },
    //   {
    //     "id": 31677,
    //     "empid": 6108,
    //     "subject": "4",
    //     "currentWorking": "Instructional Designer Chemistry",
    //     "startdate": "2024-12-10",
    //     "endate": "2024-12-12",
    //     "comments": "Due to some personal reasons.",
    //     "rcomments": null,
    //     "send_to_manager": 4974,
    //     "approved_by": null,
    //     "is_created": "2024-12-10T13:44:50.000Z",
    //     "status": 0
    //   },
    //   {
    //     "id": 31678,
    //     "empid": 6108,
    //     "subject": "3",
    //     "currentWorking": "Instructional Designer Chemistry",
    //     "startdate": "2024-12-18",
    //     "endate": "2025-01-11",
    //     "comments": "Due to  some personal reasons and marriage functions ",
    //     "rcomments": null,
    //     "send_to_manager": 4974,
    //     "approved_by": null,
    //     "is_created": "2024-12-10T13:49:13.000Z",
    //     "status": 0
    //   }
    // ]
    // const { fullDaysLeave, halfDaysLeave } = separateDaysByType(leaves, data[0]?.Date || '')
    const totalDays = getDaysArrayInMonth(data[0]?.Date || '')
    const weekends = getWeekendsInMonth(data[0]?.Date || '')
    const { fullDaysLeave, halfDaysLeave } = separateDaysByType(search?.leaves, data[0]?.Date || '')
    const combinedWeekendsHolidaysFullDaysLeaves = mergeUniqueDays(weekends, holidays, fullDaysLeave)
    const halfDaysLeaveWithoutWeekendsHolidays = halfDaysLeave?.filter(day => !weekends.includes(day) && !holidays.includes(day));
    const fullDaysLeaveWithoutWeekendsHolidays = fullDaysLeave?.filter(day => !weekends.includes(day) && !holidays.includes(day));
    const absent = totalDays?.filter(day => !fullDaysLeave.includes(day) && !halfDaysLeave.includes(day) && !markProduction.includes(day) && !weekends.includes(day) && !holidays.includes(day));
    const workingWithWeekendsHolidays = markProduction?.filter(day => weekends.includes(day) || holidays.includes(day));
    const totalAbsent = absent.length + combinedWeekendsHolidaysFullDaysLeaves?.length + halfDaysLeaveWithoutWeekendsHolidays?.length / 2;
    const totalAbsentWithoutWeekendsHolidays = absent.length + fullDaysLeaveWithoutWeekendsHolidays?.length + halfDaysLeaveWithoutWeekendsHolidays?.length / 2
    const totalWorkingDays = totalDays?.length ? (totalDays?.length - totalAbsent) : 0
    const totalWorkingHours = totalWorkingDays * 9;
    const totalWorkingInMinutes = minutesToHoursMinutes(totalWorkingHours * 60)
    workingStats = {
      totalDays,
      weekends,
      holidays,
      fullDaysLeave,
      halfDaysLeave,
      combinedWeekendsHolidaysFullDaysLeaves,
      halfDaysLeaveWithoutWeekendsHolidays,
      fullDaysLeaveWithoutWeekendsHolidays,
      totalWorkingDays,
      totalWorkingHours,
      workingWithWeekendsHolidays,
      markProduction,
      absent,
      totalAbsentWithoutWeekendsHolidays,
      totalAbsent,//: absent.length + fullDaysLeaveWithoutWeekendsHolidays?.length + halfDaysLeaveWithoutWeekendsHolidays?.length / 2,
      totalWorkingInMinutes
    };

    console.log("Working Stats:", workingStats);
    totalAllowedWorkingTimeFormatted = totalWorkingInMinutes
  } else {
    totalAllowedWorkingTimeFormatted = minutesToHoursMinutes(totalAllowedWorkingMinutes);
  }

  return {
    workingStats,
    totalWorkingTime: totalWorkingTimeFormatted,
    totalAllowedWorkingTime: totalAllowedWorkingTimeFormatted,
    totalIdleTime: totalIdleTimeFormatted
  };
}

// Function to calculate the total lag
export const calculateTotalLagByDateAndSum = (data) => {
  // Step 1: Group data by date
  const groupedData = data.reduce((acc, item) => {
    const date = new Date(item.date);
    // Format date as YYYY-MM-DD to ignore the time component
    const normalizedDate = date.toISOString().split('T')[0];
    if (!acc[normalizedDate]) {
      acc[normalizedDate] = []; // Initialize an array for each date
    }
    acc[normalizedDate].push(item); // Add the item to the respective date group
    return acc;
  }, {});

  // Step 2: Calculate the total lag for each date
  const lagSumsByDate = Object.keys(groupedData).map((date) => {
    const totalLagForDate = groupedData[date].reduce((totalLag, item) => {
      const approvedDuration = item.approvedDurations !== undefined && item.approvedDurations !== null ? item.approvedDurations : 0;

      if (item.targetUnit === "Per Day") {
        const lagInDay = ((1 / item.target) * approvedDuration);
        return totalLag + lagInDay;
      } else {
        const lag = ((1 / 480) * approvedDuration);
        return totalLag + lag;
      }
    }, 0); // Start totalLag at 0 for each date

    // Divide the total lag for the date by 1 (if necessary)
    const lagDividedByOne = totalLagForDate - 1;

    return lagDividedByOne; // Return the result for each date
  });

  // Step 3: Sum all the lag values across dates
  const totalLagSum = lagSumsByDate.reduce((sum, lagForDate) => sum + lagForDate, 0);

  return totalLagSum.toFixed(2);
};

// Function to generate a dynamic color based on the name
export const generateColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 70%, 50%)`; // Creates a unique HSL color
  return color;
};

// Function to generate a contrasting text color (white or black)
export const getContrastYIQ = (hexColor) => {
  const rgb = hexColor.match(/\d+/g); // Extract rgb values
  const yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
  return yiq >= 128 ? '#000' : '#fff'; // If light, return black text; otherwise, white
};



export const dateFormat = (dateString, withTime = true) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString('en-IN', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC'
  });
  const formattedTime = date.toLocaleTimeString('en-IN', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'UTC'
  });

  if (withTime) {
    return `${formattedDate} ${formattedTime}`;
  } else {
    return `${formattedDate}`;
  }

}
export const tenureFormat = (dateString) => {

  const moment = require('moment');

  const startDate = moment(dateString);
  const currentDate = moment();

  const years = currentDate.diff(startDate, 'years');
  startDate.add(years, 'years');

  const months = currentDate.diff(startDate, 'months');
  startDate.add(months, 'months');

  const days = currentDate.diff(startDate, 'days');

  return `${years} Years ${months} Months ${days} Days`;
}

export const hasOldEntries = (productionList) => {
  const fiveDaysAgo = new Date();
  fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 3);
  fiveDaysAgo.setUTCHours(0, 0, 0, 0); // Normalize time to midnight UTC
  return productionList.some((entry) => {
    const entryDate = new Date(entry.date);
    return entryDate < fiveDaysAgo;
  });
}
export const getOldEntries = (productionList) => {
  const threeDaysAgo = new Date();
  threeDaysAgo.setUTCDate(threeDaysAgo.getUTCDate() - 3); // Subtract 3 days in UTC
  threeDaysAgo.setUTCHours(0, 0, 0, 0); // Normalize time to midnight UTC
  // Filter entries where the date is older than 3 days
  return productionList.filter((entry) => {
    const entryDate = new Date(entry.date); // ISO string parsed as UTC automatically
    return entryDate < threeDaysAgo;
  });
};
export const calculateAmount = (item) => {
  let amount;
  let totalDurations = item.totalDurations || 0;
  let approvedDurations = item.approvedDurations || 0;
  let rejectedDurations = item.rejectedDurations || 0;
  const duration = totalDurations - approvedDurations - rejectedDurations;
  let workingDays = 20;
  const salaryPerMinute = (item.userId.monthlySalary / (workingDays * 8 * 60)); // Working days, 8 hours per day, 60 minutes
  if (item.targetUnit === "min") {
    amount = (salaryPerMinute * duration);
  } else if (item.targetUnit === "Per Day") {
    amount = (salaryPerMinute * ((480 / item.target) * duration));
  }
  return amount;
};
export const getSupTag = (type) => {
  if (type === "IST_HALF") return <sup className="supTag">1</sup>;
  if (type === "IIND_HALF") return <sup className="supTag">2</sup>;
  return null; // For FULL_DAY, return nothing
};

export const getDateRange = (start, end, subject, dayType, status) => {
  const dateArray = [];
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Loop through each day in the date range
  while (startDate <= endDate) {
    dateArray.push({
      date: startDate.toISOString().split('T')[0], // Format date as YYYY-MM-DD
      subject: subject,
      dayType: dayType,
      status: status
    });
    startDate.setDate(startDate.getDate() + 1); // Move to the next day
  }
  return dateArray;
};
export const getFormattedMonthYear = (month, year) => {
  const date = new Date(year, (month - 1)); // Create a date object with the given year and month
  const formatted = date.toLocaleString('default', { month: 'long', year: 'numeric' });
  return formatted;
}

export const secondsToTimeWithPoint = (minutes) => {
  const decimalHours = (minutes / 60);
  return decimalHours.toFixed(2); // Adjust the number of decimal places as needed
}

export const getInitials = (name) => {
  const nameParts = name.split(' ');
  const initials = nameParts[0][0] + (nameParts[1] ? nameParts[1][0] : '');
  return initials.toUpperCase();
};
// Function to generate a dynamic background color based on the user's name
export const getBackgroundColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 60%, 70%)`; // Adjust saturation and lightness as needed
  return color;
};

export const convertToWords = (num) => {
  const ones = [
    "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
    "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen",
    "Seventeen", "Eighteen", "Nineteen"
  ];

  const tens = [
    "", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"
  ];

  const scales = ["", "Thousand", "Lakh", "Crore"];

  if (num === 0) return "Zero Only";

  function numberToWords(n) {
    let word = "";
    if (n < 20) {
      word = ones[n];
    } else if (n < 100) {
      word = tens[Math.floor(n / 10)] + (n % 10 ? " " + ones[n % 10] : "");
    } else {
      word =
        ones[Math.floor(n / 100)] +
        " Hundred" +
        (n % 100 ? " and " + numberToWords(n % 100) : "");
    }
    return word;
  }

  function splitNumber(n) {
    const result = [];
    const divisors = [10000000, 100000, 1000]; // Crore, Lakh, Thousand
    for (let i = 0; i < divisors.length; i++) {
      const divisor = divisors[i];
      const quotient = Math.floor(n / divisor);
      if (quotient > 0) {
        result.push({ value: quotient, scale: scales[i + 1] });
        n = n % divisor;
      }
    }
    if (n > 0) {
      result.push({ value: n, scale: "" });
    }
    return result;
  }

  const parts = splitNumber(num);
  const words = parts
    .map((part) => `${numberToWords(part.value)} ${part.scale}`.trim())
    .filter((word) => word !== "") // Remove empty parts
    .join(" "); // Avoid extra commas for small numbers

  return `${words}  Only`;
}

export const getMonthName = (monthNumber) => {
  // Create an array of month names
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Validate the input
  if (monthNumber < 1 || monthNumber > 12) {
    return "Invalid month number";
  }

  // Return the corresponding month name
  return monthNames[monthNumber - 1];
}

export const makeSlugSorting = (data) => {
  return data.sort((a, b) => {
    const regex = /Project_(\d+)/i; // Case-insensitive match
    const matchA = a.productionSlug.match(regex);
    const matchB = b.productionSlug.match(regex);

    const projectNumberA = matchA ? parseInt(matchA[1], 10) : 0;
    const projectNumberB = matchB ? parseInt(matchB[1], 10) : 0;

    if (projectNumberA !== projectNumberB) {
      return projectNumberB - projectNumberA; // Descending order for project number
    }

    const secondSlugA = a.productionSlug.split(">")[1]?.toLowerCase() || ""; // Normalize case
    const secondSlugB = b.productionSlug.split(">")[1]?.toLowerCase() || "";

    return secondSlugA.localeCompare(secondSlugB); // Ascending order for the second slug
  });
}
export const formatProductionCounter = (durations) => {
  const hours = (durations / 60).toFixed(2);
  //const days = (hours / 8).toFixed(2);
  return hours;
};
export const formatProductionDayCounter = (durations) => {
  const hours = (durations / 60).toFixed(2);
  const days = (hours / 8).toFixed(2);
  return days;
};