import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { handleLogout } from 'src/api/AuthAPI';
import { useNotification } from 'src/components/Notifination/ToastNotification';
import { logout } from '../home-container/actions/authAction';
import { generateColor, getContrastYIQ, hasOldEntries } from "src/utils/utils";
import { getPendingProduction, getPendingTask } from "src/api/TaskApi";
import PendingAlert from "./PendingAlert";
import { actionSetPendingList } from "../dashboard-container/actions/DashboardActions";

const HeaderNav = () => {
  const authToken = useSelector((state) => state.auth.user?.jwtToken);
  const { pendingList } = useSelector((state) => state.DashboardReducer, shallowEqual);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [pendingCount, setPendingTaskCount] = useState({task:0,production:0});
  // const [popupType, setPopupType] = useState('');
  const { openSuccessNotification, openErrorNotification } = useNotification();
  useEffect(() => {
    const getPendingCounter = async () => {
      try {
        const pendingTask = await getPendingTask({});
        const pendingProduction = await getPendingProduction({ userId: user?._id });
        dispatch(actionSetPendingList({
          data: {
            task: pendingTask?.data?.data,
            production: pendingProduction?.data?.data,
            pendingType: pendingTask?.data?.data?.length > 0 ? 'Task' : 'Production'
          }
        }));
        // setPendingTaskCount({ task: pendingTask?.data?.data?.length, production: pendingProduction?.data?.data?.length });
        // setPopupType(pendingTask?.data?.data?.length > 0 ? 'Task' : 'Production');  

      } catch (error) {
        console.error(error);
        openErrorNotification(
          error.response?.data?.message ?? "Error loading pending task and production"
        );
      }
    };
    getPendingCounter();
  }, []);

  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    const isNotPendingPages =
      !path.includes("pending-production-list") &&
      !path.includes("pending-task-list");
    // Check if the user has previously clicked "Remind me later" and 5 hours have passed
    const remindMeLaterTime = localStorage.getItem("remindMeLaterTime");
    const currentTime = new Date().getTime();
    if (remindMeLaterTime && currentTime - remindMeLaterTime < 5 * 60 * 60 * 1000) {
      // If less than 5 hours, don't show the modal
      return;
    }

    const myModal = new window.bootstrap.Modal(document.getElementById('requestAccess'), {
      backdrop: 'static',
      keyboard: false,
    });
    // pendingList?.production?.length>0
    const isProductionPendingForApproval = hasOldEntries(pendingList?.production)
    if ((pendingList?.task?.length > 0 || isProductionPendingForApproval) && user?.roleDetails?.roleName === 'Admin' && isNotPendingPages) {
      myModal.show();
    }
    // myModal.dispose()
  }, [pendingList]);

  const handleLogoutClick = async () => {
    try {
      if (await handleLogout(user?._id)) {
        dispatch(logout());
        navigate("/login");
        openSuccessNotification("Logged out successfully.");
      }
    } catch (error) {
      return openErrorNotification(
        error.response.data.message ?? "Error logging out"
      );
    } finally {
      dispatch(logout());
      navigate("/login");
    }
  };
  useEffect(() => {
    // Initialize Bootstrap tooltips for elements with data-bs-toggle="tooltip"
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    // Cleanup tooltips when the component unmounts
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);
  return (
    <>
      <div className="navbar-header">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <div className="d-flex flex-wrap align-items-center gap-4">
              <button type="button" className="sidebar-toggle">
                {/* <iconify-icon
            icon="heroicons:bars-3-solid"
            className="icon text-xl non-active"
          /> */}
                <i className="mdi mdi-menu mdi-24px icon non-active"></i>
                <i className="mdi mdi-arrow-right mdi-24px icon active"></i>
                {/* <iconify-icon
            icon="iconoir:arrow-right"
            className="icon text-2xl active"
          /> */}
              </button>
              <button type="button" className="sidebar-mobile-toggle">
                <iconify-icon icon="heroicons:bars-3-solid" className="icon" />
              </button>
              <h6 className="fw-semibold mb-0">Digital Employee Panel</h6>
            </div>
          </div>
          <div className="col-auto">
            <div className="d-flex flex-wrap align-items-center gap-3">
              <button
                type="button"
                data-theme-toggle=""
                className="w-40-px h-40-px bg-neutral-200 rounded-circle d-flex justify-content-center align-items-center"
              />
              {/* <div className="dropdown">
          <button
            className="has-indicator w-40-px h-40-px bg-neutral-200 rounded-circle d-flex justify-content-center align-items-center"
            type="button"
            data-bs-toggle="dropdown"
          >
            <iconify-icon
              icon="iconoir:bell"
              className="text-primary-light text-xl"
            />
          </button>
          <div className="dropdown-menu to-top dropdown-menu-lg p-0">
            <div className="m-16 py-12 px-16 radius-8 bg-primary-50 mb-16 d-flex align-items-center justify-content-between gap-2">
              <div>
                <h6 className="text-lg text-primary-light fw-semibold mb-0">
                  Notifications
                </h6>
              </div>
              <span className="text-primary-600 fw-semibold text-lg w-40-px h-40-px rounded-circle bg-base d-flex justify-content-center align-items-center">
                05
              </span>
            </div>
            <div className="max-h-400-px overflow-y-auto scroll-sm pe-4">
              <a
                href="#" onClick={(e) => e.preventDefault()}
                className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between"
              >
                <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                  <span className="w-44-px h-44-px bg-success-subtle text-success-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
                    <iconify-icon
                      icon="bitcoin-icons:verify-outline"
                      className="icon text-xxl"
                    />
                  </span>
                  <div>
                    <h6 className="text-md fw-semibold mb-4">
                      Congratulations
                    </h6>
                    <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                      Your profile has been Verified. Your profile has been
                      Verified
                    </p>
                  </div>
                </div>
                <span className="text-sm text-secondary-light flex-shrink-0">
                  23 Mins ago
                </span>
              </a>
              <a
                href="#" onClick={(e) => e.preventDefault()}
                className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between bg-neutral-50"
              >
                <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                  <span className="w-44-px h-44-px bg-success-subtle text-success-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
                    <img
                      src="/assets/images/notification/profile-1.png"
                      alt=""
                    />
                  </span>
                  <div>
                    <h6 className="text-md fw-semibold mb-4">
                      Ronald Richards
                    </h6>
                    <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                      You can stitch between artboards
                    </p>
                  </div>
                </div>
                <span className="text-sm text-secondary-light flex-shrink-0">
                  23 Mins ago
                </span>
              </a>
              <a
                href="#" onClick={(e) => e.preventDefault()}
                className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between"
              >
                <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                  <span className="w-44-px h-44-px bg-info-subtle text-info-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
                    AM
                  </span>
                  <div>
                    <h6 className="text-md fw-semibold mb-4">Arlene McCoy</h6>
                    <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                      Invite you to prototyping
                    </p>
                  </div>
                </div>
                <span className="text-sm text-secondary-light flex-shrink-0">
                  23 Mins ago
                </span>
              </a>
              <a
                href="#" onClick={(e) => e.preventDefault()}
                className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between bg-neutral-50"
              >
                <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                  <span className="w-44-px h-44-px bg-success-subtle text-success-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
                    <img
                      src="/assets/images/notification/profile-2.png"
                      alt=""
                    />
                  </span>
                  <div>
                    <h6 className="text-md fw-semibold mb-4">Annette Black</h6>
                    <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                      Invite you to prototyping
                    </p>
                  </div>
                </div>
                <span className="text-sm text-secondary-light flex-shrink-0">
                  23 Mins ago
                </span>
              </a>
              <a
                href="#" onClick={(e) => e.preventDefault()}
                className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between"
              >
                <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                  <span className="w-44-px h-44-px bg-info-subtle text-info-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
                    DR
                  </span>
                  <div>
                    <h6 className="text-md fw-semibold mb-4">
                      Darlene Robertson
                    </h6>
                    <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                      Invite you to prototyping
                    </p>
                  </div>
                </div>
                <span className="text-sm text-secondary-light flex-shrink-0">
                  23 Mins ago
                </span>
              </a>
            </div>
            <div className="text-center py-12 px-16">
              <a
                href="#" onClick={(e) => e.preventDefault()}
                className="text-primary-600 fw-semibold text-md"
              >
                See All Notification
              </a>
            </div>
          </div>
        </div> */}
              {/* Notification dropdown end */}
              <div className="dropdown">
                <button
                  className="d-flex justify-content-center align-items-center rounded-circle"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  {/* <img
              src="/assets/images/user.png"
              alt="image"
              className="w-40-px h-40-px object-fit-cover rounded-circle"
            /> */}
                  <span
                    className="img imgsSet mx-auto"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-bs-custom-class="tooltip-dark"
                    data-bs-title={`${user.firstName} ${user?.lastName}`}
                  >
                    {user.profilePicture ? (
                      <img
                        src={user.profilePicture}
                        className="img-fluid"
                        alt=""
                      />
                    ) : (
                      <span
                        className="initials d-flex justify-content-center align-items-center w-100 h-100"
                        style={{
                          backgroundColor: generateColor(`${user.firstName}${user?.lastName}`),
                          color: getContrastYIQ(generateColor(`${user.firstName}${user?.lastName}`)),
                        }}
                      >
                        {`${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`}
                      </span>
                    )}
                  </span>
                </button>
                <div className="dropdown-menu to-top dropdown-menu-sm">
                  <ul className="to-top-list">
                    {/* <li>
                <a
                  className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-primary d-flex align-items-center gap-3"
                  href="#" onClick={(e) => e.preventDefault()}
                >
                  <iconify-icon
                    icon="solar:user-linear"
                    className="icon text-xl"
                  />{" "}
                  My Profile
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-primary d-flex align-items-center gap-3"
                  href="#" onClick={(e) => e.preventDefault()}
                >
                  <iconify-icon
                    icon="tabler:message-check"
                    className="icon text-xl"
                  />{" "}
                  Change Password{" "}
                </a>
              </li> 
              <li>
                <a
                  className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-primary d-flex align-items-center gap-3"
                  href="#" onClick={(e) => e.preventDefault()}
                >
                  <iconify-icon
                    icon="icon-park-outline:setting-two"
                    className="icon text-xl"
                  />{" "}
                  Official Letter
                </a>
              </li>*/}
                    <li>
                      <button
                        className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-danger d-flex align-items-center gap-3"
                        onClick={handleLogoutClick}
                      >
                        <iconify-icon icon="lucide:power" className="icon text-xl" />{" "}
                        Log Out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Profile dropdown end */}
            </div>
          </div>
        </div>
      </div>
      <PendingAlert />
    </>
  )
}
export default HeaderNav



