import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
import OrdinalNumberDisplay from "src/components/OrdinalNumberDisplay";
import { costFormat } from "src/utils/CommonUtils";

const OADAndCMDList = () => {
  const { OAD_CMD_Details } = useSelector((state) => state.ProjectReducer, shallowEqual);
  const { type } = OAD_CMD_Details
  const users = (OAD_CMD_Details.project) ? OAD_CMD_Details.project[type.toLowerCase() === "oad" ? "oad" : "cmd"] || [] : [];
  const netTotal = (OAD_CMD_Details.project) ? OAD_CMD_Details.project[type.toLowerCase()].reduce((total, item) => (item.type == "add") ? (total + item.value) : (total - item.value), 0) : 0;
  const user = useSelector((state) => state.auth.user);

  // Function to get the initials from the name
  const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts[0][0] + (nameParts[1] ? nameParts[1][0] : '');
    return initials.toUpperCase();
  };

  // Function to generate a dynamic background color based on the user's name
  const getBackgroundColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 60%, 70%)`; // Adjust saturation and lightness as needed
    return color;
  };

  return (
    <>
      <div className="modal fade cusmodl" id="ViewCmdCostModual" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between pb-0 border-0"><h6 className="mb-2 fw-bold text-lg mb-0">{type} Ledger</h6><button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table bordered-table mb-0 custm2nth">
                  <thead className="sticky-header">
                    <tr>
                      <th scope="col">Sr. No.</th>
                      <th scope="col">{type.toLowerCase() === "oad" ? "Month" : "Milestone"}</th>
                      <th scope="col">Updated by</th>
                      {/* <th scope="col">Description</th> */}
                      <th scope="col">Updated Date</th>
                      <th scope="col" className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users
                      .sort((a, b) => new Date(a.updatedTs) - new Date(b.updatedTs))
                      .map((log, index) => {
                        const userDetails = log.userDetails?.[0] || user
                        const userName = `${userDetails?.firstName} ${userDetails?.lastName}`;
                        const day = new Date(log.ts).getUTCDate() - 1;


                        return (
                          <tr key={index}>
                            <td>{index + 1}</td> {/* Sr. No. */}
                            <td>
                              {type.toLowerCase() === "oad"
                                ? moment(log.ts).format("MMM YYYY")
                                : (<>
                                   <div className="text-black fw-bold">Milestone</div>
                                  <div className="font-12 fw-medium"><OrdinalNumberDisplay number={day + 1} /> - <OrdinalNumberDisplay number={day + 10} /> Days</div>
                                </>)}

                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                {/* <span className="UsIMgName"><img src={user.img} alt={user.name} className="flex-shrink-0 radius-8" /></span> */}
                                <span
                                  className="UsIMgName"
                                  style={{ backgroundColor: getBackgroundColor(userName) }}
                                >
                                  {userDetails?.profilePicture ? (
                                    <img src={userDetails?.profilePicture} alt={userName} className="flex-shrink-0 radius-8" />
                                  ) : (
                                    <span className="initials">{getInitials(userName)}</span>
                                  )}
                                </span>

                                <span className="text-md text-secondary-light fw-semibold flex-grow-1">{userName}</span>
                              </div>
                            </td>
                            {/* <td><div className="mw300px">{log.description}</div></td> */}
                            <td>{moment(log.updatedTs).format("DD MMM YYYY")}</td>
                            {/* <td className="text-end"><span className={`${log.statusClass} px-24 py-4 rounded-pill fw-medium text-lg fw-bold`}>{(log.type==="add")?`+${log.value}`:`-${log.value}`}</span></td>  */}
                            <td className="text-end">
                              <span
                                className={`
      ${log.statusClass} 
      px-24 py-4 rounded-pill fw-medium text-lg fw-bold 
      ${log.type === "add" ? "text-success-600" : "text-danger-600"}
    `}
                              >
                                {log.type === "add" ? `+` : `-`} {costFormat(log.value)}
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={(5)}><div className="d-flex align-items-center justify-content-end gap-3 fssetNAP px-24 py-10">Net Amount <span className="NAPrice">{costFormat(netTotal)}</span></div></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default OADAndCMDList