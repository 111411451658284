import React, { useState } from 'react';

const OrangeCategory = () => {
    // Define the tab data
    const tabs = [
        { id: 'orange1', label: 'Week 1', content: 'Home Content' },
        { id: 'orange2', label: 'Week 2', content: 'Details Content' },
        { id: 'orange3', label: 'Week 3', content: 'Profile Content' },
        { id: 'orange4', label: 'Week 4', content: 'Settings Content' },
        { id: 'orange5', label: 'Week 5', content: 'Settings Content' },
    ];

    // Set the active tab state
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    // Sample data for the table
    const [categoryDatas, setCategoryDatas] = useState([
        { id: 1, projectCode: 'P001-A', clientName: 'Nidhi Sharma', projectOwner: 'Nidhi Sharma', remarks: 'watchful and prompt to meet danger or emergency an alert guard trying to stay alert to possible' },
        { id: 2, projectCode: 'P001-A', clientName: 'Nidhi Sharma', projectOwner: 'Nidhi Sharma', remarks: 'Sep Allocation' },  
        { id: 3, projectCode: 'P001-A', clientName: 'Nidhi Sharma', projectOwner: 'Nidhi Sharma', remarks: 'watchful and prompt to meet ' },  
        { id: 4, projectCode: 'P001-A', clientName: 'Nidhi Sharma', projectOwner: 'Nidhi Sharma', remarks: 'Sep Allocation' },  
        { id: 5, projectCode: 'P001-A', clientName: 'Nidhi Sharma', projectOwner: 'Nidhi Sharma', remarks: 'watchful and prompt to meet ' },  
    ]); 

    return (
        <> 

            <div
                className="modal fade cusmodl"
                id="categoryModual"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between pb-0 border-0 flex-column align-items-end">
                           <div className="d-flex justify-content-between w-100"> <h6 className="mb-2 fw-bold text-lg mb-0">Orange Category {tabs.find(tab => tab.id === activeTab)?.label}</h6>
                           <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">✗</button></div>
                            <ul className="nav focus-tab nav-pills justify-content-end mb-16 mt-24" id="pills-tab-two" role="tablist">
                                {tabs.map(tab => (
                                    <li className="nav-item" role="presentation" key={tab.id}>
                                        <button
                                            className={`nav-link fw-semibold text-primary-light rounded-pill px-16 py-10 ${activeTab === tab.id ? 'active' : ''}`}
                                            id={`pills-focus-${tab.id}-tab`}
                                            data-bs-toggle="pill"
                                            data-bs-target={`#pills-focus-${tab.id}`}
                                            type="button"
                                            role="tab"
                                            aria-controls={`pills-focus-${tab.id}`}
                                            aria-selected={activeTab === tab.id}
                                            onClick={() => setActiveTab(tab.id)}
                                        >
                                            {tab.label}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="modal-body"> 
                            <div className="tab-content" id="pills-tab-twoContent">
                                {tabs.map(tab => (
                                    <div
                                        className={`tab-pane fade ${activeTab === tab.id ? 'active show' : ''}`}
                                        id={`pills-focus-${tab.id}`}
                                        role="tabpanel"
                                        aria-labelledby={`pills-focus-${tab.id}-tab`}
                                        tabIndex={0}
                                        key={tab.id}
                                    >
                                        <div className="">
                                            <h5>458</h5>
                                            <div className="d-flex align-items-center gap-2 w-100 mb-24">
                                                <div className="w-100 ms-auto">
                                                    <div
                                                        className="progress height15 progress-sm rounded-pill my-0"
                                                        role="progressbar"
                                                        aria-label="Success example"
                                                        aria-valuenow={10}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                    >
                                                        <div
                                                            className="progress-bar progress-bar-orange rounded-pill"
                                                            style={{ width: "10%" }}
                                                        />
                                                    </div>
                                                </div>
                                                <span className="text-neutral-300 text-2xxl line-height-1">
                                                    10%
                                                </span>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="table-responsive table-container">
                                                <table className="table basic-border-table mb-0 table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>S.L</th>
                                                            <th>Project Code </th>
                                                            <th>Client Name</th>
                                                            <th>Project Owner</th>
                                                            <th>Remarks   </th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {categoryDatas.map((categoryData) => (
                                                            <tr key={categoryData.id}>
                                                                <td>{categoryData.id.toString().padStart(2, '0')}</td>
                                                                <td>
                                                                    <a href="#" onClick={(e) => e.preventDefault()} className="text-primary-600">
                                                                        {categoryData.projectCode}
                                                                    </a>
                                                                </td>
                                                                <td>{categoryData.clientName}</td>
                                                                <td>{categoryData.projectOwner}</td>
                                                                <td><div className="mxw200">{categoryData.remarks}</div></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrangeCategory;
