import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { generateMonthData } from "src/utils/CommonUtils"
import { getDateFromDate, getDateRange, getDayFromDate } from "src/utils/utils"

const AttendanceCalendar = (props) => {
  const { selectedMonth, selectedYear, search } = useSelector((state) => state.ProductionReducer, shallowEqual);
  // Month value should be from 0 to 11
  const year = selectedYear;
  const month = (selectedMonth - 1); // August (0-indexed, so 7 represents August)
  const dateWiseProductions = search?.data.filter(item => item.date).map(item => new Date(item.date).toISOString().split('T')[0]);
  const dateWiseLeaves = search?.leaves?.flatMap(item => getDateRange(item.startdate, item.endate, item.subject, item.dayType));
  const dateWiseHolidays = search?.holidays;
  const dateWiseHubStaff = search?.HubstaffResponse;
  const monthDays = generateMonthData(year, month, dateWiseProductions, dateWiseLeaves, dateWiseHubStaff)
  const combinedMonthDays = monthDays.flat();
  const today = new Date().toISOString().split("T")[0]; // Current Date in YYYY-MM-DD
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    // if (search?.data?.length > 0) {
    setIsDataLoaded(true);
    // }

  }, [search?.data]); // ✅ Runs only when `search.data` changes
  useEffect(() => {
    setIsDataLoaded(false);

  }, [props?.isRefresh]); // ✅ Runs only when date changes

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  return (
    <>
      <div className="row mb-16 mt-40">
        <div className="d-flex justify-content-between gap-0 w-100  text-sm">
          {isDataLoaded &&
            combinedMonthDays.map((day, monthIndex) => {

              const formattedDayDate = new Date(day.date).toISOString().split('T')[0];
              const dayOfMonth = new Date(day.date).getDate();
              const Leave = dateWiseLeaves?.find(leave => leave.date === formattedDayDate);
              const LeaveIST_HALF = dateWiseLeaves?.find(leave => leave.date === formattedDayDate && leave.dayType === 'IST_HALF');
              const LeaveIIND_HALF = dateWiseLeaves?.find(leave => leave.date === formattedDayDate && leave.dayType === 'IIND_HALF');
              const holiday = dateWiseHolidays?.find(holiday => holiday.day === dayOfMonth);

              const isPastDate = new Date(formattedDayDate) < new Date(today); // Proper Date Comparison

              let status = "";
              let bgColor = "bg-zinc-300 text-zinc-500"; // Default blank
              let leaveType = ''
              if (Leave?.subject == 1) { leaveType = 'CL' }
              if (Leave?.subject == 2) { leaveType = 'SL' }
              if (Leave?.subject == 3) { leaveType = 'OL' }

              // if (day.label === false) { status = ""; bgColor = "bg-zinc-300 text-zinc-500"; }
              // else 
              if (Leave?.subject == 1) { status = "CL"; bgColor = "bg-blue-500 text-white"; }
              else if (Leave?.subject == 2) { status = "SL"; bgColor = "bg-yellow-500 text-white"; }
              else if (Leave?.subject == 3) { status = "OL"; bgColor = "bg-danger-800 text-white"; }
              else if (holiday) { status = "H"; bgColor = "bg-pink-500 text-white"; }
              else if (day.label === "W" || 'S' === getDayFromDate(day.date)) {
                status = "W"; bgColor = "bg-zinc-300 text-white";
              }
              else if (day.label === "P") {
                if (Leave?.subject == 1) { status = "CL"; bgColor = "bg-blue-500 text-white"; }
                else if (Leave?.subject == 2) { status = "SL"; bgColor = "bg-yellow-500 text-white"; }
                else if (Leave?.subject == 3) { status = "OL"; bgColor = "bg-yellow-200 text-white"; }
                else { status = "P"; bgColor = "bg-green-500 text-white"; }
              }
              else if (day.label === "A") { status = "A"; bgColor = "bg-red-500 text-white"; }
              // else if (!day.label) { status = ""; bgColor = "bg-red-500 text-white"; }
              else if (isPastDate) { status = "A"; bgColor = "bg-red-500 text-white"; }
              // else if (holiday) { status = "H"; bgColor = "bg-pink-500 text-white"; }
              else {
                status = "";  // Keep it blank initially
                bgColor = "bg-zinc-300 text-zinc-500"; // Default blank color
              }

              if (LeaveIST_HALF && Object.keys(LeaveIST_HALF).length > 0 &&
                LeaveIIND_HALF && Object.keys(LeaveIIND_HALF).length > 0) {
                // Both objects exist and have properties
              } else {
                if (Leave?.dayType === 'IST_HALF') {
                  bgColor += ` f-${leaveType.toLowerCase()}-hday`;
                } else if (Leave?.dayType === 'IIND_HALF') {
                  bgColor += ` s-${leaveType.toLowerCase()}-hday`;
                }
              }

              /// mark attendance showing underline
              if (day.label === "P" && status !== "P") { bgColor += ` p-h-w` }

              return (
                <React.Fragment key={monthIndex}>
                  {monthIndex !== 0 && 'M' === getDayFromDate(day.date) && (
                    <div className="monlist w-50"></div>
                  )}
                  <div className="monlist w-100" key={monthIndex}>
                    <div className="p-2 bg-card text-card-foreground rounded-lg d-flex flex-column h-100">
                      <div className={`grid grid-cols-31 gap-1 text-center text-sm font-medium`}>
                        {/* {monthIndex === 0 && Array.from({ length: 7 - item.length }).map((_, index) => (
                        <div className="text-muted-foreground" key={`date_blank${index}`}></div>
                      ))} */}

                        {/* {
                        item.map((day, weekIndexDate) => {
                          return ( */}
                        <div className="text-muted-foreground" key={`date_${monthIndex}`}>{getDateFromDate(day.date)}</div>
                        {/* )
                        })
                      } */}

                      </div>
                      <div className={`grid grid-cols-31 gap-1 mt-2 text-center text-sm font-medium h-100`}>
                        {/* {
                        item.map((day, weekIndexDay) => {
                          return ( */}
                        <div className="text-muted-foreground" key={`day_${monthIndex}`}>{getDayFromDate(day.date)}</div>
                        {/* //     )
                      //   })
                      // } */}

                      </div>
                      <div className={`grid grid-cols-31 gap-1 mt-2 text-center text-sm font-medium h-100`}>
                        {/* {
                        item.map((day, weekIndexAttendance) => {
                          return ( */}
                        <div className={`${bgColor} rounded`} key={`att_${monthIndex}`}>
                          {status}
                        </div>
                        {/* // );


                          // const formattedDayDate = new Date(day.date).toISOString().split('T')[0];
                          // const dayOfMonth = new Date(day.date).getDate();
                          // const Leave = dateWiseLeaves?.find(leave => leave.date === formattedDayDate);
                          // const holiday = dateWiseHolidays?.find(holiday => holiday.day == dayOfMonth);
                          // if (day.label === "P") {
                          //   if ((Leave && Leave.subject == 1))
                          //     return (
                          //       <div className="bg-blue-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>CL</div>
                          //     )
                          //   else if ((Leave && Leave.subject == 2))
                          //     return (
                          //       <div className="bg-yellow-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>SL</div>
                          //     )
                          //   else if (Leave && Leave.subject == 4) {
                          //     return (
                          //       <div className="bg-yellow-200 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>HD</div>
                          //     );
                          //   }
                          //   return (
                          //     <div className="bg-green-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>P</div>
                          //   );
                          // }
                          // else if (day.label === "W") {
                          //   if (holiday) {
                          //     return (
                          //       <div className="bg-pink-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>H</div>
                          //     );
                          //   }
                          //   return (
                          //     <div className="bg-zinc-300 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>W</div>
                          //   )
                          // }
                          // else if ((Leave && Leave.subject == 1))
                          //   return (
                          //     <div className="bg-blue-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>CL</div>
                          //   )
                          // else if ((Leave && Leave.subject == 2))
                          //   return (
                          //     <div className="bg-yellow-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>SL</div>
                          //   )
                          // else if ((Leave && Leave.subject == 3))
                          //   return (
                          //     <div className="bg-red-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>A</div>
                          //   )
                          // else if (day.label === "A")
                          //   return (
                          //     <div className="bg-red-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>A</div>
                          //   )
                          // else
                          //   if (holiday) {
                          //     return (
                          //       <div className="bg-pink-500 text-white py-1 rounded" key={`att_${weekIndexAttendance}`}>H</div>
                          //     );
                          //   }
                          // return (
                          //   <div className="bg-zinc-300 text-zinc-500 py-1 rounded" key={`att_${weekIndexAttendance}`}></div>
                          // )
                        })
                      } */}
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })
          }
        </div>
      </div>

      <div className="d-flex flex-wrap align-items-center gap-4 py-16 border-bottom">
        <div className="d-flex align-items-center gap-2">
          <span className="w-12-px h-12-px bg-green-500 rounded-circle" />
          <span className="text-neutral-900 fw-medium">Presents</span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <span className="w-12-px h-12-px bg-pink-500 rounded-circle" />
          <span className="text-neutral-900 fw-medium">Holidays</span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <span className="w-12-px h-12-px bg-yellow-500 rounded-circle" />
          <span className="text-neutral-900 fw-medium">Sick Leave <i className='mdi mdi-information-outline' data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-custom-class="tooltip-dark"
            data-bs-html="true"
            data-bs-title="SL 1 - 1st Half Day<br>SL 2 - 2nd Half Day"></i></span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <span className="w-12-px h-12-px bg-blue-500 rounded-circle" />
          <span className="text-neutral-900 fw-medium">Casual Leave <i className='mdi mdi-information-outline' data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-custom-class="tooltip-dark"
            data-bs-html="true"
            data-bs-title="CL 1 - 1st Half Day<br>CL 2 - 2nd Half Day"></i></span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <span className="w-12-px h-12-px bg-zinc-300 rounded-circle" />
          <span className="text-zinc-300 fw-medium">Week Off</span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <span className="w-12-px h-12-px bg-danger-800 rounded-circle" />
          <span className="text-neutral-900 fw-medium">Other Leave <i className='mdi mdi-information-outline' data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-custom-class="tooltip-dark"
            data-bs-html="true"
            data-bs-title="OL 1 - 1st Half Day<br>OL 2 - 2nd Half Day"></i></span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <span className="w-12-px h-12-px bg-red-500 rounded-circle" />
          <span className="text-neutral-900 fw-medium">Absent</span>
        </div>
      </div>
    </>
  )
}
export default AttendanceCalendar