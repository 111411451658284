import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { projectOADSaveAPI } from "src/api/ProjectApi";
import { costFormat, getMonthNumber } from "src/utils/CommonUtils";
import { toast } from 'react-toastify';

const OADSavePopup = ({ OADDetails, project, onTotalChangeOAD }) => {
    const { projectId, OADAction, currentOAD } = OADDetails;
    // Finding project item
    const findItem = project?.find(item => item._id === projectId);
    const [sortedGroupedData, setSortedGroupedData] = useState([]);

    useEffect(() => {
        if (!findItem) {
            return;
        }


        // Ensure findItem.oad is an array
        const oadArray = Array.isArray(findItem?.oad) ? findItem.oad : [];

        // Group data by month and year
        const groupedData = oadArray.reduce((acc, item) => {
            if (!item.ts || !item.value) return acc;

            const date = new Date(item.ts);
            if (isNaN(date)) {
                console.error("Invalid date in item:", item); // Debugging invalid dates
                return acc;
            }

            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Months are zero-based, so add 1
            const key = `${year}-${month.toString().padStart(2, '0')}`;

            if (!acc[key]) {
                acc[key] = { year, month, totalValue: 0 };
            }

            acc[key].totalValue += item.type === "add" ? item.value : -item.value;

            return acc;
        }, {});

        // Convert object to array and sort by year & month
        const sortedData = Object.values(groupedData).sort((a, b) =>
            a.year - b.year || a.month - b.month
        );

        setSortedGroupedData(sortedData); // ✅ Update state after data is ready

    }, [project, projectId]); // Only run when project or projectId updates

    const [months, setMonths] = useState([]);

    useEffect(() => {
        setMonths(prevMonths => {
            const newMonths = sortedGroupedData?.length
                ? sortedGroupedData.map(({ year, month, totalValue }) => ({
                    month: dayjs().month(month - 1).format("MMMM"),
                    year: year.toString(),
                    value: totalValue.toString(),
                }))
                : [{ month: dayjs().format("MMMM"), year: dayjs().format("YYYY"), value: "" }];

            return JSON.stringify(prevMonths) !== JSON.stringify(newMonths) ? newMonths : prevMonths;
        });
    }, [sortedGroupedData]);

    const [initialOADValues, setInitialOADValues] = useState(sortedGroupedData);

    // Update initialOADValues only when initialValues change
    useEffect(() => {
        if (JSON.stringify(sortedGroupedData) !== JSON.stringify(initialOADValues)) {
            setInitialOADValues(sortedGroupedData);
        }
    }, [sortedGroupedData]);

    const validationSchema = Yup.object({
        costs: Yup.array().of(
            Yup.object({
                value: Yup.number()
                    .typeError("Cost must be a number")
                    .min(0, "Cost cannot be negative")
                    .required("Cost is required"),
            })
        ),
    });

    useEffect(() => {
        // Reset values when the modal is closed
        setMonths([
            { month: dayjs().format("MMMM"), year: dayjs().format("YYYY"), value: "" },
        ])
    }, [projectId, OADAction]);

    const handleAddMonth21 = (values, setValues) => {
        const lastMonth = months[months.length - 1];
        const nextMonth = dayjs(`${lastMonth.month} ${lastMonth.year}`)
            .add(1, "month")
            .format("MMMM YYYY")
            .split(" ");

        const newMonth = { month: nextMonth[0], year: nextMonth[1], value: "" };

        // Update the months state
        setMonths((prevMonths) => [...prevMonths, newMonth]);

        // Update Formik's values
        setValues({ costs: [...values.costs, newMonth] });
    };

    const handleAddMonth22 = (values, setValues) => {
        const lastMonth = values.costs[values.costs.length - 1];

        if (!lastMonth) return; // Prevent errors if there are no months

        const nextMonth = dayjs(`${lastMonth.month} ${lastMonth.year}`)
            .add(1, "month")
            .format("MMMM YYYY")
            .split(" "); // Get next month as [month, year]

        const newMonth = { month: nextMonth[0], year: nextMonth[1], value: "" };

        // Update Formik values correctly
        setValues(prevValues => {
            const updatedCosts = [...prevValues.costs, newMonth];
            return { ...prevValues, costs: updatedCosts };
        });

        // Update local months state (optional, for UI updates)
        setMonths(prev => [...prev, newMonth]);
    };

    const handleAddMonth = (values, setValues) => {
        setValues(prevValues => {
            // Create a deep copy of previous values to ensure we don't lose data
            const updatedCosts = prevValues.costs.map(item => ({ ...item }));

            const lastMonth = updatedCosts[updatedCosts.length - 1];

            if (!lastMonth) return prevValues; // Prevent errors if there are no months

            const nextMonth = dayjs(`${lastMonth.month} ${lastMonth.year}`)
                .add(1, "month")
                .format("MMMM YYYY")
                .split(" "); // Get next month as [month, year]

            const newMonth = { month: nextMonth[0], year: nextMonth[1], value: "" };

            return { ...prevValues, costs: [...updatedCosts, newMonth] };
        });

        // Ensure UI reflects updated months correctly
        // setMonths(prev => [...prev, { ...prev[prev.length - 1], value: "" }]);
    };

    const handleSubmit = async (values, { resetForm }) => {
        const storeData = values.costs
            ?.filter((item) => item.value > 0)
            ?.map((item, index) => {
                const initialVal = parseFloat(initialOADValues[index]?.totalValue || 0);
                const currentVal = parseFloat(item.value || 0);
                const difference = currentVal - initialVal; // Calculate difference

                return {
                    ...item,
                    type: difference < 0 ? "remove" : "add",
                    ts: new Date(`${item.year}-${getMonthNumber(item.month)}-01T00:00:00.000Z`),
                    projectId,
                    value: Math.abs(difference), // Add the difference field
                };
            })
            .filter(item => item.value > 0);

        // .map((item) => ({
        //     ...item,
        //     type: OADAction,
        //     ts: new Date(`${item.year}-${getMonthNumber(item.month)}-01T00:00:00.000Z`),
        //     projectId,
        // }));

        const modalElement = document.getElementById("oad-save-popup");
        const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
        if (storeData.length) {
            try {
                const response = await projectOADSaveAPI(storeData);
                resetForm();
                toast.success("OAD successfully updated!");
                setMonths([
                    { month: dayjs().format("MMMM"), year: dayjs().format("YYYY"), value: "" },
                ])

                // Close the modal after success
                if (modalInstance) modalInstance.hide();
                // Uncomment and implement if needed
                onTotalChangeOAD({ changeOAD: response?.data?.data, projectId });
            } catch (error) {
                console.error("Submission Error:", error);
            }
        } else {
            if (modalInstance) modalInstance.hide();
        }
    };

    return (
        <>
            <div
                className="modal fade"
                id="oad-save-popup"
                tabIndex={-1}
                aria-labelledby="oad-save-popupLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0 pb-0">
                            <h1 className="modal-title fs-4" id="oad-save-popupLabel">
                                {/* {OADAction === "add" ? "Add" : "Remove"}  */}
                                Add/Remove OAD Cost
                            </h1>
                        </div>
                        <div className="modal-body pt-0">
                            <Formik
                                key={JSON.stringify(months)}
                                // key={months.length}
                                enableReinitialize
                                initialValues={{ costs: months }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ values, setValues, resetForm }) => (
                                    <Form>
                                        <div className="dividerCost">
                                            <hr />
                                        </div>
                                        <div className="cruentAmSec">
                                            <p>Current OAD Cost</p>
                                            <h6>{costFormat(currentOAD)}</h6>
                                        </div>
                                        <div className="dividerCost">
                                            <hr />
                                        </div>

                                        {values.costs.map((item, index) => {
                                            const fieldName = `costs.${index}.value`;
                                            const difference = parseFloat(item.value || 0) - parseFloat(initialOADValues[index]?.totalValue || 0);

                                            return (
                                                <div className="costAmouSec" key={index}>
                                                    <div className="costAmHead">
                                                        <span className="costNumber">{index + 1}</span>
                                                        <h6>
                                                            {item.month}
                                                            <span>{item.year}</span>
                                                        </h6>

                                                    </div>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <div style={{ color: difference > 0 ? "green" : difference < 0 ? "red" : "black" }}>
                                                            {difference !== 0 ? `${difference > 0 ? "+" : ""}${costFormat(difference, '')}` : ""}
                                                        </div>
                                                        <div className="costInput">
                                                            <label htmlFor={fieldName}>₹</label>
                                                            <Field
                                                                type="text"
                                                                name={fieldName}
                                                                placeholder="Enter cost"
                                                                onInput={(e) => {
                                                                    e.target.value = e.target.value.replace(
                                                                        /[^0-9]/g,
                                                                        ""
                                                                    ); // Allow only digits
                                                                }}
                                                            />
                                                            <ErrorMessage
                                                                name={fieldName}
                                                                component="div"
                                                                className="text-danger font-10 mt-16"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        {/* Add More Section */}
                                        <div className="addCostSec">
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    handleAddMonth(values, setValues)
                                                }
                                                className="btn btn-link"
                                            >
                                                Add More
                                            </button>
                                        </div>
                                        <div className="dividerCost">
                                            <hr />
                                        </div>
                                        <div className="totalNumbercost">
                                            <h6>Total {OADAction === "add" ? "Added" : "Removed"} OAD</h6>
                                            <h5>
                                                {costFormat(
                                                    values.costs.reduce(
                                                        (sum, item) => sum + (Number(item.value) || 0),
                                                        0
                                                    )
                                                )}
                                            </h5>
                                        </div>
                                        {/* <button type="submit" className="costBtnSave" data-bs-dismiss="modal" aria-label="Close"> */}
                                        <button type="submit" className="costBtnSave">
                                            Save
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OADSavePopup;
