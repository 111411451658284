import React from "react";

const NoOfDownloads = ({ downloadsData }) => {
  return (
    <div
      className="noOfDownloadSec offcanvas offcanvas-end resizable_div"
      tabIndex={-1}
      id="no-downloads"
      aria-labelledby="no-downloadsLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="no-downloadsLabel">
          No. of Downloads
        </h5>
      </div>
      <div className="offcanvas-body">
        <div className="tbUniqSec">
          <div className="uniqId">
            <p>
              Unique ID : <span>Level_PT_ST/QT_001</span>
            </p>
            <p>
              Asset Type : <span>eLearning</span>
            </p>
            <div className="search">
              <span className="position-absolute line-height-1 me-12 searchBtn top-50 translate-middle-y">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                    strokeWidth={32}
                    d="M221.09 64a157.09 157.09 0 1 0 157.09 157.09A157.1 157.1 0 0 0 221.09 64Z"
                  ></path>
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={32}
                    d="M338.29 338.29L448 448"
                  ></path>
                </svg>
              </span>
              <input
                type="text"
                className="form-control radius-8"
                id="search"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="table-container table-responsive" id="table-container">
            <table className="table table-hover basic-border-table mb-0">
              <thead>
                <tr className="dnd-moved">
                  <th className="dragablefalse w30px" draggable="true">
                    <label className="form-check-label">S.No.</label>
                  </th>
                  <th draggable="true" className="text-center">
                    Date
                  </th>
                  <th draggable="true" className="text-center">
                    Employee ID
                  </th>
                  <th draggable="true">Download by</th>
                  <th draggable="true">Project Code</th>
                </tr>
              </thead>
              <tbody id="table-body">
                {downloadsData.map((row, index) => (
                  <tr key={index} className="dnd-moved">
                    <td draggable="false">
                      <label className="form-check-label">{index + 1}</label>
                    </td>
                    <td className="text-center text-nowrap">
                      <label className="form-check-label">{row.date}</label>
                    </td>
                    <td className="text-center">
                      <span className="btn-primary-900 bg-primary-focus rounded-pill text-bas text-sm fw-medium px-24 py-2">
                        {row.employeeId}
                      </span>
                    </td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <span
                          className="userimg"
                          style={{
                            backgroundColor: row.downloadBy.bgColor,
                            color: row.downloadBy.color,
                          }}
                        >
                          {row.downloadBy.initials}
                        </span>
                        <div className="flex-grow-1">
                          <h6 className="text-md text-primary-600 fw-medium mb-0">
                            {row.downloadBy.name}
                          </h6>
                        </div>
                      </div>
                    </td>
                    <td>{row.projectCode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="resizer1" />
    </div>
  );
};

export default NoOfDownloads;