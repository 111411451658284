import React, { useEffect, useState } from 'react';
import TopTabs from '../TopTabs';
import MyForm from './MyForm';
import { deleteResourceRequest, projectResourceLinksAPI } from 'src/api/ReportsAPI';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import ProjectPermissionHOC from 'src/hoc/ProjectPermissionHOC';

const getFavicon = (url) => {
  try {
    const domain = new URL(url).origin;
    return `https://www.google.com/s2/favicons?domain=${domain}`;
  } catch (error) {
    return "https://www.google.com/favicon.ico";
  }
};

const ProjectResourceLinks = () => {
  const { projectId } = useParams();
  const [linksData, setLinksData] = useState([]);
  const [editData, setEditData] = useState('')
  const [links, setLinks] = useState(linksData);
  const [newLink, setNewLink] = useState({ title: "", owner: "", email: "", url: "", type: "" });
  const [searchQuery, setSearchQuery] = useState("");


  // Callback function to receive data from child
  const handleDataFromChild = () => {
    getApiData();
  };
  console.log("linksData", linksData);

  const getApiData = async () => {
    try {
      const resp = await projectResourceLinksAPI({ projectId });
      setLinksData(resp?.data?.data)
      setLinks(resp?.data?.data)
    } catch (error) {
      console.error('error from profit and loss', error);
    }
  };

  useEffect(() => {
    getApiData();
  }, []);

  // const [copied, setCopied] = useState(false);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Copied to Link!');
    });
  }
  const handleEdit = (link) => {
    setEditData(link)
  }

  const handleDelete = async (itemId) => {
    toast(
      ({ closeToast }) => (
        <div>
          <p>Are you sure you want to delete this item?</p>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="btn btn-danger btn-sm"
              onClick={() => confirmDelete(itemId, closeToast)}
            >
              Yes
            </button>
            <button className="btn btn-secondary btn-sm" onClick={closeToast}>
              No
            </button>
          </div>
        </div>
      ),
      {
        autoClose: false, // Keep toast open until user action
        closeButton: false, // Remove default close button
        position: "top-center",
      }
    );
  };

  const confirmDelete = async (itemId, closeToast) => {
    closeToast(); // Close confirmation toast

    try {
      const resp = await deleteResourceRequest({ itemId });
      toast.success("Item deleted successfully!");
      getApiData();
    } catch (error) {
      toast.error("Failed to delete item.");
    }
  };

  // const handleChange = (e) => {
  //   setNewLink({ ...newLink, [e.target.name]: e.target.value });
  // };

  // const detectType = (url) => {
  //   if (url.includes("drive.google.com")) return "Drive";
  //   if (url.includes("docs.google.com")) return "Docs";
  //   if (url.includes("sheets.google.com")) return "Sheet";
  //   return "Other";
  // };

  // 🔍 Search Functionality
  const filteredLinks = links.filter(link =>
    link.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    link.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    link.url.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center pb-16 position-relative z-3 pt-24 bg-white">
            <div>
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">Project Resource Links</h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a href="#" className="d-flex align-items-center gap-1 hover-text-primary">
                    <iconify-icon icon="solar:home-smile-angle-outline" className="icon text-lg"></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Project Resource Links</li>
              </ul>
            </div>
          </div>
          <TopTabs />
          <div className="d-flex flex-wrap align-items-center gap-1 justify-content-between pt-24 pb-8 mt-10 border-top">
            <div className="search w-25">
              <input
                type="text"
                className="form-control radius-8 ps-32"
                placeholder="Search Resource"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // 🔍 Search Query Update
              />
              <span className="searchBtn posileft position-absolute top-50 translate-middle-y me-12 line-height-1">
                <i className='mdi mdi-magnify'></i>
              </span>
            </div>
            <ProjectPermissionHOC scope="view_report.projectResourceLinks.add">
              <button type="button" onClick={() => setEditData('')} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#projectRes">
                + Add Resource Links
              </button>
            </ProjectPermissionHOC>
          </div>

          {/* 🔍 Only Show Filtered Links */}
          {[...filteredLinks].sort((a, b) => a.title.localeCompare(b.title)).map((link, index) => (
            <div className="card border my-16 shadow-none cardKc01" key={index}>
              <div className="card-body">
                <div className='d-flex justify-content-between align-items-center cardKc02'>
                  <div className='cardKc03'>
                    <div className='d-flex gap-2 align-items-center justify-content-start cardKc03_01'>
                      <div className="titleText text-md">{link.title}</div>
                      <span className="badge text-sm fw-semibold bg-light-100 px-20 py-6 radius-16 text-secondary-light">{link.type}</span>
                    </div>
                    <a href={link.url} target="_blank" rel="noopener noreferrer" className='LinkUrlCard'>
                      <img src={getFavicon(link.url)} alt="Favicon" className="favicon" />
                      {link.url}
                    </a>
                    <p className='mb-0 ownerNameLink'>
                      Owner: <strong>{link.owner}</strong> ({link.email})
                    </p>
                  </div>
                  <div className='Link-cardBtns text-center'>
                    <div className='cardBtndv mb-16'>
                      <button onClick={() => handleCopy(link.url)} className='cardBtn copy'><img src='/assets/images/icons/copy-icon.svg' className='img-fluid' /></button>
                      <ProjectPermissionHOC scope="view_report.projectResourceLinks.edit">
                        <button onClick={() => handleEdit(link)} data-bs-toggle="modal" data-bs-target="#projectRes" className='cardBtn edit'><img src='/assets/images/icons/edit-icon.svg' className='img-fluid' /></button>
                      </ProjectPermissionHOC>
                      <ProjectPermissionHOC scope="view_report.projectResourceLinks.delete">
                        <button onClick={() => handleDelete(link._id)} className='cardBtn delete'><img src='/assets/images/icons/delete-icon.svg' className='img-fluid' /></button>
                      </ProjectPermissionHOC>
                    </div>
                    <a href={link.url} target="_blank" rel="noopener noreferrer" className='btn rounded-pill btn-outline-primary-600 radius-8 px-20 py-6 text-sm cardKcViewbtn'>View</a>
                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>

      {/* Modal */}
      <MyForm sendDataToParent={handleDataFromChild} editData={editData} />
    </div>
  );
};

export default ProjectResourceLinks;
