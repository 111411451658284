import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import FilterPopup from './FilterPopup';
import ManageColumn from './ManageColumn';
import { toast } from 'react-toastify';
import { userSearchAPI, userUpdateAPI } from 'src/api/UserApi';
import DatePickerComponent from 'src/components/DatePickerComponent';
import SearchInput from 'src/components/SearchInput';
import UserInfo from 'src/containers/user-container/UserInfo';
import EmployeeListRow from './EmployeeListRow';
import { debounce } from 'lodash';
import { employeeDataEditAction, employeeDataSearchAction } from './actions/EmployeeDataActions';
import { useSticky } from 'src/hooks/useSticky';
import { ExportReactCSV } from 'src/components/ExportReactCSV';
import { employeeGetLagPI, employeeGetPerfomanceAPI } from 'src/api/EmployeeDataApi';
import MonthPicker from 'src/components/MonthPicker';
import Select2Component from 'src/components/Select2Component';

const EmployeeDataContainer = () => {
  const { selectedMonth, selectedYear } = useSelector(state => state.ProductionReducer, shallowEqual);
  const dispatch = useDispatch();
  const {
    search,
  } = useSelector((state) => state.EmployeeDataReducer, shallowEqual);
  const [isFlatPikerReset, setIsFlatPikerReset] = useState(false);
  const [where, setWhere] = useState({ status: true, active: true });
  const [pageNo, setPageNo] = useState(0);
  const [reportFilter, setReportFilter] = useState({
    year: selectedYear,
    month: selectedMonth,
  });
  const [showExportButton, setShowExportButton] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState("true");
  const [lagReportDate, setLagReportDate] = useState([]);
  const [perfomanceReportDate, setPerfomanceReportDate] = useState([]);

  // const getUserEmployeeData = async () => {
  //   let body = {
  //     pageLength: 100,
  //     where
  //   };
  //   try {
  //     const resp = await userSearchAPI(body); 
  //     const filteredData = resp.data.data.filter(user => { 
  //       const matchesSearch = `${user.firstName} ${user.lastName}`.toLowerCase().includes(search.toLowerCase());
  //       const matchesDepartment = !selectedDepartment || user.departmentId?.departmentName === selectedDepartment;
  //       const matchesDesignation = !selectedDesignation || user.designationId?.designationName === selectedDesignation;
  //       return  matchesSearch && matchesDepartment && matchesDesignation;
  //     });
  //     setData(filteredData);
  //   } catch (error) {
  //     console.log('error from employee data', error);
  //   }
  // };
  const searchUser = () => {
    const payload = {
      where,
      currentPage: pageNo,
      autopopulate: true,
      fullTextSearch: true,
    }
    dispatch(employeeDataSearchAction(payload));
  }
  useEffect(() => {
    searchUser();
  }, [where])

  const handleDateRangeChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setIsFlatPikerReset(false)
      const [startDate, endDate] = selectedDates.map(date => {
        // Ensure that the date is treated as UTC and not auto-adjusted
        const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
        return utcDate.toISOString().split("T")[0]; // Get date part only (YYYY-MM-DD)
      });
      setWhere({ ...where, ts: { $gte: startDate, $lte: endDate } })
    }
  }
  const handleTextSearch = useCallback(
    debounce((searchKey, setWhere) => {
      const whereClauseKeysPaths = ['firstName', 'lastName', 'email', 'employeeID', 'phone'];
      const whereWithOrCondition = {
        $or: whereClauseKeysPaths.map((keyPath) => ({
          [keyPath]: {
            $regex: searchKey,
            $options: 'i',
          },
        })),
      };
      console.log("where22", where);
      setWhere((prevWhere) => ({ ...prevWhere, ...whereWithOrCondition }));
      // setWhere(whereWithOrCondition);
    }, 300),
    []
  );
  const handleSearchInputChange = (e) => {
    const searchKey = e.target.value;
    handleTextSearch(searchKey, setWhere);
  };
  const resetFilter = () => {
    document.getElementById('search').value = '';
    setIsFlatPikerReset(true)
    setWhere({ status: true, active: true });
    setSelectedStatus('true')
    // setFilterDefaultProps(defaultClientFilterProps);
  }
  const handleEditProfile = useCallback((
    recoard,
  ) => {
    dispatch(employeeDataEditAction(recoard));
  }, [
    dispatch,
  ]);
  const handleApplyFilters = () => {

  }
  const updateStatus = async (id) => {
    if (window.confirm('Are you sure you want to update status?')) {
      const data = { status: false, active: false };
      await userUpdateAPI({ userId: id, data });
      toast.success('Status Updated!');
      searchUser();
    }
  }
  // const { isSticky, elementRef } = useSticky(); // Use the custom hook

  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

  useEffect(() => {
    const handleScroll = () => {
      // Clear the previous timeout to prevent multiple updates
      clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          // Check if the table <thead> is within the viewport range
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
    };
  }, []);
  // Table Scroll Code End

  const tableRef = useRef(null);

  useEffect(() => {
    const tableElement = tableRef.current;

    const handleShowDropdown = () => {
      tableElement.style.overflowX = 'inherit';
    };

    const handleHideDropdown = () => {
      tableElement.style.overflowX = 'auto';
    };

    // Attach event listeners
    tableElement.addEventListener('show.bs.dropdown', handleShowDropdown);
    tableElement.addEventListener('hide.bs.dropdown', handleHideDropdown);

    // Cleanup event listeners on unmount
    return () => {
      tableElement.removeEventListener('show.bs.dropdown', handleShowDropdown);
      tableElement.removeEventListener('hide.bs.dropdown', handleHideDropdown);
    };
  }, []);
  const getExcelData = useCallback(async () => {
    try {
      const perfomanceReportDate = await employeeGetPerfomanceAPI({ where: reportFilter });
      if (perfomanceReportDate.status === 200) {

        // Count unique dates per EmpCode
        const uniqueDateCount = perfomanceReportDate.data.data.reduce((acc, item) => {
          if (!acc[item.EmpCode]) {
            acc[item.EmpCode] = new Set();
          }
          acc[item.EmpCode].add(item.Date);
          return acc;
        }, {});

        // Attach the count to each object
        const updatedPerfomanceReportDate = perfomanceReportDate.data.data.map((item) => ({
          ...item,
          EffectiveDays: uniqueDateCount[item.EmpCode].size,
        }));

        setPerfomanceReportDate(updatedPerfomanceReportDate);
      }
      const lagReportDate = await employeeGetLagPI({ where: reportFilter });
      if (lagReportDate.status === 200) {
        setLagReportDate(lagReportDate.data.data);
      }
      setShowExportButton(true);
    } catch (error) {
      console.error("Error fetching performance data:", error);
    }
  }, [reportFilter]);

  const ExportPerfomanceInExcel = useMemo(() => {
    if (perfomanceReportDate?.length === 0) {
      return [];
    }
    const results = [];
    // perfomanceReportDate?.forEach((employee) => {
    //   results.push({
    //     EMP_ID: employee.userId.employeeID,
    //     Name: `${employee.userId.firstName} ${employee.userId.lastName}`,
    //     accountNo: `\t${employee.bankDetails.accountno.toString()}`,
    //     ifsc: employee.bankDetails?.ifsc,                  
    //     totalDeduction: employee.lagHourDetails.totalDeduction?.toFixed(2),
    //     effWorkingDays: employee.daysDetails.effWorkingDays,
    //     monthlySalary: employee.monthlySalary,
    //     netSalary: employee.netSalary?.toFixed(2),
    //     leaveEncashment: employee.encashmentDetails.leaveEncashment,
    //     leaveEncashmentAmount: employee.encashmentDetails.leaveEncashmentAmount,
    //     incentives: employee.encashmentDetails.incentives,
    //     incentivesAmount: employee.encashmentDetails.incentivesAmount,
    //     TotalEncashment: employee.encashmentDetails.totalEncashment,
    //     remarks: employee.remarks,
    //   });
    // });
    return perfomanceReportDate;
  }, [
    perfomanceReportDate
  ]);
  const ExportLagInExcel = useMemo(() => {
    if (lagReportDate?.length === 0) {
      return [];
    }
    const results = [];
    // perfomanceReportDate?.forEach((employee) => {
    //   results.push({
    //     EMP_ID: employee.userId.employeeID,
    //     Name: `${employee.userId.firstName} ${employee.userId.lastName}`,
    //     accountNo: `\t${employee.bankDetails.accountno.toString()}`,
    //     ifsc: employee.bankDetails?.ifsc,                  
    //     totalDeduction: employee.lagHourDetails.totalDeduction?.toFixed(2),
    //     effWorkingDays: employee.daysDetails.effWorkingDays,
    //     monthlySalary: employee.monthlySalary,
    //     netSalary: employee.netSalary?.toFixed(2),
    //     leaveEncashment: employee.encashmentDetails.leaveEncashment,
    //     leaveEncashmentAmount: employee.encashmentDetails.leaveEncashmentAmount,
    //     incentives: employee.encashmentDetails.incentives,
    //     incentivesAmount: employee.encashmentDetails.incentivesAmount,
    //     TotalEncashment: employee.encashmentDetails.totalEncashment,
    //     remarks: employee.remarks,
    //   });
    // });
    return lagReportDate;
  }, [
    lagReportDate
  ]);
  const handleChangeDate = (date) => {
    const year = date.getFullYear();  // Example year
    const month = date.getMonth() + 1;  // Since month is 0-indexed in JavaScript, add 1
    setReportFilter({
      year,
      month,
    })
  }
  const options = [
    { value: '', label: 'Select an option' },
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' },
  ];

  const handleSelectChange = (value) => {
    setSelectedStatus(value);
    console.log('Selected Value:', value);
    if (value) {
      setWhere({ ...where, status: value, active: value });
    } else {
      const { status, active, ...rest } = where; // Remove status and active
      setWhere(rest);
    }
  };


  // New code Table Scroll set
    
  
  const scrollBarRef = useRef(null);
  const thumbRef = useRef(null);
  const [thumbWidth, setThumbWidth] = useState(50);
  const [thumbLeft, setThumbLeft] = useState(0); 
  
  useEffect(() => {
      updateThumbSize();
      window.addEventListener("resize", updateThumbSize);
      return () => window.removeEventListener("resize", updateThumbSize);
  }, []);
  
  const updateThumbSize = () => {
      const table = tableRef.current;
      const scrollbar = scrollBarRef.current;
      if (table && scrollbar) {
          const visibleRatio = table.clientWidth / table.scrollWidth;
          setThumbWidth(visibleRatio * scrollbar.clientWidth);
          setThumbLeft((table.scrollLeft / table.scrollWidth) * scrollbar.clientWidth);
      }
  };
  
  const handleTableScroll = () => {
      const table = tableRef.current;
      const scrollbar = scrollBarRef.current;
      if (table && scrollbar) {
          const scrollRatio = table.scrollLeft / (table.scrollWidth - table.clientWidth);
          setThumbLeft(scrollRatio * (scrollbar.clientWidth - thumbWidth));
      }
  };
  
  const handleThumbDrag = (event) => {
      event.preventDefault();
      const table = tableRef.current;
      const scrollbar = scrollBarRef.current;
  
      if (!table || !scrollbar) return;
  
      const startX = event.clientX;
      const startLeft = thumbLeft;
  
      const onMouseMove = (moveEvent) => {
          const deltaX = moveEvent.clientX - startX;
          const newLeft = Math.min(Math.max(startLeft + deltaX, 0), scrollbar.clientWidth - thumbWidth);
          const scrollRatio = newLeft / (scrollbar.clientWidth - thumbWidth);
          table.scrollLeft = scrollRatio * (table.scrollWidth - table.clientWidth);
          setThumbLeft(newLeft);
      };
  
      const onMouseUp = () => {
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
      };
  
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
  }; 
  // end

  return (
    <>
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="fw-semibold mb-16 d-flex align-items-center gap-4 font-24">
                Employee Data
              </h2>
              <ul className="d-flex align-items-center gap-2">
                <li className="fw-medium">
                  <a
                    href="#" onClick={(e) => e.preventDefault()}
                    className="d-flex align-items-center gap-1 hover-text-primary"
                  >
                    <iconify-icon
                      icon="solar:home-smile-angle-outline"
                      className="icon text-lg"
                    ></iconify-icon>
                    Special Access
                  </a>
                </li>
                <li>-</li>
                <li className="fw-medium">Employee Data</li>
              </ul>
              <div className="d-flex gap-2 align-items-center py-16 postionTop-0 bg-white">
                {/* Search start */}
                <SearchInput handleSearchInputChange={handleSearchInputChange} />
                {/* Search End */}
                {/* Date Range Picker start */}
                <DatePickerComponent
                  isFlatPikerReset={isFlatPikerReset}
                  onDateRangeChange={handleDateRangeChange}
                />
                <MonthPicker handleChangeDate={handleChangeDate} />
                {/* Date Range Picker end */}

                <div className="sct2drpdnNe">
                  <Select2Component
                    options={options}
                    placeholder="Select status"
                    value={selectedStatus}
                    onChange={handleSelectChange}
                  />
                </div>
                {/* Filter button start */}
                <div className="FilterBtn d-flex gap-2">
                  <button
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filterRightPopup"
                    aria-controls="filterRightPopup"
                    className="btn btn-outline-primary-600 grbtn heightAllBtnSet44 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="1.5em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"
                      />
                    </svg>{" "}
                    Filter
                  </button>
                  <button type="reset"
                    onClick={resetFilter}
                    className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2">Reset</button>
                  <button type="button"
                    onClick={getExcelData}
                    className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"
                  >Load Export Data</button>
                </div>
                {/* Filter button end */}
                {showExportButton && (
                  <>
                    <ExportReactCSV csvData={ExportLagInExcel}
                      Label={"Export Lag"}
                      fileName={"EmployeeLag-" + new Date().toLocaleDateString()} />
                    <ExportReactCSV csvData={ExportPerfomanceInExcel}
                      Label={"Export Perfomance"}
                      fileName={"EmployeePerfomance-" + new Date().toLocaleDateString()} />
                  </>
                )}

              </div>
              <div className='posSetTable'>
              <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`} ref={tableRef} onScroll={handleTableScroll} >
    <table className="table basic-border-table mb-0 table-hover" ref={elementRef}>  
                  <thead>
                    <tr className="dnd-moved">
                      <th className="w30px dragablefalse" draggable="false">
                        <label className="form-check-label">S.No.</label>
                      </th>
                      <th>User Id </th>
                      <th>Name</th>
                      <th>Department </th>
                      <th>Designation </th>
                      <th className="text-center">View </th>
                    </tr>
                  </thead>
                  <tbody id="table-body">
                    {search?.data
                      .sort((a, b) => (a.firstName + " " + a.lastName).localeCompare(b.firstName + " " + b.lastName))
                      .map((user, index) => <EmployeeListRow reportFilter={reportFilter} key={index} index={index} user={user} handleEditProfile={handleEditProfile} updateStatus={updateStatus} />)}
                  </tbody>
                </table>
              </div>
              <div className="custom-scroll" ref={scrollBarRef}>
                <div
                    className="custom-scroll-thumb"
                    ref={thumbRef}
                    style={{ width: `${thumbWidth}px`, left: `${thumbLeft}px` }}
                    onMouseDown={handleThumbDrag}
                ></div>
            </div>
            </div>
              {/* <div className="tableFooter d-flex align-items-center justify-content-end py-16">
                {/* Add pagination here if needed 
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Filter Popup */}
      <FilterPopup applyFilters={handleApplyFilters} />
      {/* Manage Column Popup */}
      <ManageColumn />
      <UserInfo />
    </>
  );
};

export default EmployeeDataContainer;
