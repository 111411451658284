import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import MonthPicker from "src/components/MonthPicker";
import StatCard from "src/components/StatCard";
import { handleChangeDateAction, productionSearchAction, resyncHubStaffDataAction } from 'src/containers/production-container/actions/ProductionActions';
import AttendanceCalendar from "src/containers/production-container/AttendanceCalendar"
import GroupCounter from "src/containers/production-container/GroupCounter";
import { CurrentYear } from 'src/utils/utils';



const Attendance = () => {
  const dispatch = useDispatch();
  // const {_id:userId,employeeID} = useSelector(state=>state.auth?.user,shallowEqual);
  const { employeeID, userId, monthParam, yearParam } = useParams();
  const { selectedMonth: month2, selectedYear: year2, HubstaffResponse } = useSelector(state => state.ProductionReducer, shallowEqual);

  const selectedMonth = Number(monthParam)
  const selectedYear = Number(yearParam)

  const [where, setWhere] = useState({
    userId,
    $expr: {
      $and: [
        { $eq: [{ $year: "$date" }, selectedYear] },
        { $eq: [{ $month: "$date" }, selectedMonth] }
      ]
    }
  });
  const [whereMySql, setWhereMySql] = useState({
    empid: employeeID,
    year: selectedYear,
    month: selectedMonth,
  });

  useEffect(() => {
    search2(where);
  }, [
    dispatch,
    where
  ]);

  const search2 = useCallback(() => {
    dispatch(productionSearchAction({
      where: where,
      whereMySql: whereMySql
    }));
  }, [dispatch, where, whereMySql])

  const handleChangeDate = (date) => {
    const year = date.getFullYear();  // Example year
    const month = date.getMonth() + 1;  // Since month is 0-indexed in JavaScript, add 1
    setWhere({
      ...where,
      $expr: {
        $and: [
          { $eq: [{ $year: "$date" }, year] },
          { $eq: [{ $month: "$date" }, month] }
        ]
      }
    })
    setWhereMySql({
      ...whereMySql,
      year,
      month
    })

    dispatch(handleChangeDateAction({ data: { selectedMonth: month, selectedYear: year } }))
  }

  useEffect(() => {
    // console.log("Running useEffect2...");

    setWhere({
      ...where,
      $expr: {
        $and: [
          { $eq: [{ $year: "$date" }, Number(yearParam)] },
          { $eq: [{ $month: "$date" }, Number(monthParam)] }
        ]
      }
    });

    setWhereMySql({
      ...whereMySql,
      year: Number(yearParam),
      month: Number(monthParam)
    });
    dispatch(handleChangeDateAction({ data: { selectedMonth: Number(monthParam), selectedYear: Number(yearParam) } }));
  }, [monthParam, yearParam]);

  // const { HubstaffResponse } = useSelector(state => state.ProductionReducer, shallowEqual);
  useEffect(() => {
    // Initialize Bootstrap tooltips for elements with data-bs-toggle="tooltip"
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    // Cleanup tooltips when the component unmounts
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, []);

  const handleReSyncHubStaff = () => {
    if (window.confirm("Are you sure you want to reSync HubStaff for this month?")) {
      dispatch(resyncHubStaffDataAction({ employeeID: employeeID, selectedMonth: whereMySql.month, selectedYear: whereMySql.year }));
    }
  }


  return (
    <>
      <div className="col-md-12">
        <div className="card h-100 p-0">
          <div className="card-body p-24">
            <div className="d-flex justify-content-between gap-2 align-items-center py-16">
              <MonthPicker handleChangeDate={handleChangeDate} lastMonthMidDate={new Date(yearParam, monthParam - 1, 1)} />
              <button type="button" className="btn btn-primary d-inline-block" onClick={handleReSyncHubStaff}>
                ReSync HubStaff for this month
              </button>

            </div>
            {/* <div className="row row-cols-xxxl-6 row-cols-xl-6 row-cols-lg-6 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-4">
                            {stats.map((stat, index) => (
                                <StatCard
                                    key={index}
                                    title={stat.title}
                                    value={stat.value}
                                    allowedValue={stat.allowedValue}
                                    customClass={stat.customClass}
                                />
                            ))}
                        </div> */}
            <GroupCounter />
            <AttendanceCalendar isRefresh={whereMySql} />

          </div>
        </div>
      </div>
    </>
  )
}
export default Attendance