import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import OrdinalNumberDisplay from 'src/components/OrdinalNumberDisplay';
import { projectCMDSaveAPI } from 'src/api/ProjectApi';
import { costFormat } from 'src/utils/CommonUtils';
import { toast } from 'react-toastify';

const CMDSavePopup = ({ CMDDetails, project, onTotalChangeCMD }) => {
    const { projectId, milestoneType, currentCMD } = CMDDetails;

    // Finding project item
    const findItem = project?.find(item => item._id === projectId);

    // Initialize milestone values
    const initialValues = {
        milestone1: 0.0,
        milestone2: 0.0,
        milestone3: 0.0,
    };

    // Populate milestone values from findItem if available
    findItem?.cmd?.forEach(item => {
        if (item.active) {
            const day = new Date(item.ts).getUTCDate();
            const value = item.type === "add" ? item.value : -item.value;

            if (day >= 1 && day <= 10) {
                initialValues.milestone1 += value;
            } else if (day >= 11 && day <= 20) {
                initialValues.milestone2 += value;
            } else {
                initialValues.milestone3 += value;
            }
        }
    });

    // State to store the initial CMD values for difference calculation
    const [initialCMDValues, setInitialCMDValues] = useState(initialValues);

    // Update initialCMDValues only when initialValues change
    useEffect(() => {
        if (JSON.stringify(initialValues) !== JSON.stringify(initialCMDValues)) {
            setInitialCMDValues(initialValues);
        }
    }, [initialValues]);

    const validationSchema = Yup.object({
        milestone1: Yup.number().min(0, "Value cannot be negative").required("Required"),
        milestone2: Yup.number().min(0, "Value cannot be negative").required("Required"),
        milestone3: Yup.number().min(0, "Value cannot be negative").required("Required"),
    });

    const handleSubmit2 = async (values, { resetForm }) => {
        const currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth() + 1;
        currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;

        const milestones = [
            { ts: new Date(`${currentYear}-${currentMonth}-01T00:00:00.000Z`), value: parseFloat(values.milestone1) },
            { ts: new Date(`${currentYear}-${currentMonth}-11T00:00:00.000Z`), value: parseFloat(values.milestone2) },
            { ts: new Date(`${currentYear}-${currentMonth}-21T00:00:00.000Z`), value: parseFloat(values.milestone3) },
        ].filter(milestone => milestone.value > 0)
            .map(milestone => ({ ...milestone, type: milestoneType, projectId }));


        if (milestones.length) {
            // try {
            //     const response = await projectCMDSaveAPI(milestones);
            //     resetForm();
            //     toast.success("CMD successfully updated!");
            //     onTotalChangeCMD({ changeCMD: response?.data?.data, projectId });
            // } catch (error) {
            //     console.error("Submission Error:", error);
            // }
        }
    };

    const handleSubmit = async (values, { resetForm }) => {
        const currentYear = new Date().getFullYear();
        let currentMonth = new Date().getMonth() + 1;
        currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;

        // Calculate differences dynamically
        const differences = {
            difference1: parseFloat(values.milestone1) - parseFloat(initialCMDValues.milestone1),
            difference2: parseFloat(values.milestone2) - parseFloat(initialCMDValues.milestone2),
            difference3: parseFloat(values.milestone3) - parseFloat(initialCMDValues.milestone3),
        };

        const milestones = [
            { ts: new Date(`${currentYear}-${currentMonth}-01T00:00:00.000Z`), value: Math.abs(differences.difference1), type: differences.difference1 < 0 ? "remove" : "add" },
            { ts: new Date(`${currentYear}-${currentMonth}-11T00:00:00.000Z`), value: Math.abs(differences.difference2), type: differences.difference2 < 0 ? "remove" : "add" },
            { ts: new Date(`${currentYear}-${currentMonth}-21T00:00:00.000Z`), value: Math.abs(differences.difference3), type: differences.difference3 < 0 ? "remove" : "add" },
        ]
            .filter(milestone => milestone.value > 0)
            .map(milestone => ({ ...milestone, projectId }));

        const modalElement = document.getElementById("addcmd-cost");
        const modalInstance = window.bootstrap.Modal.getInstance(modalElement);

        if (milestones.length) {
            try {
                const response = await projectCMDSaveAPI(milestones);
                resetForm();
                toast.success("CMD successfully updated!");
                onTotalChangeCMD({ changeCMD: response?.data?.data, projectId });

                // Close the modal after success
                if (modalInstance) modalInstance.hide();
            } catch (error) {
                console.error("Submission Error:", error);
            }
        } else {
            if (modalInstance) modalInstance.hide();
        }
    };

    return (
        <div className="modal fade" id="addcmd-cost" tabIndex={-1} aria-labelledby="addcmd-costLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content cmdCostMain">
                    <div className="modal-header border-0 pb-0">
                        <h1 className="modal-title fs-4" id="addcmd-costLabel">
                            {/* {milestoneType[0].toUpperCase() + milestoneType.slice(1)} */}
                            Add/Remove CMD Cost
                        </h1>
                    </div>
                    <div className="modal-body pt-0">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize={true}
                        >
                            {({ values }) => {
                                const totalChangeCMD = Object.values(values).reduce(
                                    (sum, val) => sum + (parseFloat(val) || 0), 0
                                );

                                return (
                                    <Form>
                                        <div className="dividerCost"><hr /></div>
                                        <div className="cruentAmSec">
                                            <p>Current CMD Cost</p>
                                            <h6>{costFormat(currentCMD)}</h6>
                                        </div>
                                        <div className="dividerCost"><hr /></div>

                                        {["1", "2", "3"].map((milestone, index) => {
                                            const fieldName = `milestone${milestone}`;
                                            const differenceField = `difference${milestone}`;
                                            const difference = (parseFloat(values[fieldName] || 0) - parseFloat(initialCMDValues[fieldName] || 0));

                                            return (
                                                <div className="costAmouSec" key={milestone}>
                                                    <div className="costAmHead">
                                                        <span className="costNumber">{milestone}</span>
                                                        <h6>
                                                            Milestone
                                                            <span>
                                                                <OrdinalNumberDisplay number={index * 10 + 1} /> - <OrdinalNumberDisplay number={index * 10 + 10} /> Days
                                                            </span>
                                                        </h6>
                                                    </div>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <div style={{ color: difference > 0 ? "green" : difference < 0 ? "red" : "black" }}>
                                                            {difference !== 0 ? `${difference > 0 ? "+" : ""}${costFormat(difference, '')}` : ""}
                                                        </div>
                                                        <div className="costInputbg">
                                                            <div className="costInput">
                                                                <label htmlFor={fieldName}>₹</label>
                                                                <Field
                                                                    type="text"
                                                                    name={fieldName}
                                                                    placeholder="Enter cost"
                                                                    onInput={(e) => {
                                                                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                                                    }}
                                                                />
                                                                <Field type="hidden" name={differenceField} value={difference} />

                                                            </div>
                                                            <ErrorMessage name={fieldName} component="div" className="error errorMsgn01" />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        <div className="dividerCost"><hr /></div>
                                        <div className="totalNumbercost">
                                            <h6>Total {milestoneType === 'add' ? 'Added' : 'Removed'} CMD</h6>
                                            <h5>{costFormat(totalChangeCMD)}</h5>
                                        </div>
                                        <button type="submit" className="costBtnSave">
                                            Save
                                        </button>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CMDSavePopup;
