import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DateFormat from 'src/utils/DateFormat';
import DropDownSelect from '../main-container/DropDownSelect';
import { useDispatch } from 'react-redux';
import { projectAssigneeLoadAction } from './actions/ClientActions';
import { useAssignPopup } from 'src/hooks/useAssignPopup';
import { generateColor, getContrastYIQ } from 'src/utils/utils';
import AssigneeCircle from './AssigneeCircle';
import RemarkContainer from './RemarkContainer';
import { ExportReactCSV } from 'src/components/ExportReactCSV';



const ClientList = (props) => {

  const {
    filteredData,
    setClientId,
    handleEditClick,
    handleEditSubCodeDescriptionClick,
    handleSubCodeDescriptionSaveClick,
    handleEditStatusClick,
    handleInputStatusChange,
    handleSaveClick,
    edit,
    subCodeDescription,
    edit3,
    handleCancelClick,
    isLoading,
    handleInputChange,
    handleSubCodeDescriptionChange,
    selectedUserId,
    handleAssignToChange,
    userList,
    handleInputChange1,
    handleSaveClick1,
    handleCancelClick1,
    handleEditClick1,
    profitAndLoss,
    pageNo,
    OAD,
    CMD,
    data,
    onRoleChange,
    columnsVisibility, handleViewAllChange, handleColumnChange, handleApply, labelSnumber // Added data as a prop
  } = props;
  let counter = 1
  const [accessRole, setAccessRole] = useState('admin');
  const { handleOpenAssignPopup } = useAssignPopup();


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSaveClick();
    }
  }
  const [sortConfig, setSortConfig] = useState({ key: 'profitAndLoss', direction: 'desc' });
  const sortedData = useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        let aValue, bValue;
        if (sortConfig.key === 'profitAndLoss') {
          // Find the profit or loss for each project
          const aProfitLoss = profitAndLoss.find(profit => profit.projectId === a._id);
          const bProfitLoss = profitAndLoss.find(profit => profit.projectId === b._id);

          let aValue = parseFloat(aProfitLoss?.profitOrLossPercentage) || 0;
          let bValue = parseFloat(bProfitLoss?.profitOrLossPercentage) || 0;

          if (!isFinite(aValue)) aValue = 0;
          if (!isFinite(bValue)) bValue = 0;
          // Return the sorting result based on direction
          if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0; // When values are equal
        }
        else if (sortConfig.key === 'NetCost') {
          // Find the profit or loss for each project
          const aProfitLoss = profitAndLoss.find(profit => profit.projectId === a._id);
          const bProfitLoss = profitAndLoss.find(profit => profit.projectId === b._id);

          let aValue = parseFloat(((aProfitLoss?.inHouseTotalCost || 0) + (aProfitLoss?.vendorTotalCost || 0))) || 0;
          let bValue = parseFloat(((bProfitLoss?.inHouseTotalCost || 0) + (bProfitLoss?.vendorTotalCost || 0))) || 0;

          if (!isFinite(aValue)) aValue = 0;
          if (!isFinite(bValue)) bValue = 0;
          // Return the sorting result based on direction
          if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0; // When values are equal
        }
        else if (sortConfig.key === 'NetDelivered') {
          // Find the profit or loss for each project
          const aProfitLoss = profitAndLoss.find(profit => profit.projectId === a._id);
          const bProfitLoss = profitAndLoss.find(profit => profit.projectId === b._id);

          let aValue = parseFloat(((aProfitLoss?.clientTotalCost || 0) + (aProfitLoss?.clientTotalCost || 0))) || 0;
          let bValue = parseFloat(((bProfitLoss?.clientTotalCost || 0) + (bProfitLoss?.clientTotalCost || 0))) || 0;

          if (!isFinite(aValue)) aValue = 0;
          if (!isFinite(bValue)) bValue = 0;
          // Return the sorting result based on direction
          if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
          return 0; // When values are equal
        }
        else if (sortConfig.key === 'projectCode') {
          // Extract last 3 digits from Project Code
          const aLastDigits = parseInt(a.clients.ClientProjectCode.slice(-3), 10);
          const bLastDigits = parseInt(b.clients.ClientProjectCode.slice(-3), 10);
          aValue = aLastDigits;
          bValue = bLastDigits;
        }
        else if (sortConfig.key === 'OAD') {
          const totalOADa = OAD
            .filter(OADObj => OADObj.projectId === a._id)
            .reduce((total, item) => item.type === "add" ? total + item.value : total - item.value, 0);
          const totalOADb = OAD
            .filter(OADObj => OADObj.projectId === b._id)
            .reduce((total, item) => item.type === "add" ? total + item.value : total - item.value, 0);
          aValue = totalOADa;
          bValue = totalOADb;
        }
        else if (sortConfig.key === 'CMD') {
          const totalOADa = CMD
            .filter(CMDObj => CMDObj.projectId === a._id)
            .reduce((total, item) => item.type === "add" ? total + item.value : total - item.value, 0);
          const totalCMDb = CMD
            .filter(CMDObj => CMDObj.projectId === b._id)
            .reduce((total, item) => item.type === "add" ? total + item.value : total - item.value, 0);
          aValue = totalOADa;
          bValue = totalCMDb;
        }


        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return sortableData;
  }, [filteredData, profitAndLoss, sortConfig]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

  useEffect(() => {
    const handleScroll = () => {
      // Clear the previous timeout to prevent multiple updates
      clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          // Check if the table <thead> is within the viewport range
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
    };
  }, []);
  // Table Scroll Code Start
  const { viewProject } = useAssignPopup();

  const [projectId, setProjectId] = useState('');
  const getRemarks = (projectId, resetForm) => {
    setProjectId(projectId)
  }

  // Function to export data to CSV
  // const exportToCSV = () => {
  //   if (!sortedData.length) {
  //     toast.error('No data to export!');
  //     return;
  //   }

  // Define CSV headers based on column visibility
  const headers = [];
  headers.push("S.No");
  headers.push("Created Date");
  headers.push("Project Code");
  headers.push("Sub Code");
  headers.push("Sub Code Description");
  headers.push("Status");
  headers.push("P & L");
  headers.push("OAD");
  headers.push("CMD");
  headers.push("Net Cost");
  headers.push("Net Delivered");
  headers.push("Expected");
  headers.push("Registered Name");
  headers.push("Assign to");
  headers.push("Comm. Name");

  // Generate CSV rows
  const rows = sortedData.map((project, index) => {
    const row = [];
    const profitLoss = profitAndLoss.find(
      (profit) => profit.projectId === project._id
    );
    const totalOAD = OAD
      .filter(OADObj => OADObj.projectId === project._id)
      .reduce((total, item) => item.type === "add" ? total + item.value : total - item.value, 0);

    // Calculate total CMD for the current project
    const totalCMD = CMD
      .filter(CMDObj => CMDObj.projectId === project._id)
      .reduce((total, item) => item.type === "add" ? total + item.value : total - item.value, 0);
    row.push(index + 1);
    row.push(new Date(project.ts).toLocaleDateString());
    row.push(project.clients?.ClientProjectCode || "");
    row.push(project?.ProjectSubCode || "");
    row.push(project?.ProjectSubCodeDescription || "");
    row.push(project?.ProjectStatus || "");
    row.push((profitLoss?.profitOrLossPercentage == -100.00 || !profitLoss?.profitOrLossPercentage) ? 'NA' : profitLoss?.profitOrLossPercentage);
    row.push(totalOAD || 0);
    row.push(totalCMD || 0);
    row.push(((profitLoss?.inHouseTotalCost || 0) + (profitLoss?.vendorTotalCost || 0))?.toFixed(2) || 0);
    row.push(profitLoss?.clientTotalCost?.toFixed(2) || 0);
    row.push(project?.ProjectExpected || 0);
    row.push(project?.clients?.ClientRegisteredName);
    row.push(project?.participants?.map(user => user.firstName + " " + user.lastName));
    row.push(project?.clients?.ClientCommercialName);


    return row.join(",");
  });

  // Combine headers and rows
  const csvData = [headers.join(","), ...rows].join("\n");  


   // New code Table Scroll set
    
  const tableRef = useRef(null);
  const scrollBarRef = useRef(null);
  const thumbRef = useRef(null);
  const [thumbWidth, setThumbWidth] = useState(50);
  const [thumbLeft, setThumbLeft] = useState(0); 
  
  useEffect(() => {
      updateThumbSize();
      window.addEventListener("resize", updateThumbSize);
      return () => window.removeEventListener("resize", updateThumbSize);
  }, []);
  
  const updateThumbSize = () => {
      const table = tableRef.current;
      const scrollbar = scrollBarRef.current;
      if (table && scrollbar) {
          const visibleRatio = table.clientWidth / table.scrollWidth;
          setThumbWidth(visibleRatio * scrollbar.clientWidth);
          setThumbLeft((table.scrollLeft / table.scrollWidth) * scrollbar.clientWidth);
      }
  };
  
  const handleTableScroll = () => {
      const table = tableRef.current;
      const scrollbar = scrollBarRef.current;
      if (table && scrollbar) {
          const scrollRatio = table.scrollLeft / (table.scrollWidth - table.clientWidth);
          setThumbLeft(scrollRatio * (scrollbar.clientWidth - thumbWidth));
      }
  };
  
  const handleThumbDrag = (event) => {
      event.preventDefault();
      const table = tableRef.current;
      const scrollbar = scrollBarRef.current;
  
      if (!table || !scrollbar) return;
  
      const startX = event.clientX;
      const startLeft = thumbLeft;
  
      const onMouseMove = (moveEvent) => {
          const deltaX = moveEvent.clientX - startX;
          const newLeft = Math.min(Math.max(startLeft + deltaX, 0), scrollbar.clientWidth - thumbWidth);
          const scrollRatio = newLeft / (scrollbar.clientWidth - thumbWidth);
          table.scrollLeft = scrollRatio * (table.scrollWidth - table.clientWidth);
          setThumbLeft(newLeft);
      };
  
      const onMouseUp = () => {
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
      };
  
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
  }; 
  // end

  return (
    <>
      <div className='text-end pb-10'>
        <ExportReactCSV csvData={csvData} fileName={"ClientList-" + new Date().toLocaleDateString()} />
      </div>

      <div className='posSetTable'>
              <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`} ref={tableRef} onScroll={handleTableScroll} >
    <table className="table basic-border-table mb-0 table-hover" ref={elementRef}> 

          <thead>
            <tr>
              {columnsVisibility.SNo && <th className="w30px dragablefalse" draggable="false">
                <label className="form-check-label">{labelSnumber}S.No.</label>
              </th>}
              {columnsVisibility.createdDate && <th>Created Date</th>}
              {columnsVisibility.projectCode && <th onClick={() => handleSort('projectCode')} style={{ cursor: 'pointer' }}>
                Project Code {sortConfig.key === 'projectCode' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
              </th>}
              {columnsVisibility.sub && <th>Sub</th>}
              {columnsVisibility.ProjectSubCodeDescription && <th>Project Description</th>}
              {columnsVisibility.changeStatus && <th>Change Status</th>}
              {columnsVisibility.profitLoss && <th onClick={() => handleSort('profitAndLoss')} style={{ cursor: 'pointer' }}>
                P & L {sortConfig.key === 'profitAndLoss' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
              </th>}
              {columnsVisibility.OAD && <th onClick={() => handleSort('OAD')} style={{ cursor: 'pointer' }}>
                OAD {sortConfig.key === 'OAD' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
              </th>}
              {columnsVisibility.CMD && <th onClick={() => handleSort('CMD')} style={{ cursor: 'pointer' }}>
                CMD {sortConfig.key === 'CMD' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
              </th>}
              {columnsVisibility.NetCost && <th onClick={() => handleSort('NetCost')} style={{ cursor: 'pointer' }}>
                Net Cost {sortConfig.key === 'NetCost' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
              </th>}
              {columnsVisibility.NetDelivered && <th onClick={() => handleSort('NetDelivered')} style={{ cursor: 'pointer' }}>
                Net Delivered {sortConfig.key === 'NetDelivered' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
              </th>}
              {/* {columnsVisibility.DS && <th className="text-center">DS</th>} */}
              {columnsVisibility.expected && <th>Expected</th>}
              {columnsVisibility.registeredName && <th>Registered Name</th>}
              {columnsVisibility.assignTo && <th>Assign to

                {/* return ( */}
                <select className='form-select' onChange={handleAssignToChange} value={selectedUserId}>
                  <option value=''>ALL</option>
                  {userList
                    ?.sort((a, b) => a.fullName.localeCompare(b.fullName))
                    ?.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.fullName}
                      </option>
                    ))}
                </select>
                {/* ); */}
              </th>}
              {columnsVisibility.assignSales && <th>BDM</th>}
              {columnsVisibility.commercialName && <th>Comm. Name</th>}
              {columnsVisibility.addSubProject && <th>Add Sub Project</th>}
              {columnsVisibility.remark && <th>Remark</th>}
              {columnsVisibility.action && <th>Action</th>}
            </tr>
          </thead>

          <tbody>
            {!isLoading &&
              sortedData.map((project, clientIndex) => {
                let projectStatusClass =
                  project.ProjectStatus === 'Active'
                    ? 'success-500'
                    : project.ProjectStatus === 'Inactive'
                      ? 'danger-500'
                      : 'neutral-300';
                const profitLoss = profitAndLoss.find(
                  (profit) => profit.projectId === project._id
                );
                const totalOAD = OAD
                  .filter(OADObj => OADObj.projectId === project._id)
                  .reduce((total, item) => item.type === "add" ? total + item.value : total - item.value, 0);

                // Calculate total CMD for the current project
                const totalCMD = CMD
                  .filter(CMDObj => CMDObj.projectId === project._id)
                  .reduce((total, item) => item.type === "add" ? total + item.value : total - item.value, 0);
                return (
                  <tr className="dnd-moved" key={project._id}>
                    {columnsVisibility.SNo && (
                      <td draggable="false">
                        <label className="form-check-label">{clientIndex + 1}</label>
                      </td>
                    )}
                    {columnsVisibility.createdDate && (
                      <td>
                        <DateFormat dateString={project.ts} />
                      </td>
                    )}
                    {columnsVisibility.projectCode && (
                      <td className="fw-medium text-primary-600">
                        {project.clients.ClientProjectCode}
                      </td>
                    )}
                    {columnsVisibility.sub && <td>
                      {project.ProjectSubCode}
                    </td>}
                    {columnsVisibility.ProjectSubCodeDescription && <td>
                      <div className="d-flex align-items-center gap-2">
                        {subCodeDescription.clientId === project.clients._id &&
                          subCodeDescription.projectId === project._id ? (
                          <>
                            <input
                              type="text"
                              value={subCodeDescription.ProjectSubCodeDescription}
                              className="form-control"
                              onChange={handleSubCodeDescriptionChange}
                              onKeyDown={handleKeyDown}
                            />
                            <button
                              className="btn mx-auto rounded-pill btn-success-600 radius-8 px-6 py-2 d-flex align-items-center gap-2"
                              onClick={handleSubCodeDescriptionSaveClick}
                            >
                              <i className="mdi mdi-check"></i>
                            </button>
                            <button
                              className="btn mx-auto rounded-pill btn-danger-600 radius-8 px-6 py-2 d-flex align-items-center gap-2"
                              onClick={handleCancelClick}
                            >
                              <i className="mdi mdi-close"></i>
                            </button>
                          </>
                        ) : (
                          <>
                            <div className='d-flex align-items-center'>
                              <div className='mxwset2hdr mxmhetsetscoll'>{`${project?.ProjectSubCodeDescription || ''}`}</div>
                              {accessRole === 'admin' && (
                                <button
                                  className="btn mx-auto rounded-pill btn-info-600 radius-8 px-6 py-6 d-flex align-items-center gap-2"
                                  onClick={() =>
                                    handleEditSubCodeDescriptionClick(
                                      project.clients._id,
                                      project._id,
                                      `${project?.ProjectSubCodeDescription || ''}`
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-pencil"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path>
                                  </svg>
                                </button>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </td>}
                    {columnsVisibility.changeStatus && (
                      // {`p-0 bg-${//projectStatusClass}`}
                      <td className="" key={project._id}>
                        <div className="btn-group">
                          <div
                            className={`bg-${projectStatusClass} d-flex align-items-center gap-2 rounded-0 px-16 text-white ${project.ProjectStatus === '' ? 'invisible' : ''
                              }`}
                          >
                            <img
                              src="/assets/images/left-sidebar/activetable.svg"
                              className="img-fluid"
                            />
                            {project.ProjectStatus}
                          </div>
                          <button
                            type="button"
                            className={`btn btn-${projectStatusClass} dropdown-toggle dropdown-toggle-split rounded-0 border-start`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            data-bs-reference="parent"
                            onClick={() =>
                              handleEditStatusClick(
                                project.clients._id,
                                project._id,
                                project.ProjectStatus
                              )
                            }
                          ></button>
                          <ul className="dropdown-menu">
                            <li className="mb-8">
                              <div className="form-check checked-primary d-flex align-items-center gap-2 radio-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio"
                                  id={`radio1-${project._id}`}
                                  checked={project.ProjectStatus === 'Active'}
                                  onChange={() => handleInputStatusChange('Active')}
                                />
                                <label
                                  className="form-check-label line-height-1 fw-medium text-secondary-light"
                                  htmlFor={`radio1-${project._id}`}
                                >
                                  Active
                                </label>
                              </div>
                            </li>
                            <li className="mt-8">
                              <div className="form-check checked-primary d-flex align-items-center gap-2 radio-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio"
                                  id={`radio2-${project._id}`}
                                  checked={project.ProjectStatus === 'Inactive'}
                                  onChange={() => handleInputStatusChange('Inactive')}
                                />
                                <label
                                  className="form-check-label line-height-1 fw-medium text-secondary-light"
                                  htmlFor={`radio2-${project._id}`}
                                >
                                  Inactive
                                </label>
                              </div>
                            </li>
                            <li className="mt-8">
                              <div className="form-check checked-primary d-flex align-items-center gap-2 radio-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="radio"
                                  id={`radio2-${project._id}`}
                                  checked={project.ProjectStatus === 'Hide'}
                                  onChange={() => handleInputStatusChange('Hide')}
                                />
                                <label
                                  className="form-check-label line-height-1 fw-medium text-secondary-light"
                                  htmlFor={`radio2-${project._id}`}
                                >
                                  Hide
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </td>
                    )}
                    {columnsVisibility.profitLoss && (
                      <td className="fw-medium text-success-600">
                        {(profitLoss?.profitOrLossPercentage == -100.00 || !profitLoss?.profitOrLossPercentage) ? '-∞' : profitLoss?.profitOrLossPercentage}%
                      </td>
                    )}
                    {columnsVisibility.OAD && (
                      <td className="fw-medium">
                        {totalOAD || '0'}
                      </td>
                    )}
                    {columnsVisibility.CMD && (
                      <td className="fw-medium">
                        {totalCMD || '0'}
                      </td>
                    )}
                    {columnsVisibility.NetCost && <td className="fw-medium">
                      {((profitLoss?.inHouseTotalCost || 0) + (profitLoss?.vendorTotalCost || 0))?.toFixed(2) || '0'}
                    </td>}
                    {columnsVisibility.NetDelivered && <td className="fw-medium">
                      {profitLoss?.clientTotalCost?.toFixed(2) || '0'}
                    </td>}
                    {/* {columnsVisibility.DS && (
            <td className="fw-medium">
               <span className="badge text-sm d-flex align-items-center justify-content-center gap-2 fw-semibold text-danger-600 bg-danger-100 px-10 py-4 rounded-pill text-white"><i className="mdi mdi-check-circle mdi-24px"></i> Not Achieved</span>
               <span className="badge text-sm d-flex align-items-center justify-content-center gap-2 fw-semibold text-success-600 bg-success-100 px-10 py-4 rounded-pill text-white"><i className="mdi mdi-check-circle mdi-24px"></i> Achieved</span>
            </td>
          )} */}
                    {columnsVisibility.expected && (
                      <td className="fw-medium text-danger-600">
                        <div className="d-flex align-items-center gap-2">
                          {edit.clientId === project.clients._id &&
                            edit.projectId === project._id ? (
                            <>
                              <input
                                type="number"
                                value={edit.ProjectExpected}
                                className="form-control txtRed"
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                              />
                              <button
                                className="btn mx-auto rounded-pill btn-success-600 radius-8 px-6 py-2 d-flex align-items-center gap-2"
                                onClick={handleSaveClick}
                              >
                                <i className="mdi mdi-check"></i>
                              </button>
                              <button
                                className="btn mx-auto rounded-pill btn-danger-600 radius-8 px-6 py-2 d-flex align-items-center gap-2"
                                onClick={handleCancelClick}
                              >
                                <i className="mdi mdi-close"></i>
                              </button>
                            </>
                          ) : (
                            <>
                              {`${project?.ProjectExpected || ''}`}%
                              {accessRole === 'admin' && (
                                <button
                                  className="btn mx-auto rounded-pill btn-info-600 radius-8 px-6 py-6 d-flex align-items-center gap-2"
                                  onClick={() =>
                                    handleEditClick(
                                      project.clients._id,
                                      project._id,
                                      `${project?.ProjectExpected || ''}`
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    className="bi bi-pencil"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path>
                                  </svg>
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                    )}
                    {columnsVisibility.registeredName && (
                      <td><div className="mxwset2hdr">{project.clients.ClientRegisteredName}</div></td>
                    )}
                    {columnsVisibility.assignTo && (
                      <td>
                        <div className="d-flex align-items-center flex-wrap">
                          {project.participants && (
                            <>
                              {project.participants
                                .slice(0, 2)
                                .map((user, userIndex) => (
                                  <AssigneeCircle key={userIndex} user={user} />
                                ))}
                              {project.participants.length > 2 && (
                                <span className="w-32-px h-32-px rounded-circle object-fit-cover position-relative ms--10px border bg-neutral-100 text-secondary-light text-xs d-inline-flex align-items-center justify-content-center">
                                  +{project.participants.length - 2}
                                </span>
                              )}
                            </>
                          )}
                          <button
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#assignTaskPopup"
                            aria-controls="assignTaskPopup"
                            onClick={() => {
                              handleOpenAssignPopup(project, project.clients, 'client');
                              onRoleChange('Manager')
                            }}
                            className="w-32-px h-32-px rounded-circle border border-primary-600 text-primary-600 ms-8 border-dashed text-secondary-light text-xs d-inline-flex align-items-center justify-content-center"
                          >
                            <iconify-icon icon="ic:baseline-plus" className="" />
                          </button>
                        </div>
                      </td>
                    )}
                    {columnsVisibility.assignSales && (
                      <td>
                        <div className="d-flex align-items-center flex-wrap">
                          {project?.sales && (
                            <AssigneeCircle user={project?.sales} />
                          )}
                          <button
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#assignTaskPopup"
                            aria-controls="assignTaskPopup"
                            onClick={() => {
                              handleOpenAssignPopup(project, project.clients, 'Sales');
                              onRoleChange('Sales')
                            }}
                            className="w-32-px h-32-px rounded-circle border border-primary-600 text-primary-600 ms-8 border-dashed text-secondary-light text-xs d-inline-flex align-items-center justify-content-center"
                          >
                            <iconify-icon icon="ic:baseline-plus" className="" />
                          </button>
                        </div>
                      </td>
                    )}
                    {columnsVisibility.commercialName && (
                      <td><div className="mxwset2hdr">{project.clients.ClientCommercialName}</div></td>
                    )}
                    {columnsVisibility.addSubProject && (
                      <td className="text-center">
                        <button
                          className="addbtns"
                          data-bs-toggle="modal"
                          data-bs-target="#taskModual"
                          onClick={() => setClientId(project.clients._id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={22}
                            height={22}
                            fill="currentColor"
                            className="bi bi-plus-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                          </svg>
                        </button>
                      </td>
                    )}
                    {columnsVisibility.remark && (
                      <td className="text-center">
                        <button
                          className="btn btn-primary-600 radius-6 px-14 py-6 text-sm"
                          title='Remark'
                          data-bs-toggle="offcanvas"
                          data-bs-target="#remarksec"
                          aria-controls="remarksec"
                          onClick={() => getRemarks(project._id)}
                        >
                          View
                        </button>
                      </td>
                    )}
                    {columnsVisibility.action && (
                      <td className="text-center">
                        {/* <button 
                type="button"
                className="card-edit-button text-primary-600"
                id={project._id}
                onClick={()=>{viewProject('edit',project._id,sortedData)}}>
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                fill="currentColor"
                className="bi bi-pencil"
                viewBox="0 0 16 16"
                >
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"></path>
                </svg>
                </button> */}
                        <button
                          type="button"
                          onClick={() => { viewProject('view', project._id, sortedData) }}
                          className="btn btn-primary-600 radius-6 px-14 py-6 text-sm"
                        >
                          View Report
                        </button>
                      </td>
                    )}

                  </tr>
                );
              })}
          </tbody>

        </table>
      </div>
      <div className="custom-scroll" ref={scrollBarRef}>
                <div
                    className="custom-scroll-thumb"
                    ref={thumbRef}
                    style={{ width: `${thumbWidth}px`, left: `${thumbLeft}px` }}
                    onMouseDown={handleThumbDrag}
                ></div>
            </div>
      </div>

      <RemarkContainer projectId={projectId} />
    </>
  )
}
export default ClientList