import React, { useEffect, useRef, useState } from 'react';
import { costFormat } from 'src/utils/CommonUtils';

const PaymentInvoiceTable = ({ groupedInvoices }) => {
  const [collapse, setCollapse] = useState({});

  const toggleCollapse = (id) => {
    setCollapse((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const data = [
    {
      id: 'demo1',
      projectCode: 'Project _415',
      customerName: 'Hasmukhbhai Parekh',
      delivered: '₹23591.01',
      invoiceRaised: '₹0',
      pending: '₹61740.00',
      paid: '16 Oct 2024',
      balance: '₹61740.00',
      subRows: [
        {
          id: 'demo10',
          projectCode: 'Project 407 - A - Dummy delivery',
          legalName: 'HCL',
          commercialName: 'Vineet kumar HCL',
          unitCost: '₹40.00',
          delivered: 8,
          invoiceDeliveries: 2,
          balQty: 0.5,
          totalCost: '₹61740.00',
          subItems: [
            { id: 'sub1', description: '1. Project 407 - A - Dummy Delivery - category', unitCost: '₹40.00', delivered: 1, balQty: 0 },
            { id: 'sub2', description: '2. Project 407 - A - Dummy Delivery - category', unitCost: '₹40.00', delivered: 1, balQty: 0 },
          ]
        },
      ]
    },
    {
      id: 'demo21',
      projectCode: 'Project _416',
      customerName: 'Aasmukhbhai Parekh',
      delivered: '₹24591.01',
      invoiceRaised: '₹100',
      pending: '₹62740.00',
      paid: '16 Oct 2024',
      balance: '₹61740.00',
      subRows: [
        {
          id: 'demo31',
          projectCode: 'Project 407 - A - Dummy delivery',
          legalName: 'HCL',
          commercialName: 'Vineet kumar HCL',
          unitCost: '₹40.00',
          delivered: 8,
          invoiceDeliveries: 2,
          balQty: 0.5,
          totalCost: '₹61740.00',
          subItems: [
            { id: 'sub1', description: '1. Project 407 - A - Dummy Delivery - category', unitCost: '₹40.00', delivered: 1, balQty: 0 },
            { id: 'sub2', description: '2. Project 407 - A - Dummy Delivery - category', unitCost: '₹40.00', delivered: 1, balQty: 0 },
          ]
        },
      ]
    },
  ];

  // Table Scroll Code Start
  const elementRef = useRef(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID

  useEffect(() => {
    const handleScroll = () => {
      // Clear the previous timeout to prevent multiple updates
      clearTimeout(debounceTimeoutRef.current);

      debounceTimeoutRef.current = setTimeout(() => {
        if (elementRef.current) {
          const { top, bottom } = elementRef.current.getBoundingClientRect();
          const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

          // Check if the table <thead> is within the viewport range
          const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
          setIsScrollable(shouldScroll);
        }
      }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
    };
  }, []);
  // Table Scroll Code End


   // Initialize tooltips when data or columns change
   useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));
    return () => {
      tooltipList.forEach(tooltip => tooltip.dispose());
    };
  }, [groupedInvoices]); // Reinitialize tooltips when groupedInvoices change



  // New code Table Scroll set
        
  const tableRef = useRef(null);
  const scrollBarRef = useRef(null);
  const thumbRef = useRef(null);
  const [thumbWidth, setThumbWidth] = useState(50);
  const [thumbLeft, setThumbLeft] = useState(0); 
  
  useEffect(() => {
      updateThumbSize();
      window.addEventListener("resize", updateThumbSize);
      return () => window.removeEventListener("resize", updateThumbSize);
  }, []);
  
  const updateThumbSize = () => {
      const table = tableRef.current;
      const scrollbar = scrollBarRef.current;
      if (table && scrollbar) {
          const visibleRatio = table.clientWidth / table.scrollWidth;
          setThumbWidth(visibleRatio * scrollbar.clientWidth);
          setThumbLeft((table.scrollLeft / table.scrollWidth) * scrollbar.clientWidth);
      }
  };
  
  const handleTableScroll = () => {
      const table = tableRef.current;
      const scrollbar = scrollBarRef.current;
      if (table && scrollbar) {
          const scrollRatio = table.scrollLeft / (table.scrollWidth - table.clientWidth);
          setThumbLeft(scrollRatio * (scrollbar.clientWidth - thumbWidth));
      }
  };
  
  const handleThumbDrag = (event) => {
      event.preventDefault();
      const table = tableRef.current;
      const scrollbar = scrollBarRef.current;
  
      if (!table || !scrollbar) return;
  
      const startX = event.clientX;
      const startLeft = thumbLeft;
  
      const onMouseMove = (moveEvent) => {
          const deltaX = moveEvent.clientX - startX;
          const newLeft = Math.min(Math.max(startLeft + deltaX, 0), scrollbar.clientWidth - thumbWidth);
          const scrollRatio = newLeft / (scrollbar.clientWidth - thumbWidth);
          table.scrollLeft = scrollRatio * (table.scrollWidth - table.clientWidth);
          setThumbLeft(newLeft);
      };
  
      const onMouseUp = () => {
          document.removeEventListener("mousemove", onMouseMove);
          document.removeEventListener("mouseup", onMouseUp);
      };
  
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onMouseUp);
  }; 
  // end
  return (
    <div className='posSetTable'>
    <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`} ref={tableRef} onScroll={handleTableScroll} >
<table className="table basic-border-table mb-0 table-hover Tbltopfixedsciky" ref={elementRef}>
        <thead className="z-3">
          <tr>
            {/* <th><input className="form-check-input" type="checkbox" /></th> */}
            <th>S.No.</th>
            <th>Project Code</th>
            <th>Customer Name</th>
            <th>Delivered</th>
            <th>Invoice Raised</th>
            <th>Pending</th>
            <th>Paid</th>
            <th>Cancelled</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(groupedInvoices).map((row, index) => (
            <React.Fragment key={row.cientId}>
              {/* Main row */}
              <tr>
                <td>{index + 1}</td>
                <td className="fw-bold fw-bold d-flex align-items-center gap-2">
                  <button
                    className={`text-dblue steactive accordion-toggle ${collapse[row.cientId] ? 'active' : ''}`}
                    onClick={() => toggleCollapse(row.cientId)}
                  >
                    <i className="mdi mdi-play-circle-outline mdi-36px"></i>
                  </button>
                  {row.ClientProjectCode}
                </td>
                <td><div  className="detaset"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-custom-class="tooltip-primary"
                                                        data-bs-title={`${row.ClientRegisteredName}`}>{row.ClientRegisteredName}</div></td>
                <td>{costFormat(row.totalDeliveryCost)}</td>
                <td>{costFormat(row.RaisedInvoice)}</td>
                <td>{costFormat(row.PendingInvoice)}</td>
                <td>{costFormat(row.PaidInvoice)}</td>
                <td>{costFormat(row.CancelledInvoice)}</td>
                <td>{costFormat(row.totalDeliveryCost - row.RaisedInvoice)}</td>
              </tr>
              {/* Sub-row */}
              {collapse[row.cientId] && ((//Object.values(row?.projects)?.map((row1) => (

                Object.values(row?.projects).map((subRow, p_index) => (
                  <React.Fragment key={p_index}>
                    <tr>
                      <td colSpan="9" className="hiddenRow">
                        <div className="accordian-body collapse show">
                          <table className="table basic-border-table mb-0 table-hover">
                            <thead>
                              <tr className="infobgblack">
                                <th><input type="checkbox" /></th>
                                <th>#</th>
                                <th>Project</th>
                                <th>Legal Name</th>
                                <th>Commercial Name</th>
                                <th>Unit Cost</th>
                                <th>Delivered</th>
                                <th>Invoice Deliveries</th>
                                <th>Bal. Qty.</th>
                                <th>Total Cost</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><input type="checkbox" /></td>
                                <td>{index + 1}.{p_index + 1}</td>
                                <td className="fw-bold fw-bold d-flex align-items-center gap-2">
                                  <button
                                    className={`text-dblue steactive accordion-toggle ${collapse[p_index] ? 'active' : ''}`}
                                    onClick={() => toggleCollapse(p_index)}
                                  >
                                    <i className="mdi mdi-play-circle-outline mdi-36px"></i>
                                  </button>
                                  {/* {row.ClientProjectCode} {row.invoices[0]?.ProjectSubCode} */}

                                  {row.ClientProjectCode} {Object.values(subRow)
                                    .flatMap(catRow => Object.values(catRow))
                                    .find(item => item)?.ProjectSubCode || ''}
                                </td>
                                <td><div className="tableFixed w-190-px">{row.ClientRegisteredName}</div></td>
                                <td><div className="tableFixed w-190-px">{row.ClientCommercialName}</div></td> 
                                <td></td>
                                <td>{costFormat(Object.values(subRow).flatMap(catRow => Object.values(catRow)).reduce((sum, item) => sum + (item.totalDurations || 0), 0), '')}</td>
                                <td>{Object.values(subRow)
                                  .flatMap(catRow => Object.values(catRow)) // Flatten all items
                                  .reduce((sum, item) => {
                                    // Sum the nested cat_payment_details values
                                    return sum + (item.cat_payment_details?.reduce(
                                      (nestedSum, detail) => nestedSum + parseFloat(detail.deliveredInvoice?.$numberDecimal || 0),
                                      0
                                    ) || 0);
                                  }, 0)}</td>
                                <td>{costFormat(Object.values(subRow)
                                  .flatMap(catRow => Object.values(catRow)) // Flatten all items
                                  .reduce((sum, item) => {
                                    const deliveredSum = item.cat_payment_details?.reduce(
                                      (nestedSum, detail) => nestedSum + parseFloat(detail.deliveredInvoice?.$numberDecimal || 0),
                                      0
                                    ) || 0;

                                    return sum + (item.totalDurations - deliveredSum);
                                  }, 0), '')}</td>
                                <td>{costFormat(Object.values(subRow).flatMap(catRow => Object.values(catRow)).reduce((sum, item) => sum + (item.totalCost || 0), 0))}</td>
                              </tr>
                              {collapse[p_index] && Object.values(subRow).map((catRow, iii) => (

                                Object.values(catRow).map((item, c_index) => (
                                  <tr key={`c_${c_index}`} className="hiddenRow accordian-body collapse show">
                                    <td></td>
                                    <td>{index + 1}.{p_index + 1}.{iii + 1}</td>
                                    <td className="text-dblue">{item.productionSlug}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{item.unitCost}</td>
                                    <td>{item.totalDurations}</td>
                                    <td>{item?.cat_payment_details?.reduce((sum, item) => sum + parseFloat(item.deliveredInvoice?.$numberDecimal || 0), 0)}</td>
                                    <td>{item.totalDurations - item?.cat_payment_details?.reduce((sum, item) => sum + parseFloat(item.deliveredInvoice?.$numberDecimal || 0), 0)}</td>
                                    <td>{costFormat(item.totalCost)}</td>
                                  </tr>
                                ))
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
    <div className="custom-scroll" ref={scrollBarRef}>
                <div
                    className="custom-scroll-thumb"
                    ref={thumbRef}
                    style={{ width: `${thumbWidth}px`, left: `${thumbLeft}px` }}
                    onMouseDown={handleThumbDrag}
                ></div>
            </div>
    </div>
  );
};

export default PaymentInvoiceTable;
