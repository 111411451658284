import React, { useState, useEffect } from 'react';
import { costFormat, fullMonthName } from 'src/utils/CommonUtils';

const SalaryDetailItem = ({ month, year, netSalary }) => (
  <div className="salary-card">
    <h6 className="fw-semibold text-primary-light mb-1 text-lg">{costFormat(netSalary.$numberDecimal)}</h6>
    <p>{fullMonthName(month)}</p>
    <p className="mb-0">{year}</p>
  </div>
);

// const SalaryDetailItem = ({ label, value, color }) => (
//   <div className="detail-item w-100 border-end text-center d-flex justify-content-between flex-column">
//     <span class={`badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle mx-auto text-white mb-4 ${color}`}>{value}</span>
//     <p className="mb-0">{label}</p>
//   </div>
// );


const SalaryComponent = ({ workingStats, salaryList = [], totalDays, leaveDetails }) => {
  return (
    <div>
      {/* <h2 className="card-title mb-16">Previous Salary</h2>
      <div className="salary-grid mb-24">
        {salaryList.length > 0 ? (
          salaryList.map((detail, index) => (
            <SalaryDetailItem key={index} {...detail} />
          ))
        ) : (
          <p>No details.</p>
        )}
      </div> */}

      <h2 className="card-title mb-16">Workforce Calendar Summary</h2>

      <div className="salary-detail-grid bg-primary-50 radius-10 p-16">
        <div className="detail-item w-100 border-end text-center d-flex justify-content-between flex-column">
          <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle mx-auto text-white mb-4 bg-primary-600">
            {/* {totalDays?.totalDays} */}
            {workingStats?.totalDays?.length}
          </span>
          <p className="mb-0 fs-14">Total Days</p>
        </div>
        <div className="detail-item w-100 border-end text-center d-flex justify-content-between flex-column">
          <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle mx-auto text-white mb-4 bg-lilac-600">
            {totalDays?.OffSaturday}
          </span>
          <p className="mb-0 fs-14">Off Saturday</p>
        </div>
        <div className="detail-item w-100 border-end text-center d-flex justify-content-between flex-column">
          <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle mx-auto text-white mb-4 bg-danger-600">
            {/* {leaveDetails?.totalLeaves || totalDays?.totalAbsent} */}
            {workingStats?.totalAbsentWithoutWeekendsHolidays}
          </span>
          <p className="mb-0 fs-14">Total Absent</p>
        </div>
        <div className="detail-item w-100 border-end text-center d-flex justify-content-between flex-column">
          <div className='d-flex justify-content-center align-items-center'>
          <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle mx-auto text-white mb-4 bg-success-600">
            {/* {totalDays?.effWorkingDays} */}
            {workingStats?.totalWorkingDays}
          </span>
          {workingStats?.workingWithWeekendsHolidays?.length > 0 &&
            <>
              {"+"}
              <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle mx-auto text-white mb-4 bg-success-600">
                {workingStats?.workingWithWeekendsHolidays?.length}
              </span>
            </>
          }
          </div>
          <p className="mb-0 fs-14">Eff. Working Days</p>
        </div>
        <div className="detail-item w-100 border-end text-center d-flex justify-content-between flex-column">
          <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle mx-auto text-white mb-4 bg-pink-600">
            {totalDays?.totalSunday}
          </span>
          <p className="mb-0 fs-14">Total Sunday</p>
        </div>
        <div className="detail-item w-100 border-end text-center d-flex justify-content-between flex-column">
          <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle mx-auto text-white mb-4 bg-violet-600">
            {totalDays?.totalHoliday}
          </span>
          <p className="mb-0 fs-14">Total Holiday</p>
        </div>
        <div className="detail-item w-100 border-end text-center d-flex justify-content-between flex-column">
          <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle mx-auto text-white mb-4 bg-neutral-500">
            {totalDays?.workingDays}
          </span>
          <p className="mb-0 fs-14">Working Days</p>
        </div>
      </div>
    </div>
  );
};

export default SalaryComponent;
