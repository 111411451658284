import React, { useState, useEffect, useRef } from "react"; 

const EffortEstimateManage2 = () => {
    const tableRef = useRef(null);
    const scrollBarRef = useRef(null);
    const thumbRef = useRef(null);
    const [thumbWidth, setThumbWidth] = useState(50);
    const [thumbLeft, setThumbLeft] = useState(0);
    const [collapsedModules, setCollapsedModules] = useState({});

    useEffect(() => {
        updateThumbSize();
        window.addEventListener("resize", updateThumbSize);
        return () => window.removeEventListener("resize", updateThumbSize);
    }, []);

    const updateThumbSize = () => {
        const table = tableRef.current;
        const scrollbar = scrollBarRef.current;
        if (table && scrollbar) {
            const visibleRatio = table.clientWidth / table.scrollWidth;
            setThumbWidth(visibleRatio * scrollbar.clientWidth);
            setThumbLeft((table.scrollLeft / table.scrollWidth) * scrollbar.clientWidth);
        }
    };

    const handleTableScroll = () => {
        const table = tableRef.current;
        const scrollbar = scrollBarRef.current;
        if (table && scrollbar) {
            const scrollRatio = table.scrollLeft / (table.scrollWidth - table.clientWidth);
            setThumbLeft(scrollRatio * (scrollbar.clientWidth - thumbWidth));
        }
    };

    const handleThumbDrag = (event) => {
        event.preventDefault();
        const table = tableRef.current;
        const scrollbar = scrollBarRef.current;

        if (!table || !scrollbar) return;

        const startX = event.clientX;
        const startLeft = thumbLeft;

        const onMouseMove = (moveEvent) => {
            const deltaX = moveEvent.clientX - startX;
            const newLeft = Math.min(Math.max(startLeft + deltaX, 0), scrollbar.clientWidth - thumbWidth);
            const scrollRatio = newLeft / (scrollbar.clientWidth - thumbWidth);
            table.scrollLeft = scrollRatio * (table.scrollWidth - table.clientWidth);
            setThumbLeft(newLeft);
        };

        const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const [modules, setModules] = useState([
        {
            id: 1, name: "Module 1", isEditing: false, tasks: [
                { id: 1, name: "Task 1", isEditing: false, cost: 0 },
                { id: 2, name: "Task 2", isEditing: false, cost: 0 },
                { id: 3, name: "Task 3", isEditing: false, cost: 0 },
                { id: 4, name: "Task 4", isEditing: false, cost: 0 }
            ]
        },
        {
            id: 2, name: "Module 2", isEditing: false, tasks: [
                { id: 1, name: "Task 1", isEditing: false, cost: 0 },
                { id: 2, name: "Task 2", isEditing: false, cost: 0 },
                { id: 3, name: "Task 3", isEditing: false, cost: 0 },
                { id: 4, name: "Task 4", isEditing: false, cost: 0 }
            ]
        },
        {
            id: 3, name: "Module 3", isEditing: false, tasks: [
                { id: 1, name: "Task 1", isEditing: false, cost: 0 },
                { id: 2, name: "Task 2", isEditing: false, cost: 0 },
                { id: 3, name: "Task 3", isEditing: false, cost: 0 },
                { id: 4, name: "Task 4", isEditing: false, cost: 0 }
            ]
        },
    ]);

    const [designations, setDesignations] = useState([
        { id: 1, name: "Designation 1", isEditing: false },
        { id: 2, name: "Designation 2", isEditing: false },
        { id: 3, name: "Designation 3", isEditing: false },
        { id: 4, name: "Designation 4", isEditing: false },
        { id: 5, name: "Designation 5", isEditing: false }
    ]);

    const handleAddModule = () => {
        setModules([
            ...modules,
            {
                id: Date.now(),
                name: `Module ${modules.length + 1}`,
                tasks: [
                    { id: Date.now() + 1, name: "Task 1", isEditing: false },
                    { id: Date.now() + 2, name: "Task 2", isEditing: false },
                    { id: Date.now() + 3, name: "Task 3", isEditing: false },
                    { id: Date.now() + 4, name: "Task 4", isEditing: false },
                ],
            },
        ]);

        setTimeout(() => {
            const tableContainer = tableRef.current;
            if (tableContainer) {
                tableContainer.scrollTo({ top: tableContainer.scrollHeight, behavior: "smooth" });
            }
        }, 0);
    };

    const handleAddTask = (moduleId) => {
        setModules(modules.map(module =>
            module.id === moduleId
                ? {
                    ...module,
                    tasks: [...module.tasks, { id: Date.now(), name: `Task ${module.tasks.length + 1}`, isEditing: false }]
                }
                : module
        ));
    };

    const handleEditTask = (moduleId, taskId, newName) => {
        setModules(modules.map(m => m.id === moduleId ? {
            ...m,
            tasks: m.tasks.map(t => t.id === taskId ? { ...t, name: newName } : t)
        } : m));
    };

    const toggleEditTask = (moduleId, taskId) => {
        setModules(modules.map(m => m.id === moduleId ? {
            ...m,
            tasks: m.tasks.map(t => t.id === taskId ? { ...t, isEditing: !t.isEditing } : t)
        } : m));
    };

    const handleRemoveTask = (moduleId, taskId) => {
        setModules(modules.map(m => m.id === moduleId ? { ...m, tasks: m.tasks.filter(t => t.id !== taskId) } : m));
    };

    const handleAddDesignation = () => {
        setDesignations([...designations, { id: Date.now(), name: `Designation ${designations.length + 1}`, isEditing: false }]);

        setTimeout(() => {
            const tableContainer = document.querySelector(".table-responsive");
            if (tableContainer) {
                tableContainer.scrollTo({ left: tableContainer.scrollWidth, behavior: "smooth" });
            }
            updateThumbSize();
        }, 100);
    };

    const handleEditDesignation = (id, newName) => {
        setDesignations(designations.map(d => d.id === id ? { ...d, name: newName } : d));
    };

    const handleRemoveDesignation = (id) => {
        setDesignations((prevDesignations) => prevDesignations.filter(d => d.id !== id));

        setTimeout(() => {
            updateThumbSize();
        }, 100);
    };

    const toggleEditDesignation = (id) => {
        setDesignations(designations.map(d => d.id === id ? { ...d, isEditing: !d.isEditing } : d));
    };

    const handleEditCost = (moduleId, taskId, newCost) => {
        setModules(modules.map(m =>
            m.id === moduleId
                ? {
                    ...m,
                    tasks: m.tasks.map(t =>
                        t.id === taskId ? { ...t, cost: Number(newCost) } : t
                    )
                }
                : m
        ));
    };

    const handleEditModule = (moduleId, newName) => {
        setModules(modules.map(m => m.id === moduleId ? { ...m, name: newName } : m));
    };

    const toggleEditModule = (moduleId) => {
        setModules(modules.map(m => m.id === moduleId ? { ...m, isEditing: !m.isEditing } : m));
    };

    const handleRemoveModule = (moduleId) => {
        setModules(modules.filter(m => m.id !== moduleId));
    };

   

     const toggleCollapseModule = (moduleId) => {
        setCollapsedModules(prevState => ({
            ...prevState,
            [moduleId]: !prevState[moduleId]
        }));
    };

    return (
        <div className="p-6 position-relative">
            <div className="d-flex flex-wrap justify-content-between align-items-center py-16">
                <h1 className="text-xl font-bold text-purple-600 mb-0">Effort Estimate Manage</h1> <button type="button" className="btn btn-neutral-100 text-primary-light px-16 py-8 radius-6 fw-medium text-sm d-flex align-items-center gap-2">Export  <img src="/assets/images/left-sidebar/xls-icon.svg" className="img-fluid" alt="" />
</button> 
</div>
            <div className="table-responsive ETable pb-0" ref={tableRef} onScroll={handleTableScroll} >
                <table className="table basic-table mb-0 pdingSet savedataEffort">
                    <thead className="shadow-none">
                        <tr className="bg-gray-100">
                            <th className="align-middle dname01">Module</th>
                            {designations.map((d) => (
                                <th key={d.id} className="text-center align-middle Hovnetup01">
                                    <div className="d-flex align-items-center gap-12 text-nowrap butnetup01 justify-content-center">
                                        {d.isEditing ? (
                                            <input
                                                type="text"
                                                value={d.name}
                                                onChange={(e) => handleEditDesignation(d.id, e.target.value)}
                                                className="w-full border p-1"
                                                onKeyDown={(e) => e.key === "Enter" && toggleEditDesignation(d.id)}
                                                onBlur={() => toggleEditDesignation(d.id)}
                                            />
                                        ) : (
                                            <span className="dname01">{d.name}</span>
                                        )}
                                    </div>
                                </th>
                            ))}
                            <th className="align-middle text-center dname01">Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {modules.map((module) => (
                            <React.Fragment key={module.id}>
                                <tr className="bg-gray-50 possetStky">
                                    <td colSpan={designations.length + 2} className="font-semibold flex items-center align-middle Hovnetup01 modalStky01" onClick={() => toggleCollapseModule(module.id)}>
                                        <div className="d-flex align-items-center gap-12 modalHeading01 butnetup01">
                                            {module.isEditing ? (
                                                <input
                                                    type="text"
                                                    value={module.name}
                                                    onChange={(e) => handleEditModule(module.id, e.target.value)}
                                                    className="form-control"
                                                    onKeyDown={(e) => e.key === "Enter" && toggleEditModule(module.id)}
                                                    onBlur={() => toggleEditModule(module.id)}
                                                />
                                            ) : (
                                                <div className="d-flex align-items-center gap-2 cursor-pointer mobtsetlin">
                                                    {collapsedModules[module.id] ? (
                                                        <i className="mdi mdi-chevron-down mdi-36px text-gray-600" />
                                                    ) : (
                                                        <i className="mdi mdi-chevron-up  mdi-36px text-dblue" />
                                                    )}
                                                    <span>{module.name}</span>
                                                    <span className="badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle bg-primary-600  text-white">4</span>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                {!collapsedModules[module.id] && module.tasks.map((task, index) => {
                                    const isFirstTask = index === 0;
                                    const isLastTask = index === module.tasks.length - 1;

                                    return (
                                        <tr key={task.id} className={`taskStpes01 ${isFirstTask ? "first-task" : ""} ${isLastTask ? "last-task" : ""}`}>
                                            <td className="align-middle Hovnetup01">
                                                <div className="d-flex align-items-center gap-12 butnetup01">
                                                    {task.isEditing ? (
                                                        <input
                                                            type="text" disabled
                                                            value={task.name}
                                                            onChange={(e) => handleEditTask(module.id, task.id, e.target.value)}
                                                            className="w-full border p-1"
                                                            onKeyDown={(e) => e.key === "Enter" && toggleEditTask(module.id, task.id)}
                                                            onBlur={() => toggleEditTask(module.id, task.id)}
                                                        />
                                                    ) : (
                                                        <span>{task.name}</span>
                                                    )}
                                                </div>
                                            </td>
                                            {designations.map((d) => (
                                                <td key={d.id} className="text-center align-middle">
                                                    <input type="number" className="form-control text-center w-set-input mx-auto" defaultValue={0} disabled />
                                                </td>
                                            ))}
                                            <td className="text-center align-middle">
                                                <input
                                                    type="number"
                                                    value={task.cost || 0}
                                                    onChange={(e) => handleEditCost(module.id, task.id, e.target.value)}
                                                    className="form-control text-center w-set-input mx-auto" disabled
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr className="bg-gray-100 border-0">
                            <th className="font-bold align-middle text-nowrap py-24 border-0">Total Effort in mins</th>
                            {designations.map((_, index) => (
                                <th key={index} className="font-bold text-center align-middle py-24 border-0">0</th>
                            ))}
                            <th className="font-bold text-center align-middle py-24 border-0">0</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="custom-scroll" ref={scrollBarRef}>
                <div
                    className="custom-scroll-thumb"
                    ref={thumbRef}
                    style={{ width: `${thumbWidth}px`, left: `${thumbLeft}px` }}
                    onMouseDown={handleThumbDrag}
                ></div>
            </div>
        </div>
    );
};

export default EffortEstimateManage2;