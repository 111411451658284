// import React from 'react';
// import PropTypes from 'prop-types';
// import { Field, ErrorMessage } from 'formik';

// const component = ({
//     field, form, ...rest
// }) => (
//     <>
//         {/* <Field
//             as="textarea"
//             {...field}
//             {...rest}
//             value={field.value === undefined || field.value === null ? '' : field.value}
//         /> */}
//         <Field
//             as="textarea"
//             {...field} // ✅ Handles value automatically
//             {...rest}
//         />
//         <ErrorMessage
//             component="div"
//             className="text-danger"
//             name={field.name}
//         />
//     </>
// );

// component.propTypes = {
//     field: PropTypes.any.isRequired,
//     form: PropTypes.any.isRequired,
// };

// const FormikTextarea = (props) => (
//     <Field
//         {...props}
//         component={component}
//     />
// );

// export default FormikTextarea;
/// change because formreset not working

import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";

const FormikTextarea = ({ name, ...rest }) => {
    const { values, setFieldValue } = useFormikContext(); // Access Formik context

    return (
        <>
            <Field
                as="textarea"
                name={name}
                {...rest}
                value={values[name] || ""} // Ensure value is controlled
                onChange={(e) => setFieldValue(name, e.target.value)} // Update value in Formik
            />
            <ErrorMessage component="div" className="text-danger" name={name} />
        </>
    );
};

FormikTextarea.propTypes = {
    name: PropTypes.string.isRequired,
};

export default FormikTextarea;
