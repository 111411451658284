// LeaveDetails.js
import React, { useEffect } from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { costFormat } from 'src/utils/CommonUtils';
import { useState } from 'react';
import { getMonthName } from 'src/utils/utils';

const LeaveDetails = ({ values, employeeDetails, leaveDetails, leaves, handleChange }) => {
    const { setFieldValue } = useFormikContext();
    const [totalSummary, setTotalSummary] = useState(leaves?.totalSummary || { TotalAdjustedCL: 0, TotalAdjustedSL: 0, TotalAppliedCL: 0, TotalAppliedSL: 0, TotalSL: 0, TotalCL: 0 });
    useEffect(() => {
        setTotalSummary(leaves?.totalSummary || { TotalAdjustedCL: 0, TotalAdjustedSL: 0, TotalAppliedCL: 0, TotalAppliedSL: 0, TotalSL: 0, TotalCL: 0 });
    }, [leaves])
    const selectedMonth = getMonthName(values.salaryMonth);
    const getMonthAdjustedLeave = leaves?.result.find(leave => leave.month === selectedMonth);
    const adjustableSikLeaves = (totalSummary.TotalSL - totalSummary.TotalAdjustedSL <= 0) ? getMonthAdjustedLeave?.adjustedSL : totalSummary.TotalSL - totalSummary.TotalAdjustedSL;
    const adjustableCasualLeaves = (totalSummary.TotalCL - totalSummary.TotalAdjustedCL <= 0) ? getMonthAdjustedLeave?.adjustedCL : totalSummary.TotalCL - totalSummary.TotalAdjustedCL;

    const getSumBeforeMonth = (monthName) => {
        const targetIndex = leaves?.result?.findIndex(item => item.month === monthName);

        if (targetIndex === -1) return { sumAdjustedCL: 0, sumAdjustedSL: 0 };

        const filteredData = leaves?.result.slice(0, targetIndex); // Get months before the target month

        const sumAdjustedCL = filteredData?.reduce((sum, item) => sum + item.adjustedCL, 0);
        const sumAdjustedSL = filteredData?.reduce((sum, item) => sum + item.adjustedSL, 0);

        return { sumAdjustedCL, sumAdjustedSL };
    };
    const getClSl = (monthName) => {
        const targetIndex = leaves?.result?.findIndex(item => item.month === monthName);

        if (targetIndex === -1) return { sumCL: 0, sumSL: 0 };

        const filteredData = leaves?.result.slice(0, targetIndex + 1); // Include selected month

        const sumCL = filteredData?.reduce((sum, item) => sum + item.CL, 0);
        const sumSL = filteredData?.reduce((sum, item) => sum + item.SL, 0);

        return { sumCL, sumSL };
    };
    const { sumCL, sumSL } = getClSl(selectedMonth)
    const { sumAdjustedCL, sumAdjustedSL } = getSumBeforeMonth(selectedMonth)

    // console.log("sumAdjustedCL, sumAdjustedSL", values.leaveDetails?.totalLeaves, sumAdjustedCL, sumAdjustedSL, leaves?.result, sumCL, sumSL);

    // console.log("selectedMonth, getMonthAdjustedLeave, adjustableSikLeaves, adjustableCasualLeaves==", selectedMonth, getMonthAdjustedLeave, adjustableSikLeaves, adjustableCasualLeaves);

    const formGroups = [
        {
            id: "totalLeaves",
            label: "Total Leaves",
            name: "leaveDetails.totalLeaves",
            type: "number",
            placeholder: "0",
            value: leaveDetails?.totalAbsent
        },
        {
            id: "adjustedSL",
            label: "Adjusted Sick Leaves",
            name: "leaveDetails.adjustedSL",
            type: "select",
            // options: Array.from({ length: values?.leaveBalance?.remainingSL + 1 }, (_, i) => i),
            // options: Array.from({ length: (adjustableSikLeaves) + 1 }, (_, i) => i),
            options: Array.from({ length: sumSL - (sumAdjustedSL) + 1 }, (_, i) => i),
        },
        {
            id: "adjustedCL",
            label: "Adjusted Casual Leaves",
            name: "leaveDetails.adjustedCL",
            type: "select",
            // options: Array.from({ length: values?.leaveBalance?.remainingCL + 1 }, (_, i) => i),
            // options: Array.from({ length: (adjustableCasualLeaves) + 1 }, (_, i) => i),
            options: Array.from({ length: sumCL - (sumAdjustedCL) + 1 }, (_, i) => i),
        },
    ];
    useEffect(() => {
        const day_salary = leaveDetails?.daySalary || 0;
        const totalCL = leaveDetails?.adjustedSL || 0;
        const totalSL = leaveDetails?.adjustedCL || 0;
        const totalCLAmt = day_salary * totalCL;
        const totalSLAmt = day_salary * totalSL;
        const totalAdjustedLeaves = totalCL + totalSL;
        if (leaveDetails) {
            leaveDetails.adjustedSickLeavesAmount = totalCLAmt || 0;
            leaveDetails.adjustedCasualLeavesAmount = totalSLAmt || 0;
            const effectiveLeaves = (leaveDetails?.totalLeaves || 0) - totalCL - totalSL;
            const totalLeaveDeductionAmount = (effectiveLeaves * day_salary)?.toFixed() || 0;
            leaveDetails.otherLeaveDeduction = effectiveLeaves || 0
            leaveDetails.totalLeaveDeductionAmount = totalLeaveDeductionAmount || 0
            leaveDetails.otherLeaveDeductionAmount = totalLeaveDeductionAmount || 0
            //    Math.max(0, Math.ceil(leaveDetails.otherLeaveDeductionAmount - totalAdjustedLeaves));
        }
        //    const totalAbsent = totalLeaves - totalAdjustedLeaves;
        //    const otherLeaveDeduction = employeeDetails?.totalLeaveDeduction || 0;
    }, [leaveDetails])

    return (
        <div className="col-xl-6 col-md-12 mb-24 gy-3">
            <div className="possettop">
                <h4 className="card-title">Leave Details</h4>
                <div className="radius-10 border p-16">
                    {formGroups.map(({ id, label, name, type, placeholder, options }) => (
                        <div className="align-items-center d-flex justify-content-between py-8" key={name}>
                            <label className="form-label mb-0">{label}</label>
                            <div className="inpset001">
                                {type === "number" ? (
                                    <Field
                                        id={id}
                                        name={name}
                                        type="number"
                                        className="form-control w-160-px ms-auto"
                                        placeholder={placeholder}
                                    // onChange={handleChange}
                                    />
                                ) : (
                                    <Field as="select" name={name} className="form-select w-160-px"
                                        onChange={(e) => {
                                            const selectedValue = Number(e.target.value);
                                            setFieldValue(name, selectedValue);
                                        }}
                                    // onChange={handleChange}
                                    >
                                        {options.map((option, i) => (
                                            <>
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                                {/* (values.leaveDetails?.totalLeaves % 1 === 0.5) && */}
                                                {i !== options.length - 1 && <option key={option + 0.5} value={option + 0.5}>
                                                    {option + 0.5}
                                                </option>}
                                            </>
                                        ))}
                                    </Field>
                                )}
                                <ErrorMessage name={name} component="div" className="text-danger small mt-1" />
                            </div>
                        </div>
                    ))}

                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-top mt-16">
                        <label className="form-label mb-0 text-neutral-500">Effective Leaves</label>
                        <span className="text-md fw-semibold text-neutral-800">{leaveDetails?.otherLeaveDeduction || 0}</span>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-top">
                        <label className="form-label mb-0 text-neutral-500">Adjusted Sick Leaves Amount</label>
                        <span className={`text-md fw-semibold text-neutral-800 ${leaveDetails?.adjustedSickLeavesAmount < 0 ? 'text-red' : ''}`}>
                            {costFormat(leaveDetails?.adjustedSickLeavesAmount)}
                        </span>
                    </div>
                    <div className="align-items-center d-flex justify-content-between py-8 py-16 border-top">
                        <label className="form-label mb-0 text-neutral-500">Adjusted Casual Leaves Amount</label>
                        <span className={`text-md fw-semibold text-neutral-800 ${leaveDetails?.adjustedCasualLeavesAmount < 0 ? 'text-red' : ''}`}>
                            {costFormat(leaveDetails?.adjustedCasualLeavesAmount)}
                        </span>
                    </div>
                    <div className={`align-items-center d-flex justify-content-between py-8 py-16 px-16 bg-primary-50 radius-10 ${employeeDetails.totalLeaveDeduction < 0 ? 'text-red bg-danger-100' : ''}`}>
                        <label className="form-label mb-0 text-dblue">Total Deduction</label>
                        <span className={`text-xl fw-bold ${leaveDetails?.totalLeaveDeductionAmount < 0 ? 'text-red' : ''}`}>
                            {costFormat(leaveDetails?.totalLeaveDeductionAmount)}
                        </span>
                    </div>
                    {/* <div className="bunSeeLeave mt-16">
                        <button type='button' className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2" data-bs-toggle="modal" data-bs-target="#LeaveDetailsModal">See Leave Details <i className="mdi mdi-eye mdi-18px"></i></button>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
export default LeaveDetails;
