import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./content-hub.css";

const ContentHub = () => {
  const [modalContent, setModalContent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(0); // Track selected slide index
  const [showRemarkModal, setShowRemarkModal] = useState(false); // State for showing the Remarkmodal
  const mainSliderRef = useRef(null); // Ref for the main slider
  const thumbnailSliderRef = useRef(null); // Ref for the thumbnail slider

  const contentData = [
    {
      id: 1,
      image: "./assets/images/content-hub/Level1.png",
      title: "Level_PT_ST/QT_001",
      tags: ["eLearning", "Level 1", "Welcome Screen", "Click and Reveal", "Fill Ups"],
      slides: [
        "./assets/images/content-hub/popu-img.png",
        "./assets/images/content-hub/thumb-slide2.png",
        "./assets/images/content-hub/thumb-slide3.png",
        "./assets/images/content-hub/thumb-slide1.png",
        "./assets/images/content-hub/thumb-slide1.png",
      ],
    },
    {
      id: 2,
      image: "./assets/images/content-hub/Level2.png",
      title: "Level_PT_ST/QT_001",
      tags: ["eLearning", "Level 1", "Welcome Screen", "Click and Reveal", "Fill Ups"],
      slides: [
        "./assets/images/content-hub/popu-img.png",
        "./assets/images/content-hub/thumb-slide2.png",
        "./assets/images/content-hub/thumb-slide3.png",
        "./assets/images/content-hub/thumb-slide1.png",
        "./assets/images/content-hub/thumb-slide1.png",
      ],
    },
    {
      id: 3,
      image: "./assets/images/content-hub/Level3.png",
      title: "Level_PT_ST/QT_001",
      tags: ["eLearning", "Level 1", "Welcome Screen", "Click and Reveal", "Fill Ups"],
      slides: [
        "./assets/images/content-hub/popu-img.png",
        "./assets/images/content-hub/thumb-slide2.png",
        "./assets/images/content-hub/thumb-slide3.png",
        "./assets/images/content-hub/thumb-slide1.png",
        "./assets/images/content-hub/thumb-slide1.png",
      ],
    },
    {
      id: 4,
      image: "./assets/images/content-hub/Level4.png",
      title: "Level_PT_ST/QT_001",
      tags: ["eLearning", "Level 1", "Welcome Screen", "Click and Reveal", "Fill Ups"],
      slides: [
        "./assets/images/content-hub/popu-img.png",
        "./assets/images/content-hub/thumb-slide2.png",
        "./assets/images/content-hub/thumb-slide3.png",
        "./assets/images/content-hub/thumb-slide1.png",
        "./assets/images/content-hub/thumb-slide1.png",
      ],
    },
    {
      id: 5,
      image: "./assets/images/content-hub/Level5.png",
      title: "Level_PT_ST/QT_001",
      tags: ["eLearning", "Level 1", "Welcome Screen", "Click and Reveal", "Fill Ups"],
      slides: [
        "./assets/images/content-hub/popu-img.png",
        "./assets/images/content-hub/thumb-slide2.png",
        "./assets/images/content-hub/thumb-slide3.png",
        "./assets/images/content-hub/thumb-slide1.png",
        "./assets/images/content-hub/thumb-slide1.png",
      ],
    },
    {
      id: 6,
      image: "./assets/images/content-hub/Level6.png",
      title: "Level_PT_ST/QT_001",
      tags: ["eLearning", "Level 1", "Welcome Screen", "Click and Reveal", "Fill Ups"],
      slides: [
        "./assets/images/content-hub/popu-img.png",
        "./assets/images/content-hub/thumb-slide2.png",
        "./assets/images/content-hub/thumb-slide3.png",
        "./assets/images/content-hub/thumb-slide1.png",
        "./assets/images/content-hub/thumb-slide1.png",
      ],
    },
    {
      id: 7,
      image: "./assets/images/content-hub/Level7.png",
      title: "Level_PT_ST/QT_001",
      tags: ["eLearning", "Level 1", "Welcome Screen", "Click and Reveal", "Fill Ups"],
      slides: [
        "./assets/images/content-hub/popu-img.png",
        "./assets/images/content-hub/thumb-slide2.png",
        "./assets/images/content-hub/thumb-slide3.png",
        "./assets/images/content-hub/thumb-slide1.png",
        "./assets/images/content-hub/thumb-slide1.png",
      ],
    },
    {
      id: 8,
      image: "./assets/images/content-hub/Level8.png",
      title: "Level_PT_ST/QT_001",
      tags: ["eLearning", "Level 1", "Welcome Screen", "Click and Reveal", "Fill Ups"],
      slides: [
        "./assets/images/content-hub/popu-img.png",
        "./assets/images/content-hub/thumb-slide2.png",
        "./assets/images/content-hub/thumb-slide3.png",
        "./assets/images/content-hub/thumb-slide1.png",
        "./assets/images/content-hub/thumb-slide1.png",
      ],
    },
    {
      id: 9,
      image: "./assets/images/content-hub/Level9.png",
      title: "Level_PT_ST/QT_001",
      tags: ["eLearning", "Level 1", "Welcome Screen", "Click and Reveal", "Fill Ups"],
      slides: [
        "./assets/images/content-hub/popu-img.png",
        "./assets/images/content-hub/thumb-slide2.png",
        "./assets/images/content-hub/thumb-slide3.png",
        "./assets/images/content-hub/thumb-slide1.png",
        "./assets/images/content-hub/thumb-slide1.png",
      ],
    },
    {
      id: 10,
      image: "./assets/images/content-hub/Level10.png",
      title: "Level_PT_ST/QT_001",
      tags: ["eLearning", "Level 1", "Welcome Screen", "Click and Reveal", "Fill Ups"],
      slides: [
        "./assets/images/content-hub/popu-img.png",
        "./assets/images/content-hub/thumb-slide2.png",
        "./assets/images/content-hub/thumb-slide3.png",
        "./assets/images/content-hub/thumb-slide1.png",
        "./assets/images/content-hub/thumb-slide1.png",
      ],
    },
  ];

  const handleImageClick = (content) => {
    setModalContent(content);
    setShowModal(true);
    setSelectedSlide(0); // Reset selected slide to the first slide
  };

  const handleThumbnailClick = (index) => {
    setSelectedSlide(index); // Update selected slide on thumbnail click
    mainSliderRef.current.slickGoTo(index); // Force main slider to go to the selected slide
    thumbnailSliderRef.current.slickGoTo(index); // Force thumbnail slider to go to the selected slide
  };

  const settings = {
    dots: false,
    infinite: false,
    fade: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: selectedSlide, // Set the initial slide based on selected thumbnail
    afterChange: (index) => {
      setSelectedSlide(index); // Sync selected slide state
      thumbnailSliderRef.current.slickGoTo(index); // Sync thumbnail slider
    },
  };

  const thumbnailSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    afterChange: (index) => {
      setSelectedSlide(index); // Sync selected slide state
      mainSliderRef.current.slickGoTo(index); // Sync main slider
    },
  };

  // Define all filters dynamically
  const [filters, setFilters] = useState([
    {
      id: "level",
      label: "Level",
      options: ["Level 1", "Level 2", "Level 3"],
      selected: "",
    },
    {
      id: "pageType",
      label: "Page Type",
      options: [
        "Welcome Screen",
        "Landing Page",
        "Objective Screens",
        "Interactive Page",
        "Infographics Page",
        "Content Screens",
        "Assessment",
      ],
      selected: "",
    },
    {
      id: "screenType",
      label: "Screen Type",
      options: ["Flip cards", "Hotspot", "Click and Reveal"],
      selected: "",
    },
    {
      id: "questionType",
      label: "Question Type",
      options: ["Match", "Fill Ups", "MCQs"],
      selected: "",
    },
  ]);

  // Handle dropdown change
  const handleFilterChange = (id, value) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.id === id ? { ...filter, selected: value } : filter
      )
    );
  };

  // Clear all selections
  const clearAllFilters = () => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => ({ ...filter, selected: "" }))
    );
  };

  // Toggle Remarkmodal visibility
  const toggleRemarkModal = () => {
    setShowRemarkModal(!showRemarkModal);
  };

  // Close Remarkmodal when clicking outside
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("Remarkmodal")) {
      setShowRemarkModal(false);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <h2 className="align-items-center text-center font-24 fw-semibold gap-4 mb-0">
                Content Hub
              </h2>
              <p className="text-center mb-0">
                The best e-learning banner work, designs, illustrations, and graphic elements
              </p>
              <div className="d-flex align-items-center">
                {/* Dynamically Render Select Options */}
                <div className="selectOptionAll">
                  {filters.map((filter) => (
                    <select
                      key={filter.id}
                      className="form-select form-select-list"
                      aria-label="Default select example"
                      value={filter.selected}
                      onChange={(e) => handleFilterChange(filter.id, e.target.value)}
                    >
                      <option value="">{filter.label}</option>
                      {filter.options.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ))}
                </div>

                {/* Clear All Button */}
                <div className="clearAllSec">
                  <a href="#/" onClick={clearAllFilters}>
                    Clear All
                  </a>
                </div>
              </div>
              <div className="justify-content-center allImgShowRows">
                {contentData.map((content) => (
                  <div key={content.id} className="contenImgSec" onClick={() => handleImageClick(content)}>
                    <div className="pptImgThum">
                      <img src={content.image} alt="" />
                    </div>
                    <p>{content.title}<span>eLearning</span></p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && modalContent && (
        <div className="modal fade imgPopuContentHub show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{modalContent.title}<span>eLearning</span></h5>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body">
                <div className="d-flex gap-16 hubPopupFlex">
                  {/* Main Slider */}
                  <div className="imgShowPopup">
                    <Slider {...settings} ref={mainSliderRef}>
                      {modalContent.slides.map((slide, index) => (
                        <div key={index}>
                          <img src={slide} alt={`Slide ${index + 1}`} />
                        </div>
                      ))}
                    </Slider>
                  </div>

                  {/* Thumbnail Slider */}
                  <div className="popIMgContent">
                    <button type="button" className="btn btn-primary w-100 click-download" onClick={toggleRemarkModal}>
                      Download
                    </button>
                    <div className="popTags">
                      <p className="tagHead">Related tags</p>
                      <div className="imgCate">
                        {modalContent.tags.map((tag, index) => (
                          <a key={index} href="#/">{tag}</a>
                        ))}
                      </div>
                      <div className="tagDetails">
                        <p><span>File:</span>82.5 MB / PPT</p>
                        <p><span>Uploaded on:</span>16 August 2024</p>
                        <p><span>Uploaded by:</span><span className="imgProLetter">NG</span>Nikita Gupta</p>
                        <p><span>Unique ID:</span>{modalContent.title}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="thumbnailHeading">
                  <p>Other Slides</p>
                </div>

                {/* Thumbnails */}
                <div className="slider-container">
                  <Slider {...thumbnailSettings} ref={thumbnailSliderRef}>
                    {modalContent.slides.map((slide, index) => (
                      <div key={index} onClick={() => handleThumbnailClick(index)}>
                        <img
                          src={slide}
                          alt={`Thumb ${index + 1}`}
                          style={{ cursor: "pointer", border: selectedSlide === index ? "2px solid blue" : "none" }}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>

                {/* Remarkmodal */}
                {showRemarkModal && (
                  <div className="Remarkmodal" onClick={handleOutsideClick}>
                    <div className="remark-content">
                      <h4>Enter Project Code</h4>
                      <div className="projectCode">
                        <label htmlFor="exampleFormControlInput1" className="form-label">
                          Project Code
                        </label>
                        <select className="form-select" aria-label="Default select example">
                          <option value="">Select Project Code</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary w-100 close-button"
                        onClick={toggleRemarkModal}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContentHub;