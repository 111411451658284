import React from 'react'
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HighchartsReact from 'highcharts-react-official';

Highcharts3D(Highcharts);

const LineGraph = ({ series }) => {
    const lineChartOptions = {
        chart: {
            type: 'line',
        },
        title: {
            text: '',
            align: 'left',
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: 'category',
            title: {
                text: '',
            },
        },
        yAxis: {
            title: {
                text: '',
            },
            min: 0,
        },
        series: series,
        colors: [
            '#16A34A', // Vibrant Green
            // '#487fff', // Blue
            '#90d5ff', // Sea Blue
            '#DC2626', // Bold Red
            '#EAB308', // Rich Yellow
            '#909090', // Gray
            '#FF8DA1', // Pink
            '#F97316', // Warm Orange
            '#D946EF', // Neon Pink
            '#4F46E5', // Electric Indigo
            '#059669',  // Fresh Emerald
        ],
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            floating: true,
            x: -10,
            y: -10,
        },
        tooltip: {
            shared: true,
            valueSuffix: '',
        },
    };
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={lineChartOptions}
        />
    )
}

export default LineGraph