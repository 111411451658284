import React from 'react' 
import "./style-project-scope.css"

const ProjectScope = () => {
  return (
    <div>
      <div className="row">
  <div className="col-xl-12 col-xxl-12">
    <div className="card h-100">
      <div className="bgMultiColor">
        <div className="bgImgMultiColor">
          <h1>Innovative design solutions</h1>
          <p>
            All the resources you require, from stunning stock photos and
            videos.
          </p>
        </div>
      </div>
      <div className="card-body p-24">
        <h2 className="align-items-center text-center font-24 fw-semibold gap-4 mb-16">
          A vast collection of assets at your fingertips.
        </h2>
        <a href="" className="elerSec">
          <span>
            <img
              src="./assets/images/special-access/e-learning-assets.svg"
              alt=""
            />
          </span>
          E-learning Assets{" "}
          <span>
            <svg
              width={18}
              height={18}
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2_210)">
                <path
                  d="M14.4827 3.74285L7.49721 3.1317C7.31193 3.11873 7.12897 3.17905 6.98774 3.29967C6.8465 3.42029 6.75829 3.59155 6.74211 3.77657C6.72592 3.9616 6.78305 4.14557 6.90119 4.28888C7.01934 4.43219 7.18904 4.52337 7.37375 4.54277L12.6558 5.00488L3.11334 13.0119C2.96942 13.1327 2.87938 13.3057 2.86301 13.4928C2.84663 13.68 2.90527 13.8659 3.02603 14.0098C3.14678 14.1538 3.31976 14.2438 3.50691 14.2602C3.69405 14.2766 3.88004 14.2179 4.02395 14.0972L13.5664 6.09011L13.1043 11.3721C13.0944 11.4657 13.1033 11.5602 13.1304 11.6503C13.1576 11.7403 13.2023 11.8241 13.2622 11.8967C13.322 11.9693 13.3957 12.0292 13.4789 12.073C13.5622 12.1168 13.6533 12.1435 13.747 12.1517C13.8407 12.1599 13.9351 12.1494 14.0247 12.1207C14.1142 12.0921 14.1972 12.0458 14.2687 11.9847C14.3402 11.9237 14.3989 11.8489 14.4412 11.765C14.4836 11.681 14.5088 11.5894 14.5153 11.4956L15.1265 4.51011C15.1428 4.323 15.0842 4.13705 14.9634 3.99317C14.8427 3.84929 14.6698 3.75925 14.4827 3.74285Z"
                  fill="#0073EA"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_210">
                  <rect
                    width={17}
                    height={17}
                    fill="white"
                    transform="translate(0.5 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
        </a>
      </div>
    </div>
  </div>
</div> 
    </div>
  )
}

export default ProjectScope
