import React, { useEffect, useState } from "react";

const LeaveTable = ({ className, leaves }) => {
  // Initialize leave data in state
  const [leaveData, setLeaveData] = useState(leaves?.result || []);
  const [totalSummary, setTotalSummary] = useState(leaveData.totalSummary || { TotalAdjustedCL: 0, TotalAdjustedSL: 0, TotalAppliedCL: 0, TotalAppliedSL: 0, TotalSL: 0, TotalCL: 0 });

  useEffect(() => {
    setLeaveData(leaves?.result || []);
    setTotalSummary(leaves?.totalSummary || { TotalAdjustedCL: 0, TotalAdjustedSL: 0, TotalAppliedCL: 0, TotalAppliedSL: 0, TotalSL: 0, TotalCL: 0 });
  }, [leaves])

  return (
    <div className={`${className}`}>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="text-green text-lg">Approved Leaves</h3>
        <h3 className="text-violet-600 text-lg">Adjusted Leaves</h3>
      </div>
      <div className="table-responsive">
        <table className="table bordered-table mb-0 bdplspading">
          <thead>
            <tr>
              <th className="text-start">Month</th>
              <th className="text-center">CL</th>
              <th className="text-center">SL</th>
              <th className="text-center">CL (REM.)</th>
              <th className="text-center">SL (REM.)</th>
            </tr>
          </thead>
          <tbody>
            {leaveData.map((data, index) => (
              <tr key={index}>
                <td className="text-start fw-semibold">{data.month}</td>
                <td className="text-center"><span className={`${data.appliedCL ? "bg-danger-600" : "bg-neutral-400"} badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle text-white mx-auto`}>{data.appliedCL}</span></td>
                <td className="text-center"><span className={`${data.appliedSL ? "bg-danger-600" : "bg-neutral-400"} badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle text-white mx-auto`}>{data.appliedSL}</span></td>
                <td className="text-center"><span className={`${data.adjustedCL ? "bg-primary-600" : "bg-neutral-400"} badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle text-white mx-auto`}>{data.adjustedCL}</span></td>
                <td className="text-center"><span className={`${data.adjustedSL ? "bg-primary-600" : "bg-neutral-400"} badge text-sm fw-semibold w-32-px h-32-px d-flex justify-content-center align-items-center rounded-circle text-white mx-auto`}>{data.adjustedSL}</span></td>
              </tr>
            ))}
            {/* Total row */}
          </tbody>
          <tfoot>
            <tr>
              <td className="text-start fw-bold text-xl ps-16">Total</td>
              <td className="text-center fw-bold text-md">{totalSummary.TotalAppliedCL}</td>
              <td className="text-center fw-bold text-md">{totalSummary.TotalAppliedSL}</td>
              <td className="text-center fw-bold text-md">{totalSummary.TotalAdjustedCL}({totalSummary.TotalCL - totalSummary.TotalAdjustedCL})</td>
              <td className="text-center fw-bold text-md">{totalSummary.TotalAdjustedSL}({totalSummary.TotalSL - totalSummary.TotalAdjustedSL})</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default LeaveTable;
