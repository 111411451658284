import React from 'react';
import { convertToIndianWords, costFormat, formatDate } from 'src/utils/CommonUtils';
import { toWords } from "number-to-words";

// const InvoiceDownloadTemplate = ({ contentRef2 }) => {
const InvoiceDownloadTemplate = React.forwardRef(({ matchedItem }, ref) => {
    const currencies = [
        {
            "currencyTitle": "INR",
            "symbol": "₹"
        },
        {
            "currencyTitle": "USD",
            "symbol": "$"
        },
        {
            "currencyTitle": "AUD",
            "symbol": "A$"
        },
        {
            "currencyTitle": "GBP",
            "symbol": "£"
        },
        {
            "currencyTitle": "EUR",
            "symbol": "€"
        },
        {
            "currencyTitle": "SGD",
            "symbol": "S$"
        },
        {
            "currencyTitle": "AED",
            "symbol": "د.إ"
        },
        {
            "currencyTitle": "CNY",
            "symbol": "¥"
        },
        {
            "currencyTitle": "XCD",
            "symbol": "EC$"
        },
        {
            "currencyTitle": "CAD",
            "symbol": "CA$"
        }
    ]
    const getCurrencyTitle = (symbol) => {
        const currency = currencies.find(c => c.symbol === symbol);
        return currency ? currency.currencyTitle : symbol;
    }

    const ucwords = (str) => {
        if (!str) {
            return false
        }
        return str
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };
    let totalGst = 0

    const gstFun = (value, postfix = '') => {
        let newValue
        if (value > 0 && matchedItem?.stateCode !== '09') {
            newValue = value + postfix
        } else {
            newValue = '-'
        }
        return newValue
    }
    const gstStateFun = (value, postfix = '') => {
        let newValue
        if (value > 0 && matchedItem?.stateCode === '09') {
            newValue = value + postfix
        } else {
            newValue = '-'
        }
        return newValue
    }
    const sellerGst = matchedItem?.sellerGst ? matchedItem?.sellerGst : "09AANCA8296L2ZP"
    const sellerAdd = matchedItem?.sellerAdd ? matchedItem?.sellerAdd : "A 61 B/3, Ground floor, Sector-63, Gautam Buddha Nagar, Noida, UP-201301"
    return (

        <div>
            <div ref={ref}
                id="invoiceContent"
                style={{ width: "100%", maxWidth: 1080, margin: "0 auto", color: "#333", fontFamily: "Inter, sans-serif", backgroundColor: "#fff", paddingLeft: 20, paddingRight: 20, borderRadius: 8, lineHeight: "1.5", fontSize: 14 }} >
                <style
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');\n        body,p,h3,h2,h1,h4{\n  font-family: Inter, sans-serif;\n  font-optical-sizing: auto; \n  font-style: normal;\n}\n"
                    }}
                />
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 0, borderBottom: "1px solid #ccc" }}>
                    <span style={{ fontSize: "40px", color: "#fff", backgroundColor: "#007bff", padding: "0px 30px 15px 30px", borderRadius: "8px 8px 0px 0", margin: 0, height: "66px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 500 }}>{matchedItem?.currency === "₹" ? 'Tax' : ''} Invoice</span>
                    <span><img src="/assets/images/acadecraft-logo.svg" alt="" /></span>
                </div>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start", marginBottom: 0, borderBottom: "10px solid #0073EA" }}>
                    <div style={{ flex: 1, padding: 10 }}>
                        <p style={{ margin: 0, fontSize: 13, lineHeight: "1.4", fontWeight: "bold" }}>Acadecraft Private Limited</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", marginTop: 10 }}>GSTIN: {sellerGst}</p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex", }}><span style={{ whiteSpace: 'nowrap' }}>Add:</span> <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5, wordBreak: 'break-word', }}>{sellerAdd}</span></p>
                        {/* <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex" }}>City: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Noida</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex" }}>State: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Uttar Pradesh</span></p> */}
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex" }}>State Code: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>{sellerGst.substring(0, 2)}</span></p>
                    </div>
                    <div style={{ flex: 1, padding: 10 }}>
                        <p style={{ margin: 0, fontSize: 12, lineHeight: "1.6", color: "#0073EA" }}>Buyer (Bill to)</p>
                        <p style={{ margin: "2px 0", fontSize: 13, lineHeight: "1.4", fontWeight: "bold" }}>{matchedItem?.clientName}</p>
                        {/* <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", fontWeight: "bold" }}> {matchedItem?.currency === "₹" ? 'GSTIN' : 'VAT'}: {matchedItem?.clientGstVat}</p> */}
                        {matchedItem?.clientGstVat && (
                            <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", fontWeight: "bold" }}>
                                {matchedItem?.currency === "₹" ? "GSTIN" : "VAT"}: {matchedItem.clientGstVat}
                            </p>
                        )}
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: "flex", }}><span style={{ whiteSpace: 'nowrap' }}>Add:</span> <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5, wordBreak: 'break-word', }}>{matchedItem?.clientAddress}</span></p>
                        {/* <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>City: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Noida</span></p>
                        <p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6" }}>State: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>Uttar Pradesh</span></p> */}
                        {matchedItem?.currency === "₹" && (<p style={{ margin: "2px 0", fontSize: 9, lineHeight: "1.6", display: 'flex' }}>
                            State Code: <span style={{ display: "inline-block", color: "#7B7E8B", paddingLeft: 5 }}>{matchedItem?.stateCode}</span></p>
                        )}
                    </div>
                    <div style={{ flex: 1, padding: 10, textAlign: "right" }}>
                        <div style={{ display: "inline-block", textAlign: "left" }}>
                            <p style={{ margin: "2px 0", fontSize: 15, lineHeight: "1.4", fontWeight: "bold", display: "flex" }}><span style={{ display: "inline-block", fontWeight: "normal", paddingRight: "5px" }}>Invoice No:</span> {matchedItem?.clientInvoiceNo}</p>
                            <p style={{ margin: "2px 0", fontSize: 10, lineHeight: "1.6", marginTop: 20, color: "#7B7E8B", display: "flex" }}>Invoice Date: <span style={{ fontWeight: "bold", color: "#323338", paddingLeft: "5px" }}>{formatDate(matchedItem?.invoiceDate)}</span></p>
                            <p style={{ margin: "2px 0", fontSize: 10, lineHeight: "1.6", color: "#7B7E8B", display: "flex" }}>Purchase Order: <span style={{ fontWeight: "bold", color: "#323338", paddingLeft: "5px" }}>{matchedItem?.poNubmer || 'Nil'}</span></p>
                        </div>
                    </div>
                </div>

                <table style={{ width: "100%", borderCollapse: "collapse", marginTop: 15, fontSize: 12, lineHeight: "1.5" }}>
                    <thead>
                        <tr style={{ backgroundColor: "#fff", color: "#7B7E8B", fontWeight: "normal", verticalAlign: "middle" }}>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Sr. No.</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Description of Goods/Services</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Unit of Measurement</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Unit Price</th>
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Total Units</th>
                            {matchedItem?.currency === "₹" && (
                                <>
                                    <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>HSN/SAC Code</th>
                                </>
                            )}
                            <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }} rowSpan={2}>Total Value</th>
                            {matchedItem?.currency === "₹" && (
                                <>
                                    <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle", textAlign: "center" }} colSpan={2}>CGST</th>
                                    <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle", textAlign: "center" }} colSpan={2}>SGST/UTGST</th>
                                    <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle", textAlign: "center" }} colSpan={2}>IGST</th>
                                </>
                            )}
                        </tr>
                        {matchedItem?.currency === "₹" && (
                            <tr>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Rate (%)</th>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Amount</th>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Rate (%)</th>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Amount</th>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Rate (%)</th>
                                <th style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.4", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>Amount</th>
                            </tr>
                        )}
                    </thead>

                    <tbody>
                        {matchedItem?.invoiceItems.map((item, index) => {
                            const totalAmount = Number(((item?.price.$numberDecimal || 0) * (item?.quantity || 0)).toFixed(2))
                            const subTotalGst = Number((totalAmount * (item?.gstRate || 0) / 100).toFixed(2))
                            totalGst += subTotalGst
                            return (<tr key={index}>
                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{index + 1}</td>
                                <td style={{ border: "1px solid #ddd", padding: 8, lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item?.description}</td>
                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item?.uom}</td>
                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item?.price.$numberDecimal}</td>
                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item?.quantity}</td>
                                {matchedItem?.currency === "₹" && (
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{item?.sacCode}</td>

                                )}
                                <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle", textAlign: "right" }}>{totalAmount}</td>
                                {matchedItem?.currency === "₹" && (
                                    <>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}><span>{gstStateFun((item?.gstRate / 2).toFixed(2), '%')}</span></td>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{gstStateFun((matchedItem?.stateCode === '09') ? (subTotalGst / 2).toFixed(2) : 0.00)}</td>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}><span>{gstStateFun((item?.gstRate / 2).toFixed(2), '%')}</span></td>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{gstStateFun((matchedItem?.stateCode === '09') ? (subTotalGst / 2).toFixed(2) : 0.00)}</td>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}><span>{gstFun(item?.gstRate, '%')}</span></td>
                                        <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>{gstFun((matchedItem?.stateCode !== '09') ? subTotalGst : 0.00)}</td>

                                    </>
                                )}
                            </tr>)
                        })}

                        <tr>
                            <td colSpan={matchedItem?.currency === "₹" ? 6 : 2} style={{ border: "1px solid #ddd", padding: 8, textAlign: "end", lineHeight: "1.6", fontSize: 12, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                Total
                            </td>
                            <td colspan={matchedItem?.currency === "₹" ? 1 : 4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle", textAlign: "right" }}>
                                {costFormat(matchedItem?.subTotal.$numberDecimal, getCurrencyTitle(matchedItem?.currency))}
                            </td>
                            {matchedItem?.currency === "₹" && (
                                <>
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                        {(matchedItem?.stateCode === '09') ? costFormat((totalGst / 2).toFixed(2), getCurrencyTitle(matchedItem?.currency)) : 0.00}
                                    </td>
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                        {(matchedItem?.stateCode === '09') ? costFormat((totalGst / 2).toFixed(2), getCurrencyTitle(matchedItem?.currency)) : 0.00}
                                    </td>
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} />
                                    <td style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                        {(matchedItem?.stateCode !== '09') ? costFormat(totalGst, getCurrencyTitle(matchedItem?.currency)) : 0.00}
                                    </td>
                                </>
                            )}
                        </tr>

                        <tr>
                            <td colSpan={matchedItem?.currency === "₹" ? 7 : 2} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", verticalAlign: "middle" }}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Rounding off
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                {costFormat(Math.round(matchedItem?.grandTotal.$numberDecimal), getCurrencyTitle(matchedItem?.currency))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Total Value (in Fig)
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 14, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                {costFormat(Math.round(matchedItem?.grandTotal.$numberDecimal), getCurrencyTitle(matchedItem?.currency))} /-
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                Total Value (in words)
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}>:</span>
                                                {ucwords(convertToIndianWords(Math.round(matchedItem?.grandTotal.$numberDecimal)))} {matchedItem?.currency === "₹" ? 'Rupees ' : ''}Only
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: "0 8px 0 0", textAlign: "start", lineHeight: "1.6", fontSize: 8, color: "#7B7E8B", fontWeight: 500, verticalAlign: "middle" }}>
                                                {matchedItem?.currency === "₹" ? 'Amount of tax subject to reverse charges' : ''}
                                            </td>
                                            <td style={{ padding: "0 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, verticalAlign: "middle" }}>
                                                <span style={{ display: "inline-block", marginRight: 5 }}></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td colSpan={matchedItem?.currency === "₹" ? 6 : 4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "left", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }} >
                                {/* Notes: {matchedItem?.notes} */}
                                {matchedItem?.notes && `Notes: ${matchedItem.notes}`}

                            </td>
                        </tr>

                        <tr>
                            <td colSpan={matchedItem?.currency === "₹" ? 7 : 2} style={{ border: "1px solid #ddd", verticalAlign: "middle" }}>
                                {matchedItem?.showBankDetails === "yes" && (
                                    <table style={{ width: "100%", borderCollapse: "collapse", verticalAlign: "middle" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ border: "1px solid #ddd", borderTop: 0, borderLeft: 0, padding: "0 8px 7px 8px", textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account Holder Name</td>
                                                <td style={{ border: "1px solid #ddd", borderTop: 0, padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>Acadecraft Private Limited</td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Name of Bank</td>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICICI BANK LTD</td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account No</td>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>003105029905</td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Account Type</td>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>CURRENT</td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>IFSC Code</td>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICIC0000031</td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Swift Code</td>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>ICICINBBCTS</td>
                                            </tr>
                                            <tr>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderLeft: 0, borderBottom: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: 500, whiteSpace: "nowrap", verticalAlign: "middle" }}>Bank Address</td>
                                                <td style={{ border: "1px solid #ddd", padding: "0 8px 7px 8px", borderRight: 0, borderBottom: 0, textAlign: "start", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: 500, width: "100%", verticalAlign: "middle" }}>K-1, Senior Mall, Sector 18, Gautam Buddh Nagar District, Noida, Uttar Pradesh.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </td>
                            <td colSpan={matchedItem?.currency === "₹" ? 6 : 4} style={{ border: "1px solid #ddd", padding: 8, textAlign: "center", lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold", verticalAlign: "middle" }}>
                                <p style={{ lineHeight: "1.6", fontSize: 10, color: "#323338", fontWeight: "bold" }}>For Acadecraft Private Limited</p>
                                <p><img src="/assets/images/authirisedsignatory1.svg" /></p>
                                <p style={{ lineHeight: "1.6", fontSize: 10, marginBottom: "0", color: "#323338", fontWeight: "normal", verticalAlign: "middle" }}>Authorised Signatory</p>
                            </td>
                        </tr>

                    </tbody>
                </table>
                {matchedItem?.invoiceItems?.length >= 4 && (
                    <div style={{
                        pageBreakBefore: "always",
                        marginTop: "30px"
                    }}></div>
                )}
                <div style={{ marginTop: 10, fontSize: 12, lineHeight: "1.6" }}>
                    <p style={{ margin: "2px 0", textAlign: "left", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: "normal", display: "flex" }}>
                        <span style={{ fontWeight: "bold" }}>Note:</span> This invoice was system
                        generated, signature are not required
                    </p>
                    <p style={{ margin: "2px 0", textAlign: "left", lineHeight: "1.6", fontSize: 10, color: "#7B7E8B", fontWeight: "normal", display: "flex" }} >
                        <span style={{ fontWeight: "bold" }}>Declaration:</span> We declare that
                        this invoice shows the actual price of the goods described and that all
                        particulars are true and correct.
                    </p>
                </div>
            </div>
        </div >
    );
});

export default InvoiceDownloadTemplate;
