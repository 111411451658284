import React, { useState } from "react";

const ContentFilterPopup = () => {
  // Define filter categories dynamically
  const [filters, setFilters] = useState([
    { id: "department", label: "Department", options: ["Awesome", "Beast", "Compatible", "Thomas Edison", "Nikola"], selected: "" },
    { id: "cadre", label: "Cadre", options: ["Awesome", "Beast", "Compatible", "Thomas Edison", "Nikola"], selected: "" },
    { id: "assetType", label: "Asset Type", options: ["Awesome", "Beast", "Compatible", "Thomas Edison", "Nikola"], selected: "" },
    { id: "date", label: "Date", options: ["Awesome", "Beast", "Compatible", "Thomas Edison", "Nikola"], selected: "" },
    { id: "level", label: "Level", options: ["Awesome", "Beast", "Compatible", "Thomas Edison", "Nikola"], selected: "" },
    { id: "status", label: "Status", options: ["Awesome", "Beast", "Compatible", "Thomas Edison", "Nikola"], selected: "" },
    { id: "pageType", label: "Page Type", options: ["Awesome", "Beast", "Compatible", "Thomas Edison", "Nikola"], selected: "" },
    { id: "screenType", label: "Screen Type", options: ["Awesome", "Beast", "Compatible", "Thomas Edison", "Nikola"], selected: "" },
    { id: "questionType", label: "Question Type", options: ["Awesome", "Beast", "Compatible", "Thomas Edison", "Nikola"], selected: "" },
  ]);

  // Handle dropdown change
  const handleFilterChange = (id, value) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.id === id ? { ...filter, selected: value } : filter
      )
    );
  };

  // Handle Filter button click
  const handleFilterSubmit = () => {
    const selectedFilters = filters.reduce((acc, filter) => {
      acc[filter.id] = filter.selected;
      return acc;
    }, {});
    console.log("Selected Filters:", selectedFilters);
  };

  return (
    <div className="filterSecPopup offcanvas offcanvas-end" id="filterRightPopup" aria-labelledby="filterRightLayout">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="filterRightLayout">Filter</h5>
        <button type="button" className="d-none btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body pt-0">
        <div className="assignPopoHeight">
          {filters.map((filter) => (
            <div key={filter.id} className="filterStyleSec selectSec">
              <label htmlFor={filter.id}>{filter.label}</label>
              <select
                id={filter.id}
                className="searchOption"
                value={filter.selected}
                onChange={(e) => handleFilterChange(filter.id, e.target.value)}
              >
                <option value="">Select {filter.label}</option>
                {filter.options.map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))}
              </select>
            </div>
          ))}
        </div>
        <div>
          <button type="button" className="btn btn-primary text-sm w-100 my-12 px-14 py-6 radius-6" onClick={handleFilterSubmit}>
            Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentFilterPopup