import React, { useRef, useEffect, useState } from "react";

const StickyTableHeader = () => {
  const tableRef = useRef(null);
  const headerRef = useRef(null);
  const [columnWidths, setColumnWidths] = useState([]);

  const columns = ["Name", "Age", "City"];
  const data = [
    { Name: "John", Age: 25, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Jane", Age: 30, City: "Los Angeles dgsdg dshgsdg sdg sdg sdgsdg sdgsdgsdg" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 32323232, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 987987987, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 654654654, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28333, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 546546, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28333, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
    { Name: "Mike", Age: 28, City: " was searching why % doesn't seem to work. So, I tested out using 100vh instead of just setting it at 100% it seems that 100vh works really well acros" },
  ];

  useEffect(() => {
    if (tableRef.current) {
      const widths = Array.from(
        tableRef.current.querySelectorAll("tbody tr:first-child td")
      ).map((td) => td.offsetWidth);
      setColumnWidths(widths);
    }
  }, [data]); // Ensure data is inside useEffect dependency

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {/* Sticky Header Table */}
      <table ref={headerRef} style={{ width: "100%", position: "sticky", top: 0, background: "white", zIndex: 1000 }}>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index} style={{ width: columnWidths[index] || "auto" }}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
      </table>

      {/* Scrollable Table */}
      <div style={{ maxHeight: "400px", overflow: "auto" }}>
        <table ref={tableRef} style={{ width: "100%" }}>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>{row[col]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StickyTableHeader;
