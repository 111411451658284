import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import TopTabs from '../TopTabs';
import SearchInput from 'src/components/SearchInput';
import DatePickerComponent from 'src/components/DatePickerComponent';
import VendorDeliveryTable from './VendorDeliveryTable';
import VendorAddClientDelivery from './VendorAddClientDelivery';
import { projectOAD_CMD_DetailsAction } from '../../actions/ProjectActions';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import VendorDeliverySavePopup from '../VendorDeliverySavePopup';
import { useParams } from 'react-router-dom';
import { vendorDeliveryProjectSearchAPI } from 'src/api/ReportsAPI';
import BackProjectButton from 'src/components/BackProjectButton';
import { vendorDeliverySearchAction } from './actions/VendorDeliveryActions';
import InvoiceTableModal from './InvoiceTableModal';
import { ExportReactCSV } from 'src/components/ExportReactCSV';
import ViewInvoiceListModal from './ViewInvoiceListModal';
import GenerateInvoicePopup from './GenerateInvoicePopup';
import { upperCase } from 'lodash';
import { costFormat, truncateNotes } from 'src/utils/CommonUtils';
import { dateFormat, getBackgroundColor } from 'src/utils/utils';

const VendorDeliveryContainer = () => {

  const { search: vendorDeliverySearch, totalSummary } = useSelector((state) => state.VendorDeliveryReducer, shallowEqual);
  const [searchQuery, setSearchQuery] = useState("");
  const { projectId } = useParams();
  const [search, setSearch] = useState(false);
  const [deliveries, setDeliveries] = useState([]);
  const [totalcost, setTotalCost] = useState(0)
  const [totalSearch, setTotalSearch] = useState(0)
  const [commonData, setCommonData] = useState(false);
  const [where, setWhere] = useState({
    projectId,
  });
  const [selectedDelivery, setSelectedDelivery] = useState([]);
  const [invoicePayload, setInvoicePayload] = useState({})

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const getInitials = (name) => {
    const nameParts = name.split(' ');
    const initials = nameParts.map(part => part[0]).join('');
    return initials;
  };
  // Function to determine background color based on manager's name
  const getBackgroundColor = (name) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 70%, 50%)`; // HSL for more variation
    return color;
  };
  const getTextColor = (bgColor) => {
    const rgb = bgColor.match(/\d+/g).map(Number);
    const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    return brightness > 150 ? '#000' : '#fff'; // Light or dark text based on background
  };
  const trimmedQuery = (typeof searchQuery === 'string' ? searchQuery : '').trim().toLowerCase();
  const filteredData = trimmedQuery
    ? deliveries.filter((item) =>
      (item?.description?.toLowerCase() || '').includes(trimmedQuery) ||
      String(item.value).toLowerCase().includes(trimmedQuery) ||
      item.fullName.toLowerCase().includes(trimmedQuery)
    )
    : deliveries; // Return all data if the searchQuery is empty
  const handleDateRangeChange = (selectedDates) => {
  }

  const dispatch = useDispatch();
  const project = ''
  // Event handler for button
  const handlePlusClick = (type, mode, project) => {
    dispatch(projectOAD_CMD_DetailsAction({ data: { type, mode, project } }));
    setSearch(true)
  };
  // const [individual, setIndividual] = useState([])
  // const [totalDuration, setTotalDuration] = useState()
  // const { currentRecord } = useSelector((state) => state.ProjectReducer, shallowEqual)
  // const projectCode = (currentRecord?.RefClientId?.ClientProjectCode) ? `${currentRecord?.RefClientId?.ClientProjectCode} ${currentRecord.ProjectSubCode}` : ''

  const getProjectVendorDeliveryData = async () => {
    try {
      const resp = await vendorDeliveryProjectSearchAPI({ projectId });
      setDeliveries(resp?.data?.data || [])
      // const filterSkillsData = skillsData.filter(item =>
      //   item?.fullName?.toLowerCase().includes(search.toLowerCase())
      // );
      setTotalCost(resp?.data?.total)
    } catch (error) {
      console.log('error from project vendor delivery', error);
    }
  };

  useEffect(() => {
    getProjectVendorDeliveryData();
    // Update total based on filtered data
    const newTotal = filteredData.reduce((acc, item) => {
      if (item.type === "add") {
        return acc + item.value;
      } else {
        return acc - item.value;
      }
    }, 0); // Start with 0 instead of using a separate `total` variable

    setTotalSearch(newTotal); // Update total state
  }, [projectId, search]);

  useEffect(() => {
    // Update total based on filtered data
    const newTotal = filteredData.reduce((acc, item) => {
      if (item.type === "add") {
        return acc + item.value;
      } else {
        return acc - item.value;
      }
    }, 0); // Start with 0 instead of using a separate `total` variable

    setTotalSearch(newTotal); // Update total state
  }, [searchQuery]);

  const handleChange = (ee) => {
    setSearch(ee)
    getProjectVendorDeliveryData();
  }

  useEffect(() => {
    // Timeout to ensure tooltips are initialized after rendering
    const timer = setTimeout(() => {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl));

      // Cleanup on unmount or if the tooltips need to be re-initialized
      return () => {
        tooltipList.forEach(tooltip => tooltip.dispose());
      };
    }, 100); // Delay initialization to ensure DOM is fully updated

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, [searchQuery, handleChange]);

  useEffect(() => {
    handleSearch(where);
  }, [
    dispatch,
    where
  ]);
  const handleSearch = useCallback(() => {
    dispatch(vendorDeliverySearchAction({
      where: where
    }));
  }, [dispatch, where])
  const ExportInExcel = useMemo(() => {
    if (vendorDeliverySearch?.data?.length === 0) {
      return [];
    }
    const results = [];
    vendorDeliverySearch?.data?.forEach((row, index) => {
      results.push({
        SrNo: index + 1,
        UpdatedBy: `${row.userId.firstName} ${row.userId.lastName}`,
        VendorName: `${row.vendorId.firstName} ${row.vendorId.lastName}`,
        productionSlug: row.productionSlug,
        moduleName: row.moduleId.moduleName,
        totalDurations: row.totalDurations,
        perUnitCost: row.perUnitCost,
        totalCost: row.totalCost,
      });
    });
    return results;
  }, [
    vendorDeliverySearch
  ]);
  useEffect(() => {
    if (selectedDelivery && selectedDelivery.length > 0) {
      const uniqueVendors = new Set(selectedDelivery.map(item => item.vendorId._id));
      // If more than one unique vendor exists, set `commonData` to false
      setCommonData(uniqueVendors.size === 1);
    } else {
      // No deliveries selected, set `commonData` to false
      setCommonData(false);
    }
  }, [selectedDelivery]);





  
    // Table Scroll Code Start
    const elementRef = useRef(null);
    const [isScrollable, setIsScrollable] = useState(false);
    const debounceTimeoutRef = useRef(null); // Store the debounce timeout ID
  
    useEffect(() => {
      const handleScroll = () => {
        // Clear the previous timeout to prevent multiple updates
        clearTimeout(debounceTimeoutRef.current);
  
        debounceTimeoutRef.current = setTimeout(() => {
          if (elementRef.current) {
            const { top, bottom } = elementRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  
            // Check if the table <thead> is within the viewport range
            const shouldScroll = top <= 100 && bottom >= viewportHeight - 100;
            setIsScrollable(shouldScroll);
          }
        }, 100); // Adjust the debounce delay as needed (e.g., 100ms)
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
        clearTimeout(debounceTimeoutRef.current); // Cleanup on unmount
      };
    }, []);
    // Table Scroll Code End
  
       // New code Table Scroll set
        
       const tableRef = useRef(null);
       const scrollBarRef = useRef(null);
       const thumbRef = useRef(null);
       const [thumbWidth, setThumbWidth] = useState(50);
       const [thumbLeft, setThumbLeft] = useState(0); 
       
       useEffect(() => {
           updateThumbSize();
           window.addEventListener("resize", updateThumbSize);
           return () => window.removeEventListener("resize", updateThumbSize);
       }, []);
       
       const updateThumbSize = () => {
           const table = tableRef.current;
           const scrollbar = scrollBarRef.current;
           if (table && scrollbar) {
               const visibleRatio = table.clientWidth / table.scrollWidth;
               setThumbWidth(visibleRatio * scrollbar.clientWidth);
               setThumbLeft((table.scrollLeft / table.scrollWidth) * scrollbar.clientWidth);
           }
       };
       
       const handleTableScroll = () => {
           const table = tableRef.current;
           const scrollbar = scrollBarRef.current;
           if (table && scrollbar) {
               const scrollRatio = table.scrollLeft / (table.scrollWidth - table.clientWidth);
               setThumbLeft(scrollRatio * (scrollbar.clientWidth - thumbWidth));
           }
       };
       
       const handleThumbDrag = (event) => {
           event.preventDefault();
           const table = tableRef.current;
           const scrollbar = scrollBarRef.current;
       
           if (!table || !scrollbar) return;
       
           const startX = event.clientX;
           const startLeft = thumbLeft;
       
           const onMouseMove = (moveEvent) => {
               const deltaX = moveEvent.clientX - startX;
               const newLeft = Math.min(Math.max(startLeft + deltaX, 0), scrollbar.clientWidth - thumbWidth);
               const scrollRatio = newLeft / (scrollbar.clientWidth - thumbWidth);
               table.scrollLeft = scrollRatio * (table.scrollWidth - table.clientWidth);
               setThumbLeft(newLeft);
           };
       
           const onMouseUp = () => {
               document.removeEventListener("mousemove", onMouseMove);
               document.removeEventListener("mouseup", onMouseUp);
           };
       
           document.addEventListener("mousemove", onMouseMove);
           document.addEventListener("mouseup", onMouseUp);
       }; 
       // end

  return (
    <>
      <VendorDeliverySavePopup projectSearch={project} projectId={projectId} handleChange={handleChange} />
      <div className="row">
        <div className="col-xxl-12 col-xl-12">
          <div className="card h-100">
            <div className="card-body p-24">
              <div className="border-bottom  pb-16">

                <BackProjectButton />

                <ul className="d-flex align-items-center gap-2">
                  <li className="fw-medium">
                    <a
                      href="#"
                      className="d-flex align-items-center gap-1 hover-text-primary"
                    >
                      <iconify-icon
                        icon="solar:home-smile-angle-outline"
                        className="icon text-lg"
                      ></iconify-icon>
                      Special Access
                    </a>
                  </li>
                  <li>-</li>
                  <li className="fw-medium">Vendor Delivery </li>
                </ul>
              </div>
              <TopTabs />
              <div className="d-flex gap-2 align-items-center py-24 bg-white postionTop-0">
                <div className="WBtn_fstx">
                  <button className="btn_001 PlusBtn_001" data-bs-toggle="modal" data-bs-target="#uniqueCostModual"
                    onClick={() => handlePlusClick('OAD', 'add', project)}>
                    <i className="mdi mdi-plus" />
                  </button>
                  <div className="oadTxt">
                    Add Vendor Delivery
                  </div>
                  <button className="btn_001 MinBtn_001" data-bs-toggle="modal" data-bs-target="#uniqueCostModual"
                    onClick={() => handlePlusClick('OAD', 'remove', project)}>
                    <i className="mdi mdi-minus" />
                  </button>
                </div>
                {/* <button
                  data-bs-toggle="modal"
                  data-bs-target="#vendoraddClientDelivery"
                  className="btn btn-primary text-sm heightAllBtnSet44 btn-sm px-12 py-10 radius-8 d-flex align-items-center gap-2"
                >
                  <iconify-icon
                    icon="ic:baseline-plus"
                    className="icon text-xl line-height-1"
                  ></iconify-icon>
                  Add Vendor Delivery
                </button> */}

                <SearchInput handleSearchInputChange={handleSearchInputChange} />
                <DatePickerComponent
                  onDateRangeChange={handleDateRangeChange}
                />
                <div className="FilterBtn d-flex gap-2"><button type="button" className="btn btn-outline-primary-600 radius-8 px-20 py-9 d-flex align-items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 13h12v-2H6M3 6v2h18V6M10 18h4v-2h-4z"></path></svg> Filter</button>
                </div>
                <div className="d-flex align-items-center gap-2">
                  {commonData && selectedDelivery.length > 0 && (
                    <button type="button" className="btn btn-primary-600 radius-8 px-14 py-9 text-sm" data-bs-toggle="modal" data-bs-target="#combinedinvoiveModal">Generate Combined Invoice</button>
                  )}
                  {/* <button type="button" className="btn btn-neutral-100 text-primary-light px-16 py-8 radius-6 fw-medium text-sm d-flex align-items-center gap-2">Export<img src="/assets/images/left-sidebar/xls-icon.svg" className="img-fluid" /></button> */}
                  <ExportReactCSV csvData={ExportInExcel} fileName={"VendorDeliveryList-" + new Date().toLocaleDateString()} />
                </div>
              </div>

              <div className="TotlU">
                {/* <ul>
                  <li>
                    {totalSummary?.totalPaidUnits+totalSummary?.totalUnPaidUnits}
                    <span className="d-block">Total</span></li>
                  <li className="text-green">{totalSummary?.totalPaidUnits} <span className="d-block">Total Paid Unit</span></li>
                  <li>{totalSummary?.totalUnPaidUnits} <span className="d-block">Total Unpaid Unit</span></li>
                  <li className="text-dblue">₹{totalSummary?.totalPaidAmount} <span className="d-block">Total Paid Amount</span></li> 
                  <li>{totalSummary?.FullyPaidCount} <span className="d-block">Fully Paid</span></li> 
                  <li>{totalSummary?.PartiallyPaidCount} <span className="d-block">Partially Paid</span></li>
                </ul> */}
                <ul>
                  <li>
                    {searchQuery ? (
                      <>
                        {costFormat(totalSearch)} / {costFormat(totalcost)}
                      </>
                    ) : (
                      costFormat(totalcost)
                    )}
                    <span className="d-block">Total</span></li>
                </ul>
              </div>

              {/* <VendorDeliveryTable 
              data={vendorDeliverySearch.data||[]} 
              total={vendorDeliverySearch.total} 
              selectedDelivery={selectedDelivery}
              setSelectedDelivery={setSelectedDelivery}
              setInvoicePayload={setInvoicePayload}
              status="Fully Paid"

               /> */}
               <div className='posSetTable'>
              <div className={`table-body-scrollable ${isScrollable ? 'scrollable' : ''}`} ref={tableRef} onScroll={handleTableScroll} >
    <table className="table basic-border-table mb-0 table-hover Tbltopfixedsciky" ref={elementRef}>
                <thead>
                  <tr>
                    <th className="align-middle">S.No.</th>
                    <th className="align-middle">Date</th>
                    <th className="align-middle">Status</th>
                    <th className="align-middle">Amount</th>
                    <th className="align-middle">Uploaded By</th>
                    <th className="align-middle">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((row, index) => {
                    const bgColor = getBackgroundColor(row.fullName);
                    const textColor = getTextColor(bgColor);
                    return (
                      <tr key={row._id}>
                        <td>{index + 1}</td>
                        <td>{dateFormat(row.ts, false)}</td>
                        <td className={`bg ${row.type === 'add' ? 'green' : 'invoice-under-process'}`}>
                          <span className={`status-badge d-flex align-items-center gap-2 ${row.type === 'add' ? 'green' : 'invoice-under-process'}`}>
                            <i className={`mdi mdi-${row.type === 'add' ? 'check' : 'close'} mdi-24px`} /> {upperCase(row.type)}
                          </span>
                        </td>
                        <td>{costFormat(row.value)}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            {row.profilePicture ? (
                              <img
                                src={row.profilePicture}
                                alt={row.fullName}
                                className="initials-image"
                                style={{ width: '35px', height: '35px', borderRadius: '50%', marginRight: '8px' }}
                              />
                            ) : (
                              <span
                                className="initials-circle UsIMgName"
                                style={{ backgroundColor: bgColor, color: textColor }}>
                                {getInitials(row.fullName)}
                              </span>
                            )}
                            {row.fullName}
                          </div>
                        </td>
                        <td>
                          <div className="detaset" data-bs-toggle="tooltip"

                            data-bs-placement="top"

                            data-bs-custom-class="tooltip-primary"

                            data-bs-title={`${row?.description}`}>{truncateNotes(row?.description)}</div>
                        </td>
                      </tr>
                    )
                  }
                  )}
                </tbody>
              </table> 
            </div>
            <div className="custom-scroll" ref={scrollBarRef}>
                <div
                    className="custom-scroll-thumb"
                    ref={thumbRef}
                    style={{ width: `${thumbWidth}px`, left: `${thumbLeft}px` }}
                    onMouseDown={handleThumbDrag}
                ></div>
            </div>
            </div>
            </div>
          </div>
        </div>
      </div>
      <InvoiceTableModal selectedDelivery={selectedDelivery} search={handleSearch} />
      <VendorAddClientDelivery search={handleSearch} />
      <ViewInvoiceListModal selectedDelivery={selectedDelivery} />
      <GenerateInvoicePopup invoicePayload={invoicePayload} search={handleSearch} />
    </>
  )
}
export default VendorDeliveryContainer