import { useState } from "react"

const FilterPopup = ({ where, setWhere, data }) => {
  const [status, setStatus] = useState(undefined);
  const [manager, setManager] = useState(undefined);
  const [OAD, setOAD] = useState({ type: 'above', value: null });
  const [CMD, setCMD] = useState({ type: 'Below', value: null });
  const uniqueManagers = data?.reduce((acc, current) => {
    if (!acc.find(item => item.managerId === current.managerId)) {
      acc.push(current);
    }
    return acc;
  }, []);
  const applyFilter = () => {
    let updatedWhere = { ...where };
    // Check if status is set
    if (status !== undefined) {
      updatedWhere.active = status;
    } else {
      delete updatedWhere.active;
    }
    // Check if manager is set
    if (manager !== undefined) {
      // updatedWhere?.$and[1]?.participants?.$elemMatch?.userId = manager;
      // updatedWhere.$and.push({
      //   participants: {
      //     $elemMatch: {
      //       userId: "manager",
      //       status: "Active"
      //     }
      //   }
      // });
      if (!manager) {
        delete updatedWhere.$and[1];
        // Remove null values from the $and array
        updatedWhere.$and = updatedWhere.$and.filter(item => item !== null);
      } else {
        if (updatedWhere?.$and.length === 1) {
          updatedWhere.$and[1] = {}; // Initialize the second index if it doesn't exist
        }
        updatedWhere.$and[1].participants = {
          $elemMatch: {
            userId: manager,
            status: "Active",
            role: "Manager"
          }
        };
      }
    } else {
      delete updatedWhere?.$and[1];
    }

    // Check if OAD has a value
    if (OAD?.value) {
      updatedWhere.OAD = OAD;
    } else {
      delete updatedWhere.OAD;
    }

    // Check if CMD has a value
    if (CMD?.value) {
      updatedWhere.CMD = CMD;
    } else {
      delete updatedWhere.CMD;
    }

    // Update the `where` state with the combined filters
    setWhere(updatedWhere);
  };
  return (
    <div
      className="offcanvas offcanvas-end"
      id="special-access-fil"
      aria-labelledby="filterRightLayout"
    >
      <div className="offcanvas-header border-bottom">
        <h5 className="modal-title">Filter</h5>
        <button
          type="button"
          className="btn-close d-none"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body">
        <div className="assignPopoHeight">
          <form className="AllFilter_Select" action="">
            {/* <input className="form-check-input" type="checkbox" id="allFilter" />
        <label className="allFilter" htmlFor="allFilter">
          All Filter <span>3 Select</span>
        </label> */}
          </form>
         {/*  <div className="selectSec pt-2 radioBtnActiveDe">
            <label className="form-label fw-semibold text-primary-light text-sm mb-8">Status</label>
            <div className="form-group AllFilter_Select pt-0" action="">
          <input
            className="form-check-input radio"
            type="checkbox"
            defaultValue={1}
            name="status"
            id="stactive"
            onClick={() => {
              setStatus((prevStatus) => {
                  return prevStatus === true ? undefined : true;
              });
          }}
          />
          <label htmlFor="stactive">Active</label>
        </div>
        <div className="form-group AllFilter_Select pt-0" action="">
          <input
            className="form-check-input radio"
            type="checkbox"
            defaultValue={0}
            name="status"
            id="stdeactive"
            defaultChecked=""
            onClick={() => {
              setStatus((prevStatus) => {
                  return prevStatus === false ? undefined : false;
              });
          }}
          />
          <label htmlFor="stdeactive">Deactive</label>
        </div> */}
            {/* <label className="C_radioBtn">
              Active
              <input type="radio" defaultChecked="checked" name="radio" />
              <span className="checkmark" />
            </label>
            <label className="C_radioBtn">
              Deactive
              <input type="radio" name="radio" />
              <span className="checkmark" />
            </label> 
          </div>*/}
          {/* <div className="selectSec pt-0">
            <label className="form-label fw-semibold text-primary-light text-sm mb-8">OAD</label>
            <div className="costSec">
              <div className="costinputSec">
                <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">₹</label>
                <input type="number" name="OAD" min={0} defaultValue="00" onChange={(e) => setOAD({ ...OAD, value: e.target.value })} />
              </div>
              <select className="slectIconsShow" onChange={(e) => setOAD({ ...OAD, type: e.target.value })}>
                <option value="Above">Above</option>
                <option value="Below">Below</option>
              </select>
            </div>
          </div> */}
          {/* <div className="selectSec pt-0">
            <label className="form-label fw-semibold text-primary-light text-sm mb-8">CMD</label>
            <div className="costSec">
              <div className="costinputSec">
                <label className="form-label fw-semibold text-primary-light text-md mb-0 me-4">₹</label>
                <input type="number" name="CMD" min={0} defaultValue="00" onChange={(e) => setCMD({ ...CMD, value: e.target.value })} />
              </div>
              <select className="slectIconsShow" onChange={(e) => setCMD({ ...CMD, type: e.target.value })}>
                <option value="Above">Below</option>
                <option value="Below">Above</option>
              </select>
            </div>
          </div> */}
        </div>
        <div>
          <div className="selectSec pt-0">
            <label className="form-label fw-semibold text-primary-light text-sm mb-8">Manager</label>
            <div className="costSec">
              <select className="slectIconsShow w-100" onChange={(e) => setManager(e.target.value)}>
                <option value="">Select</option>
                {uniqueManagers?.map((option) => (
                  <option key={option.managerId} value={option.managerId}>
                    {option.mangerName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

      </div>
      <div className="offcanvas-footer px-16">
        <button
          type="button"
          className="btn btn-primary radius-6 px-14 py-6 text-sm w-100 my-12" data-bs-dismiss="offcanvas" aria-label="Close"
          onClick={applyFilter}
        >
          Apply Filter
        </button>
      </div>
    </div>

  )
}
export default FilterPopup