import { round } from "lodash";
import React, { useEffect, useState } from "react";
import FormikTextarea from "src/components/formik/FormikTextarea";

const FinalSalary = ({ values }) => {
    // State variables for each field 
    const [leaveDeduction, setLeaveDeduction] = useState(values?.leaveDetails?.otherLeaveDeductionAmount);
    const [lagDeduction, setLagDeduction] = useState(0);

    const [taxDeduction, setTaxDeduction] = useState(values?.lagHourDetails?.taxDeduction);
    // const [extraDeduction, setExtraDeduction] = useState(values?.lagHourDetails?.extraDeduction);
    const [otherDeduction, setOtherDeduction] = useState(values?.lagHourDetails?.otherDeduction);
    const [pfDeduction, setPfDeduction] = useState(values?.lagHourDetails?.pfDeduction);

    const [totalEncashment, setTotalEncashment] = useState(0.0);
    const [extraDeductionCost, setExtraDeductionCost] = useState(0);
    const [remarks, setRemarks] = useState("");

    // Update final salary whenever deductions or encashment change
    //  useEffect(() => {
    //     setFinalSalary(salary - leaveDeduction - lagDeduction - taxDeduction - otherDeduction -  pfDeduction);
    // }, [salary, leaveDeduction, lagDeduction, totalEncashment, taxDeduction, otherDeduction,  pfDeduction,]);
    useEffect(() => {
        const totalLeaveDeductionAmount = Number(values.leaveDetails?.totalLeaveDeductionAmount);
        const taxDeduction = Number(values?.lagHourDetails?.taxDeduction);
        const otherDeduction = Number(values?.lagHourDetails?.otherDeduction);
        const pfDeduction = Number(values?.lagHourDetails?.pfDeduction);
        const extraDeduction = Number(values?.lagHourDetails?.extraDeduction || 0);
        const lagHoursAmount = Number(values?.lagHourDetails?.lagHoursAmount || 0);
        const productionLagDayAmount = Number(values?.lagHourDetails?.productionLagDayAmount || 0);
        setLagDeduction(lagHoursAmount + productionLagDayAmount)
        const day_salary = Number(values?.leaveDetails?.daySalary || 0)
        const extraDeductionAmount = Number(extraDeduction * day_salary);
        values.extraDeductionAmount = extraDeductionAmount.toFixed(2);
        setExtraDeductionCost(round(values.extraDeductionAmount))
        if (values.lagHourDetails) {
            values.lagHourDetails.totalDeduction = Math.round(
                totalLeaveDeductionAmount + taxDeduction + otherDeduction + pfDeduction + extraDeductionAmount + lagHoursAmount + productionLagDayAmount
            );;
        }
        let totalEnchashMentVal = 0;
        if (values.encashmentDetails) {
            const totalEncashment = Number((
                Number(values.encashmentDetails.leaveEncashmentAmount) +
                Number(values.encashmentDetails.incentivesAmount) +
                Number(values.encashmentDetails.previousMonthAmount) +
                Number(values.encashmentDetails.performanceBonus) +
                Number(values.encashmentDetails.increment)
            ).toFixed(2));
            setTotalEncashment(totalEncashment);
            totalEnchashMentVal = totalEncashment;
        }
        // Ensure `values.monthlySalary` and all deductions are numbers
        const finalSalary = (
            Number(values.monthlySalary || 0) + totalEnchashMentVal - (Number(values?.lagHourDetails?.totalDeduction || 0))
        );
        values.netSalary = finalSalary.toFixed(2);
    }, [values])

    return (
        <div className="setbdn100 mt-24">
            <h4 className="card-title">Final Salary</h4>
            <div className="radius-10 border p-16">
                <div className="align-items-center d-flex justify-content-between py-8 mt-16 border-bottom">
                    <label className="form-label mb-0 text-xl fw-bold">Salary</label>
                    <div className="d-flex gap-2 align-items-center text-xl fw-bold">
                        ₹{values?.monthlySalary?.toFixed(2)}
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Leave Deduction</label>
                    <div className={`textAmunt ${values?.leaveDetails?.otherLeaveDeductionAmount < 0 ? "text-red" : ""}`}>
                        {values?.leaveDetails?.otherLeaveDeductionAmount < 0 ? `-₹${Math.abs(values?.leaveDetails?.otherLeaveDeductionAmount)}` : `₹${values?.leaveDetails?.otherLeaveDeductionAmount}`}
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">PF Deduction</label>
                    <div className={`textAmunt ${values?.lagHourDetails?.pfDeduction < 0 ? "text-red" : ""}`}>
                        {values?.lagHourDetails?.pfDeduction < 0 ? `-₹${Math.abs(values?.lagHourDetails?.pfDeduction)}` : `₹${values?.lagHourDetails?.pfDeduction}`}
                    </div>
                </div>
                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Tax Deduction</label>
                    <div className={`textAmunt ${values?.lagHourDetails?.taxDeduction < 0 ? "text-red" : ""}`}>
                        {values?.lagHourDetails?.taxDeduction < 0 ? `-₹${Math.abs(values?.lagHourDetails?.taxDeduction)}` : `₹${values?.lagHourDetails?.taxDeduction}`}
                    </div>
                </div>
                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Other Deduction</label>
                    <div className={`textAmunt ${values?.lagHourDetails?.otherDeduction < 0 ? "text-red" : ""}`}>
                        {values?.lagHourDetails?.otherDeduction < 0 ? `-₹${Math.abs(values?.lagHourDetails?.otherDeduction)}` : `₹${values?.lagHourDetails?.otherDeduction}`}
                    </div>
                </div>


                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Lag Deduction</label>
                    <div className={`textAmunt ${lagDeduction < 0 ? "text-red" : ""}`}>
                        {lagDeduction < 0 ? `-₹${Math.abs(lagDeduction)}` : `₹${lagDeduction}`}
                    </div>
                </div>
                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Extra Deduction</label>
                    <div className={`textAmunt ${extraDeductionCost < 0 ? "text-red" : ""}`}>
                        {extraDeductionCost < 0 ? `-₹${Math.abs(extraDeductionCost)}` : `₹${extraDeductionCost}`}
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8">
                    <label className="form-label mb-0">Total Encashment</label>
                    <div className="textAmunt">
                        ₹{totalEncashment}
                    </div>
                </div>

                <div className="align-items-center d-flex justify-content-between py-8 py-16 px-16 border-top border-bottom mt-16">
                    <label className="form-label mb-0 text-dblue text-xxl">Final Salary</label>
                    <span className="text-xxl fw-bold text-dblue">
                        ₹{values?.netSalary}
                    </span>
                </div>

                <div className="mt-16">
                    <label className="fw-bold mb-2">Remarks</label>
                    {/* <textarea
                        className="form-control"
                        placeholder="Remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    ></textarea> */}
                    <FormikTextarea
                        name="remarks"
                        className="form-control"
                        rows={4}
                        cols={50}
                        placeholder="Enter remarks"
                        defaultValue={""}
                    />
                    <button
                        type="submit"
                        className="btn btn-primary-600 w-100 mt-16"
                        onClick={() => console.log("Form submitted with remarks:", remarks)}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FinalSalary;
