import React, { useState, useEffect, useRef } from "react";

const EffortEstimateManage = () => {

    const tableRef = useRef(null);
    const scrollBarRef = useRef(null);
    const thumbRef = useRef(null);
    const [thumbWidth, setThumbWidth] = useState(50);
    const [thumbLeft, setThumbLeft] = useState(0);

    useEffect(() => {
        updateThumbSize();
        window.addEventListener("resize", updateThumbSize);
        return () => window.removeEventListener("resize", updateThumbSize);
    }, []);

    const updateThumbSize = () => {
        const table = tableRef.current;
        const scrollbar = scrollBarRef.current;
        if (table && scrollbar) {
            const visibleRatio = table.clientWidth / table.scrollWidth;
            setThumbWidth(visibleRatio * scrollbar.clientWidth);
            setThumbLeft((table.scrollLeft / table.scrollWidth) * scrollbar.clientWidth);
        }
    };

    const handleTableScroll = () => {
        const table = tableRef.current;
        const scrollbar = scrollBarRef.current;
        if (table && scrollbar) {
            const scrollRatio = table.scrollLeft / (table.scrollWidth - table.clientWidth);
            setThumbLeft(scrollRatio * (scrollbar.clientWidth - thumbWidth));
        }
    };

    const handleThumbDrag = (event) => {
        event.preventDefault();
        const table = tableRef.current;
        const scrollbar = scrollBarRef.current;

        if (!table || !scrollbar) return;

        const startX = event.clientX;
        const startLeft = thumbLeft;

        const onMouseMove = (moveEvent) => {
            const deltaX = moveEvent.clientX - startX;
            const newLeft = Math.min(Math.max(startLeft + deltaX, 0), scrollbar.clientWidth - thumbWidth);
            const scrollRatio = newLeft / (scrollbar.clientWidth - thumbWidth);
            table.scrollLeft = scrollRatio * (table.scrollWidth - table.clientWidth);
            setThumbLeft(newLeft);
        };

        const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };














    const [modules, setModules] = useState([
        {
            id: 1, name: "Module 1", isEditing: false, tasks: [
                { id: 1, name: "Task 1", isEditing: false, cost: 0 },
                { id: 2, name: "Task 2", isEditing: false, cost: 0 },
                { id: 3, name: "Task 3", isEditing: false, cost: 0 },
                { id: 4, name: "Task 4", isEditing: false, cost: 0 }
            ]
        },
    ]);

    const [designations, setDesignations] = useState([
        { id: 1, name: "Designation 1", isEditing: false },
        { id: 2, name: "Designation 2", isEditing: false },
        { id: 3, name: "Designation 3", isEditing: false },
        { id: 4, name: "Designation 4", isEditing: false },
        { id: 5, name: "Designation 5", isEditing: false }
    ]);
    const handleAddModule = () => {
        // Add the new module
        setModules([
            ...modules,
            {
                id: Date.now(),
                name: `Module ${modules.length + 1}`,
                tasks: [
                    { id: Date.now() + 1, name: "Task 1", isEditing: false },
                    { id: Date.now() + 2, name: "Task 2", isEditing: false },
                    { id: Date.now() + 3, name: "Task 3", isEditing: false },
                    { id: Date.now() + 4, name: "Task 4", isEditing: false },
                ],
            },
        ]);

        // Scroll to the bottom after the module is added
        setTimeout(() => {
            const tableContainer = tableRef.current;
            if (tableContainer) {
                tableContainer.scrollTo({ top: tableContainer.scrollHeight, behavior: "smooth" });
            }
        }, 0); // Use a minimal delay to ensure DOM updates
    };

    const handleAddTask = (moduleId) => {
        setModules(modules.map(module =>
            module.id === moduleId
                ? {
                    ...module,
                    tasks: [...module.tasks, { id: Date.now(), name: `Task ${module.tasks.length + 1}`, isEditing: false }]
                }
                : module
        ));
    };

    const handleEditTask = (moduleId, taskId, newName) => {
        setModules(modules.map(m => m.id === moduleId ? {
            ...m,
            tasks: m.tasks.map(t => t.id === taskId ? { ...t, name: newName } : t)
        } : m));
    };

    const toggleEditTask = (moduleId, taskId) => {
        setModules(modules.map(m => m.id === moduleId ? {
            ...m,
            tasks: m.tasks.map(t => t.id === taskId ? { ...t, isEditing: !t.isEditing } : t)
        } : m));
    };

    const handleRemoveTask = (moduleId, taskId) => {
        setModules(modules.map(m => m.id === moduleId ? { ...m, tasks: m.tasks.filter(t => t.id !== taskId) } : m));
    };

    // const handleAddDesignation = () => {
    //     setDesignations([...designations, { id: Date.now(), name: `Designation ${designations.length + 1}`, isEditing: false }]);
    // };
    const handleAddDesignation = () => {
        setDesignations([...designations, { id: Date.now(), name: `Designation ${designations.length + 1}`, isEditing: false }]);

        // Wait for DOM update
        setTimeout(() => {
            const tableContainer = document.querySelector(".table-responsive");
            if (tableContainer) {
                tableContainer.scrollTo({ left: tableContainer.scrollWidth, behavior: "smooth" });
            }
            updateThumbSize(); // <-- Scrollbar size update karega
        }, 100);
    };



    const handleEditDesignation = (id, newName) => {
        setDesignations(designations.map(d => d.id === id ? { ...d, name: newName } : d));
    };

    // const handleRemoveDesignation = (id) => {
    //     setDesignations(designations.filter(d => d.id !== id));
    // };
    const handleRemoveDesignation = (id) => {
        setDesignations((prevDesignations) => prevDesignations.filter(d => d.id !== id));

        // Wait for DOM update
        setTimeout(() => {
            updateThumbSize(); // <-- Scrollbar size update karega
        }, 100);
    };


    const toggleEditDesignation = (id) => {
        setDesignations(designations.map(d => d.id === id ? { ...d, isEditing: !d.isEditing } : d));
    };


    const handleEditCost = (moduleId, taskId, newCost) => {
        setModules(modules.map(m =>
            m.id === moduleId
                ? {
                    ...m,
                    tasks: m.tasks.map(t =>
                        t.id === taskId ? { ...t, cost: Number(newCost) } : t
                    )
                }
                : m
        ));
    };



    const handleEditModule = (moduleId, newName) => {
        setModules(modules.map(m => m.id === moduleId ? { ...m, name: newName } : m));
    };

    const toggleEditModule = (moduleId) => {
        setModules(modules.map(m => m.id === moduleId ? { ...m, isEditing: !m.isEditing } : m));
    };

    const handleRemoveModule = (moduleId) => {
        setModules(modules.filter(m => m.id !== moduleId));
    };

    return (
        <div className="p-6"> 
                    <div className="d-flex flex-wrap justify-content-between align-items-center py-16">
                        <h1 className="text-xl font-bold text-purple-600 mb-0">Effort Estimate Manage</h1>  <button onClick={handleAddDesignation} className="mt-4 ml-4 px-4 py-6 bg-green-600 rounded-lg addMoreBtnsDesignation ">+ Add more Designation</button>
                    </div>
                    <div className="table-responsive ETable pb-0" ref={tableRef} onScroll={handleTableScroll}>
                        <table className="table basic-table mb-0 pdingSet">
                            <thead className="shadow-none">
                                <tr className="bg-gray-100">
                                    <th className="align-middle dname01">Module</th>
                                    {designations.map((d) => (
    <th key={d.id} className="text-center align-middle Hovnetup01">
        <div className="d-flex align-items-center gap-12 text-nowrap butnetup01 justify-content-center ThTopDeltSet">
            {/* First Dropdown - Always Visible */}
           <div className="d-flex justify-content-center flex-column gap-6 handleESetTion">
           <select
                value={d.name}
                onChange={(e) => handleEditDesignation(d.id, e.target.value)}
                className="form-control border p-1"
            >
                <option value="">Designation</option>
                <option value="Manager">Manager</option>
                <option value="Developer">Developer</option>
                <option value="HR">HR</option>
            </select>

            {/* Second Dropdown - Shows Based on First Dropdown Selection */}
            {d.name && d.name !== "Select Designation" && (
                <select className="form-control border p-1 ms-2">
                    <option value="">Resource</option>
                    {d.name === "Manager" && (
                        <>
                            <option value="Senior Manager">Senior Manager</option>
                            <option value="Assistant Manager">Assistant Manager</option>
                        </>
                    )}
                    {d.name === "Developer" && (
                        <>
                            <option value="Frontend Developer">Frontend Developer</option>
                            <option value="Backend Developer">Backend Developer</option>
                        </>
                    )}
                    {d.name === "HR" && (
                        <>
                            <option value="Recruiter">Recruiter</option>
                            <option value="HR Executive">HR Executive</option>
                        </>
                    )}
                </select>
            )}
           </div>

            {/* Delete Button */}
            <button onClick={() => handleRemoveDesignation(d.id)} className="text-red-600 Btndpst01">
                <i className="mdi mdi-delete mdi-18px"></i>
            </button>
        </div>
    </th>
))}


                                    <th className="align-middle text-center dname01">Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modules.map((module) => (
                                    <React.Fragment key={module.id}>
                                        <tr className="bg-gray-50">
                                            <td colSpan={3} className="font-semibold flex items-center align-middle Hovnetup01 modalStky01">
                                                <div className="d-flex align-items-center gap-12 modalHeading01 butnetup01">
                                                    {module.isEditing ? (
                                                        <input
                                                            type="text"
                                                            value={module.name}
                                                            onChange={(e) => handleEditModule(module.id, e.target.value)}
                                                            className="form-control"
                                                            onKeyDown={(e) => e.key === "Enter" && toggleEditModule(module.id)}
                                                            onBlur={() => toggleEditModule(module.id)}
                                                        />
                                                    ) : (
                                                        <span className="">{module.name}</span>
                                                    )}
                                                    {!module.isEditing && (
                                                        <button onClick={() => toggleEditModule(module.id)} className="text-blue-600 Btndpst01"><i className="mdi mdi-pencil mdi-18px"></i></button>
                                                    )}
                                                    {module.isEditing && (
                                                        <button onClick={() => toggleEditModule(module.id)} className="text-green-600 Btndpst01">✔</button>
                                                    )}
                                                    <button onClick={() => handleRemoveModule(module.id)} className="text-red-600 Btndpst01"><i className="mdi mdi-delete mdi-18px"></i></button>

                                                </div>
                                            </td>
                                        </tr>
                                        {module.tasks.map((task, index) => {
                                            const isFirstTask = index === 0; // First task
                                            const isLastTask = index === module.tasks.length - 1; // Last task

                                            return (
                                                <tr key={task.id} className={`taskStpes01 ${isFirstTask ? "first-task" : ""} ${isLastTask ? "last-task" : ""}`}>
                                                    <td className="align-middle Hovnetup01">
                                                        <div className="d-flex align-items-center gap-12 butnetup01">
                                                            {task.isEditing ? (
                                                                <input
                                                                    type="text"
                                                                    value={task.name}
                                                                    onChange={(e) => handleEditTask(module.id, task.id, e.target.value)}
                                                                    className="w-full border p-1"
                                                                    onKeyDown={(e) => e.key === "Enter" && toggleEditTask(module.id, task.id)}
                                                                    onBlur={() => toggleEditTask(module.id, task.id)}
                                                                />
                                                            ) : (
                                                                <span>{task.name}</span>
                                                            )}
                                                            {!task.isEditing && (
                                                                <button onClick={() => toggleEditTask(module.id, task.id)} className="text-blue-600 Btndpst01">
                                                                    <i className="mdi mdi-pencil mdi-18px"></i>
                                                                </button>
                                                            )}
                                                            {task.isEditing && (
                                                                <button onClick={() => toggleEditTask(module.id, task.id)} className="text-green-600 Btndpst01">✔</button>
                                                            )}
                                                            <button onClick={() => handleRemoveTask(module.id, task.id)} className="text-red-600 Btndpst01">
                                                                <i className="mdi mdi-delete mdi-18px"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    {designations.map((d) => (
                                                        <td key={d.id} className="text-center align-middle">
                                                            <input type="number" className="form-control text-center w-set-input mx-auto" defaultValue={0} />
                                                        </td>
                                                    ))}
                                                    <td className="text-center align-middle">
                                                        <input
                                                            type="number"
                                                            value={task.cost || 0}
                                                            onChange={(e) => handleEditCost(module.id, task.id, e.target.value)}
                                                            className="form-control text-center w-set-input mx-auto"
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}

                                        <tr className="admortask">
                                            <td colSpan={designations.length + 2} className="text-blue-600 cursor-pointer addMoreBtnsDesignation align-middle pb-10" onClick={() => handleAddTask(module.id)}>
                                                <span className="admrtask">+ Add more task</span>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr className="bg-gray-100 border-0">
                                    <th className="font-bold align-middle text-nowrap py-24 border-0">Total Effort in mins</th>
                                    {designations.map((_, index) => (
                                        <th key={index} className="font-bold text-center align-middle py-24 border-0">0</th>
                                    ))}
                                    <th className="font-bold text-center align-middle py-24 border-0">0</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="custom-scroll" ref={scrollBarRef}>
                        <div
                            className="custom-scroll-thumb"
                            ref={thumbRef}
                            style={{ width: `${thumbWidth}px`, left: `${thumbLeft}px` }}
                            onMouseDown={handleThumbDrag}
                        ></div>
                    </div> 
                    <div className="AddNewModuleDv text-center mt-32"><button onClick={handleAddModule} className="mt-4 bg-blue-600 rounded-lg BtnAddNewModule">+ Add New Module</button></div> 
        </div>
    );
};

export default EffortEstimateManage